import { useForm } from "react-hook-form";
import { Text, ToastMessage, Winicon } from "wini-web-components";
import { CKEditorForm, ImportFileForm, TextFieldForm } from "../../../project-component/component-form";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { ProjectController } from "./controller";
import { randomGID, Ultis } from "../../../Utils";
import { BaseDA, imgFileTypes } from "../../../da/baseDA";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import ConfigApi, { ConfigDomain } from "../../../da/configApi";

export default function CreateProject() {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const _pid = ConfigDomain.includes(window.location.hostname) ? searchParams.get('id') : Ultis.getCookie("pid")
    const user = useSelector((store) => store.customer.data)
    const methods = useForm({ shouldFocusError: false })
    const navigate = useNavigate()

    const onSubmit = async (ev) => {
        if (_pid) {
            if (ev.LogoId instanceof File) {
                const uploadRes = await BaseDA.uploadFiles([ev.LogoId])
                if (uploadRes) ev.LogoId = uploadRes[0].id
            } else if (typeof ev.LogoId !== "string") ev.LogoId = ev.LogoId.id
            const res = await ProjectController.edit([ev])
            if (res.code === 200) {
                ToastMessage.success("Save project successfully!")
                navigate(ConfigDomain.includes(window.location.hostname) ? '/' : -1, { replace: true })
            } else ToastMessage.errors(res.message)
        } else {
            const newProject = {
                ...ev,
                Id: randomGID(),
                CustomerId: user?.Id,
                DateCreated: Date.now(),
            }
            const uploadRes = await BaseDA.uploadFiles([ev.LogoId])
            if (uploadRes) newProject.LogoId = uploadRes[0].id
            const res = await ProjectController.add([newProject])
            if (res.code === 200) navigate('/', { replace: true })
            else ToastMessage.errors(res.message)
        }
    }

    useEffect(() => {
        if (_pid) {
            ProjectController.getByIds([_pid]).then(res => {
                if (res.code === 200) {
                    Object.keys(res.data[0]).forEach(k => {
                        if (k === "LogoId") {
                            BaseDA.getFilesInfor(res.data.map(e => e[k])).then(files => {
                                if (files) methods.setValue(k, { ...files[0], type: files[0].type ? files[0].type : imgFileTypes.some(t => files[0].name.toLowerCase().endsWith(t)) ? "image" : "file", url: ConfigApi.imgUrlId + files[0].id })
                            })
                        } else methods.setValue(k, res.data[0][k])
                    })
                }
            })
        }
    }, [])

    return <div className="col view" style={{ alignItems: 'center', paddingTop: '3.2rem', overflow: 'hidden auto' }}>
        <div className="col" style={{ gap: '3.2rem' }}>
            <Text className="heading-3">{_pid ? "Edit project" : "New project"}</Text>
            <TextFieldForm
                required
                className={'row'}
                style={{ width: '68rem', gap: '0.4rem' }}
                labelStyle={{ width: "16rem" }}
                errors={methods.formState.errors}
                register={methods.register}
                name={'Name'}
                label={'Name'}
            />
            <TextFieldForm
                required
                className={'row'}
                style={{ width: '68rem', gap: '0.4rem' }}
                labelStyle={{ width: "16rem" }}
                errors={methods.formState.errors}
                register={methods.register}
                name={'Sologan'}
                label={'Sologan'}
            />
            {ConfigDomain.includes(window.location.hostname) ? <TextFieldForm
                required
                className={'row'}
                style={{ width: '68rem', gap: '0.4rem' }}
                labelStyle={{ width: "16rem" }}
                errors={methods.formState.errors}
                register={methods.register}
                name={'Domain'}
                label={'Domain'}
            /> : null}
            <ImportFileForm
                required
                control={methods.control}
                className={'row'}
                style={{ width: '68rem', gap: '0.4rem' }}
                labelStyle={{ width: "16rem" }}
                errors={methods.formState.errors}
                name="LogoId"
                label="Logo"
                title="Upload logo"
                direction="column"
                allowType={["image/svg+xml"]}
            />
            <CKEditorForm
                className={'row'}
                required
                labelStyle={{ width: "16rem" }}
                style={{ width: '68rem', gap: '0.4rem', alignItems: "start" }}
                errors={methods.formState.errors}
                control={methods.control}
                name={'Description'}
                label={'Description'}
                placeholder={'Write something abpout this project: "This is a project,..."'}
            />
            <div className={'row'} style={{ justifyContent: 'end' }}>
                <button type="button" className={`row ${methods.watch('Name') && methods.watch('Description') ? 'button-primary' : 'button-disabled'}`} onClick={methods.handleSubmit(onSubmit)}>
                    <Text className="button-text-3">Save</Text>
                </button>
            </div>
        </div>
        {ConfigDomain.includes(window.location.hostname) && <NavLink to={'/'} className={'icon-button32 row'} style={{ position: 'fixed', right: '6%' }}>
            <Winicon src={"fill/user interface/c-remove"} />
        </NavLink>}
    </div>
}