import { useDispatch } from "react-redux"
import { PageActions } from "../reducer"
import { IconProperties } from "./icon/icon"
import { AppearanceProperties } from "./appearance/appearance"

export default function SettingIcon({ selected }) {
    const dispatch = useDispatch()
    const _iframe = document.getElementById("iframe-render-page-view")

    const props = {
        docBody: _iframe.contentWindow.document.body,
        selected: selected,
        onChange: (newValue) => {
            if (newValue) PageActions.updateLayerAll(dispatch, [newValue])
        }
    }

    return selected && <div className='col'>
        <IconProperties {...props} />
        <AppearanceProperties {...props} />
    </div>
}