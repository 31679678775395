import { configureStore } from '@reduxjs/toolkit'
import moduleReducer from './screen/module/reducer'
import customerReducer from './screen/wini/customer/reducer'
import projectReducer from './screen/wini/project/reducer'
import pageReducer from './screen/module/page/reducer'
import designTokenReducer from './screen/wini/designToken/reducer'

export const store = configureStore({
    reducer: {
        // account: accountReducer,
        module: moduleReducer,
        customer: customerReducer,
        project: projectReducer,
        page: pageReducer,
        designToken: designTokenReducer,
    },
})

export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch