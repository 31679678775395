import styles from './view.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { forwardRef, useRef, useState, React, useEffect } from 'react'
import { Button, closePopup, Popup, Select1, showPopup, Text, TextField, ToastMessage, Winicon } from 'wini-web-components'
import { useFieldArray, useForm } from "react-hook-form"
import { randomGID, Ultis } from "../../../Utils"
import { ColorPicker } from '../../module/page/componentProperties/functions/funtions'
import { DesignTokenActions } from './reducer'
import { DesignTokenType } from './da'
import { TableController } from '../table/controller'
import { TextFieldForm } from '../../../project-component/component-form'
import ConfigApi from '../../../da/configApi'

const PopupDesignToken = forwardRef(function PopupDesignToken(data, ref) {
    const designToken = useSelector((store) => store.designToken?.data);
    const dispatch = useDispatch();
    const popupRef = useRef()

    useEffect(() => {
        DesignTokenActions.getData(dispatch)
    }, [])

    const [group, setGroup] = useState({ Id: "all", Name: "" });

    const showCreateVariable = (event) => {
        const onClick = (ev) => {
            const valueString = JSON.stringify(ev.Value);
            const _newItem = {
                Id: randomGID(),
                Name: 'Defaut',
                ...ev,
                Sort: group.Id === "all" ? designToken.length : designToken.filter(e => e.ParentId === group.Id).length,
                Value: valueString,
                DateCreated: Date.now()
            }
            DesignTokenActions.add(dispatch, { data: [_newItem] })
            closePopup(popupRef)
        }
        const _rect = event.target.closest("button").getBoundingClientRect();
        showPopup({
            ref: popupRef,
            clickOverlayClosePopup: true,
            style: { position: 'absolute', bottom: `calc(100dvh - ${_rect.y}px)`, left: _rect.x, width: _rect.width },
            body: <div className='col popup-actions' style={{ borderRadius: 8 }}>
                <button type='button' className='row' onClick={() => { onClick({ Type: DesignTokenType.color, Value: { lightMode: '#FFFFFFFF', darkMode: '#000000FF' }, ParentId: group.Id }) }} >
                    <Winicon src='outline/development/paint-brush' size={"1.2rem"} />
                    <Text className='label-4'>Color</Text>
                </button>
                <button type='button' className='row' onClick={() => { onClick({ Type: DesignTokenType.string, Value: { lightMode: '', darkMode: '' }, ParentId: group.Id }) }} >
                    <Winicon src='outline/text/text' size={"1.2rem"} />
                    <Text className='label-4'>String</Text>
                </button>
                {/* <button type='button' className='row' onClick={() => { onClick({ Type: DesignTokenType.icon, Value: { lightMode: 'fill/development/icon', darkMode: 'fill/development/icon' }, ParentId: group.Id }) }}>
                    <Winicon src='fill/development/icon' size={"1.2rem"} />
                    <Text className='label-4'>Icon</Text>
                </button> */}
                <button type='button' className='row' onClick={() => { onClick({ Type: DesignTokenType.image, Value: { lightMode: 'https://file-mamager.wini.vn/Upload/2024/09/yuy_81f2.jpg', darkMode: 'https://file-mamager.wini.vn/Upload/2024/09/yuy_81f2.jpg' }, ParentId: group.Id }) }} >
                    <Winicon src='outline/multimedia/image' size={"1.2rem"} />
                    <Text className='label-4'>Image</Text>
                </button>
            </div>
        })
    }

    return <div className="col" style={{ flex: 1, width: '100%', height: '100%', backgroundColor: '#ffffff' }}>
        <Popup ref={popupRef} />
        <div className="row" style={{ flex: 1, height: '100%' }}>
            <SidebarLeft setGroup={setGroup} group={group} />
            <div className="col" style={{ width: '100%', flex: '1', height: '100%', paddingTop: "1rem", backgroundColor: "#EFEFF0" }}>
                <div className='col' style={{ flex: 1, height: '100%' }}>
                    <div className={`row ${styles['dynamic']}`} style={{ padding: "1rem 1.5rem", gap: "2.25rem", borderBottom: "var(--neutral-bolder-border)", backgroundColor: "var(--neutral-main-background-color)", height: "5.4rem", }}>
                        <div className='row' style={{ padding: "0rem 1rem", alignItems: 'center', justifyContent: "center", width: "100%", }}>
                            <Winicon src='outline/user interface/dock-left' size={"2rem"} />
                            <div style={{ flex: 1 }} />
                            <button type="button" className="row icon-button32" onClick={() => { closePopup(ref) }}><Winicon src={"fill/user interface/e-remove"} size="2.4rem" /></button>
                        </div>
                    </div>
                    <div className='col' style={{ overflow: 'auto', flex: 1, height: "100%" }}>
                        <div className={`row ${styles['dynamic']}`} style={{ backgroundColor: "#FFF", width: '100%', height: "5.4rem", borderBottom: "var(--neutral-bolder-border)" }}>
                            <div className='row' style={{ padding: "0 2rem", justifyContent: 'center', flex: 2, height: "100%", borderRight: "var(--neutral-bolder-border)" }}>
                                <Text className='heading-9'>Name</Text>
                            </div>
                            <div className='row' style={{ padding: "0 2rem", justifyContent: 'center', flex: 2, height: "100%", borderRight: "var(--neutral-bolder-border)" }}>
                                <Text className='heading-9'>Light Mode</Text>
                            </div>
                            <div className='row' style={{ padding: "0 2rem", justifyContent: 'center', flex: 2, height: "100%", borderRight: "var(--neutral-bolder-border)" }}>
                                <Text className='heading-9'>Dark Mode</Text>
                            </div>
                            <div className='row' style={{ justifyContent: 'center', flex: 1, height: "100%" }}>
                                <Text className='heading-9'>Action</Text>
                            </div>
                        </div>
                        <GroupData group={group} />
                    </div>
                    <div className='row' style={{ padding: "1rem 2.5rem", position: "sticky", bottom: "0px", backgroundColor: "#FFF" }}>
                        <Button
                            label='Create design token'
                            prefix={<Winicon src={"fill/user interface/e-add"} size={"1.4rem"} />}
                            onClick={showCreateVariable}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
})

const GroupData = ({ group }) => {
    const popupRef = useRef();
    const designToken = useSelector((store) => store.designToken?.data);
    const dispatch = useDispatch()
    const methods = useForm({ shouldFocusError: false, defaultValues: { dataByGroup: [] } })
    const arrayDataByGroup = useFieldArray({
        name: "dataByGroup",
        control: methods.control,
        keyName: undefined
    })

    const handleOnChange = async (ev) => {
        const valueString = JSON.stringify(ev.Value);
        delete ev.id;
        DesignTokenActions.edit(dispatch, { data: [{ ...ev, Value: valueString }] })
        arrayDataByGroup.update(arrayDataByGroup.fields.findIndex(e => e.Id === ev.Id), ev)
    }

    useEffect(() => {
        if (designToken?.length) {
            const _filter = designToken.filter(e => e.Type !== DesignTokenType.group && (group.Id.length !== 32 || e.ParentId === group.Id));
            methods.setValue('dataByGroup', _filter);
        }
    }, [designToken?.length, group?.Id])

    return <div className='col' style={{ width: '100%' }}>
        <Popup ref={popupRef} />
        {group.Id === "all" ?
            <>
                {(designToken ?? []).filter(e => e.Type === DesignTokenType.group).map((item) => {
                    return <div className='col' key={item.Id} style={{ width: "100%" }} >
                        <div className='col' style={{ width: '100%', padding: "1.2rem 2.5rem", borderBottom: "var(--neutral-bolder-border)" }}>
                            <Text className='label-3'>{item.Name}</Text>
                        </div>
                        {arrayDataByGroup.fields.filter(e => e.ParentId === item.Id).map((ev) => {
                            return <TitleColor
                                key={ev.Id}
                                item={methods.watch(`dataByGroup[${arrayDataByGroup.fields.findIndex(e => e.Id === ev.Id)}]`)}
                                handleOnChange={handleOnChange}
                            />
                        })}
                    </div>
                })}
            </>
            :
            <>
                {arrayDataByGroup.fields.filter(e => e.Type !== DesignTokenType.group).map((ev) => {
                    return <TitleColor
                        key={ev.Id}
                        item={methods.watch(`dataByGroup[${arrayDataByGroup.fields.findIndex(e => e.Id === ev.Id)}]`)}
                        handleOnChange={handleOnChange}
                    />
                })}
            </>
        }
    </div>
}

const SidebarLeft = ({ setGroup, group }) => {
    const refEditGroup = useRef();
    const designToken = useSelector((store) => store.designToken.data);
    const dispatch = useDispatch();
    const [editId, setEdit] = useState();
    const arrSearchSkin = [
        { id: 1, name: "Skin" },
        { id: 2, name: "Store Color" }
    ]

    const moreActions = async (ev) => {
        showPopup({
            ref: refEditGroup,
            clickOverlayClosePopup: true,
            style: { position: 'absolute', top: ev.clientY, left: ev.clientX },
            body: <div className='popup-actions col'>
                <button type='button' onClick={() => {
                    const _newItem = {
                        Id: randomGID(),
                        Name: 'new group',
                        Type: DesignTokenType.group,
                        Sort: Math.max(...designToken?.filter(e => e.Type === DesignTokenType.group).map(e => e.Sort), 0) + 1,
                        DateCreated: Date.now()
                    }
                    DesignTokenActions.add(dispatch, { data: [_newItem] })
                    setGroup({ Id: _newItem.Id, Name: _newItem.Name });
                    ToastMessage.success("Add group successfully!");
                    closePopup(refEditGroup)
                }}>
                    <Text className='button-text-3'>Add group</Text>
                </button>
                <button type='button' onClick={() => {
                    Ultis.setStorage("designToken-pid", ConfigApi.currentPid ?? Ultis.getCookie("pid"))
                    closePopup(refEditGroup)
                }}>
                    <Text className='button-text-3'>Copy all</Text>
                </button>
                {Ultis.getStorage("designToken-pid") && <button type='button' onClick={async () => {
                    const tmpPid = ConfigApi.currentPid
                    ConfigApi.currentPid = Ultis.getStorage("designToken-pid")
                    const _designTokenController = new TableController("designtoken")
                    ConfigApi.currentPid = tmpPid
                    const copyDesignTokens = await _designTokenController.getAll()
                    Ultis.clearStorage()
                    if (copyDesignTokens.code === 200 && copyDesignTokens.data.length) {
                        DesignTokenActions.add(dispatch, { data: copyDesignTokens.data })
                    }
                    closePopup(refEditGroup)
                }}>
                    <Text className='button-text-3'>Paste</Text>
                </button>}
                {designToken?.length ? <button type='button' onClick={() => {
                    DesignTokenActions.delete(dispatch, { data: designToken.map(e => e.Id) })
                    closePopup(refEditGroup)
                }}>
                    <Text className='button-text-3'>Delete all</Text>
                </button> : null}
            </div>
        })
    }

    const showEditGroup = (event, item) => {
        showPopup({
            ref: refEditGroup,
            clickOverlayClosePopup: true,
            style: { position: 'absolute', top: event.clientY, left: event.clientX },
            body: <div className='popup-actions col' >
                <button type='button' onClick={() => {
                    setEdit(item.Id)
                    closePopup(refEditGroup)
                }}>
                    <Text className='button-text-3'>Edit</Text>
                </button>
                <button type='button' onClick={() => {
                    Ultis.setCookie("copy-design-tokens", JSON.stringify(designToken.filter(e => e.Type !== DesignTokenType.group && e.ParentId === item.Id)), 0.0035)
                    closePopup(refEditGroup)
                }}>
                    <Text className='button-text-3'>Copy tokens</Text>
                </button>
                <button type='button' onClick={() => {
                    let copyDesignTokens = Ultis.getCookie("copy-design-tokens")
                    if (copyDesignTokens) {
                        copyDesignTokens = JSON.parse(copyDesignTokens)
                        DesignTokenActions.add(dispatch, {
                            data: copyDesignTokens.map(e => {
                                return { ...e, Id: randomGID(), ParentId: item.Id, DateCreated: Date.now() }
                            })
                        })
                    }
                    closePopup(refEditGroup)
                }}>
                    <Text className='button-text-3'>Paste</Text>
                </button>
                <button type='button' onClick={() => {
                    DesignTokenActions.delete(dispatch, { data: [item.Id] })
                    closePopup(refEditGroup)
                }}>
                    <Text className='button-text-3'>Delete</Text>
                </button>
            </div>
        })
    }

    return <div className="col" style={{ height: "100dvh", borderRight: "var(--neutral-bolder-border)" }}>
        <Popup ref={refEditGroup} />
        <div className='col' style={{ width: '100%', height: "100%" }}>
            <div className='row' style={{ padding: "2rem", gap: "2rem", borderBottom: "var(--neutral-bolder-border)", backgroundColor: "var(--neutral-main-background-color)", height: '6.4rem' }}>
                <div style={{ flex: 1 }}>
                    <Select1 placeholder='Skin' style={{ backgroundColor: 'var(--neutral-absolute-background-color)' }} options={arrSearchSkin} />
                </div>
                <Winicon src='fill/text/menu-dots' size={"1.6rem"} onClick={moreActions} />
            </div>
            <div className={`row ${styles['groupTile']} ${group?.Id === "all" ? styles["selected"] : ""}`} style={{ borderBottom: "var(--neutral-bolder-border)", height: "5.4rem" }}>
                <Text className='body-3' style={{ flex: 1 }} onClick={() => { setGroup({ Id: "all", Name: "" }) }}>All variables</Text>
                <Text className='body-3'>{designToken.filter(e => e.Type !== DesignTokenType.group).length}</Text>
            </div>
            <div className='col' style={{ height: "100%", flex: 1, overflow: 'hidden auto', scrollbarWidth: "none" }}>
                {designToken?.filter(e => e.Type === DesignTokenType.group)?.map((item) => {
                    return <div
                        key={item.Id}
                        className={`${styles['groupTile']} row ${group?.Id === item.Id ? styles["selected"] : ""}`}
                        style={{ padding: "0.8rem 2rem", order: item.Sort }}
                        onContextMenu={(event) => {
                            event.preventDefault()
                            showEditGroup(event, item)
                        }}
                        onClick={() => { setGroup({ Id: item.Id, Name: item.Name }) }}
                    >
                        {editId === item.Id ? <TextField
                            autoFocus
                            className='label-4'
                            style={{ width: '100%', padding: '0.6rem 0.4rem' }}
                            onComplete={(ev) => { ev.target.blur() }}
                            defaultValue={item.Name}
                            onBlur={(ev) => {
                                delete ev.id
                                DesignTokenActions.edit(dispatch, { data: [{ ...item, Name: ev.target.value.trim() }] })
                                setEdit(undefined)
                            }}
                        /> : <Text className='label-4' style={{ padding: '0.8rem 0', flex: 1 }}>
                            {item.Name}
                        </Text>}
                        <Text className='label-4'>
                            {designToken.filter(e => e.Type !== DesignTokenType.group && e.ParentId === item.Id).length}
                        </Text>
                    </div>
                })}
            </div>
        </div>
    </div>
}

const TitleColor = ({ item, handleOnChange }) => {
    const methods = useForm({ shouldFocusError: false })
    const dispatch = useDispatch()

    useEffect(() => {
        Object.keys(item).forEach(p => {
            methods.setValue(p, item[p])
        })
        if (item.Type === "color") {
            methods.setValue(`valueLight`, item.Value.lightMode.substring(0, 7))
            methods.setValue(`valueDark`, item.Value.darkMode.substring(0, 7))
            methods.setValue(`opacityValueLight`, Ultis.hexToPercent(item.Value.lightMode.substring(7)))
            methods.setValue(`opacityValueDark`, Ultis.hexToPercent(item.Value.darkMode.substring(7)))
        }
    }, [item])

    return <div className='row' style={{ backgroundColor: "var(--neutral-absolute-background-color)", borderBottom: "var(--neutral-bolder-border)", width: '100%', order: item.Sort }}>
        <div className='row' style={{ justifyContent: 'center', padding: "0rem 2rem", borderRight: "var(--neutral-bolder-border)", flex: 2, height: "6.3rem" }}>
            <div className='row' style={{ padding: "0rem 1rem ", gap: '1rem', width: "100%" }}>
                <TextFieldForm
                    className='body-3'
                    register={methods.register}
                    errors={methods.formState.errors}
                    name='Name'
                    onFocus={(ev) => { ev.target.select() }}
                    prefix={<Winicon src='outline/development/paint-brush' size={"1.4rem"} />}
                    style={{ width: '100%' }}
                    onComplete={(ev) => { ev.target.blur() }}
                    onBlur={(ev) => { handleOnChange({ ...item, Name: ev.target.value.trim() }) }}
                />
            </div>
        </div>
        <div className='row' style={{ justifyContent: "center", padding: "0rem 2rem", borderRight: "var(--neutral-bolder-border)", flex: 2, height: "6.3rem" }}>
            {item.Type === DesignTokenType.color ?
                <ColorPicker
                    colorName={`valueLight`}
                    style={{ width: "100%" }}
                    colorOpcaityName={`opacityValueLight`}
                    register={methods.register}
                    colorValue={methods.watch(`Value`)?.lightMode}
                    onChange={({ colorValue }) => {
                        const newItem = { ...item, Value: { ...item.Value, lightMode: colorValue } }
                        handleOnChange(newItem)
                    }}
                /> :
                <TextFieldForm
                    style={{ width: '100%' }}
                    name={`Value.lightMode`}
                    errors={methods.formState.errors}
                    register={methods.register}
                    onBlur={(ev) => {
                        let _vl = ev.target.value.trim()
                        if (_vl.length) {
                            const newItem = { ...item, Value: { ...item.Value, lightMode: _vl } }
                            handleOnChange(newItem)
                        }
                    }}
                />
            }
        </div>
        <div className='row' style={{ justifyContent: "center", padding: "0rem 2rem", borderRight: 'var(--neutral-bolder-border)', flex: 2, height: "6.3rem" }}>
            {item.Type === DesignTokenType.color ?
                <ColorPicker
                    style={{ width: "100%" }}
                    colorName={`valueDark`}
                    colorOpcaityName={`opacityValueDark`}
                    register={methods.register}
                    colorValue={methods.watch(`Value`)?.darkMode}
                    onChange={({ colorValue }) => {
                        const newItem = { ...item, Value: { ...item.Value, darkMode: colorValue } }
                        handleOnChange(newItem)
                    }}
                /> :
                <TextFieldForm
                    style={{ width: '100%' }}
                    name={`Value.darkMode`}
                    errors={methods.formState.errors}
                    register={methods.register}
                    onBlur={(ev) => {
                        let _vl = ev.target.value.trim()
                        if (_vl.length) {
                            const newItem = { ...item, Value: { ...item.Value, darkMode: _vl } }
                            handleOnChange(newItem)
                        }
                    }}
                />
            }
        </div>
        <div className='row' style={{ justifyContent: "center", flex: 1, height: "6.3rem" }}>
            <Winicon src='outline/user interface/trash-can' size={"2.4rem"} style={{ padding: "0.4rem 1.2rem" }} onClick={() => { DesignTokenActions.delete(dispatch, { data: [item.id ?? item.Id] }) }} />
        </div>
    </div>
}

export default PopupDesignToken