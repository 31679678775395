import { closePopup, Popup, showPopup, Text, Winicon } from "wini-web-components"
import { useSelector } from "react-redux"
import { useEffect, useRef, useState } from "react"
import { ChartCard } from "../../../project-component/cards"
import { TableController } from "../../wini/table/controller"
import { ComponentType } from "../../wini/table/da"
import RenderFormByType from "../manager/formByType"
import RenderCard from "../../wini/table/card/renderCard"
import { SettingDataController } from "../controller"
import { useLocation, useNavigate } from "react-router-dom"
import { ActionType, FilterType, TriggerType } from "../da"
import ConfigApi from "../../../da/configApi"
import { regexUrl } from "../config"

export default function RenderPageByLayers({ onDemo = false, listLayers, listLayout, showLayout = true }) {
    const { layers, layout } = useSelector((store) => store.page.data)
    const navigate = useNavigate()

    const renderPageView = (item, list = []) => {
        const children = list.filter(e => e.ParentId === item.Id)
        let _props = { ...item.Setting }
        if (onDemo) {
            if (!item.PageId) _props['layout-id'] = item.LayoutId ?? item.Id
            _props.id = item.Id
            _props.className = _props.className ? (_props.className + " layer-item") : "layer-item"
        } else if (_props.action?.length && Array.isArray(_props.action)) {
            Object.values(TriggerType).forEach(trigger => {
                const triggerActions = _props.action.filter(e => e.Type === trigger)
                switch (trigger) {
                    case TriggerType.click:
                        const _onClick = async (acts = []) => {
                            for (const [i, act] of acts.entries()) {
                                switch (act.Action) {
                                    case ActionType.navigate:
                                        if (act.To) {
                                            if (act.To.startsWith("http")) window.open(act.To, "_blank")
                                            else if (regexUrl.test(act.To)) navigate("/" + act.To)
                                            else {
                                                const pageTarget = act.To.indexOf("?") >= 0 ? act.To.slice(0, act.To.indexOf("?")) : act.To
                                                const pageController = new TableController("page")
                                                const res = await pageController.getByListId([pageTarget])
                                                if (res.code === 200 && res.data[0]) {
                                                    const pageData = res.data[0]
                                                    let url = act.To.replace(pageData.Id, pageData.Url)
                                                    const newSearchParams = new URLSearchParams()
                                                    const declareSearchParams = pageData.Params?.length ? JSON.parse(pageData.Params) : []
                                                    declareSearchParams.filter(e => e.Value?.length).forEach(e => newSearchParams.append(e.Key, e.Value))
                                                    const urlQueryParams = url.indexOf("?") >= 0 ? url.slice(url.indexOf("?") + 1) : ""
                                                    if (urlQueryParams.length) {
                                                        urlQueryParams.split("&").filter(e => declareSearchParams.some(v => v.Key === e.split("=")[0])).forEach(e => newSearchParams.append(e.split("=")[0], e.split("=")[1]))
                                                    }
                                                    url = url.indexOf("?") >= 0 ? url.slice(0, url.indexOf("?") + 1) : url
                                                    if (window.location.pathname.startsWith("/demoFullPage/")) {
                                                        newSearchParams.append("pid", ConfigApi.currentPid)
                                                        navigate("/demoFullPage" + (url.startsWith("/") ? url.slice(1) : url) + newSearchParams.toString())
                                                    } else navigate((url.startsWith("/") ? url.slice(1) : url) + newSearchParams.toString())
                                                }
                                            }
                                        }
                                        break;
                                    case ActionType.submit:
                                        const formElement = document.getElementById(act.To)
                                        const submitBtn = formElement?.querySelector(`:scope > button.submit-form`)
                                        const successBtn = formElement?.querySelector(`:scope > button.success-form`)
                                        if (submitBtn) {
                                            if (successBtn && triggerActions.slice(i + 1).length) successBtn.onclick = () => _onClick(triggerActions.slice(i + 1))
                                            submitBtn.click()
                                        }
                                        return;
                                    case ActionType.showPopup:
                                        const popupElement = document.getElementById(act.To)
                                        const openPopupBtn = popupElement?.querySelector(`:scope > button.open`)
                                        if (openPopupBtn) openPopupBtn.click()
                                        return;
                                    default:
                                        break;
                                }
                            }
                        }
                        if (triggerActions.length) {
                            _props.onClick = () => _onClick(triggerActions)
                            if (_props.style) _props.style = { ..._props.style, cursor: "pointer" }
                            else _props.style = { cursor: "pointer" }
                        }
                        break;
                    default:
                        break;
                }
            })
        }
        delete _props.action
        switch (item.Type) {
            case ComponentType.container:
                if (_props) _props['component-type'] = item.Type
                return <div key={item.Id} {..._props}>
                    {
                        item.Setting?.className?.includes("layout-body") ?
                            (listLayers ?? layers).filter(e => !e.ParentId).map(e => renderPageView(e, listLayers ?? layers)) :
                            children.map(e => renderPageView(e, list))
                    }
                </div>
            case ComponentType.text:
                return <Text key={item.Id} {..._props}>{item.Setting?.value ?? ""}</Text>
            case ComponentType.img:
                return <img key={item.Id} alt="" draggable={false} {..._props} />
            case ComponentType.chart:
                return <ChartById key={item.Id} {..._props} />
            case ComponentType.form:
                _props.id = item.Id
                return <FormById key={item.Id} {..._props} />
            case ComponentType.card:
                return <CardById key={item.Id} {..._props} />
            case ComponentType.icon:
                return <Winicon key={item.Id} {..._props} />
            case ComponentType.popup:
                _props.id = item.Id
                return <ActionPopup key={item.Id} {..._props} >
                    {
                        item.Setting?.className?.includes("layout-body") ?
                            (listLayers ?? layers).filter(e => !e.ParentId).map(e => renderPageView(e, listLayers ?? layers)) :
                            children.map(e => renderPageView(e, list))
                    }
                </ActionPopup>
            default:
                return <div key={item.Id} {..._props} />
        }
    }

    const returnView = () => {
        if (listLayout) return listLayout.filter(e => !e.ParentId).map(e => renderPageView(e, listLayout))
        else if (layout && showLayout) return layout.filter(e => !e.ParentId).map(e => renderPageView(e, layout))
        else if (listLayers) return listLayers.filter(e => !e.ParentId).map(e => renderPageView(e, listLayers))
        else return layers.filter(e => !e.ParentId).map(e => renderPageView(e, layers))
    }

    return returnView()
}

const ChartById = ({ id, cardId, style, className }) => {
    const [data, setData] = useState()

    useEffect(() => {
        if (cardId) {
            const _settingDataController = new SettingDataController("chart")
            _settingDataController.getByIds([cardId]).then(async (res) => {
                if (res.code === 200) {
                    const _relController = new TableController("rel")
                    const relRes = await _relController.getByListId(res.data.map(e => e.RelativeId))
                    if (relRes.code === 200 && relRes.data[0]) {
                        setData({
                            ...res.data[0],
                            TableFK: relRes.data[0].TableFK
                        })
                    }
                }
            })
        }
    }, [cardId])

    return data ? <ChartCard
        id={id}
        item={data}
        timeRannge={120}
        style={style}
        className={className}
    /> : <div id={id} />
}

const FormById = ({ id, formId, style, className }) => {
    const [data, setData] = useState()

    useEffect(() => {
        if (formId) {
            const _settingDataController = new SettingDataController("form")
            _settingDataController.getByIds([formId]).then(async (res) => {
                if (res.code === 200) {
                    let _formItem = res.data[0]
                    if (typeof _formItem.Props === "string") _formItem.Props = JSON.parse(_formItem.Props)
                    setData(_formItem)
                }
            })
        }
    }, [formId])

    return data ? <RenderFormByType
        isActive
        id={id}
        formItem={data}
        style={style}
        className={className}
    /> : <div id={id} />
}

const CardById = ({ id, cardId, style, className, filter = [] }) => {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const [cardItem, setCardItem] = useState()
    const [_filter, setFilter] = useState([])

    useEffect(() => {
        if (cardId) {
            const _settingDataController = new SettingDataController("card")
            _settingDataController.getByIds([cardId]).then(async (res) => {
                if (res.code === 200) {
                    let _cardItem = res.data[0]
                    if (_cardItem.Props && typeof _cardItem.Props === "string") _cardItem.Props = JSON.parse(_cardItem.Props)
                    setCardItem(_cardItem)
                }
            })
        }
    }, [cardId])

    useEffect(() => {
        const validFilter = filter.filter(e => {
            switch (e.Type) {
                case FilterType.params:
                    return e.Name?.length && searchParams.get(e.Name)
                case FilterType.fixed:
                    return e.Name?.length && e.Value?.length
                case FilterType.limit:
                    return e.Value !== undefined && e.Value !== null
                default:
                    return false
            }
        })
        if (validFilter.length) {
            setFilter(validFilter.map(e => {
                if (e.Type === FilterType.params) {
                    const paramsValue = searchParams.get(e.Name)
                    return { ...e, Value: paramsValue }
                } else return e
            }))
        }
    }, [location, filter.length])

    return cardItem ? <RenderCard
        id={id}
        className={className}
        style={style}
        cardItem={cardItem}
        layers={cardItem.Props}
        filter={_filter}
    /> : <div id={id} />
}

const ActionPopup = ({ id, children, className = "" }) => {
    const ref = useRef()

    useEffect(() => {
        if (ref.current) ref.current.setState({ ...ref.current.state, content: children })
    }, [children])

    return <div id={id} className={"action-popup-container " + className}>
        <Popup ref={ref} />
        <button hidden type="button" className="open" onClick={() => {
            showPopup({
                ref: ref,
                content: children ?? <div />
            })
        }} />
        <button hidden type="button" className="close" onClick={() => { closePopup(ref) }} />
    </div>
}