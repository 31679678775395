import { Ultis } from "../Utils";

export default class ConfigApi {
    // static url = 'http://localhost:3000/api/'
    // static socketUrl = 'http://localhost:3000'
    // static url = 'https://server.wini.vn/api/'
    static url = 'https://apicore.wini.vn/api/'
    static socketUrl = 'https://apicore.wini.vn'
    static fileUrl = "https://file-mamager.wini.vn/api/";
    static imgUrl = "https://file-mamager.wini.vn/";
    static imgUrlId = "https://apicore.wini.vn/api/file/img/";
    static currentPid = Ultis.getCookie("pid");
}

export const ConfigDomain = ["localhost", "admin.wini.vn"]