import { Text, Winicon } from "wini-web-components";
import styles from './view.module.css'
import { useEffect, useState } from "react";
import WorkflowOverviewPage from "./overview/overview";
import { useLocation } from "react-router-dom";
import { TableController } from "../table/controller";

export default function WorkflowDetail() {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const id = searchParams.get("id")
    const _wfController = new TableController('workflow')
    const [tab, setTab] = useState(0)
    const [workflowItem, setWfItem] = useState()

    const renderTab = () => {
        if (!workflowItem) return <div />
        switch (tab) {
            case 0:
                return <WorkflowOverviewPage workflowItem={workflowItem} />
            case 1:
                return <div />
            case 2:
                return <div />
            default:
                return <div />
        }
    }

    useEffect(() => {
        if (id) {
            _wfController.getByListId([id]).then(res => {
                if (res.code === 200) setWfItem(res.data[0])
            })
        }
    }, [id])

    return <div className="col full-view">
        <div className="col" style={{ padding: "1.6rem 3.2rem 0 3.2rem", borderBottom: 'var(--neutral-bolder-border)' }}>
            <div className='row' style={{ gap: '1.6rem', padding: "0.6rem 0" }}>
                <button type='button' className='row' style={{ gap: '0.8rem' }}>
                    <Winicon src="outline/files/folder" size={'2.2rem'} />
                    <Text className='heading-6'>{workflowItem?.Name}</Text>
                    <Winicon src={"fill/arrows/down-arrow"} size={"1.2rem"} />
                </button>
                <Winicon src="outline/user interface/star" size={'2rem'} />
            </div>
            <div className="row" style={{ gap: "2.4rem" }}>
                <div className={`row ${styles['tabbar']} ${tab === 0 ? styles['selected'] : ""}`} onClick={() => { setTab(0) }}>
                    <Winicon src="outline/user interface/home" size={'1.4rem'} />
                    <Text className="label-4">Overview</Text>
                </div>
                <div className={`row ${styles['tabbar']} ${tab === 1 ? styles['selected'] : ""}`} onClick={() => { setTab(1) }}>
                    <Winicon src="outline/user interface/bullet-list" size={'1.4rem'} />
                    <Text className="label-4">Task list</Text>
                </div>
                <div className={`row ${styles['tabbar']} ${tab === 2 ? styles['selected'] : ""}`} onClick={() => { setTab(2) }}>
                    <Winicon src="outline/user interface/clock" size={'1.4rem'} />
                    <Text className="label-4">Timeline</Text>
                </div>
                <div className={`row ${styles['tabbar']} ${tab === 3 ? styles['selected'] : ""}`} onClick={() => { setTab(3) }}>
                    <Winicon src="outline/user interface/g-chart" size={'1.4rem'} />
                    <Text className="label-4">Dashboard</Text>
                </div>
                <div className={`row ${styles['tabbar']} ${tab === 4 ? styles['selected'] : ""}`} onClick={() => { setTab(4) }}>
                    <Winicon src="outline/user interface/attach" size={'1.4rem'} />
                    <Text className="label-4">File</Text>
                </div>
                <div className={`row ${styles['tabbar']} ${tab === 5 ? styles['selected'] : ""}`} onClick={() => { setTab(5) }}>
                    <Winicon src="outline/user interface/b-comment" size={'1.4rem'} />
                    <Text className="label-4">Comment</Text>
                </div>
                <div className={`row ${styles['tabbar']} ${tab === 6 ? styles['selected'] : ""}`} onClick={() => { setTab(6) }}>
                    <Winicon src="outline/user interface/e-add" size={'1.4rem'} />
                    <Text className="label-4">More view</Text>
                </div>
            </div>
        </div>
        {renderTab()}
    </div>
}