import { useSelector } from "react-redux"
import { ComponentType } from "../../../wini/table/da"
import SettingText from "./text"
import SettingImg from "./img"
import SettingContainer from "./container"
import SettingChart from "./chart"
import SettingForm from "./form"
import SettingCard from "./card"
import { useEffect, useState } from "react"
import SettingIcon from "./icon"
import SettingPopup from "./popup"

export default function PropertiesTab({ methods }) {
    const { layers, layout } = useSelector((store) => store.page.data)
    const [selected, setSelected] = useState()

    useEffect(() => {
        if (layers?.length && layout?.length) {
            const _id = methods.getValues("selectedId")
            if (_id) {
                if (selected?.Id === _id) {
                    setTimeout(() => {
                        setSelected([...layers, ...layout].find(e => e.Id === _id))
                    }, 500)
                } else setSelected([...layers, ...layout].find(e => e.Id === _id))
            } else setSelected(undefined)
        }
    }, [layers, layout, methods.watch("selectedId")])

    const renderSettingView = () => {
        switch (selected?.Type) {
            case ComponentType.text:
                return <SettingText selected={selected} />
            case ComponentType.img:
                return <SettingImg selected={selected} />
            case ComponentType.container:
                return <SettingContainer selected={selected} />
            case ComponentType.form:
                return <SettingForm selected={selected} />
            case ComponentType.card:
                return <SettingCard selected={selected} />
            case ComponentType.chart:
                return <SettingChart selected={selected} />
            case ComponentType.icon:
                return <SettingIcon selected={selected} />
            case ComponentType.popup:
                return <SettingPopup selected={selected} />
            default:
                return <div />
        }
    }

    return <div className="col" style={{ backgroundColor: 'var(--neutral-absolute-background-color)', flex: 1, overflow: 'hidden auto', scrollbarWidth: "none" }}>{renderSettingView()}</div>
}


