import { Dispatch, PayloadAction, UnknownAction, createSlice } from '@reduxjs/toolkit'
import { ProjectItem } from './da'
import { ProjectController } from './controller'
import { Ultis } from '../../../Utils'
import ConfigApi from '../../../da/configApi'
import { MenuItem } from '../table/da'
import { TableController } from '../table/controller'
import { ToastMessage } from 'wini-web-components'

interface ProjectSimpleResponse {
    data?: {
        data: ProjectItem,
        menu: Array<MenuItem>
    },
    onLoading?: boolean,
    type?: string
}

const initState: ProjectSimpleResponse = {
    data: undefined,
    onLoading: false
}

export const projectSlice = createSlice({
    name: 'project',
    initialState: initState,
    reducers: {
        handleActions: (state, action: PayloadAction<any>) => {
            switch (action.payload.type) {
                case 'GETINFOR':
                    state.data = action.payload.data
                    break;
                case 'ADDMENU':
                    state.data = state.data ? { ...state.data, menu: [...state.data.menu, ...action.payload.data] } : undefined
                    break;
                case 'EDITMENU':
                    state.data = state.data ? { ...state.data, menu: action.payload.data } : undefined
                    break;
                case 'DELETEMENU':
                    state.data = state.data ? { ...state.data, menu: state.data.menu.filter(e => !action.payload.data.includes(e.Id)) } : undefined
                    break;
                case 'RESET':
                    state.data = undefined
                    break;
                default:
                    break;
            }
            state.onLoading = false
        },
        onFetching: (state) => {
            state.onLoading = true
        },
    },
})

const { handleActions, onFetching } = projectSlice.actions

export default projectSlice.reducer

export class ProjectActions {
    static getInfor = (dispatch: Dispatch<UnknownAction>) => {
        dispatch(onFetching())
        ProjectController.getByIds([ConfigApi.currentPid ?? Ultis.getCookie("pid")]).then(async (res) => {
            if (res.code === 200) {
                const menuController = new TableController("menu")
                const menuRes = await menuController.getAll()
                dispatch(handleActions({
                    type: 'GETINFOR',
                    data: {
                        data: res.data[0],
                        menu: menuRes.data ?? []
                    },
                }))
            }
        })
    }

    static addMenu = async (dispatch: Dispatch<UnknownAction>, data: Array<MenuItem>) => {
        const menuController = new TableController("menu")
        const res = await menuController.add(data.map(e => {
            return { ...e, Setting: e.Setting && typeof e.Setting !== "string" ? JSON.stringify(e.Setting) : e.Setting }
        }))
        if (res.code !== 200) return ToastMessage.errors(res.message)
        dispatch(handleActions({
            type: 'ADDMENU',
            data: res.data,
        }))
    }

    static editMenu = async (dispatch: Dispatch<UnknownAction>, data: Array<MenuItem>) => {
        const menuController = new TableController("menu")
        const res = await menuController.edit(data.map(e => {
            return { ...e, Setting: e.Setting && typeof e.Setting !== "string" ? JSON.stringify(e.Setting) : e.Setting }
        }))
        if (res.code !== 200) return ToastMessage.errors(res.message)
        dispatch(handleActions({
            type: 'EDITMENU',
            data: res.data,
        }))
    }

    static deleteMenu = async (dispatch: Dispatch<UnknownAction>, data: Array<string>) => {
        const menuController = new TableController("menu")
        const res = await menuController.delete(data)
        if (res.code !== 200) return ToastMessage.errors(res.message)
        dispatch(handleActions({
            type: 'DELETEMENU',
            data: res.data,
        }))
    }

    static reset = (dispatch: Dispatch<UnknownAction>) => {
        dispatch(handleActions({ type: 'RESET' }))
    }
}