import { Button, closePopup, Popup, showPopup, Text, TextField, Winicon } from 'wini-web-components'
import styles from './index.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { forwardRef, useEffect, useRef } from 'react'
import { CustomerActions } from '../screen/wini/customer/reducer'
import ConfigApi from '../da/configApi'
import { useTranslation } from 'react-i18next'
import { t } from 'i18next'

export default function Header() {
    const project = useSelector((store) => store.project.data)
    const user = useSelector((store) => store.customer.data)
    const dispatch = useDispatch()
    const ref = useRef()
    const { i18n, t } = useTranslation();

    useEffect(() => {
        CustomerActions.getInfor(dispatch)
    }, [])

    const showUserActions = (ev) => {
        const _box = ev.target.getBoundingClientRect()
        showPopup({
            ref: ref,
            clickOverlayClosePopup: true,
            style: { top: `${_box.y + _box.height + 2}px`, right: `${document.body.offsetWidth - _box.right}px`, position: 'absolute', width: 'fit-content' },
            body: <PopupUserActions ref={ref} />
        })
    }

    const showSelectLanguage = (ev) => {
        const _box = ev.target.closest('button').getBoundingClientRect()
        const onChangeLang = (lang_code) => {
            i18n.changeLanguage(lang_code);
            closePopup(ref)
        };
        showPopup({
            ref: ref,
            clickOverlayClosePopup: true,
            style: { top: `${_box.y + _box.height + 2}px`, right: `${document.body.offsetWidth - _box.right}px`, position: 'absolute', width: 'fit-content' },
            body: <div className='col popup-actions'>
                <button type='button' className='row' style={{ gap: "1rem" }} onClick={() => { onChangeLang("vi") }}>
                    <Winicon src='color/flags/vietnam' size={'2.4rem'} />
                    <Text className='button-text-3'>VI</Text>
                </button>
                <button type='button' className='row' style={{ gap: "1rem" }} onClick={() => { onChangeLang("en") }}>
                    <Winicon src='color/flags/usa' size={'2.4rem'} />
                    <Text className='button-text-3'>EN</Text>
                </button>
            </div>
        })
    }

    return <div className={`row ${styles['header']}`}>
        <Popup ref={ref} />
        <Winicon src="fill/user interface/menu-6" size={'1.6rem'} onClick={() => {
            const _view = document.body.querySelector(`.main-layout > div[class*="router_sidebar"] + div`)
            if (_view.classList.contains("full-view")) _view.classList.remove("full-view")
            else _view.classList.add("full-view")
        }} />
        <Button
            prefix={<Winicon src={"fill/user interface/c-add"} size={"1.4rem"} color='var(--primary-main-color)' />}
            label='Button'
            style={{ border: 'var(--neutral-bolder-border)', backgroundColor: "var(--neutral-absolute-background-color)", width: '9.4rem', borderRadius: '2.4rem' }}
        />
        <TextField
            prefix={<Winicon src={"fill/development/zoom"} size={"1.4rem"} />}
            className={`placeholder-2 ${styles['header-search']}`}
            placeholder={t("search")}
        />
        <div style={{ flex: 1 }} />
        <button type='button' className='row icon-button24'>
            <Winicon src='fill/user interface/bell' size={'1.6rem'} />
        </button>
        <button type='button' className='row icon-button24'>
            <Winicon src="fill/user interface/mail" size={'1.8rem'} />
        </button>
        <button type='button' className='row icon-button32' onClick={showSelectLanguage}>
            <Winicon src={`color/flags/${i18n.language === "vi" ? "vietnam" : "usa"}`} size={'2.8rem'} />
        </button>
        {project?.LogoId ? <img src={ConfigApi.imgUrlId + project?.LogoId} style={{ height: "3.2rem", objectFit: "contain", objectPosition: "center" }} />
            : <button type='button' className='row' onClick={showUserActions}>
                <img src={user?.AvatarUrl ?? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTU3FcmHH1HtWFPQqC9Z-IK4JdvSWkvswcDfA&s"} alt='' style={{ borderRadius: "50%", width: '3.2rem', height: '3.2rem' }} />
            </button>}
    </div>
}

const PopupUserActions = forwardRef(function PopupUserActions(data, ref) {
    const user = useSelector((store) => store.customer.data)

    return <div className={`col popup-actions ${styles['account-actions']}`}>
        <button className='row' style={{ gap: '1.2rem', padding: '1rem 1.6rem' }}>
            <Winicon src='outline/users/user-c-frame' />
            <Text className='label-4'>Profile</Text>
        </button>
        <button className='row' style={{ gap: '1.2rem', padding: '1rem 1.6rem' }}>
            <Winicon src='outline/user interface/gear' />
            <Text className='label-4'>Setting</Text>
        </button>
        <div className='col divider' />
        <button type='button' onClick={() => {
            closePopup(ref)
            CustomerActions.logout()
        }} className='col' style={{ gap: '0.4rem', padding: '1rem 1.6rem' }}>
            <Text className='label-4' style={{ color: 'var(--error-main-color)' }}>Logout</Text>
            <Text className='subtitle-4' >{user?.Email ?? user?.Username ?? ''}</Text>
        </button>
    </div>
})