import { forwardRef, useEffect, useRef, useState } from "react";
import { FilledAreaChart, FilledBarChart, FilledBubbleChart, FilledColumnChart, FilledDoughnutChart, FilledLineChart, FilledOnlyNumberCard, FilledPieChart, FilledRadarChart, FilledScatterChart } from "../../../assets/icon";
import { closePopup, ComponentStatus, Dialog, DialogAlignment, showDialog, Text, ToastMessage } from "wini-web-components";
import { useFieldArray, useForm } from "react-hook-form";
import { Select1Form, TextFieldForm } from "../../../project-component/component-form";
import { EChartType, Reducers } from "../da";
import { useLocation, useParams } from "react-router-dom";
import { EventStep } from "../../wini/table/da";
import { DataController } from "../controller";
import MapGroupData from "./mapGroupData";
import { randomGID, Ultis } from "../../../Utils";

const listDashboardCard = [EChartType.line, EChartType.bar, EChartType.horizontalBar, EChartType.scatter, EChartType.bubble, EChartType.radar, EChartType.area, EChartType.doughnut, EChartType.pie]

const PopupAddCard = forwardRef(function PopupAddCard(data, ref) {
    const [step, setStep] = useState(0)
    const [cardItem, setCardItem] = useState()
    const dialogRef = useRef()

    const _onCancel = () => {
        showDialog({
            ref: dialogRef,
            alignment: DialogAlignment.center,
            status: ComponentStatus.WARNING,
            title: 'Hủy thêm mới',
            onSubmit: () => { closePopup(ref) }
        })
    }

    const renderTabView = () => {
        switch (step) {
            case 0:
                return <SettingData
                    tbName={data.tbName}
                    item={cardItem}
                    colController={data.colController}
                    relController={data.relController}
                    onSubmit={(_newCard) => {
                        setStep(1)
                        setCardItem({ ..._newCard, TbName: data.tbName })
                    }}
                    onCancel={_onCancel}
                />
            case 1:
                return <ChartOptions
                    item={cardItem}
                    onBack={() => { setStep(0) }}
                    onSelect={(v) => {
                        setCardItem({ ...cardItem, Type: v })
                        setStep(2)
                    }}
                    onCancel={_onCancel}
                />
            case 2:
                return <SettingChart
                    item={cardItem}
                    onBack={() => { setStep(1) }}
                    onCancel={_onCancel}
                    onSubmit={(v) => {
                        let _tmp = { ...v, TbName: data.tbName }
                        delete _tmp.TableFK
                        data.onSuccess(_tmp)
                        closePopup(ref)
                    }}
                />
            default:
                break;
        }
    }

    useEffect(() => {
        if (data.item) setCardItem({ ...data.item, Setting: JSON.parse(data.item.Setting) })
    }, [])

    return <div className="col" style={{ width: '100%', height: '100%', flex: 1 }}>
        <Dialog ref={dialogRef} />
        <div className="row" style={{ justifyContent: 'center', padding: '2.4rem 2.4rem 3.2rem' }}>
            <div className={`col step-container ${step === 0 ? "selected" : ""} ${step >= 0 ? "active" : ""}`}>
                <div className="step-item row">
                    <div className="line" style={{ visibility: 'hidden' }} />
                    <Text className="semibold2 row">1</Text>
                    <div className="line" />
                </div>
                <Text className="heading-8">Setting data</Text>
            </div>
            <div className={`col step-container ${step === 1 ? "selected" : ""} ${step >= 1 ? "active" : ""}`}>
                <div className="step-item row">
                    <div className="line" />
                    <Text className="semibold2 row">2</Text>
                    <div className="line" />
                </div>
                <Text className="heading-8">Choose chart</Text>
            </div>
            <div className={`col step-container ${step === 2 ? "selected" : ""} ${step >= 2 ? "active" : ""}`}>
                <div className="step-item row">
                    <div className="line" />
                    <Text className="semibold2 row">3</Text>
                    <div className="line" style={{ visibility: 'hidden' }} />
                </div>
                <Text className="heading-8">Setting chart</Text>
            </div>
        </div>
        {renderTabView()}
    </div>
})

export default PopupAddCard

const SettingData = ({ colController, relController, item, onSubmit, onCancel, tbName }) => {
    const methods = useForm({ shouldFocusError: false })
    const [_cols, setCols] = useState([])
    const [_rels, setRels] = useState([])
    const _onSubmit = (ev) => {
        onSubmit(ev)
    }

    useEffect(() => {
        if (item) Object.keys(item).forEach((prop) => {
            methods.setValue(prop, item[prop])
        })
        relController.getListSimple(
            {
                page: 1,
                size: 50,
                query: `@TablePK:{${tbName}}`,
            }
        ).then((res) => {
            if (res.code === 200) setRels(res.data ?? [])
            else {
                setRels([])
                ToastMessage.errors(res.message)
            }
        })
    }, [item])

    useEffect(() => {
        if (_rels.length && methods.getValues("RelativeId")) {
            const _fkTb = _rels.find(e => e.Id === methods.getValues("RelativeId"))?.TableFK
            if (_fkTb) {
                colController.getListSimple(
                    {
                        page: 1,
                        size: 50,
                        query: `@TableName:{${_fkTb}}`,
                        returns: ['Id', 'Name', 'DataType']
                    }
                ).then((res) => {
                    if (res.code === 200) setCols(res.data)
                    else {
                        setCols([])
                        ToastMessage.errors(res.message)
                    }
                })
                methods.setValue("TableFK", _fkTb)
            }
        }
    }, [_rels, methods.watch("RelativeId")])

    return <div className="col" style={{ flex: 1, height: '100%' }}>
        <div className="col" style={{ flex: 1, height: '100%', padding: '2.4rem', gap: '2rem', overflow: 'hidden auto' }}>
            <TextFieldForm
                required
                label="Key name"
                name="Name"
                style={{ gap: '0.8rem' }}
                errors={methods.formState.errors}
                register={methods.register}
            />
            <TextFieldForm
                required
                label="Title"
                name="Title"
                style={{ gap: '0.8rem' }}
                errors={methods.formState.errors}
                register={methods.register}
            />
            <Select1Form
                required
                label="TableFK"
                name="RelativeId"
                style={{ gap: '0.8rem' }}
                control={methods.control}
                errors={methods.formState.errors}
                options={_rels.map((el) => {
                    return {
                        id: el.Id,
                        name: el.TableFK
                    }
                })}
            />
            <div className="row" style={{ gap: '1.6rem', paddingBottom: '2rem' }}>
                <Select1Form
                    required
                    label="GroupBy"
                    name="GroupBy"
                    style={{ gap: '0.8rem', flex: 1 }}
                    control={methods.control}
                    errors={methods.formState.errors}
                    options={_cols.map((el) => {
                        return {
                            id: el.Name,
                            name: el.Name
                        }
                    })}
                />
                <Select1Form
                    required
                    label="Reducer"
                    name="Reducer"
                    style={{ gap: '0.8rem', flex: 1 }}
                    control={methods.control}
                    errors={methods.formState.errors}
                    options={EventStep.REDUCE.EXPRESSION.map((el) => {
                        return {
                            id: el,
                            name: el
                        }
                    })}
                />
                {methods.watch("Reducer") && methods.watch("Reducer") !== Reducers.COUNT ? <Select1Form
                    required
                    label="By"
                    name="ReducerBy"
                    style={{ gap: '0.8rem', flex: 1 }}
                    control={methods.control}
                    errors={methods.formState.errors}
                    options={_cols.map((el) => {
                        return {
                            id: el.Name,
                            name: el.Name,
                            DataType: el.DataType
                        }
                    })}
                    onChange={(v) => { methods.setValue("DataType", v.DataType) }}
                /> : <div style={{ flex: 1 }} />}
            </div>
        </div>
        <div className="row popup-footer">
            <Text className="button-text-3" onClick={onCancel}>Cancel</Text>
            <div style={{ flex: 1 }}></div>
            <button type="button" className={`row ${methods.watch('Name') != undefined ? "button-primary" : "button-disabled"}`} onClick={methods.handleSubmit(_onSubmit)}>
                <Text className="button-text-3">Continue</Text>
            </button>
        </div>
    </div>
}

const ChartOptions = ({ onSelect, item, onCancel, onBack }) => {
    const [chartType, setChartType] = useState()

    useEffect(() => {
        if (item.Type) setChartType(item.Type)
    }, [item])

    return <div className="col" style={{ flex: 1, width: '100%', height: '100%', borderRadius: '0.8rem' }}>
        <div className="row" style={{ flex: 1, width: '100%', height: '100%', overflow: 'hidden auto', padding: '1.6rem 2.4rem', flexWrap: 'wrap', gap: '1.6rem', alignContent: 'start' }}>
            {listDashboardCard.map((cardName, i) => {
                switch (cardName) {
                    case EChartType.bar:
                        var icon = <FilledColumnChart size={'3.6rem'} color='#0968D3' />
                        break;
                    case EChartType.pie:
                        icon = <FilledPieChart size={'3.6rem'} color='#0968D3' />
                        break;
                    case EChartType.horizontalBar:
                        icon = <FilledBarChart size={'3.6rem'} color='#0968D3' />
                        break;
                    case EChartType.line:
                        icon = <FilledLineChart size={'3.6rem'} color='#0968D3' />
                        break;
                    case EChartType.scatter:
                        icon = <FilledScatterChart size={'3.6rem'} color='#0968D3' />
                        break;
                    case EChartType.bubble:
                        icon = <FilledBubbleChart size={'3rem'} color='#0968D3' />
                        break;
                    case EChartType.doughnut:
                        icon = <FilledDoughnutChart size={'3.6rem'} color='#0968D3' />
                        break;
                    case EChartType.area:
                        icon = <FilledAreaChart size={'3rem'} color='#0968D3' />
                        break;
                    case EChartType.radar:
                        icon = <FilledRadarChart size={'3.6rem'} color='#0968D3' />
                        break;
                    default:
                        break;
                }
                return <button key={'chart-' + i} onClick={() => { setChartType(cardName) }} type="button" className={`col col8 card-option ${chartType === cardName ? 'selected' : ''}`}>
                    <div className="row chart-icon" >{icon}</div>
                    <Text className="heading-6">{cardName}</Text>
                    <Text className="body-3">It is a long established fact that a reader will be distracted by the readable content.</Text>
                </button>
            })}
        </div>
        <div className="row popup-footer">
            <Text className="button-text-3" onClick={onCancel}>Cancel</Text>
            <div style={{ flex: 1 }}></div>
            <button type="button" className={'button-grey'} onClick={onBack}>
                <Text className="button-text-3">Back</Text>
            </button>
            <button type="button" className={`row ${chartType != undefined ? "button-primary" : "button-disabled"}`} onClick={() => { onSelect(chartType) }}>
                <Text className="button-text-3">Continue</Text>
            </button>
        </div>
    </div>
}

const SettingChart = ({ item, onCancel, onBack, onSubmit }) => {
    const location = useLocation()
    const methods = useForm({ shouldFocusError: false, defaultValues: { Datasets: [], Legend: "top", BorderWidth: 1 } })
    const _datasets = useFieldArray({
        control: methods.control,
        name: "Datasets",
        keyName: undefined
    })
    const [chartItem, setChartItem] = useState()
    const [_result, setResult] = useState([])
    const dialogRef = useRef()
    const _groupByTimeChart = [EChartType.bar, EChartType.horizontalBar, EChartType.bubble, EChartType.line, EChartType.scatter, EChartType.radar]

    const _onSubmit = (ev) => {
        showDialog({
            ref: dialogRef,
            alignment: DialogAlignment.center,
            status: ComponentStatus.WARNING,
            title: 'Bạn chắc chắn muốn thêm card ' + chartItem.Title,
            onSubmit: () => {
                let _newChartItem = { ...item, DateCreated: Date.now(), Setting: JSON.stringify(ev) }
                _newChartItem.Id ??= randomGID()
                onSubmit(_newChartItem)
            }
        })
    }

    const settingChartForm = () => {
        if (!chartItem) return <div />
        return <>
            <div className="row" style={{ gap: '3.2rem', marginBottom: '1.6rem' }}>
                <TextFieldForm
                    name="BorderWidth"
                    errors={methods.formState.errors}
                    register={methods.register}
                    type="number"
                    label="BorderWidth"
                    style={{ flex: 1, gap: '0.8rem' }}
                // onBlur={(ev) => { methods.setValue("BorderWidth", parseFloat(ev.target.value)) }}
                />
                <Select1Form
                    control={methods.control}
                    errors={methods.formState.errors}
                    name="Legend"
                    label="Legend"
                    style={{ flex: 1, gap: '0.8rem' }}
                    options={['top', 'right', 'bottom', 'left'].map((e) => {
                        return {
                            id: e,
                            name: e
                        }
                    })}
                />
            </div>
            <div className="row" style={{ gap: '3.2rem', padding: '1.2rem 0', backgroundColor: "#f9fafb", borderTop: 'var(--neutral-bolder-border)', borderBottom: 'var(--neutral-bolder-border)', zIndex: 2, top: 0, position: 'sticky' }}>
                <Text className="semibold2" style={{ width: '12rem', textAlign: 'center' }}>{item.GroupBy}</Text>
                <Text className="semibold2" style={{ flex: 2, textAlign: 'center' }}>Label</Text>
                <Text className="semibold2" style={{ flex: 1, textAlign: 'center', minWidth: '18rem' }}>BackgroundColor</Text>
            </div>
            {_datasets.fields.map((_dataItem, i) => {
                return <div key={_dataItem.Id} className="row" style={{ padding: '0.4rem 0', gap: '3.2rem', overflow: 'visible' }}>
                    <Text className="semibold2" style={{ width: '12rem', textAlign: 'center' }}>{_dataItem.Id}</Text>
                    <TextFieldForm
                        required
                        name={`Datasets[${i}].Label`}
                        register={methods.register}
                        errors={methods.formState.errors}
                        style={{ flex: 2, height: '3.6rem' }}
                        textFieldStyle={{ height: '3.6rem', padding: '0.6rem 1.2rem' }}
                        placeholder="Nhập label"
                        onBlur={(ev) => {
                            const _tmp = methods.getValues(`Datasets`)
                            methods.setValue("Datasets", _tmp.map((e, index) => {
                                if (index === i) return { ...e, Label: ev.target.value }
                                else return e
                            }))
                        }}
                    />
                    <TextFieldForm
                        required
                        name={`Datasets[${i}].BackgroundColor`}
                        register={methods.register}
                        errors={methods.formState.errors}
                        style={{ flex: 1, height: '3.6rem', minWidth: '18rem' }}
                        textFieldStyle={{ height: '3.6rem', padding: '0.6rem 1.2rem' }}
                        prefix={
                            <input
                                type="color"
                                {...methods.register(`Datasets[${i}].BackgroundColor`)}
                                onBlur={(ev) => {
                                    const _tmp = methods.getValues(`Datasets`)
                                    methods.setValue("Datasets", _tmp.map((e, index) => {
                                        if (index === i) return { ...e, BackgroundColor: ev.target.value }
                                        else return e
                                    }))
                                }}
                                style={{ maxWidth: '2.4rem' }}
                            />
                        }
                        placeholder="Nhập backgroundClor"
                    />
                </div>
            })}
        </>
    }

    useEffect(() => {
        if (item) {
            const _reducers = [{ Name: item.Name, GroupBy: item.GroupBy, Reducer: item.Reducer, ReducerBy: item.ReducerBy }]
            if (_groupByTimeChart.includes(item.Type)) {
                _reducers.push({ Name: "_day", GroupBy: "_day", Query: `"timefmt(@DateCreated/1000, '%d-%m')"` })
            }
            const _tbRelController = new DataController(item.TableFK)
            _tbRelController.groupBy({ reducers: _reducers }).then((res) => {
                if (res.code === 200) {
                    setResult(res.data)
                    const _groups = []
                    res.data.forEach(e => {
                        if (!_groups.includes(e[item.GroupBy])) _groups.push(e[item.GroupBy])
                    })
                    item.Setting ??= {
                        Datasets: _groups.map(e => {
                            return {
                                Id: e,
                                Label: e,
                                BackgroundColor: Ultis.generateRandomColor(),
                            }
                        }),
                        Legend: "top"
                    }
                    methods.setValue("Datasets", item.Setting.Datasets)
                    setChartItem(item)
                }
            })
        }
    }, [item])

    return <div className="col" style={{ flex: 1, width: '100%', height: '100%', borderRadius: '0.8rem' }}>
        <Dialog ref={dialogRef} />
        <div className="row" style={{ flex: 1, width: '100%', height: '100%' }}>
            <div className="col" style={{ flex: 3, height: '100%', overflow: 'hidden auto', padding: '0 2.4rem', gap: '0.8rem' }}>
                {settingChartForm()}
                <div className="col divider" />
                <div style={{ width: '100%', overflow: 'hidden' }}>
                    {chartItem && <MapGroupData
                        listData={_result}
                        chartItem={{ ...chartItem, Setting: { Datasets: methods.watch("Datasets"), Legend: methods.watch("Legend"), BorderWidth: methods.watch("BorderWidth") } }}
                        filterTime="_day"
                    />}
                </div>
            </div>
            <div className="semibold3" style={{ whiteSpace: "break-spaces", flex: 2, overflow: "hidden auto", height: '100%', paddingLeft: '1.6rem', borderLeft: 'var(--neutral-bolder-border)' }}>{JSON.stringify(_result, null, 2)}</div>
        </div>
        <div className="row popup-footer">
            <Text className="button-text-3" onClick={onCancel}>Cancel</Text>
            <div style={{ flex: 1 }}></div>
            <button type="button" className={'button-grey'} onClick={onBack}>
                <Text className="button-text-3">Back</Text>
            </button>
            <button type="button" className={`row button-primary`} onClick={methods.handleSubmit(_onSubmit)}>
                <Text className="button-text-3">Save</Text>
            </button>
        </div>
    </div>
}