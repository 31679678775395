import styles from './screen.module.css'
import { useDispatch, useSelector } from "react-redux"
import { ComponentType } from "../../wini/table/da"
import { useEffect, useRef } from "react"
import { PageActions } from "./reducer"
import { useLocation } from 'react-router-dom'
import { socket } from '../../../socket'
import { randomGID, Ultis } from '../../../Utils'
import ConfigApi from '../../../da/configApi'

export default function PageView({ methods }) {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const _pageId = searchParams.get("pageid")
    const { page, layers, layout } = useSelector((store) => store.page.data)
    const dispatch = useDispatch()
    const settingPageRef = useRef()
    const iframeRef = useRef()

    const handleDragToTarget = (ev, _layerItem, mousemove = false) => {
        if (!mousemove) ev.preventDefault()
        let _parent = ev.target.closest('div[component-type="Container"]:not(.layout-body, .main-layout)')
        let _demo = iframeRef.current.contentWindow.document.body.querySelector(`div[class*="demo-component-in-container"]`)
        if (_parent) {
            const outsideParent = _parent.parentElement.closest('div[component-type="Container"]:not(.layout-body, .main-layout)')
            if (outsideParent) {
                const outsideParentRect = _parent.getBoundingClientRect()
                if ((ev.pageX - 4) <= outsideParentRect.x || (ev.pageX + 4) >= outsideParentRect.right || (ev.pageY - 4) <= outsideParentRect.y || (ev.pageY + 4) >= outsideParentRect.bottom) {
                    _parent = outsideParent
                }
            }
            let _children = [..._parent.children].filter(e => !e.classList.contains(styles['demo-component-in-container'])).sort((a, b) => parseInt(window.getComputedStyle(a).order ?? 0) - parseInt(window.getComputedStyle(b).order ?? 0))
            if (!_demo) {
                _demo = iframeRef.current.contentWindow.document.createElement("div")
                _demo.className = styles['demo-component-in-container']
            }
            const _direction = window.getComputedStyle(_parent).flexDirection
            let _order = 0
            let _distance = 0
            if (_direction === "column") {
                if (_layerItem) {
                    const _style = window.getComputedStyle(_layerItem)
                    var demoW = _style.width === "auto" || _style.width === "fit-content" ? `${_layerItem.offsetWidth}px` : _style.width
                    var demoMW = _style.maxWidth
                }
                _demo.style.height = '0.3rem'
                _demo.style.width = demoW ?? "2rem"
                _demo.style.maxWidth = demoMW ?? "100%"
                if (_children.length) {
                    let closestHTML = [..._children].sort((aHTML, bHTML) => {
                        let aRect = aHTML.getBoundingClientRect()
                        let bRect = bHTML.getBoundingClientRect()
                        let a_center_oy = Math.abs(ev.pageY - (aRect.y + aRect.height / 2))
                        let b_center_oy = Math.abs(ev.pageY - (bRect.y + bRect.height / 2))
                        return a_center_oy - b_center_oy
                    })[0]
                    if (closestHTML) {
                        let htmlRect = closestHTML.getBoundingClientRect()
                        _order = _children.indexOf(closestHTML)
                        _distance = ev.pageY - (htmlRect.y + htmlRect.height / 2)
                        if (_distance < 0) _order--
                    } else _order = _children.length - 1
                }
            } else {
                if (_layerItem) {
                    const _style = window.getComputedStyle(_layerItem)
                    var demoH = _style.height === "auto" || _style.height === "fit-content" ? `${_layerItem.offsetHeight}px` : _style.height
                    var demoMH = _style.maxHeight
                }
                _demo.style.width = '0.3rem'
                _demo.style.height = demoH ?? "2rem"
                _demo.style.maxHeight = demoMH ?? "100%"
                if (_children.length) {
                    let isWrap = window.getComputedStyle(_parent).flexWrap === 'wrap'
                    let closestHTML = [..._children].sort((aHTML, bHTML) => {
                        let aRect = aHTML.getBoundingClientRect()
                        let bRect = bHTML.getBoundingClientRect()
                        let a_center_ox = Math.abs(ev.pageX - (aRect.x + aRect.width / 2))
                        let b_center_ox = Math.abs(ev.pageX - (bRect.x + bRect.width / 2))
                        if (isWrap) {
                            a_center_ox = Math.sqrt(Math.pow(ev.pageX - (aRect.x + aRect.width / 2), 2) + Math.pow(ev.pageY - (aRect.y + aRect.height / 2), 2))
                            b_center_ox = Math.sqrt(Math.pow(ev.pageX - (bRect.x + bRect.width / 2), 2) + Math.pow(ev.pageY - (bRect.y + bRect.height / 2), 2))
                        }
                        return a_center_ox - b_center_ox
                    })[0]
                    if (isWrap) closestHTML = _children.find(childHTML => childHTML.getBoundingClientRect().bottom >= ev.pageY)
                    if (closestHTML) {
                        let htmlRect = closestHTML.getBoundingClientRect()
                        _order = _children.indexOf(closestHTML)
                        _distance = ev.pageX - (htmlRect.x + htmlRect.width / 2)
                        if (_distance < 0) _order--
                    } else _order = _children.length - 1
                }
            }
            _demo.style.order = _order
            if (_demo.parentElement !== _parent) _parent.appendChild(_demo)
        } else if (_demo) _demo.parentElement.removeChild(_demo)
    }

    const onResize = (ev, _layerElement) => {
        switch (iframeRef.current.contentWindow.document.body.getAttribute("on-resize")) {
            case "top":
                _layerElement.forEach((element, i) => {
                    if (!element.tmpY) element.tmpY = ev.pageY
                    if (!element.tmpH) element.tmpH = element.offsetHeight
                    element.style.height = `${element.tmpH - (ev.pageY - element.tmpY)}px`
                    if (i === 0) socket.emit('page-demo', { pageid: _pageId, type: "SELECTID", data: element.id })
                })
                break;
            case "right":
                _layerElement.forEach((element, i) => {
                    if (!element.tmpX) element.tmpX = ev.pageX
                    if (!element.tmpW) element.tmpW = element.offsetWidth
                    element.style.width = `${element.tmpW + (ev.pageX - element.tmpX)}px`
                    if (i === 0) socket.emit('page-demo', { pageid: _pageId, type: "SELECTID", data: element.id })
                })
                break;
            case "bottom":
                _layerElement.forEach((element, i) => {
                    if (!element.tmpY) element.tmpY = ev.pageY
                    if (!element.tmpH) element.tmpH = element.offsetHeight
                    element.style.height = `${element.tmpH + (ev.pageY - element.tmpY)}px`
                    if (i === 0) socket.emit('page-demo', { pageid: _pageId, type: "SELECTID", data: element.id })
                })
                break;
            case "left":
                _layerElement.forEach((element, i) => {
                    if (!element.tmpX) element.tmpX = ev.pageX
                    if (!element.tmpW) element.tmpW = element.offsetWidth
                    element.style.width = `${element.tmpW - (ev.pageX - element.tmpW)}px`
                    if (i === 0) socket.emit('page-demo', { pageid: _pageId, type: "SELECTID", data: element.id })
                })
                break;
            case "topleft":
                _layerElement.forEach((element, i) => {
                    if (!element.tmpY) element.tmpY = ev.pageY
                    if (!element.tmpH) element.tmpH = element.offsetHeight
                    element.style.height = `${element.tmpH - (ev.pageY - element.tmpY)}px`
                    if (!element.tmpX) element.tmpX = ev.pageX
                    if (!element.tmpW) element.tmpW = element.offsetWidth
                    element.style.width = `${element.tmpW - (ev.pageX - element.tmpW)}px`
                    if (i === 0) socket.emit('page-demo', { pageid: _pageId, type: "SELECTID", data: element.id })
                })
                break;
            case "topright":
                _layerElement.forEach((element, i) => {
                    if (!element.tmpY) element.tmpY = ev.pageY
                    if (!element.tmpH) element.tmpH = element.offsetHeight
                    element.style.height = `${element.tmpH - (ev.pageY - element.tmpY)}px`
                    if (!element.tmpX) element.tmpX = ev.pageX
                    if (!element.tmpW) element.tmpW = element.offsetWidth
                    element.style.width = `${element.tmpW + (ev.pageX - element.tmpX)}px`
                    if (i === 0) socket.emit('page-demo', { pageid: _pageId, type: "SELECTID", data: element.id })
                })
                break;
            case "bottomright":
                _layerElement.forEach((element, i) => {
                    if (!element.tmpY) element.tmpY = ev.pageY
                    if (!element.tmpH) element.tmpH = element.offsetHeight
                    element.style.height = `${element.tmpH + (ev.pageY - element.tmpY)}px`
                    if (!element.tmpX) element.tmpX = ev.pageX
                    if (!element.tmpW) element.tmpW = element.offsetWidth
                    element.style.width = `${element.tmpW + (ev.pageX - element.tmpX)}px`
                    if (i === 0) socket.emit('page-demo', { pageid: _pageId, type: "SELECTID", data: element.id })
                })
                break;
            case "bottomleft":
                _layerElement.forEach((element, i) => {
                    if (!element.tmpY) element.tmpY = ev.pageY
                    if (!element.tmpH) element.tmpH = element.offsetHeight
                    element.style.height = `${element.tmpH + (ev.pageY - element.tmpY)}px`
                    if (!element.tmpX) element.tmpX = ev.pageX
                    if (!element.tmpW) element.tmpW = element.offsetWidth
                    element.style.width = `${element.tmpW - (ev.pageX - element.tmpW)}px`
                    if (i === 0) socket.emit('page-demo', { pageid: _pageId, type: "SELECTID", data: element.id })
                })
                break;
            default:
                break;
        }
    }

    const checkResize = (ev, rect) => {
        if (ev.pageX >= (rect.x - 4) && ev.pageX <= (rect.x + 4)) {
            if (ev.pageY >= (rect.y - 4) && ev.pageY <= (rect.y + 4)) {
                return "topleft"
            } else if (ev.pageY >= (rect.bottom - 4) && ev.pageY <= (rect.bottom + 4)) {
                return "bottomleft"
            } else if (ev.pageY >= rect.y && ev.pageY <= rect.bottom) {
                return "left"
            } else return null
        } else if (ev.pageX >= (rect.right - 4) && ev.pageX <= (rect.right + 4)) {
            if (ev.pageY >= (rect.y - 4) && ev.pageY <= (rect.y + 4)) {
                return "topright"
            } else if (ev.pageY >= (rect.bottom - 4) && ev.pageY <= (rect.bottom + 4)) {
                return "bottomright"
            } else if (ev.pageY >= rect.y && ev.pageY <= rect.bottom) {
                return "right"
            } else return null
        } else if (ev.pageY >= (rect.y - 4) && ev.pageY <= (rect.y + 4) && ev.pageX >= rect.x && ev.pageX <= rect.right) {
            return "top"
        } else if (ev.pageY >= (rect.bottom - 4) && ev.pageY <= (rect.bottom + 4) && ev.pageX >= rect.x && ev.pageX <= rect.right) {
            return "bottom"
        } else return null
    }

    const handleIframeOnClick = (ev, level = 1) => {
        ev.preventDefault()
        ev.stopPropagation()
        const _layerItem = ev.target.closest('.layer-item')
        if (_layerItem?.id) {
            if (ev.ctrlKey) {
                methods.setValue("selectedId", _layerItem?.id)
            } else {
                let parentLayerItems = [...iframeRef.current.contentWindow.document.querySelectorAll(`.layer-item:has(.layer-item[id="${_layerItem.id}"])`)]
                parentLayerItems.push(_layerItem)
                parentLayerItems = parentLayerItems.slice(0, parseInt(iframeRef.current.getAttribute("level")) + level)
                methods.setValue("selectedId", parentLayerItems.pop().id)
            }
        }
    }

    const handleIframeOnMouseMove = (ev) => {
        ev.stopPropagation()
        ev.preventDefault()
        const _layerElement = iframeRef.current.contentWindow.document.querySelectorAll(`.layer-item[id="${iframeRef.current.getAttribute('selected-id')}"]`)
        const _selectedLayer = _layerElement[0]
        if (ev.buttons === 1) {
            if (_selectedLayer?.classList.contains("page-container")) return false
            if (!iframeRef.current.getAttribute("on-drag")) return iframeRef.current.setAttribute("on-drag", "true")
            let _layerItem = iframeRef.current.contentWindow.document.body.querySelector(".dragging.layer-item:not(.layout-body, .main-layout)")
            if (_layerItem) handleDragToTarget(ev, _layerItem, true)
            else if (iframeRef.current.contentWindow.document.body.getAttribute("on-resize")) onResize(ev, _layerElement)
            else _layerElement.forEach((e) => e.classList.add('dragging'))
        } else {
            if (_selectedLayer) {
                const _selectedRect = _selectedLayer.getBoundingClientRect()
                var resizeType = checkResize(ev, _selectedRect)
            }
            if (resizeType) return iframeRef.current.contentWindow.document.body.setAttribute("on-resize", resizeType)
            else iframeRef.current.contentWindow.document.body.removeAttribute("on-resize")
            const _layerItem = ev.target.closest('.layer-item')
            if (_layerItem) {
                if (ev.ctrlKey) {
                    methods.setValue("hoverId", _layerItem?.id)
                } else {
                    let parentLayerItems = [...iframeRef.current.contentWindow.document.querySelectorAll(`.layer-item:has(.layer-item[id="${_layerItem.id}"])`)]
                    parentLayerItems.push(_layerItem)
                    parentLayerItems = parentLayerItems.slice(0, parseInt(iframeRef.current.getAttribute("level")) + 1)
                    methods.setValue("hoverId", parentLayerItems.pop().id)
                }
            }
        }
    }

    useEffect(() => {
        if (!iframeRef.current || !layers?.length || !layout?.length) return false;
        const handleOnKeyDown = (ev) => {
            if (!['input', 'textarea'].includes(ev.target.localName)) {
                const _iframe = document.getElementById('iframe-render-page-view')
                const _selectedId = _iframe.getAttribute('selected-id')
                const _element = _iframe.contentWindow.document.getElementById(_selectedId)
                if (_element) {
                    let _deleteIds = []
                    switch (ev.key.toLowerCase()) {
                        case 'delete':
                            if (['page-container', 'main-layout', 'layout-body'].every(v => !_element.classList.contains(v))) {
                                _deleteIds = [..._element.querySelectorAll('.layer-item[id]')].map(e => e.id)
                                _deleteIds.push(_selectedId)
                            }
                            break;
                        case 'backspace':
                            if (['page-container', 'main-layout', 'layout-body'].every(v => !_element.classList.contains(v))) {
                                _deleteIds = [..._element.querySelectorAll('.layer-item[id]')].map(e => e.id)
                                _deleteIds.push(_selectedId)
                            }
                            break;
                        case 'c':
                            if (ev.ctrlKey) {
                                const _copyIds = [..._element.querySelectorAll('*[id]')].map(e => e.id).filter(id => id.length === 32)
                                _copyIds.unshift(_selectedId)
                                const copyList = []
                                for (const e of [...layers, ...layout]) {
                                    const _idx = _copyIds.findIndex(id => id === e.Id)
                                    if (_idx >= 0) copyList.push({ ...e, '__sort': _idx })
                                }
                                return Ultis.setCookie("copy-layers", JSON.stringify(copyList), 0.0035)
                            }
                            break;
                        case 'v':
                            if (ev.ctrlKey) {
                                let _pasteLayer = Ultis.getCookie("copy-layers")
                                if (_pasteLayer) {
                                    const _newLayers = []
                                    _pasteLayer = JSON.parse(_pasteLayer).sort((a, b) => a['__sort'] - b['__sort'])
                                    const _newParentId = _iframe.contentWindow.document.getElementById(_pasteLayer[0].Id !== _selectedId ? _selectedId : _pasteLayer[0].ParentId)?.closest('div[component-type="Container"]:not(.layout-body, .main-layout)')?.id
                                    if (_newParentId) {
                                        if (_element.getAttribute("layout-id")) var _layoutId = _element.getAttribute("layout-id")
                                        _pasteLayer.forEach((e, i) => {
                                            let _tmp = { ...e, ProjectId: ConfigApi.currentPid }
                                            delete _tmp['__sort']
                                            if (_layoutId) _tmp.LayoutId = _layoutId
                                            else {
                                                delete _tmp.LayoutId
                                                _tmp.PageId = _pageId
                                            }
                                            _tmp.id = _tmp.Id
                                            _tmp.Id = randomGID()
                                            _tmp.DateCreated = Date.now()
                                            if (i === 0) _tmp.ParentId = _newParentId
                                            else if (i) _tmp.ParentId = _newLayers.find(el => el.id === _tmp.ParentId)?.Id
                                            _newLayers.push(_tmp)
                                        });
                                        PageActions.addLayers(dispatch, _newLayers.map(e => {
                                            let _tmp = { ...e }
                                            delete _tmp.id
                                            return _tmp
                                        }))
                                        setTimeout(() => {
                                            methods.setValue("selectedId", _newLayers[0].Id)
                                        }, 200)
                                    }
                                }
                            }
                            break;
                        default:
                            break;
                    }
                    if (_deleteIds.length) PageActions.deleteLayers(dispatch, _deleteIds)
                }
            }
        }
        const selectedId = methods.watch("selectedId")
        if (selectedId) {
            iframeRef.current.setAttribute("level", iframeRef.current.contentWindow.document.querySelectorAll(`.layer-item:has(.layer-item[id="${selectedId}"])`).length)
        } else {
            iframeRef.current.setAttribute("level", 1)
        }
        iframeRef.current.contentWindow.addEventListener('keydown', handleOnKeyDown);
        window.addEventListener("keydown", handleOnKeyDown)
        return () => {
            if (iframeRef.current?.contentWindow) iframeRef.current.contentWindow.removeEventListener("keydown", handleOnKeyDown)
            window.removeEventListener("keydown", handleOnKeyDown)
        }
    }, [iframeRef.current, methods.watch("selectedId"), layers?.length, layout?.length])

    useEffect(() => {
        if (!iframeRef.current) return
        const handleIframeOnMouseUp = () => {
            const resizeType = iframeRef.current.contentWindow.document.body.getAttribute("on-resize")
            let _layerItem = iframeRef.current.contentWindow.document.body.querySelector(".dragging.layer-item:not(.layout-body, .main-layout)")
            if (iframeRef.current.getAttribute("on-drag")) {
                if (resizeType) {
                    const _selectedId = iframeRef.current.getAttribute('selected-id')
                    if (_selectedId) {
                        const _layerElement = iframeRef.current.contentWindow.document.querySelectorAll(`.layer-item[id="${_selectedId}"]`)
                        _layerElement.forEach(e => {
                            delete e.tmpW
                            delete e.tmpH
                            delete e.tmpX
                            delete e.tmpY
                        })
                        let tmp = [...layers, ...layout].find(e => e.Id === _selectedId)
                        if (tmp) {
                            tmp = { ...tmp, Setting: { ...tmp.Setting, style: { ...tmp.Setting.style } } }
                            if (["top", "bottom"].some(t => resizeType.includes(t)))
                                tmp.Setting.style.height = `${Math.round(parseFloat(_layerElement[0].style.height.replace("px", "")))}px`
                            if (["left", "right"].some(t => resizeType.includes(t)))
                                tmp.Setting.style.width = `${Math.round(parseFloat(_layerElement[0].style.width.replace("px", "")))}px`
                            tmp.Setting = JSON.stringify(tmp.Setting)
                            PageActions.editLayers(dispatch, [tmp])
                        }
                    }
                } else if (_layerItem) {
                    let _demo = iframeRef.current.contentWindow.document.body.querySelector(`div[class*="demo-component-in-container"]`)
                    if (_demo) {
                        const _parent = _demo.parentElement
                        const _children = [..._parent.children].filter(e => e.id !== _layerItem.id).sort((a, b) => parseInt(window.getComputedStyle(a).order) - parseInt(window.getComputedStyle(b).order))
                        const _layerIds = []
                        _children.forEach(e => {
                            const _id = e.id?.length === 32 ? e.id : _layerItem.id
                            if (_layerIds.every(id => _id !== id)) _layerIds.push(_id)
                        })
                        const _layoutItem = _parent.closest('div[layout-id]')
                        let _layoutId = undefined
                        if (_layoutItem) {
                            _layoutId = _layoutItem.getAttribute('layout-id')
                            if (_layoutId === _layoutItem.id || _layoutItem.classList.contains('layout-body')) _layoutId = undefined
                        }
                        const _layerChildren = (_layoutId ? layout : layers).filter(e => e.ParentId === _parent.id || e.Id === _layerItem.id)
                        const updateChildren = iframeRef.current.contentWindow.document.querySelectorAll(`[id="${_layerItem.id}"]`)
                        updateChildren.forEach(e => e.classList.remove('dragging'))
                        _demo.remove()
                        PageActions.editLayers(dispatch, _layerIds.map((id, i) => {
                            let _tmp = _layerChildren.find(el => el.Id === id)
                            return {
                                ..._tmp,
                                ParentId: _parent.id,
                                LayoutId: _layoutId,
                                PageId: _layoutId ? undefined : page.Id,
                                Setting: JSON.stringify({ ..._tmp.Setting, style: { ..._tmp.Setting.style, order: i } }),
                            }
                        }))
                    }
                }
                iframeRef.current.classList.remove("dragging")
                iframeRef.current.removeAttribute("on-drag")
            }
        }
        const handleIframeOnDrop = (ev) => {
            ev.preventDefault()
            let _demo = iframeRef.current.contentWindow.document.body.querySelector(`div[class*="demo-component-in-container"]`)
            if (_demo) {
                const _parent = _demo.parentElement
                const _children = [..._parent.children].sort((a, b) => parseInt(window.getComputedStyle(a).order) - parseInt(window.getComputedStyle(b).order))
                const _layoutItem = _parent.closest('div[layout-id]')
                let _layoutId = undefined
                if (_layoutItem) {
                    _layoutId = _layoutItem.getAttribute('layout-id')
                    if (_layoutId === _layoutItem.id || _layoutItem.classList.contains('layout-body')) _layoutId = undefined
                }
                const _layerChildren = (_layoutId ? layout : layers).filter(e => e.ParentId === _parent.id)
                PageActions.editLayers(dispatch, _children.map((e, i) => {
                    let _tmp;
                    if (e.id) {
                        _tmp = _layerChildren.find(el => el.Id === e.id)
                        if (_tmp) return { ..._tmp, Setting: JSON.stringify({ ..._tmp.Setting, style: { ..._tmp.Setting.style, order: i } }) }
                    }
                    _tmp = {
                        LayoutId: _layoutId,
                        PageId: _layoutId ? undefined : page.Id,
                        DateCreated: Date.now(),
                        ParentId: _parent.id,
                    }
                    const _compType = ev.dataTransfer.getData('component-type')
                    switch (_compType) {
                        case ComponentType.form:
                            _tmp = {
                                ..._tmp,
                                Name: ComponentType.form,
                                Type: ComponentType.form,
                                Setting: { formId: ev.dataTransfer.getData('component-id'), style: { order: i } }
                            }
                            break;
                        case ComponentType.card:
                            _tmp = {
                                ..._tmp,
                                Name: ComponentType.card,
                                Type: ComponentType.card,
                                Setting: { cardId: ev.dataTransfer.getData('component-id'), style: { width: ev.dataTransfer.getData('component-width'), order: i } }
                            }
                            break;
                        case ComponentType.chart:
                            _tmp = {
                                ..._tmp,
                                Name: ComponentType.chart,
                                Type: ComponentType.chart,
                                Setting: { cardId: ev.dataTransfer.getData('component-id'), style: { order: i } }
                            }
                            break;
                        case ComponentType.container:
                            _tmp = {
                                ..._tmp,
                                Name: ComponentType.container,
                                Type: ComponentType.container,
                                Setting: { style: { order: i } }
                            }
                            const parentStyle = window.getComputedStyle(_parent)
                            if (parentStyle.flexDirection === "row") {
                                _tmp.Setting.style.height = parentStyle.flexWrap === "wrap" ? '4.8rem' : parentStyle.height === "fit-content" || parentStyle.height === "auto" ? '100%' : '4.8rem'
                                _tmp.Setting.style.width = '8rem'
                            } else {
                                _tmp.Setting.style.width = parentStyle.width === "fit-content" || parentStyle.width === "auto" ? '100%' : '4.8rem'
                                _tmp.Setting.style.height = '8rem'
                            }
                            break;
                        default:
                            _tmp = {
                                ..._tmp,
                                Name: _compType,
                                Type: _compType,
                                Setting: { style: { order: i } }
                            }
                            break;
                    }
                    return _tmp
                }))
                _parent.removeChild(_demo)
            }
            iframeRef.current.classList.remove('dragging')
        }
        if (layers?.length || layout?.length) {
            iframeRef.current.contentWindow.addEventListener('drop', handleIframeOnDrop);
            iframeRef.current.contentWindow.addEventListener('mouseup', handleIframeOnMouseUp);
        }
        return () => {
            if (iframeRef.current?.contentWindow) {
                iframeRef.current.contentWindow.removeEventListener('mouseup', handleIframeOnMouseUp)
                iframeRef.current.contentWindow.removeEventListener('drop', handleIframeOnDrop)
            }
        }
    }, [layers, layout, iframeRef?.current])

    return <div ref={settingPageRef} className={`col ${styles['setting-page-body']}`}>
        <div style={{ position: 'relative', flex: 1, width: '100%', height: '100%' }}>
            <iframe title='demo-page' ref={iframeRef} id='iframe-render-page-view' selected-id={methods.watch("selectedId")}
                onLoad={(ev) => {
                    setTimeout(() => {
                        const iframeDoc = ev.target.contentDocument || ev.target.contentWindow.document;
                        iframeDoc.addEventListener('mousemove', handleIframeOnMouseMove);
                        iframeDoc.addEventListener('click', handleIframeOnClick);
                        iframeDoc.addEventListener('dblclick', (ev) => { handleIframeOnClick(ev, 2) });
                        iframeDoc.addEventListener('dragover', handleDragToTarget);
                        socket.emit('page-demo', { pageid: page.Id, type: "SYNC", data: { page, layers, layout } })
                    }, 1000)
                }} src={`${window.location.origin}/demoPage?pageid=${_pageId}`} />
        </div>

    </div>
}