import React, { useEffect, useRef } from "react";
import ReactEcharts from "echarts-for-react";
import { checkTimeChartType, formatNumberConvert } from "../../../Utils";
import { EChartType } from "../da";

export default function RenderChartByType(props: { type: EChartType, datasets: Array<any>, pieColors?: Array<string>, labels?: Array<string>, legendPosition?: "left" | "top" | "right" | "bottom" | "center" }) {
    let option: any;
    switch (props.type) {
        case EChartType.line:
            option = {
                legend: {
                    left: 'left',
                    icon: 'roundRect',
                    borderRadius: 8,
                },
                tooltip: {},
                dataset: {
                    source: [
                        ['0', ...(props.labels ?? [])],
                        ...props.datasets
                    ]
                },
                yAxis: {
                    type: 'value',
                    axisLine: {
                        lineStyle: {
                            type: 'dotted'
                        }
                    },
                    axisLabel: {
                        formatter: (value: any) => formatNumberConvert(value)
                    }
                },
                xAxis: { type: 'category', boundaryGap: false },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                series: props.pieColors?.map((c) => {
                    return {
                        type: props.type,
                        itemStyle: { color: c, borderRadius: [2, 2, 0, 0] },
                        radius: '50%',
                        barMinWidth: 8,
                        // barWidth: 8,
                        barMaxWidth: 80,
                        barGap: 0.2,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        },
                    }
                }),
            };
            break;
        case EChartType.bar:
            option = {
                legend: {
                    left: 'left',
                    icon: 'roundRect',
                    borderRadius: 8,
                },
                tooltip: {},
                dataset: {
                    source: [
                        ['0', ...(props.labels ?? [])],
                        ...props.datasets
                    ]
                },
                yAxis: {
                    type: 'value',
                    axisLine: {
                        lineStyle: {
                            type: 'dotted'
                        }
                    },
                    axisLabel: {
                        formatter: (value: any) => formatNumberConvert(value)
                    }
                },
                xAxis: { type: 'category' },
                series: props.pieColors?.map((c) => {
                    return {
                        type: props.type,
                        itemStyle: { color: c, borderRadius: [2, 2, 0, 0] },
                        radius: '50%',
                        barMinWidth: 8,
                        // barWidth: 8,
                        barMaxWidth: 80,
                        barGap: 0.2,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        },
                    }
                }),
            };
            break;
        case EChartType.horizontalBar:
            option = {
                legend: {
                    left: 'left',
                    icon: 'roundRect',
                    borderRadius: 8,
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                dataset: {
                    source: [
                        ['0', ...(props.labels ?? [])],
                        ...props.datasets
                    ]
                },
                xAxis: {
                    type: 'value',
                    axisLine: {
                        lineStyle: {
                            type: 'dotted'
                        }
                    },
                    axisLabel: {
                        formatter: (value: any) => formatNumberConvert(value)
                    }
                },
                yAxis: { type: 'category' },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                series: props.pieColors?.map((c) => {
                    return {
                        type: 'bar',
                        itemStyle: { color: c, borderRadius: [2, 2, 0, 0] },
                        radius: '50%',
                        barMinWidth: 8,
                        // barWidth: 8,
                        barMaxWidth: 80,
                        barGap: 0.2,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        },
                    }
                }),
            };
            break;
        case EChartType.scatter:
            break;
        case EChartType.bubble:
            break;
        case EChartType.radar:
            option = {
                legend: {
                    left: 'left',
                    icon: 'roundRect',
                    borderRadius: 8,
                },
                radar: {
                    // shape: 'circle',
                    indicator: props.datasets.map((e: Array<any>) => {
                        return {
                            name: e[0],
                            max: Math.max(...e.slice(1))
                        }
                    })
                },
                series: props.pieColors?.map((c) => {
                    return {
                        type: props.type,
                        itemStyle: { color: c, borderRadius: [2, 2, 0, 0] },
                        radius: '50%',
                        barMinWidth: 8,
                        // barWidth: 8,
                        barMaxWidth: 80,
                        barGap: 0.2,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        },
                    }
                }),
            };
            break;
        default:
            break;
    }

    // const option = {
    //     legend: {
    //         left: 'left',
    //         icon: 'roundRect',
    //         borderRadius: 8,
    //     },
    //     tooltip: {},
    //     dataset: checkTimeChartType(props.type) ? {
    //         source: [
    //             ['0', ...(props.labels ?? [])],
    //             ...props.datasets,
    //         ]
    //     } : undefined,
    //     yAxis: checkTimeChartType(props.type) ? [{
    //         type: 'value',
    //         axisLine: {
    //             lineStyle: {
    //                 type: 'dotted'
    //             }
    //         },
    //         axisLabel: {
    //             formatter: (value: any) => formatNumberConvert(value)
    //         }
    //     },] : undefined,
    //     xAxis: checkTimeChartType(props.type) ? {
    //         type: 'category',
    //         data: props.labels ?? []
    //     } : undefined,
    //     series: props.datasets.map((item: any) => (
    //         {
    //             type: props.type,
    //             itemStyle: checkTimeChartType(props.type) ? {
    //                 color: item.color,
    //                 borderRadius: [2, 2, 0, 0]
    //             } : undefined,
    //             data: checkTimeChartType(props.type) ? undefined : props.labels?.map((itemLb, index) => ({
    //                 value: props.datasets[index], name: itemLb, itemStyle: {
    //                     color: props.pieColors![index],
    //                 },
    //             })),
    //             radius: '50%',
    //             barMinWidth: 8,
    //             barWidth: 8,
    //             barGap: 0.2,
    //             emphasis: {
    //                 itemStyle: {
    //                     shadowBlur: 10,
    //                     shadowOffsetX: 0,
    //                     shadowColor: 'rgba(0, 0, 0, 0.5)'
    //                 }
    //             },
    //         }
    //     ))
    // };

    return option && <ReactEcharts option={option} style={{ width: '100%', aspectRatio: '2 / 1' }} />;
}

