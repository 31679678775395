import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { Select1, Text, TextField, Winicon } from "wini-web-components"
import { cssRegex, onFocus, transformRegex } from "../functions/common"

export const PositionProperties = ({ selected, onChange, docBody = document.body.querySelector(`div[class*="overview-container"]`) }) => {
    const methods = useForm({ shouldFocusError: false })
    const [isOpen, setIsOpen] = useState(true)
    const [nodeElement, setNode] = useState()

    useEffect(() => {
        const node = docBody.querySelector(`*[id="${selected.Id}"]`)
        if (node.id !== nodeElement?.id) setNode(node)
        if (node) {
            const _nodeStyle = window.getComputedStyle(node)
            if (selected.Setting.style.position) methods.setValue('position', selected.Setting.style.position)
            else methods.setValue('position', _nodeStyle.position)
            const transforms = selected.Setting.style.transform?.match(transformRegex) ?? []
            const _transX = transforms.find(e => e.startsWith('translateX'))
            methods.setValue('translateX', _transX ? _transX.substring(_transX.indexOf("(") + 1, _transX.lastIndexOf(")")) : "none")
            const _transY = transforms.find(e => e.startsWith('translateY'))
            methods.setValue('translateY', _transY ? _transY.substring(_transY.indexOf("(") + 1, _transY.lastIndexOf(")")) : "none")
            if (selected.Setting.style.top) methods.setValue('top', selected.Setting.style.top)
            else methods.setValue('top', "auto")
            if (selected.Setting.style.right) methods.setValue('right', selected.Setting.style.right)
            else methods.setValue('right', "auto")
            if (selected.Setting.style.bottom) methods.setValue('bottom', selected.Setting.style.bottom)
            else methods.setValue('bottom', "auto")
            if (selected.Setting.style.left) methods.setValue('left', selected.Setting.style.left)
            else methods.setValue('left', "auto")
        }
    }, [selected])

    return <div className='col' style={{ borderBottom: 'var(--neutral-main-border)' }}>
        <div className="row" style={{ padding: '0.8rem 1.2rem' }}>
            <Text className="semibold1" style={{ flex: 1 }}>Position</Text>
            <Winicon onClick={() => { setIsOpen(!isOpen) }} size={'1.4rem'} style={{ padding: "0.2rem" }} src={`outline/arrows/${isOpen ? "up" : "down"}-arrow`} />
        </div>
        <div className="col" style={{ padding: '0 1.2rem 0.8rem', gap: '0.8rem', display: isOpen ? 'flex' : 'none' }}>
            <div className='row' style={{ gap: '0.8rem', flexWrap: "wrap" }}>
                <Select1
                    readOnly
                    style={{ width: `calc(100% - 2.4rem - 2px - 0.8rem)` }}
                    prefix={<Winicon src="outline/location/appointment" size='1rem' />}
                    className="regular1"
                    name="position"
                    placeholder="position"
                    value={methods.watch('position')}
                    options={['static', 'sticky', 'relative', 'absolute', 'fixed'].map(e => {
                        return { id: e, name: e }
                    })}
                    onChange={(v) => {
                        if (v.id !== methods.getValues('position')) {
                            let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, position: v.id } } }
                            if (v.id === "static") delete _tmp.Setting.style.position
                            onChange(_tmp)
                        }
                    }}
                />
                <TextField
                    style={{ width: `calc((100% - 2.4rem - 2px - 1.6rem) / 2)` }}
                    onComplete={(ev) => ev.target.blur()}
                    prefix={<Text className="subtitle-5">X</Text>}
                    className="regular1"
                    name="translateX"
                    placeholder="translateX"
                    onFocus={(ev) => onFocus(ev, methods)}
                    register={methods.register('translateX', {
                        onBlur: (ev) => {
                            const _newV = ev.target.value.trim().toLowerCase()
                            if (cssRegex.test(_newV)) {
                                const _transY = methods.getValues("translateY")
                                let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, transform: `${_newV === "none" || _newV === "auto" ? "" : `translateX(${_newV})`} ${_transY === "none" || _transY === "auto" ? "" : `translateY(${_transY})`}`.trim() } } }
                                if (!_tmp.Setting.style.transform.length) delete _tmp.Setting.style.transform
                                onChange(_tmp)
                            } else ev.target.value = methods.getValues("_tmp")
                            methods.setValue('_tmp', null)
                        }
                    })}
                />
                <TextField
                    style={{ width: `calc((100% - 2.4rem - 2px - 1.6rem) / 2)` }}
                    onComplete={(ev) => ev.target.blur()}
                    prefix={<Text className="subtitle-5">Y</Text>}
                    className="regular1"
                    name="translateY"
                    placeholder="translateY"
                    onFocus={(ev) => onFocus(ev, methods)}
                    register={methods.register('translateY', {
                        onBlur: (ev) => {
                            const _newV = ev.target.value.trim().toLowerCase()
                            if (cssRegex.test(_newV)) {
                                const _transX = methods.getValues("translateX")
                                let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, transform: `${_transX === "none" || _transX === "auto" ? "" : `translateX(${_transX})`} ${_newV === "none" || _newV === "auto" ? "" : `translateY(${_newV})`}`.trim() } } }
                                if (!_tmp.Setting.style.transform.length) delete _tmp.Setting.style.transform
                                onChange(_tmp)
                            } else ev.target.value = methods.getValues("_tmp")
                            methods.setValue('_tmp', null)
                        }
                    })}
                />
                <TextField
                    style={{ width: `calc((100% - 2.4rem - 2px - 1.6rem) / 2)` }}
                    onComplete={(ev) => ev.target.blur()}
                    prefix={<Text className="subtitle-5">T</Text>}
                    className="regular1"
                    name="top"
                    placeholder="top"
                    onFocus={(ev) => onFocus(ev, methods)}
                    register={methods.register('top', {
                        onBlur: (ev) => {
                            const _newV = ev.target.value.trim().toLowerCase()
                            if (cssRegex.test(_newV)) {
                                let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, top: _newV } } }
                                if (_newV === "auto" || _newV === "none") delete _tmp.Setting.style.top
                                onChange(_tmp)
                            } else ev.target.value = methods.getValues("_tmp")
                            methods.setValue('_tmp', null)
                        }
                    })}
                />
                <TextField
                    style={{ width: `calc((100% - 2.4rem - 2px - 1.6rem) / 2)` }}
                    onComplete={(ev) => ev.target.blur()}
                    prefix={<Text className="subtitle-5">R</Text>}
                    className="regular1"
                    name="right"
                    placeholder="right"
                    onFocus={(ev) => onFocus(ev, methods)}
                    register={methods.register('right', {
                        onBlur: (ev) => {
                            const _newV = ev.target.value.trim().toLowerCase()
                            if (cssRegex.test(_newV)) {
                                let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, right: _newV } } }
                                if (_newV === "auto" || _newV === "none") delete _tmp.Setting.style.right
                                onChange(_tmp)
                            } else ev.target.value = methods.getValues("_tmp")
                            methods.setValue('_tmp', null)
                        }
                    })}
                />
                <TextField
                    style={{ width: `calc((100% - 2.4rem - 2px - 1.6rem) / 2)` }}
                    onComplete={(ev) => ev.target.blur()}
                    prefix={<Text className="subtitle-5">B</Text>}
                    className="regular1"
                    name="bottom"
                    placeholder="bottom"
                    onFocus={(ev) => onFocus(ev, methods)}
                    register={methods.register('bottom', {
                        onBlur: (ev) => {
                            const _newV = ev.target.value.trim().toLowerCase()
                            if (cssRegex.test(_newV)) {
                                let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, bottom: _newV } } }
                                if (_newV === "auto" || _newV === "none") delete _tmp.Setting.style.bottom
                                onChange(_tmp)
                            } else ev.target.value = methods.getValues("_tmp")
                            methods.setValue('_tmp', null)
                        }
                    })}
                />
                <TextField
                    style={{ width: `calc((100% - 2.4rem - 2px - 1.6rem) / 2)` }}
                    onComplete={(ev) => ev.target.blur()}
                    prefix={<Text className="subtitle-5">L</Text>}
                    className="regular1"
                    name="left"
                    placeholder="left"
                    onFocus={(ev) => onFocus(ev, methods)}
                    register={methods.register('left', {
                        onBlur: (ev) => {
                            const _newV = ev.target.value.trim().toLowerCase()
                            if (cssRegex.test(_newV)) {
                                let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, left: _newV } } }
                                if (_newV === "auto" || _newV === "none") delete _tmp.Setting.style.left
                                onChange(_tmp)
                            } else ev.target.value = methods.getValues("_tmp")
                            methods.setValue('_tmp', null)
                        }
                    })}
                />
            </div>
        </div>
    </div>
}
