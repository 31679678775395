import styles from './index.module.css'
import { forwardRef, useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { closePopup, Popup, Select1, showPopup, Text, TextField, Winicon } from "wini-web-components"
import { FilledLetterSpacing } from "../../../../../assets/icon"
import { ColorPicker, PopupSelectTokens } from "../functions/funtions"
import { Ultis } from "../../../../../Utils"
import { cssRegex, onFocus } from '../functions/common'
import { DesignTokenType } from '../../../../wini/designToken/da'
import { useSelector } from 'react-redux'

export const FontProperties = ({ selected, onChange, docBody = document.body.querySelector(`div[class*="overview-container"]`) }) => {
    const methods = useForm({ shouldFocusError: false })
    const [isOpen, setIsOpen] = useState(true)
    const [nodeElement, setNode] = useState()
    const popupRef = useRef()

    useEffect(() => {
        const node = docBody.querySelector(`*[id="${selected.Id}"]`)
        if (node.id !== nodeElement?.id) setNode(node)
        if (node) {
            const _nodeStyle = window.getComputedStyle(node)
            if (!selected.Setting.style.font) {
                methods.setValue('font-family', _nodeStyle.fontFamily.replaceAll('"', ''))
                methods.setValue('font-weight', _nodeStyle.fontWeight)
                if (selected.Setting.style?.fontSize) methods.setValue('font-size', selected.Setting.style.fontSize)
                else methods.setValue('font-size', _nodeStyle.fontSize)
                if (selected.Setting.style?.lineHeight) methods.setValue('line-height', selected.Setting.style.lineHeight)
                else methods.setValue('line-height', _nodeStyle.lineHeight)
            }
            methods.setValue('text-align', _nodeStyle.textAlign)
            methods.setValue('max-line', _nodeStyle.getPropertyValue('--max-line')?.length ? _nodeStyle.getPropertyValue('--max-line') : "none")
            if (selected.Setting.style?.letterSpacing) methods.setValue('letter-spacing', selected.Setting.style.letterSpacing)
            else methods.setValue('letter-spacing', _nodeStyle.letterSpacing)
            if (!selected.Setting.style?.color?.startsWith("var")) {
                const _color = Ultis.rgbToHex(_nodeStyle.color)
                methods.setValue('color', _color)
                methods.setValue('color-value', _color.substring(0, 7))
                methods.setValue('color-opacity', `${Ultis.hexToPercent(_color.substring(7))}`)
                methods.setValue('opacity', parseFloat(_nodeStyle.opacity))
            }
        }
    }, [selected])

    const showPopupSelectToken = (ev) => {
        const _rect = ev.target.closest(".row").getBoundingClientRect()
        showPopup({
            ref: popupRef,
            clickOverlayClosePopup: true,
            style: { position: 'absolute', top: _rect.bottom, left: _rect.x, width: _rect.width, maxHeight: "fit-content" },
            body: <PopupSelectTokens
                ref={popupRef}
                selected={selected.Setting.style.font}
                type={DesignTokenType.string}
                onSelect={(ev) => {
                    let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, font: `var(${ev.Name})` } } }
                    delete _tmp.Setting.style.fontWeight
                    delete _tmp.Setting.style.fontSize
                    delete _tmp.Setting.style.lineHeight
                    delete _tmp.Setting.style.fontFamily
                    onChange(_tmp)
                }}
            />
        })
    }

    return <div className='col' style={{ borderBottom: 'var(--neutral-main-border)' }}>
        <Popup ref={popupRef} />
        <div className="row" style={{ padding: '0.8rem 1.2rem', gap: "0.4rem" }}>
            <Text className="semibold1" style={{ flex: 1 }}>Font</Text>
            <Winicon onClick={showPopupSelectToken} size={'1.4rem'} style={{ padding: "0.2rem" }} src={`outline/business/coins`} />
            <Winicon onClick={() => { setIsOpen(!isOpen) }} size={'1.4rem'} style={{ padding: "0.2rem" }} src={`outline/arrows/${isOpen ? "up" : "down"}-arrow`} />
        </div>
        <div className="row" style={{ padding: '0 1.2rem 0.8rem', gap: '0.8rem', flexWrap: "wrap", display: isOpen ? 'flex' : 'none' }}>
            {selected.Setting.style.font ? <button type='button' className={`row ${styles['font-token-tile']} ${styles['selected']}`} onClick={showPopupSelectToken}>
                <Winicon src='outline/text/typography' size={"1rem"} />
                <Text className='label-5' style={{ flex: 1 }}>{selected.Setting.style.font.replace("var(", "").replace(")", "")}</Text>
            </button> : <>
                <Select1
                    readOnly
                    placeholder={'Font family'}
                    className="regular1 col24"
                    style={{ '--gutter': "0.8rem" }}
                    value={methods.watch('font-family')}
                    options={['Inter', 'Arial', 'Times New Roman', 'Verdana', 'Roboto', 'Source Serif 4'].map(e => {
                        return { id: e, name: e.replaceAll('"', '') }
                    })}
                    onChange={(v) => {
                        onChange({ ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, fontFamily: `"${v.id}"` } } })
                    }}
                />
                <Select1
                    readOnly
                    placeholder={'Font weight'}
                    prefix={<Winicon src="fill/text/bold" size={"1.2rem"} />}
                    className="regular1 col12"
                    style={{ '--gutter': "0.8rem" }}
                    value={methods.watch('font-weight')}
                    options={['200', '400', '500', '600', '800', '900'].map(e => {
                        return { id: e, name: e }
                    })}
                    onChange={(v) => {
                        onChange({ ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, fontWeight: v.id } } })
                    }}
                />
                <TextField
                    className="regular1 col12"
                    name="font-size"
                    prefix={<Winicon src="fill/text/increase-font-size" size={"1.2rem"} />}
                    style={{ '--gutter': "0.8rem" }}
                    placeholder="Font size"
                    onFocus={(ev) => onFocus(ev, methods)}
                    onComplete={(ev) => ev.target.blur()}
                    register={methods.register('font-size', {
                        onBlur: (ev) => {
                            const _newV = ev.target.value.trim().toLowerCase()
                            if (cssRegex.test(_newV)) {
                                let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, fontSize: _newV } } }
                                if (_newV === "auto" || _newV === "none" || _newV === "normal") delete _tmp.Setting.style.fontSize
                                onChange(_tmp)
                            } else ev.target.value = methods.getValues("_tmp")
                            methods.setValue('_tmp', null)
                        }
                    })}
                />
                <TextField
                    className="regular1 col12"
                    name="line-height"
                    style={{ '--gutter': "0.8rem" }}
                    placeholder="Line height"
                    prefix={<Winicon src="fill/text/line-height" size={"1.2rem"} />}
                    onFocus={(ev) => onFocus(ev, methods)}
                    onComplete={(ev) => ev.target.blur()}
                    register={methods.register('line-height', {
                        onBlur: (ev) => {
                            const _newV = ev.target.value.trim().toLowerCase()
                            if (cssRegex.test(_newV)) {
                                let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, lineHeight: _newV } } }
                                if (_newV === "auto" || _newV === "none" || _newV === "normal") delete _tmp.Setting.style.lineHeight
                                onChange(_tmp)
                            } else ev.target.value = methods.getValues("_tmp")
                            methods.setValue('_tmp', null)
                        }
                    })}
                />
                <TextField
                    className="regular1 col12"
                    name="letter-spacing"
                    style={{ '--gutter': "0.8rem" }}
                    placeholder="Letter spacing"
                    prefix={<FilledLetterSpacing color="var(--neutral-text-subtitle-color)" size={"1.2rem"} />}
                    onFocus={(ev) => onFocus(ev, methods)}
                    onComplete={(ev) => ev.target.blur()}
                    register={methods.register('letter-spacing', {
                        onBlur: (ev) => {
                            const _newV = ev.target.value.trim().toLowerCase()
                            if (cssRegex.test(_newV)) {
                                let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, letterSpacing: _newV } } }
                                if (_newV === "auto" || _newV === "none" || _newV === "normal") delete _tmp.Setting.style.letterSpacing
                                onChange(_tmp)
                            } else ev.target.value = methods.getValues("_tmp")
                            methods.setValue('_tmp', null)
                        }
                    })}
                />
            </>}
            <TextField
                className="regular1 col12"
                name="max-line"
                style={{ '--gutter': "0.8rem" }}
                placeholder="Max line"
                prefix={<Winicon src="fill/text/text-align-justify" size={"1.2rem"} />}
                onFocus={(ev) => onFocus(ev, methods)}
                onComplete={(ev) => ev.target.blur()}
                register={methods.register('max-line', {
                    onBlur: (ev) => {
                        const _newV = ev.target.value.trim()
                        let _tmp = { ...selected, Setting: { ...selected.Setting } }
                        if (_newV === "none" || _newV === "normal" || _newV === "auto") delete _tmp.Setting.maxLine
                        else if (!isNaN(parseInt(_newV))) _tmp.Setting.maxLine = parseInt(_newV)
                        else return methods.setValue('_tmp', null)
                        onChange(_tmp)
                        methods.setValue('_tmp', null)
                    }
                })}
            />
            <div className={`row ${styles['text-align-options']}`}>
                <Winicon
                    src={"outline/text/text-align-left"}
                    size={'1.4rem'}
                    className={['left', 'start'].includes(methods.watch("text-align")) ? styles['selected'] : ""}
                    onClick={() => {
                        onChange({ ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, textAlign: "left" } } })
                    }}
                />
                <Winicon
                    src={"outline/text/text-align-center"}
                    size={'1.4rem'}
                    className={methods.watch("text-align") === 'center' ? styles['selected'] : ""}
                    onClick={() => {
                        onChange({ ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, textAlign: "center" } } })
                    }}
                />
                <Winicon
                    src={"outline/text/text-align-justify"}
                    size={'1.4rem'}
                    className={methods.watch("text-align") === 'justify' ? styles['selected'] : ""}
                    onClick={() => {
                        onChange({ ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, textAlign: "justify" } } })
                    }}
                />
                <Winicon
                    src='outline/text/text-align-right'
                    size={'1.4rem'}
                    className={['right', 'end'].includes(methods.watch("text-align")) ? styles['selected'] : ""}
                    onClick={() => {
                        onChange({ ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, textAlign: "right" } } })
                    }}
                />
            </div>
            <ColorPicker
                style={{ width: "100%" }}
                colorName={'color-value'}
                colorOpcaityName={'color-opacity'}
                register={methods.register}
                opacity={methods.watch("opacity")}
                colorValue={methods.watch("color")}
                onChange={({ colorValue, opacity }) => {
                    onChange({ ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, color: colorValue, opacity: opacity } } })
                }}
                token={selected.Setting.style?.color?.startsWith("var") ? selected.Setting.style?.color : undefined}
                onSelectColorToken={(ev) => {
                    let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, color: `var(${ev.Name})` } } }
                    onChange(_tmp)
                }}
            />
        </div>
    </div>
}