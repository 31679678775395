import styles from './iconLibrary.module.css'
import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { InfiniteScroll, Text, TextField, Winicon } from "wini-web-components";
import { BaseDA } from '../da/baseDA';

export function IconLibrary({ style, onSelect }: { style?: CSSProperties, onSelect: (src: string) => void }) {
    const [filter, setFilter] = useState<undefined | string>();
    const [data, setData] = useState([]);
    const [endList, setEndList] = useState(false)
    const inputRef = useRef<any>()
    const iconsContainerRef = useRef<any>()

    const getIcons = async (page?: number) => {
        if (!page && endList) setEndList(false)
        if (!page || !endList) {
            const searchValue = inputRef.current?.getInput()?.value ?? ""
            console.log(searchValue)
            const response = await BaseDA.get(`https://apicore.wini.vn/api/file/icon-library?${filter ? `type=${filter}&` : ""}${searchValue.length ? `search=${searchValue}&` : ""}${page ? `page=${page}` : ""}`)
            if (response) setData(page ? [...data, ...response] : response)
            else setEndList(true)
        }
    }

    useEffect(() => {
        getIcons()
        iconsContainerRef.current?.parentElement?.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }, [filter])

    return <div className={`row ${styles['icon-library-container']}`} style={style}>
        <div className="col">
            <button type="button" className={`${styles['option']} ${filter ? "" : styles['selected']} semibold3`}>All</button>
            <button type="button" className={`${styles['option']} ${filter === "color" ? styles['selected'] : ""} semibold3`} onClick={() => { setFilter("color") }}>Color</button>
            <button type="button" className={`${styles['option']} ${filter === "fill" ? styles['selected'] : ""} semibold3`} onClick={() => { setFilter("fill") }}>Fill</button>
            <button type="button" className={`${styles['option']} ${filter === "outline" ? styles['selected'] : ""} semibold3`} onClick={() => { setFilter("outline") }}>Outline</button>
        </div>
        <InfiniteScroll className={`col ${styles['search-result-container']}`} handleScroll={endList || !data.length ? undefined : (async (onLoadMore) => {
            if (onLoadMore) getIcons(Math.ceil(data.length / 20) + 1)
        })}>
            <div className="col">
                <TextField
                    ref={inputRef}
                    placeholder="Search"
                    className={`placeholder-2 ${styles['search-input']}`}
                    prefix={<Winicon src="outline/user interface/zoom" size="1.4rem" />}
                    onComplete={(ev: any) => ev.target.blur()}
                    onBlur={(ev) => {
                        getIcons()
                        iconsContainerRef.current?.parentElement?.scrollTo({
                            top: 0,
                            behavior: "smooth"
                        })
                    }}
                />
            </div>
            <div ref={iconsContainerRef} className='row' style={{ flexWrap: "wrap", gap: "2.4rem", padding: "1.2rem" }}>
                {data.map(src => <div key={src} className={`col col6 ${styles['icon-item']}`} onDoubleClick={() => { onSelect(src) }}>
                    <Winicon src={src} size={"4rem"} />
                    <div className='label-4' style={{ width: "100%", wordBreak: "break-all" }}>{src}</div>
                </div>)}
            </div>
        </InfiniteScroll>
    </div>
}