import { useDispatch } from "react-redux"
import { PageActions } from "../reducer"
import { ImageProperties } from "./image/image"
import { BoxProperties } from "./dimension/dimension"
import { AppearanceProperties } from "./appearance/appearance"
import { PositionProperties } from "./position/position"

export default function SettingImg({ selected }) {
    const dispatch = useDispatch()
    const _iframe = document.getElementById("iframe-render-page-view")

    const props = {
        docBody: _iframe.contentWindow.document.body,
        selected: selected,
        onChange: (newValue) => {
            if (newValue) PageActions.updateLayerAll(dispatch, [newValue])
        }
    }

    return selected && <div className='col'>
        <ImageProperties {...props} />
        <PositionProperties {...props} />
        <BoxProperties {...props} />
        <AppearanceProperties {...props} />
    </div>
}