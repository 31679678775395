import { BaseDA } from "../../../da/baseDA";
import ConfigApi from "../../../da/configApi";
import { ProjectItem } from "./da";

export class ProjectController {
    static async getAll() {
        const res = await BaseDA.get(ConfigApi.url + 'wini/getAll', {
            headers: { module: 'Project' }
        })
        return res
    }

    static async getListSimple(options?: { page?: number, size?: number, query?: string, returns?: Array<string>, sortby?: { BY: string, DIRECTION?: "ASC" | "DESC" } }) {
        const res = await BaseDA.post(ConfigApi.url + 'wini/getListSimple', {
            headers: { module: 'Project' },
            body: { searchRaw: options?.query ?? "*", page: options?.page ?? 1, size: options?.size ?? 10, returns: options?.returns, sortby: options?.sortby }
        })
        return res
    }

    static async add(data: Array<ProjectItem>) {
        const res = await BaseDA.post(ConfigApi.url + 'wini/action?action=add', {
            headers: { module: 'Project' },
            body: { data: data }
        })
        return res
    }

    static async edit(data: Array<ProjectItem>) {
        const res = await BaseDA.post(ConfigApi.url + 'wini/action?action=edit', {
            headers: { module: 'Project' },
            body: { data: data }
        })
        return res
    }

    static async delete(ids: Array<string>) {
        const res = await BaseDA.post(ConfigApi.url + 'wini/action?action=delete', {
            headers: { module: 'Project' },
            body: { ids: ids }
        })
        return res
    }

    static async getByIds(ids: Array<string>) {
        const res = await BaseDA.post(ConfigApi.url + 'wini/getByIds', {
            headers: { module: 'Project' },
            body: { ids: ids }
        })
        return res
    }
}