import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { Select1, Text, TextField, Winicon } from "wini-web-components"
import { ColorPicker } from "../functions/funtions"
import { Ultis } from "../../../../../Utils"
import { BaseDA } from "../../../../../da/baseDA"
import ConfigApi from "../../../../../da/configApi"

export const BackgroundProperties = ({ selected, onChange, docBody = document.body.querySelector(`div[class*="overview-container"]`) }) => {
    const methods = useForm({ shouldFocusError: false })
    const [isOpen, setIsOpen] = useState(true)
    const [nodeElement, setNode] = useState()

    useEffect(() => {
        const node = docBody.querySelector(`*[id="${selected.Id}"]`)
        if (node.id !== nodeElement?.id) setNode(node)
        if (node) {
            const _nodeStyle = window.getComputedStyle(node)
            if (!selected.Setting.style?.backgroundColor?.startsWith("var")) {
                if (selected.Setting.style?.backgroundColor) {
                    const _bgColor = Ultis.rgbToHex(_nodeStyle.backgroundColor)
                    methods.setValue('background-color', _bgColor)
                    methods.setValue('background-color-value', _bgColor.substring(0, 7))
                    methods.setValue('background-color-opacity', `${Ultis.hexToPercent(_bgColor.substring(7))}`)
                    methods.setValue('opacity', parseFloat(_nodeStyle.opacity))
                    methods.setValue('background-color-id', undefined)
                } else {
                    methods.setValue('background-color-value', undefined)
                    methods.setValue('background-color', undefined)
                }
            }
            if (_nodeStyle.backgroundImage !== "none") {
                const _regex = /url\(["']?(.*?)["']?\)/;
                const match = _nodeStyle.backgroundImage.match(_regex);
                methods.setValue('background-image', match[1])
                methods.setValue('background-size', _nodeStyle.backgroundSize)
                const _position = _nodeStyle.backgroundPosition.split(" ")
                if (_position.every(v => v === "50%")) methods.setValue('background-position', "center")
                else if (_position[0] === "0%") methods.setValue('background-position', "left")
                else if (_position[0] === "100%") methods.setValue('background-position', "right")
                else if (_position[1] === "0%") methods.setValue('background-position', "top")
                else if (_position[1] === "100%") methods.setValue('background-position', "bottom")
            } else {
                methods.setValue('background-image', null)
                methods.setValue('background-size', null)
                methods.setValue('background-position', null)
            }
        }
    }, [selected])

    return <div className="col" style={{ borderBottom: 'var(--neutral-main-border)' }}>
        <div className="row" style={{ padding: '0.8rem 1.2rem', gap: '0.4rem' }}>
            <Text className="semibold1" style={{ flex: 1 }}>Background</Text>
            {!methods.watch("background-image") && <Winicon
                src="outline/development/image" size={'1.4rem'} style={{ padding: "0.2rem" }}
                onClick={() => {
                    let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, backgroundImage: "url(https://file-mamager.wini.vn/Upload/2024/09/yuy_81f2.jpg)" } } }
                    onChange(_tmp)
                }}
            />}
            {methods.watch("background-color-value") ?
                <Winicon onClick={() => { setIsOpen(!isOpen) }} size={'1.4rem'} style={{ padding: "0.2rem" }} src={`outline/arrows/${isOpen ? "up" : "down"}-arrow`} /> :
                <Winicon
                    size={'1.4rem'} style={{ padding: "0.2rem" }} src={`outline/user interface/e-add`}
                    onClick={() => {
                        let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, backgroundColor: "#EFEFF0FF" } } }
                        onChange(_tmp)
                    }}
                />
            }
        </div>
        <div className="col" style={{ padding: '0 1.2rem 0.8rem', gap: '0.8rem', display: isOpen ? 'flex' : 'none' }}>
            {selected.Setting.style?.backgroundColor && <ColorPicker
                colorName={'background-color-value'}
                colorOpcaityName={'background-color-opacity'}
                style={{ width: '100%', height: 28, }}
                register={methods.register}
                colorValue={methods.watch("background-color")}
                opacity={methods.watch('opacity')}
                onChange={({ colorValue, opacity }) => {
                    onChange({ ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, backgroundColor: colorValue, opacity: opacity } } })
                }}
                onDelete={() => {
                    let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style } } }
                    delete _tmp.Setting.style.backgroundColor
                    delete _tmp.Setting.style.opacity
                    onChange(_tmp)
                }}
                token={selected.Setting.style?.backgroundColor?.startsWith("var") ? selected.Setting.style?.backgroundColor : undefined}
                onSelectColorToken={(ev) => {
                    let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, backgroundColor: `var(${ev.Name})` } } }
                    onChange(_tmp)
                }}
            />}
            {methods.watch("background-size") ? <>
                <div className="row" style={{ gap: "1.2rem", width: "100%" }}>
                    <TextField
                        onComplete={(ev) => ev.target.blur()}
                        className="regular1"
                        style={{ flex: 1 }}
                        name="background-image"
                        placeholder="background-image"
                        onFocus={(ev) => { ev.target.select() }}
                        prefix={<label className="row">
                            <img src={methods.watch("background-image")} style={{ width: '2.4rem', height: '1.6rem', objectFit: 'cover', borderRadius: "0.2rem" }} />
                            <input type="file" accept="image/*" hidden onChange={async (ev) => {
                                if (ev.target.files && ev.target.files[0]) {
                                    const res = await BaseDA.uploadFiles(ev.target.files)
                                    if (res) onChange({ ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, backgroundImage: `url(${ConfigApi.imgUrl + res[0].url})` } } })
                                }
                            }} />
                        </label>}
                        register={methods.register('background-image', {
                            onBlur: (ev) => {
                                onChange({ ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, backgroundImage: `url(${ev.target.value})` } } })
                            }
                        })}
                    />
                    <Winicon src={"fill/user interface/e-delete"} style={{ padding: "0.5rem" }} size='1.4rem' onClick={() => {
                        let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style } } }
                        delete _tmp.Setting.style.backgroundImage
                        delete _tmp.Setting.style.backgroundSize
                        delete _tmp.Setting.style.backgroundPosition
                        onChange(_tmp)
                    }} />
                </div>
                <div className="row" style={{ gap: "0.8rem" }}>
                    <Select1
                        readOnly
                        style={{ flex: 1 }}
                        prefix={<Winicon src="outline/user interface/fullsize" size='1.2rem' />}
                        className="regular1"
                        name="background-size"
                        placeholder="background-size"
                        value={methods.watch('background-size')}
                        options={["contain", "cover"].map(e => {
                            return { id: e, name: e }
                        })}
                        onChange={(v) => {
                            if (v.id !== methods.getValues('background-size')) {
                                let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, backgroundSize: v.id } } }
                                if (v.id === "contain") delete _tmp.Setting.style.backgroundSize
                                onChange(_tmp)
                            }
                        }}
                    />
                    <Select1
                        readOnly
                        style={{ flex: 1 }}
                        prefix={<Winicon src="outline/development/align-center-vertical" size='1.2rem' />}
                        className="regular1"
                        name="background-position"
                        placeholder="background-position"
                        value={methods.watch('background-position')}
                        options={["center", "top", "left", "right", "bottom"].map(e => {
                            return { id: e, name: e }
                        })}
                        onChange={(v) => {
                            if (v.id !== methods.getValues('background-position')) {
                                let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, backgroundPosition: v.id } } }
                                if (v.id === "auto") delete _tmp.Setting.style.backgroundPosition
                                onChange(_tmp)
                            }
                        }}
                    />
                </div>
            </> : null}
        </div>
    </div>
}