import { Button } from "wini-web-components"

export default function SettingPopup({ selected }) {

    return selected && <div className='col' style={{ gap: "0.8rem", padding: "0.6rem 1.2rem" }}>
        <Button
            label="Close popup"
            onClick={() => {
                const handlePopupLayerBtn = document.body.querySelector(`div[class*="layer-list-container"] > button.close-popup[hidden]`)
                if (handlePopupLayerBtn) {
                    handlePopupLayerBtn.setAttribute("popupId", selected.Id)
                    handlePopupLayerBtn.click()
                }
            }}
        />
    </div>
}