import styles from './index.module.css'
import { forwardRef, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { NavLink, useLocation, useNavigate } from "react-router-dom"
import { TableController } from "../screen/wini/table/controller"
import { ModuleActions } from "../screen/module/reducer"
import { Button, closePopup, Popup, showPopup, Text, TextField, ToastMessage, Winicon } from "wini-web-components"
import { randomGID } from "../Utils"
import { Select1Form, TextAreaForm, TextFieldForm } from "../project-component/component-form"
import { useForm } from "react-hook-form"
import { ProjectActions } from "../screen/wini/project/reducer"
import { OutlineAdministrator } from '../assets/icon'
import { ConfigDomain } from '../da/configApi'
import { IconLibrary } from '../project-component/iconLibrary'
import { useTranslation } from 'react-i18next'

export default function ActionSidebar() {
    const selectedM = useSelector((store) => store.module.data)
    const listModule = useSelector((store) => store.project.data?.menu)
    const location = useLocation()
    const navigate = useNavigate()
    const popupRef = useRef()
    const dispatch = useDispatch()
    const { t } = useTranslation()

    useEffect(() => {
        if (ConfigDomain.includes(window.location.hostname) && (location.pathname === "/" || location.pathname.startsWith("/setting"))) ProjectActions.reset(dispatch)
        else ProjectActions.getInfor(dispatch)
    }, [location.pathname])

    useEffect(() => {
        if (ConfigDomain.includes(window.location.hostname)) {
            if (window.location.pathname === "/" || window.location.pathname.startsWith("/setting")) {
                ProjectActions.reset(dispatch)
                ModuleActions.setSelectedM(dispatch)
            } else if (['/admin', '/workflow'].some(e => window.location.pathname.startsWith(e))) {
                ModuleActions.setSelectedM(dispatch)
            } else if (listModule?.length) {
                const _m = listModule.find(e => e.To === location.pathname.replace("/manager", ""))
                if (_m) ModuleActions.setSelectedM(dispatch, _m)
                else navigate('/')
            }
        } else {
            if (window.location.pathname.startsWith("/setting")) {
                ModuleActions.setSelectedM(dispatch)
            } else if (listModule?.length) {
                const _m = listModule.find(e => e.To === location.pathname.replace("/manager", ""))
                if (_m) ModuleActions.setSelectedM(dispatch, _m)
                else navigate('/setting')
            }
        }
    }, [listModule?.length, location.pathname])

    const addMenuItem = (parentId) => {
        showPopup({
            ref: popupRef,
            hideButtonClose: true,
            heading: <div className="heading-7 popup-header">New module</div>,
            body: <AddModulePopup
                ref={popupRef}
                parentId={parentId}
                onSubmit={async (newItem) => {
                    const _sameLevel = listModule.filter(e => e.ParentId === parentId && e.id !== "admin" && e.parentId !== "admin")
                    newItem.Sort = _sameLevel.length
                    await ProjectActions.addMenu(dispatch, [
                        ..._sameLevel.map((e, i) => {
                            let item = { ...e, Sort: i }
                            if (item.Setting && typeof item.Setting !== "string") item.Setting = JSON.stringify(item.Setting)
                            return item
                        }),
                        newItem
                    ])
                    ToastMessage.success('Create module successfully!')
                }}
            />
        })
    }

    const deleteMenuItem = async (item) => {
        const oldList = [...listModule]
        const childrenId = listModule.filter(e => e.ParentId === item.Id).map(e => e.Id)
        ProjectActions.deleteMenu(dispatch, [item.Id, ...childrenId])
        if (selectedM.Id === item.Id || childrenId.includes(selectedM.Id)) {
            const _sameLevel = oldList.filter(e => e.ParentId === item.ParentId)
            let _index = _sameLevel.findIndex(e => e.Id === item.Id)
            if (_index === 0) _index = 1
            else _index = _index - 1
            if (_index < 0) _index += 2
            let newSelectedM = _sameLevel[_index]
            const newMenu = oldList.filter((e) => e.Id !== item.Id && childrenId.every(id => id !== e.Id))
            if (!newSelectedM?.To) newSelectedM = newMenu.find(e => e.To)
            if (newSelectedM) navigate(`/manager${newSelectedM.To}`)
            else navigate(ConfigDomain.includes(window.location.hostname) ? `/admin` : "/setting")
        }
    }

    return <div className={`col ${styles['sidebar']}`}>
        <Popup ref={popupRef} />
        <div className='col'>
            {ConfigDomain.includes(window.location.hostname) ? <>
                <NavLink to="/" className={`row ${styles['module-tile']} ${window.location.pathname === "/" ? styles['selected'] : ""}`}>
                    <Winicon src='outline/user interface/home' size={'1.8rem'} />
                    <Text className='label-3'>{t("home")}</Text>
                </NavLink>
                {location.pathname === "/" || location.pathname.startsWith("/setting") ? null : <>
                    <NavLink to={`/admin`} className={`row ${styles['module-tile']} ${window.location.pathname.startsWith("/admin") ? styles['selected'] : ""}`}>
                        <OutlineAdministrator size={'1.8rem'} />
                        <Text className='label-3'>{t("admin")}</Text>
                    </NavLink>
                    <NavLink to={`/workflow`} className={`row ${styles['module-tile']} ${window.location.pathname.startsWith("/workflow") ? styles['selected'] : ""}`}>
                        <Winicon src='outline/files/folder' size={'1.8rem'} />
                        <Text className='label-3'>{t("workflow")}</Text>
                    </NavLink>
                </>}
            </> : <NavLink to="/setting" className={`row ${styles['module-tile']} ${window.location.pathname.startsWith("/setting") ? styles['selected'] : ""}`}>
                <Winicon src='outline/user interface/settings-gear' size={'2rem'} />
                <Text className='label-3'>{t("setting")}</Text>
            </NavLink>}
        </div>
        <div className={`col ${styles['module-list-container']}`}>
            {listModule?.filter(e => !e.ParentId).map(e => <RenderModuleTile
                key={e.Id}
                item={e}
                addMenuItem={addMenuItem}
                deleteMenuItem={deleteMenuItem}
            />)}
        </div>
        {location.pathname === "/" ? null : <div className='col'>
            <Button
                label={t("add") + " module"}
                className='button-text-2 button-neutral'
                style={{ width: '100%', backgroundColor: "transparent", height: "4rem" }}
                prefix={<Winicon src={"outline/user interface/e-add"} size={"1.8rem"} />}
                onClick={() => { addMenuItem() }}
            />
            <Button
                label={t("help")}
                className='button-text-2'
                prefix={<Winicon src={"outline/technology/headset"} size={"1.8rem"} />}
                style={{ width: '100%', backgroundColor: "transparent", color: "var(--neutral-text-subtitle-color)", height: "4rem" }}
            />
        </div>
        }
    </div >
}

//#region Popup add Module 
const AddModulePopup = forwardRef(function AddModulePopup({ parentId, onSubmit, item, childrenLength = 0 }, ref) {
    const project = useSelector((store) => store.project.data)
    const methods = useForm({ shouldFocusError: false })
    const _tbController = new TableController("table")
    const _pageController = new TableController("page")
    const [tables, setTables] = useState([])
    const [pages, setPages] = useState([])
    const popupRef = useRef()
    const [otherPage, setOtherPage] = useState(false)
    const { t } = useTranslation()

    const _onSubmit = async (ev) => {
        let _newItem = {}
        if (item) {
            _newItem = { ...item, ...ev }
        } else {
            _newItem = {
                Id: randomGID(),
                ...ev,
                DateCreated: Date.now(),
                ParentId: parentId,
            }
        }
        if (!ev.To?.length) delete _newItem.To
        onSubmit(_newItem)
        closePopup(ref)
    }

    useEffect(() => {
        _pageController.getAll().then(res => {
            if (res.code === 200) setPages(res.data)
            else ToastMessage.errors(res.message)
        })
        _tbController.getAll().then(res => {
            if (res.code === 200) setTables(res.data)
            else ToastMessage.errors(res.message)
        })
    }, [])

    useEffect(() => {
        if (item) Object.keys(item).forEach(p => {
            methods.setValue(p, item[p])
        })
    }, [item])

    return <div className="col" style={{ flex: 1 }}>
        <Popup ref={popupRef} />
        <div className="col" style={{ padding: '1.6rem 2.4rem', gap: '2rem', overflow: 'hidden auto', flex: 1, width: '56rem' }}>
            <div className="row" style={{ gap: '1.2rem' }}>
                {methods.watch("Icon") ? <Button
                    prefix={<Winicon src={methods.watch("Icon")} size={"1.6rem"} />}
                    label='Remove Icon'
                    style={{ height: "4rem", gap: 12, padding: "0 1.2rem", backgroundColor: "transparent", color: "var(--primary-main-color)" }}
                    onClick={() => { methods.setValue("Icon", null) }}
                /> : <Button
                    label='Add Icon'
                    prefix={<Winicon src={"outline/user interface/e-add"} size={"1.4rem"} />}
                    style={{ height: "4rem", gap: 12, padding: "0 1.2rem" }}
                    onClick={() => {
                        showPopup({
                            ref: popupRef,
                            clickOverlayClosePopup: true,
                            style: { width: "48%", height: "80dvh" },
                            body: <IconLibrary
                                style={{ flex: 1, backgroundColor: "var(--neutral-absolute-background-color)", borderRadius: 8 }}
                                onSelect={(src) => {
                                    methods.setValue("Icon", src)
                                    closePopup(popupRef)
                                }}
                            />
                        })
                    }}
                />}
                <TextFieldForm
                    required
                    style={{ flex: 1 }}
                    name={"Name"}
                    placeholder={"Module name"}
                    register={methods.register}
                    errors={methods.formState.errors}
                />
            </div>
            {!childrenLength && <TextFieldForm
                required={parentId != undefined}
                style={{ flex: 1 }}
                prefix={<Text className='label-5' style={{ fontWeight: "500", paddingRight: "0.8rem", borderRight: "var(--neutral-bolder-border)" }}>https://{project?.Domain}/manager</Text>}
                name={"To"}
                onFocus={(ev) => { if (!ev.target.value?.length) ev.target.value = "/" }}
                onChange={(ev) => { ev.target.value = ev.target.value.trim() }}
                placeholder={"/Url"}
                register={methods.register}
                errors={methods.formState.errors}
                onBlur={(ev) => {
                    if (!ev.target.value.trim().startsWith("/")) ev.target.value = "/" + ev.target.value.trim()
                }}
            />}
            {childrenLength ? null : otherPage ? <Select1Form
                key={"page"}
                required={parentId != undefined}
                name={"TableId"}
                placeholder={"Choose page"}
                control={methods.control}
                errors={methods.formState.errors}
                options={pages.map(e => {
                    return {
                        id: e.Id,
                        name: e.Name
                    }
                })}
            /> : <Select1Form
                key={"table"}
                required={parentId != undefined}
                name={"TableId"}
                placeholder={"Choose table"}
                control={methods.control}
                errors={methods.formState.errors}
                options={[{ Name: 'Other page' }, ...tables].map(e => {
                    return {
                        id: e.Name,
                        name: e.Name
                    }
                })}
                onChange={(ev) => {
                    if (ev.id === "Other page") {
                        methods.setValue("PageId", null)
                        setOtherPage(true)
                    }
                }}
            />}
            <TextAreaForm
                name={"Description"}
                placeholder={"Write some thing about this module..."}
                register={methods.register}
                errors={methods.formState.errors}
            />
        </div>
        <div className="row popup-footer" style={{ justifyContent: "end" }}>
            <Button
                label={t("cancel")}
                style={{ width: "7.2rem", borderRadius: '0.4rem', backgroundColor: "transparent", color: "var(--neutral-text-subtitle-color)" }}
                onClick={() => { closePopup(ref) }}
            />
            <Button
                label={item ? t("save") : t("create")}
                className={methods.watch("Name")?.trim()?.length ? "button-primary" : "button-disabled"}
                style={{ width: "5.8rem", borderRadius: '0.4rem' }}
                onClick={methods.handleSubmit(_onSubmit)}
            />
        </div>
    </div>
})

//#region  RenderModuleTile
const RenderModuleTile = ({ item, addMenuItem, deleteMenuItem, space = 0 }) => {
    const listModule = useSelector((store) => store.project.data?.menu)
    const selectedM = useSelector((store) => store.module.data)
    const [isOpen, setIsOpen] = useState(false)
    const [onEdit, setOnEdit] = useState(false)
    const [isSelected, setSelected] = useState(false)
    const [children, setChildren] = useState([])
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const popupRef = useRef()
    const { t } = useTranslation()

    const handleRightClick = (ev) => {
        const _sameLevel = listModule.filter(e => e.ParentId === item.ParentId).sort((a, b) => a.Sort - b.Sort)
        const _index = _sameLevel.findIndex(e => e.Id === item.Id)
        switch (ev.type) {
            case 'contextmenu':
                ev.preventDefault()
                showPopup({
                    ref: popupRef,
                    clickOverlayClosePopup: true,
                    style: { left: ev.pageX, top: ev.pageY, borderRadius: '0.4rem' },
                    body: <div className="col popup-actions" style={{ maxHeight: 'fit-content' }}>
                        <button type="button" onClick={() => {
                            closePopup(popupRef)
                            showPopup({
                                ref: popupRef,
                                hideButtonClose: true,
                                heading: <div className="heading-7 popup-header">Edit module</div>,
                                body: <AddModulePopup
                                    ref={popupRef}
                                    item={item}
                                    childrenLength={children.length}
                                    onSubmit={async (newItem) => {
                                        ProjectActions.editMenu(dispatch, listModule.map(e => e.Id === newItem.Id ? newItem : e))
                                        if (newItem.Id === selectedM?.Id) ModuleActions.setSelectedM(dispatch, { ...selectedM, ...newItem })
                                        ToastMessage.success('Create module successfully!')
                                    }}
                                />
                            })
                        }}>
                            <Text className="button-text-3">{t('edit')}</Text>
                        </button>
                        {!item.ParentId && !item.TableId && !item.To ? <button type="button"
                            onClick={() => {
                                closePopup(popupRef)
                                addMenuItem(item.Id)
                            }}>
                            <Text className="button-text-3">Create child module</Text>
                        </button> : null}
                        {_index > 0 ? <button type="button" onClick={() => {
                            const sortList = [item, ..._sameLevel.filter(e => e.Id !== item.Id)].map((e, i) => ({ ...e, Sort: i }))
                            ProjectActions.editMenu(dispatch, listModule.map(e => sortList.find(s => s.Id === e.Id) || e))
                            closePopup(popupRef)
                        }}>
                            <Text className="button-text-3">Move to top</Text>
                        </button> : null}
                        {_index > 0 ? <button type="button" onClick={() => {
                            const sortList = [..._sameLevel.slice(0, _index - 1), item, ..._sameLevel.slice(_index - 1).filter(e => e.Id !== item.Id)].map((e, i) => ({ ...e, Sort: i }))
                            ProjectActions.editMenu(dispatch, listModule.map(e => sortList.find(s => s.Id === e.Id) || e))
                            closePopup(popupRef)
                        }}>
                            <Text className="button-text-3">Move up</Text>
                        </button> : null}
                        {(_index + 1) < _sameLevel.length ? <button type="button" onClick={() => {
                            const sortList = [..._sameLevel.slice(0, _index), _sameLevel[_index + 1], item, ..._sameLevel.slice(_index + 2)].map((e, i) => ({ ...e, Sort: i }))
                            ProjectActions.editMenu(dispatch, listModule.map(e => sortList.find(s => s.Id === e.Id) || e))
                            closePopup(popupRef)
                        }}>
                            <Text className="button-text-3">Move down</Text>
                        </button> : null}
                        {(_index + 1) < _sameLevel.length ? <button type="button" onClick={() => {
                            const sortList = [..._sameLevel.filter(e => e.Id !== item.Id), item].map((e, i) => ({ ...e, Sort: i }))
                            ProjectActions.editMenu(dispatch, listModule.map(e => sortList.find(s => s.Id === e.Id) || e))
                            closePopup(popupRef)
                        }}>
                            <Text className="button-text-3">Move to bottom</Text>
                        </button> : null}
                        <button type="button" onClick={() => {
                            closePopup(popupRef)
                            setOnEdit(true)
                        }}>
                            <Text className="button-text-3">{t("rename")}</Text>
                        </button>
                        <button type="button" onClick={() => {
                            deleteMenuItem(item)
                            closePopup(popupRef)
                        }}>
                            <Text className="button-text-3">{t('delete')}</Text>
                        </button>
                    </div >
                })
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        if (!item.parentId) setChildren(listModule.filter(e => e.ParentId === item.Id))
    }, [listModule?.length])

    useEffect(() => {
        if (selectedM) {
            if (selectedM.Id === item.Id) return setSelected(true)
            else if (selectedM.ParentId === item.Id) return setIsOpen(true)
        }
        setSelected(false)
    }, [selectedM, children.length])
    // 
    return <div className="col" style={{ order: item.Sort }}>
        <Popup ref={popupRef} />
        <button type="button" className={`row ${styles['module-tile']} ${isSelected ? styles['selected'] : ''}`}
            onContextMenu={item.Id ? (ev) => { handleRightClick(ev) } : undefined}
            onClick={() => {
                if (selectedM?.Id !== item.Id) {
                    if (item.To?.length) {
                        ModuleActions.reset(dispatch)
                        navigate(`/manager${item.To}`)
                    } else setIsOpen(!isOpen)
                }
            }}>
            <div style={{ paddingLeft: `${space}px` }}>{children.length ? <Winicon src={isOpen ? "fill/arrows/triangle-down" : "fill/arrows/triangle-right"} size={'1.2rem'} /> : item.Icon ? <Winicon src={item.Icon} size='1.6rem' /> : <div className={styles["prefix"]} />}</div>
            {onEdit ? <TextField
                autoFocus
                className="label-3"
                defaultValue={item.Name}
                onComplete={(ev) => { ev.target.blur() }}
                onBlur={async (ev) => {
                    const _newValue = ev.target.value.trim()
                    if (_newValue.length) {
                        let updateItem = { ...item, Name: _newValue }
                        ProjectActions.editMenu(dispatch, listModule.map(e => e.Id === updateItem.Id ? updateItem : e))
                        if (updateItem.Id === selectedM?.Id) ModuleActions.setSelectedM(dispatch, { ...selectedM, ...updateItem })
                    } else ev.target.value = item.Name
                    setOnEdit(undefined)
                }}
            /> : <Text className="label-3" style={{ flex: 1 }}>{item.Name}</Text>}
            {children?.length ? <Winicon src={"fill/user interface/e-add"} size={"1.4rem"} onClick={() => { addMenuItem(item.Id) }} /> : undefined}
        </button>
        {isOpen ? children.map(e => <RenderModuleTile
            key={e.Id}
            item={e}
            addMenuItem={addMenuItem}
            deleteMenuItem={deleteMenuItem}
            space={space + 16}
        />) : undefined}
    </div>
}