import React, { CSSProperties, ReactNode } from "react";
import { Button } from "wini-web-components";
import * as XLSX from 'xlsx';

enum ExcelCol {
    A = "A",
    B = "B",
    C = "C",
    D = "D",
    E = "E",
    F = "F",
    G = "G",
    H = "H",
    I = "I",
    J = "J",
    K = "K",
    L = "L",
    M = "M",
    N = "N",
    O = "O",
    P = "P",
    Q = "Q",
    R = "R",
    S = "S",
    T = "T",
    U = "U",
    V = "V",
    W = "W",
    X = "X",
    Y = "Y",
    Z = "Z",
}

interface Props {
    label: string,
    fileName?: string,
    sheetName?: string,
    getData: () => Promise<Array<{ [p: string]: any }>>,
    style?: CSSProperties,
    className?: string,
    disabled?: boolean,
    prefix?: ReactNode,
    suffix?: ReactNode,
    config?: { title: Array<string> }
}

export default function ExportXlsx(props: Props) {

    const onExport = async () => {
        /* fetch JSON data and parse */
        const raw_data = await props.getData();
        if (raw_data.length > 0) {
            const worksheet = XLSX.utils.json_to_sheet(raw_data);
            const workbook = XLSX.utils.book_new();
            worksheet["!cols"] = (props.config?.title ?? Object.keys(raw_data[0])).map(e => ({ wch: e.length * 6 }))
            XLSX.utils.sheet_add_aoa(worksheet, [props.config?.title ?? Object.keys(raw_data[0])], { origin: "A1", cellStyles: true });
            styleRow(worksheet, 0, {
                font: {
                    name: 'Calibri',
                    sz: 12,
                    bold: true,
                    color: { rgb: "FFFFFF" } // White text
                },
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: "4CAF50" } // Green background
                },
                alignment: {
                    horizontal: "center",
                    vertical: "center"
                }
            });
            XLSX.utils.book_append_sheet(workbook, worksheet, props.sheetName ?? "Sheet 1");
            XLSX.writeFile(workbook, `${props.fileName ?? "Exported"}.xlsx`, { compression: true });
        }
    }

    return <Button label={props.label} onClick={onExport} style={props.style} className={props.className} disabled={props.disabled} prefix={props.prefix} suffix={props.suffix} />
}

function styleRow(worksheet: XLSX.WorkSheet, rowIndex: number, style: any) {
    const range = XLSX.utils.decode_range(worksheet['!ref'] ?? "A1"); // Get the range of the sheet
    for (let col = range.s.c; col <= range.e.c; col++) {
        const cellAddress = XLSX.utils.encode_cell({ r: rowIndex, c: col });
        if (!worksheet[cellAddress]) continue; // Skip if cell doesn't exist
        worksheet[cellAddress].s = style;
    }
}