import { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { Button, closePopup, Popup, showPopup, Text, TextField, Winicon } from "wini-web-components"
import { cssRegex, onFocus } from "../functions/common"
import { ColorPicker } from "../functions/funtions"
import { Ultis } from "../../../../../Utils"
import { IconLibrary } from "../../../../../project-component/iconLibrary"

export const IconProperties = ({ selected, onChange, docBody = document.body.querySelector(`div[class*="overview-container"]`) }) => {
    const methods = useForm({ shouldFocusError: false })
    const [isOpen, setIsOpen] = useState(true)
    const [isOpenSize, setIsOpenSize] = useState(true)
    const [isOpenColor, setIsOpenColor] = useState(true)
    const [nodeElement, setNode] = useState()
    const ref = useRef()

    useEffect(() => {
        const node = docBody.querySelector(`*[id="${selected.Id}"]`)
        if (node.id !== nodeElement?.id) setNode(node)
        if (node) {
            const _nodeStyle = window.getComputedStyle(node)
            methods.setValue('src', selected.Setting.src)
            methods.setValue('size', selected.Setting.size ?? _nodeStyle.getPropertyValue('--size'))
            const _colorValue = _nodeStyle.getPropertyValue('--color')
            let _color = _colorValue.startsWith("#") ? _colorValue : Ultis.rgbToHex(_colorValue)
            if (_color.length < 8) _color += "ff"
            if (!_colorValue.startsWith("var")) {
                methods.setValue('color', _color)
                methods.setValue('color-value', _color.substring(0, 7))
                methods.setValue('color-opacity', `${Ultis.hexToPercent(_color.substring(7))}`)
                methods.setValue('opacity', parseFloat(_nodeStyle.opacity))
            }
        }
    }, [selected])

    return <>
        <Popup ref={ref} />
        <div className="col" style={{ borderBottom: 'var(--neutral-main-border)' }}>
            <div className="row" style={{ padding: '0.8rem 1.2rem', gap: '0.4rem' }}>
                <Text className="semibold1" style={{ flex: 1 }}>Icon</Text>
                <Winicon onClick={() => { setIsOpen(!isOpen) }} size={'1.4rem'} style={{ padding: "0.2rem" }} src={`outline/arrows/${isOpen ? "up" : "down"}-arrow`} />
            </div>
            <div className="col" style={{ padding: '0 1.2rem 0.8rem', gap: '0.8rem', display: isOpen ? 'flex' : 'none' }}>
                <Button
                    label='Change Icon'
                    prefix={<Winicon src={methods.watch("src")} size={"2.4rem"} />}
                    style={{ height: "3.2rem", gap: 12, padding: "0 1.2rem", width: "100%", justifyContent: "start" }}
                    onClick={() => {
                        showPopup({
                            ref: ref,
                            clickOverlayClosePopup: true,
                            hideButtonClose: true,
                            style: { width: "48%", height: "80dvh" },
                            body: <IconLibrary
                                style={{ flex: 1, backgroundColor: "var(--neutral-absolute-background-color)", borderRadius: 8 }}
                                onSelect={(src) => {
                                    onChange({ ...selected, Setting: { ...selected.Setting, src: src } })
                                    closePopup(ref)
                                }}
                            />
                        })
                    }}
                />
            </div>
        </div>
        <div className="col" style={{ borderBottom: 'var(--neutral-main-border)' }}>
            <div className="row" style={{ padding: '0.8rem 1.2rem', gap: '0.4rem' }}>
                <Text className="semibold1" style={{ flex: 1 }}>Size</Text>
                <Winicon onClick={() => { setIsOpenSize(!isOpenSize) }} size={'1.4rem'} style={{ padding: "0.2rem" }} src={`outline/arrows/${isOpenSize ? "up" : "down"}-arrow`} />
            </div>
            <div className="col" style={{ padding: '0 1.2rem 0.8rem', gap: '0.8rem', display: isOpenSize ? 'flex' : 'none' }}>
                <TextField
                    style={{ flex: 1 }}
                    onComplete={(ev) => ev.target.blur()}
                    prefix={<Winicon src="outline/development/3d-29" size='1.2rem' />}
                    suffix={<Winicon src="outline/arrows/down-arrow" size='1.2rem' onClick={(ev) => {
                        const _rect = ev.target.closest(".regular1").getBoundingClientRect()
                        showPopup({
                            ref: ref,
                            clickOverlayClosePopup: true,
                            hideButtonClose: true,
                            style: { left: _rect.x + _rect.width / 2, top: _rect.bottom, width: _rect.width / 2 },
                            body: <div className="col popup-actions" style={{ maxHeight: "fit-content" }}>
                                {["1rem", "1.4rem", "1.6rem", "2rem", "2.4rem", "3.2rem", "4rem", "4.8rem"].map(e => {
                                    return <button key={e} type="button" className="row" onClick={() => {
                                        onChange({ ...selected, Setting: { ...selected.Setting, size: e } })
                                        closePopup(ref)
                                    }}><Text className="button-text-3">{e}</Text></button>
                                })}
                            </div>
                        })
                    }} />}
                    className="regular1"
                    name="size"
                    placeholder="size"
                    onFocus={(ev) => onFocus(ev, methods)}
                    register={methods.register('size', {
                        onBlur: (ev) => {
                            const _newV = ev.target.value.trim().toLowerCase()
                            if (cssRegex.test(_newV)) {
                                let _tmp = { ...selected, Setting: { ...selected.Setting, size: _newV } }
                                if (_newV === "auto" || _newV === "none") delete _tmp.Setting.style.size
                                onChange(_tmp)
                            } else ev.target.value = methods.getValues("_tmp")
                            methods.setValue('_tmp', null)
                        }
                    })}
                />
            </div>
        </div>
        {!methods.watch("src")?.startsWith("color/") && <div className="col" style={{ borderBottom: 'var(--neutral-main-border)' }}>
            <div className="row" style={{ padding: '0.8rem 1.2rem', gap: '0.4rem' }}>
                <Text className="semibold1" style={{ flex: 1 }}>Color</Text>
                <Winicon onClick={() => { setIsOpenColor(!isOpenColor) }} size={'1.4rem'} style={{ padding: "0.2rem" }} src={`outline/arrows/${isOpenColor ? "up" : "down"}-arrow`} />
            </div>
            <div className="col" style={{ padding: '0 1.2rem 0.8rem', gap: '0.8rem', display: isOpenColor ? 'flex' : 'none' }}>
                <ColorPicker
                    style={{ width: "100%" }}
                    colorName={'color-value'}
                    colorOpcaityName={'color-opacity'}
                    register={methods.register}
                    opacity={methods.watch("opacity")}
                    colorValue={methods.watch("color")}
                    onChange={({ colorValue, opacity }) => {
                        onChange({ ...selected, Setting: { ...selected.Setting, color: colorValue, style: { ...selected.Setting.style, opacity: opacity } } })
                    }}
                    token={selected.Setting.color?.startsWith("var") ? selected.Setting.color : undefined}
                    onSelectColorToken={(ev) => {
                        let _tmp = { ...selected, Setting: { ...selected.Setting, color: `var(${ev.Name})` } }
                        onChange(_tmp)
                    }}
                />
            </div>
        </div>}
    </>
}