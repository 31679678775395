import { useState } from "react"
import { Text } from "wini-web-components"
import PropertiesTab from "./componentProperties/properties"
import ActionTab from './componentActions/action'

export default function RightView({ methods }) {
    const [tab, setTab] = useState(0)

    const renderTabView = () => {
        switch (tab) {
            case 0:
                return <PropertiesTab methods={methods} />
            case 1:
                return <ActionTab methods={methods} />
            default:
                return <div />
        }
    }

    return <div className="col setting-page-sidebar right">
        <div className='row action-tab'>
            <button type='button' className={`row ${tab === 0 ? "selected" : ""}`} onClick={() => { setTab(0) }}>
                <Text className='semibold1'>Properties</Text>
            </button>
            <button type='button' className={`row ${tab === 1 ? "selected" : ""}`} onClick={() => { setTab(1) }}>
                <Text className='semibold1'>Actions</Text>
            </button>
        </div>
        {renderTabView()}
    </div>
}

