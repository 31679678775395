import { forwardRef, useEffect, useRef, useState } from "react"
import { useFieldArray, useForm } from "react-hook-form"
import { closePopup, ComponentStatus, Dialog, DialogAlignment, Popup, showDialog, Text, TextField, ToastMessage, Winicon } from "wini-web-components"
import { TextAreaForm, TextFieldForm } from "../../../project-component/component-form"
import { TableController } from "../../wini/table/controller"
import { FEDataType } from "../../wini/table/da"
import { randomGID } from "../../../Utils"
import { DataController, SettingDataController } from "../controller"
import { BaseDA, imgFileTypes } from "../../../da/baseDA"
import ReportResultTable from "./table"
import SettingReportColumn from "./settingColumn"

export const SettingModel = forwardRef(function SettingModel({ id, item, onSuccess }, ref) {
    const _dataController = new DataController(item.Name)
    const _modelDataController = new SettingDataController("model")
    const _reducerDataController = new SettingDataController("reducer")
    const _relController = new TableController("rel")
    const _colController = new TableController("column")
    const popupRef = useRef()
    const dialogRef = useRef()
    const methods = useForm({ shouldFocusError: false, defaultValues: { Name: "", Columns: { "Name": 0 } } })
    const dataDemoMethods = useForm({ shouldFocusError: false, defaultValues: { Reducers: [] } })
    const _reducers = useFieldArray({
        control: dataDemoMethods.control,
        name: "Reducers",
        keyName: undefined
    })
    const [cols, setCols] = useState([])
    const [relatives, setRelatives] = useState([])
    const [data, setData] = useState({ data: [], totalCount: undefined })

    const _onSubmit = (ev) => {
        showDialog({
            ref: dialogRef,
            alignment: DialogAlignment.center,
            status: ComponentStatus.WARNING,
            title: `Are you sure to ${id ? "save" : 'create'} this model?`,
            onSubmit: async () => {
                ev.Id ??= randomGID()
                ev.DateCreated ??= Date.now()
                ev.TbName = item.Name
                if (ev.Columns && typeof ev.Columns !== "string") ev.Columns = JSON.stringify(ev.Columns)
                const reducerList = dataDemoMethods.getValues("Reducers")
                const _deleteReducerIds = reducerList.filter(e => e.isDeleted && e.ModelId).map(e => e.Id)
                if (_deleteReducerIds.length) {
                    _reducerDataController.action('delete', { ids: _deleteReducerIds }).then((res) => {
                        if (res.code !== 200) ToastMessage.errors(`delete reducer failed: ${res.message}`)
                    })
                }
                const _addReducers = reducerList.filter(e => !e.isDeleted).map(e => {
                    let _tmp = { ...e, ModelId: ev.Id }
                    delete e.id
                    return _tmp
                })
                const res = await _modelDataController.action('add', { data: [ev] })
                if (res.code !== 200) return ToastMessage.errors(res.message)
                if (_addReducers.length) {
                    const resReducers = await _reducerDataController.action('add', { data: _addReducers })
                    if (resReducers.code !== 200) return ToastMessage.errors(resReducers.message)
                }
                ToastMessage.success(`${id ? "Save" : "Create"} report model successfully!`)
                closePopup(ref)
                onSuccess()
            }
        })
    }

    const getData = async () => {
        const filterByParentId = relatives.some(e => e.TableFK === item.Name)
        let bodyJson = { page: 1, size: 10, searchRaw: "*", filter: filterByParentId ? `APPLY exists(@ParentId) AS __exist FILTER (@__exist == 0)` : undefined, }
        if (filterByParentId) {
            bodyJson.key = "ParentId"
            var res = await _dataController.filterByEmptyKey(bodyJson)
        } else {
            res = await _dataController.aggregateList(bodyJson)
        }
        if (res.code === 200) {
            const colFiles = cols.filter(e => e.DataType === FEDataType.FILE).map(e => e.Name)
            if (colFiles.length) {
                const _fileId = dataDemoMethods.getValues("_files") ?? []
                const _tmpFileIds = res.data.map(e => colFiles.map(c => e[c])).flat(Infinity).filter(id => id?.length && _fileId.every(e => e.id !== id) && !id.startsWith("http"))
                BaseDA.getFilesInfor(_tmpFileIds).then(res => {
                    if (res?.length) dataDemoMethods.setValue("_files", [..._fileId, ...res.map(e => { return { ...e, type: e.type ? e.type : imgFileTypes.some(t => e.name.toLowerCase().endsWith(t)) ? "image" : "file" } })])
                })
            }
            // get count child of parent element
            _dataController.group({ searchRaw: `@ParentId:{${res.data.map(e => `*${e.Id}*`).join(" | ")}}`, reducers: `GROUPBY 1 @ParentId REDUCE COUNT 0 AS _count` }).then(res => {
                if (res.code === 200) dataDemoMethods.setValue("__countChildren", res.data)
            })
            // call group reducer to get data after caculate
            _dataController.groupByIds({
                ids: res.data.map(e => e.Id),
                reducers: _reducers.fields.map((e) => {
                    const _rel = relatives.find(r => r.Id === e.RelativeId)
                    if (!_rel) return undefined
                    return {
                        Column: _rel.Column,
                        Name: e.Name,
                        Reducer: e.Reducer,
                        Query: e.Query,
                        ReducerBy: e.ReducerBy,
                        TbName: _rel.TableFK,
                    }
                })
            }).then(mapReducerData => {
                if (mapReducerData.code === 200) dataDemoMethods.setValue("groupData", mapReducerData.data)
            })
            setData({ data: res.data, totalCount: res.totalCount })
        } else {
            ToastMessage.errors(res.message)
        }
    }

    const initTable = async () => {
        const cols = methods.getValues("Columns")
        const relRes = await _relController.getListSimple({ page: 1, size: 100, query: `@TablePK:{${item.Name}}`, returns: ["Id", "Column", "TableFK"] })
        if (relRes.code === 200) setRelatives(relRes.data)
        if (id) {
            const reducerRes = await _reducerDataController.getListSimple({ page: 1, size: 100, query: `@ModelId:{${id}}` })
            if (reducerRes.code === 200) dataDemoMethods.setValue("Reducers", reducerRes.data)
        }
        _colController.getListSimple({ page: 1, size: 100, query: `@TableName:{${item.Name}} -@Name:{Id}`, returns: ["Id", "Name", "Setting", "DataType", "Form"] }).then(res => {
            if (res.code === 200) {
                const _colRes = res.data.map((e, i) => {
                    e.Setting = e.Setting ? JSON.parse(e.Setting) : { Title: e.Name, Sort: i }
                    e.Form = e.Form ? JSON.parse(e.Form) : {}
                    cols[e.Name] ??= e.Setting.Sort
                    return e
                }).sort((a, b) => a.Name === "Name" ? -1 : b.Name === "Name" ? 1 : cols[a.Name] - cols[b.Name])
                setCols(_colRes)
            }
        })
    }

    const getModel = async () => {
        if (id) {
            const res = await _modelDataController.getByIds([id])
            const _modelItem = res.data?.[0]
            if (_modelItem) {
                Object.keys(_modelItem).forEach((prop) => {
                    if (_modelItem[prop]) {
                        if (prop === "Columns") {
                            methods.setValue("Columns", _modelItem[prop] && typeof _modelItem[prop] === "string" ? JSON.parse(_modelItem[prop]) : _modelItem[prop])
                        } else {
                            methods.setValue(prop, _modelItem[prop])
                        }
                    }
                })
            } else ToastMessage.errors(res.message)
        }
        initTable()
    }

    useEffect(() => {
        getModel()
    }, [id])

    useEffect(() => {
        if (cols.length) getData()
    }, [cols.length])

    return <div className="col" style={{ flex: 1 }}>
        <Popup ref={popupRef} />
        <Dialog ref={dialogRef} />
        <div className='row popup-header'>
            <Text className='heading-6' style={{ flex: 1 }}>{id ? "Edit report model" : "Create report model"}</Text>
            <div className="row" style={{ gap: 4 }}>
                <button type="button" className="icon-button24 row" style={{ backgroundColor: 'var(--neutral-main-background-color)', borderRadius: "50%" }}>
                    <Winicon src="fill/user interface/menu-dots" />
                </button>
                <button type="button" className="icon-button24 row" style={{ backgroundColor: 'var(--neutral-main-background-color)', borderRadius: "50%" }}>
                    <Winicon src="fill/arrows/arrows-maximize" />
                </button>
                <button type="button" className="icon-button24 row" style={{ backgroundColor: 'var(--neutral-main-background-color)', borderRadius: "50%" }}>
                    <Winicon src="fill/arrows/refresh" />
                </button>
                <button type="button" className="icon-button24 row" style={{ backgroundColor: 'var(--neutral-main-background-color)', borderRadius: "50%" }} onClick={() => { closePopup(ref) }}>
                    <Winicon src="fill/user interface/e-remove" />
                </button>
            </div>
        </div>
        <div className="row" style={{ alignItems: 'start', flex: 1, padding: "1.6rem 0", gap: "2.4rem" }}>
            <div className="col" style={{ flex: 1, padding: '2.4rem', marginLeft: "2.4rem", width: "calc(100% - 2.4rem)", height: '100%', backgroundColor: 'var(--neutral-lighter-background-color)', borderRadius: '2.4rem' }}>
                <Text className="heading-6">Report: {methods.watch("Name")}</Text>
                <div className='row filter-table' style={{ padding: '1.2rem 0', gap: '0.8rem', pointerEvents: "none" }}>
                    <TextField
                        placeholder='Search'
                        prefix={<Winicon src={"fill/development/zoom"} size={"1.4rem"} />}
                        style={{ padding: '0.4rem 1.6rem', borderRadius: '0.6rem', height: '3.2rem' }}
                    />
                    <button className='row button-neutral' style={{ padding: '0.5rem 1rem' }}>
                        <Winicon src={"outline/development/bullet-list-67"} />
                        <Text className='button-text-3'>Filter</Text>
                    </button>
                </div>
                {cols.length ? <ReportResultTable
                    module={item.Name}
                    cols={cols.filter(e => methods.watch("Columns")[e.Name] >= 0).map((e) => { return { ...e, "_sort": methods.watch("Columns")[e.Name] } })}
                    reducers={_reducers.fields.filter(e => !e.isDeleted).map((e) => { return { ...e, "_sort": methods.watch("Columns")[e.Name] } })}
                    data={data}
                    methods={dataDemoMethods}
                    rels={relatives}
                    dataController={_dataController}
                /> : null}
            </div>
            <div className="col" style={{ height: '100%', width: '36rem', overflow: 'hidden auto', gap: '2rem', paddingRight: "2.4rem" }}>
                <TextFieldForm
                    required
                    name="Name"
                    register={methods.register}
                    errors={methods.formState.errors}
                    label="Model name"
                    onBlur={(ev) => methods.setValue("Name", ev.target.value)}
                />
                <TextAreaForm
                    name="Description"
                    register={methods.register}
                    errors={methods.formState.errors}
                    label="Description"
                    style={{ height: '20rem' }}
                />
                <SettingReportColumn
                    methods={methods}
                    cols={cols}
                    relatives={relatives}
                    reducers={_reducers.fields.filter(e => !e.isDeleted)}
                    onAdd={async (_newItem) => {
                        let _columns = methods.getValues("Columns")
                        const _ids = data.data.map(e => e.Id)
                        if (_ids.length) {
                            const res = await _dataController.groupByIds({
                                ids: _ids,
                                reducers: [..._reducers.fields, _newItem].map((e) => {
                                    const _rel = relatives.find(r => r.Id === e.RelativeId)
                                    return {
                                        Name: e.Name,
                                        Reducer: e.Reducer,
                                        Query: e.Query,
                                        ReducerBy: e.ReducerBy,
                                        TbName: _rel.TableFK,
                                        Column: _rel.Column,
                                    }
                                })
                            })
                            if (res.code === 200) {
                                let _mapData = dataDemoMethods.getValues("groupData") ?? []
                                for (const groupItem of res.data) {
                                    let _matchIndex = _mapData.findIndex(e => e[`_${item.Name}Id`] === groupItem[`_${item.Name}Id`])
                                    if (_matchIndex === -1) {
                                        _mapData.push(groupItem)
                                    } else {
                                        _mapData[_matchIndex] = { ..._mapData[_matchIndex], ...groupItem }
                                    }
                                }
                                dataDemoMethods.setValue("groupData", _mapData)
                            } else return ToastMessage.errors(res.message)
                        }
                        _reducers.append(_newItem)
                        methods.setValue("Columns", { ..._columns, [_newItem.Name]: cols.length + _reducers.fields.length })
                    }}
                    onChangeReducer={(reducerItem) => {
                        dataDemoMethods.setValue("Reducers", dataDemoMethods.getValues("Reducers").map(e => e.Id === reducerItem.Id ? reducerItem : e))
                    }}
                    onDelete={(reducerItem) => {
                        dataDemoMethods.setValue("Reducers", dataDemoMethods.getValues("Reducers").map(e => e.Id === reducerItem.Id ? { ...reducerItem, isDeleted: true } : e))
                        setData({
                            total: data.total,
                            data: data.data.map(e => {
                                delete e[reducerItem.Name]
                                return e
                            })
                        })
                        let _columns = methods.getValues("Columns")
                        delete _columns[reducerItem.Name]
                        methods.setValue("Columns", _columns)
                    }}
                />
            </div>
        </div>
        <div className="row" style={{ padding: '1.6rem 2.4rem', borderTop: "var(--neutral-bolder-border)", gap: '0.8rem' }}>
            <div style={{ flex: 1 }} />
            <button to="button" className="row button-grey" style={{ padding: "0.6rem 1.2rem" }} onClick={() => {
                showDialog({
                    ref: dialogRef,
                    alignment: DialogAlignment.center,
                    status: ComponentStatus.WARNING,
                    title: "Are you sure to cancel?",
                    content: "Everything will be lost if you cancel",
                    onSubmit: () => { closePopup(ref) }
                })
            }}>
                <Text className="button-text-3">Cancel</Text>
            </button>
            <button type="button" className={`row button-primary`} onClick={methods.handleSubmit(_onSubmit)}>
                <Text className="button-text-3">Save</Text>
            </button>
        </div>
    </div >
})