import { forwardRef, useEffect, useRef, useState } from "react";
import { Button, CalendarType, closePopup, Popup, showPopup, Text, TextArea, TextField, ToastMessage, Winicon } from "wini-web-components";
import styles from './index.module.css'
import { TableController } from '../../table/controller';
import { useFieldArray, useForm } from 'react-hook-form';
import { randomGID, regexTimeDuration, Ultis } from '../../../../Utils';
import { DatePickerForm, Select1Form, SelectMultipleForm, TextFieldForm } from "../../../../project-component/component-form";
import { SettingDataController } from "../../../module/controller";
import CaptureElement from "../../../../project-component/captureElement";
import RenderFormByType from "../../../module/manager/formByType";
import SettingSectionForm from "./settingSectionForm";

export default function WorkflowOverviewPage({ workflowItem }) {
    const _stageController = new TableController("stage")
    const [selectedStage, setSelectedStage] = useState()
    const [stages, setStages] = useState([])

    const addStage = async () => {
        const newStages = [{
            Id: randomGID(),
            Name: `Stage ${stages.length + 1}`,
            DateCreated: Date.now(),
            Description: `Stage ${stages.length + 1}`,
            Sort: Math.max(...stages.map(e => e.Sort)) + 1,
            WorkflowId: workflowItem.Id,
        }]
        newStages.push(...Array.from({ length: 2 }).map((_, i) => {
            return {
                Id: randomGID(),
                Name: 'Section ' + (i + 1),
                DateCreated: Date.now(),
                Description: 'section ' + (i + 1),
                Sort: i,
                WorkflowId: workflowItem.Id,
                ParentId: newStages[0].Id
            }
        }))
        const res = await _stageController.add(newStages)
        if (res.code === 200) {
            setStages([...stages, newStages[0]])
            setSelectedStage(newStages[0])
        }
    }

    useEffect(() => {
        if (workflowItem) {
            _stageController.group({ searchRaw: `@WorkflowId:{${workflowItem.Id}}`, reducers: `LOAD * APPLY exists(@ParentId) AS __exist FILTER (@__exist == 0) SORTBY 2 @Sort ASC ` }).then(res => {
                if (res.code === 200) {
                    setStages(res.data)
                    setSelectedStage(res.data[0])
                }
            })
        }
    }, [workflowItem])

    return <div className="row" style={{ flex: 1, width: '100%', height: '100%' }}>
        <div className={`col ${styles['stage-list']}`}>
            {stages.map((item, i) => {
                return <div key={item.Id} className='col' style={{ gap: 4, width: "100%", alignItems: "center" }}>
                    <div className={`row ${styles['stage-item']} ${selectedStage?.Id === item.Id ? styles['selected'] : ""}`} onClick={() => { setSelectedStage(item) }} >
                        <div className="col">
                            <Text className="subtitle-4">Stage {i + 1}</Text>
                            <Text className="heading-7">{item.Name}</Text>
                        </div>
                        <button type="button" className="row icon-button20">
                            <Winicon src="fill/text/menu-dots" style={{ rotate: "90deg" }} />
                        </button>
                    </div>
                    <div style={{ width: 1, height: 24, backgroundColor: "var(--neutral-text-body-reverse-color)" }} />
                </div>
            })}
            <button type="button" className="row" onClick={addStage}>
                <Winicon src="fill/user interface/e-add" size={"1.8rem"} />
            </button>
        </div>
        <WorkStageSpace stage={selectedStage} />
    </div>
}

//#region WorkStageSpace
const WorkStageSpace = ({ stage }) => {
    const _settingDataController = new SettingDataController("form")
    const _stageController = new TableController("stage")
    const _tbController = new TableController("table")
    const _relController = new TableController("rel")
    const _colController = new TableController("column")
    const methods = useForm({ shouldFocusError: false, defaultValues: { section: [], tables: [], forms: [] } })
    const methodsFormFields = useForm({ shouldFocusError: false })
    const sections = useFieldArray({
        control: methods.control,
        name: "section",
        keyName: undefined,
    })
    const tables = useFieldArray({
        control: methods.control,
        name: "tables",
        keyName: undefined,
    })
    const workSpaceRef = useRef()
    const [selectedSection, setSelectedSection] = useState()
    const [editNameId, setEditNameId] = useState()
    const [editDescriptionId, setEditDescriptionId] = useState()
    const popupRef = useRef()

    const onUpdateSection = (item, i) => {
        let newItem = { ...item }
        delete newItem.id
        sections.update(i, newItem)
        delete newItem.TbName
        _stageController.edit([newItem]).then(res => {
            if (res.code !== 200) ToastMessage.errors(res.message)
        })
    }

    const addSection = async () => {
        const _sec = methods.getValues("section")
        const newSection = {
            Id: randomGID(),
            Name: 'Section ' + (_sec.length + 1),
            DateCreated: Date.now(),
            Description: 'section ' + (_sec.length + 1),
            Sort: Math.max(..._sec.map(e => e.Sort)) + 1,
            WorkflowId: stage.WorkflowId,
            ParentId: stage.Id
        }
        const res = await _stageController.add([newSection])
        if (res.code === 200) {
            sections.append(newSection)
            setSelectedSection(newSection)
        }
    }

    const showPopupSelectForm = ({ tbItem, newItem, i, formItem }) => {
        showPopup({
            ref: popupRef,
            hideButtonClose: true,
            style: { width: '68%', height: '80%' },
            body: <SelectFormByTable
                ref={popupRef}
                item={formItem}
                tbItem={tbItem}
                onCancel={() => {
                    if (!newItem.FormId) {
                        delete newItem.TbName
                        sections.update(i, newItem)
                    }
                }}
                onSelect={(selectedForm) => {
                    newItem.FormId = selectedForm.Id
                    onUpdateSection(newItem, i)
                    const _forms = methods.getValues("forms")
                    if (_forms.every(f => f.Id !== selectedForm.Id)) {
                        methods.setValue("forms", [..._forms, selectedForm])
                    }
                }}
            />
        })
    }

    useEffect(() => {
        _tbController.getAll().then(res => {
            if (res.code === 200) methods.setValue("tables", res.data)
            else ToastMessage.errors(res.message)
        })
    }, [])

    useEffect(() => {
        methods.setValue("section", [])
        if (stage) {
            setTimeout(() => {
                _stageController.getListSimple({ page: 1, size: 200, query: `@ParentId:{${stage.Id}}`, sortby: { BY: "Sort" } }).then(res => {
                    if (res.code === 200) {
                        const _formIds = res.data.map(e => e.FormId).filter(e => e?.length)
                        if (_formIds.length) {
                            _settingDataController.getListSimple({ page: 1, size: _formIds.length, query: `@Id:{${_formIds.join(" | ")}}` }).then((formRes) => {
                                if (formRes.code === 200) {
                                    methods.setValue("forms", formRes.data.filter(e => e !== undefined).map(e => {
                                        return { ...e, Props: typeof e.Props === "string" ? JSON.parse(e.Props) : e.Props }
                                    }))
                                }
                            })
                        }
                        methods.setValue("section", res.data)
                        setSelectedSection(res.data[0])
                    }
                }, 2000)
            })
        }
    }, [stage])

    useEffect(() => {
        if (workSpaceRef.current && selectedSection) {
        }
    }, [selectedSection, workSpaceRef])

    useEffect(() => {
        const _forms = methods.getValues("forms")
        if (_forms.length) {
            _forms.forEach(e => {
                const _listFields = methodsFormFields.getValues(`__${e.TbName}`) ?? []
                if (!_listFields.length) {
                    _relController.getListSimple({ page: 1, size: 100, query: `@TableFK:{${e.TbName}}`, returns: ["Id", "Column", "TablePK", "TableFK", "Query", "Form"] }).then((async (res) => {
                        const colRes = await _colController.getListSimple({ page: 1, size: 100, query: `@TableName:{${e.TbName}} -@Name:{Id | DateCreated}`, returns: ["Id", "Name", "DataType", "Query", "Form"] })
                        if (colRes.code === 200) {
                            methodsFormFields.setValue(`__${e.TbName}`, [
                                ...colRes.data.filter(e => !e.Query).map((e) => {
                                    return { ...e, Form: e.Form ? typeof e.Form === "string" ? JSON.parse(e.Form) : e.Form : { Required: true }, type: "col" }
                                }),
                                ...(res.data ?? []).filter(e => !e.Query).map((e) => {
                                    return { ...e, Form: e.Form ? typeof e.Form === "string" ? JSON.parse(e.Form) : e.Form : { Required: true }, type: "rel" }
                                })
                            ])
                        }
                    }))
                }
            })
        }
    }, [methods.watch("forms")])

    return <div ref={workSpaceRef} className={`row ${styles['stage-work-space']}`}>
        <Popup ref={popupRef} />
        <div className="col" style={{ gap: "1.6rem", width: "29.8rem", marginRight: "2.4rem" }}>
            <img src={require("../../../../assets/workflow.png")} style={{ height: "25.6rem" }} />
            <Text className="heading-5" maxLine={3}>Automate your business process and keep work flowing.</Text>
            <Button
                label="Help document"
                style={{ width: "fit-content", backgroundColor: "var(--neutral-absolute-background-color)" }}
            />
        </div>
        {methods.watch("section").length ? sections.fields.map((item, i) => {
            const _sectionForm = methods.watch("forms").find(e => e.Id === item.FormId)
            return <div key={item.Id} className={`col ${styles['stage-section']} ${selectedSection?.Id === item.Id ? styles['selected'] : ""}`} onClick={() => { if (selectedSection?.Id !== item.Id) setSelectedSection(item) }}>
                <div className={`col ${styles['header']}`}>
                    <div className="row">
                        <div className="col" style={{ flex: 1 }}>
                            <Text className="subtitle-4">Section {i + 1}</Text>
                            {editNameId === item.Id ?
                                <TextField
                                    autoFocus
                                    defaultValue={item.Name}
                                    className='heading-7'
                                    style={{ padding: '4px 0.2rem' }}
                                    onComplete={(ev) => { ev.target.blur() }}
                                    onFocus={(ev) => { ev.target.select() }}
                                    onBlur={(ev) => {
                                        if (ev.target.value.trim()?.length) {
                                            let newItem = { ...item, Name: ev.target.value.trim() }
                                            onUpdateSection(newItem, i)
                                        }
                                        setEditNameId(undefined)
                                    }}
                                /> :
                                <Text className="heading-7" maxLine={1} onClick={() => { setEditNameId(item.Id) }} style={{ padding: '0.4rem 0' }}>{item.Name}</Text>}
                            {editDescriptionId === item.Id ?
                                <TextArea
                                    autoFocus
                                    style={{ width: '100%', padding: "0.4rem 0", height: "fit-content", minHeight: '2.2rem', maxHeight: 60, opacity: 0, transition: "opacity 0.2s ease-in-out" }}
                                    defaultValue={item.Description}
                                    className="body-3"
                                    onChange={(ev) => {
                                        ev.target.style.height = `0px`
                                        ev.target.style.height = `${ev.target.scrollHeight}px`
                                    }}
                                    onFocus={(ev) => {
                                        setTimeout(() => {
                                            ev.target.style.height = `0px`
                                            ev.target.style.height = `${ev.target.scrollHeight}px`
                                            ev.target.parentElement.style.opacity = 1
                                        }, 30)
                                    }}
                                    onBlur={(ev) => {
                                        if (ev.target.value.trim()?.length) {
                                            let newItem = { ...item, Description: ev.target.value.trim() }
                                            onUpdateSection(newItem, i)
                                        }
                                        setEditDescriptionId(undefined)
                                    }}
                                /> : <Text className="body-3" maxLine={2} onClick={() => { setEditDescriptionId(item.Id) }} style={{ padding: "0.4rem 0" }}>{item.Description}</Text>}
                        </div>
                        <button type="button" className="row icon-button20">
                            <Winicon src="fill/text/menu-dots" style={{ rotate: "90deg" }} />
                        </button>
                    </div>
                    <Button
                        label="Setting section"
                        className="button-text-6"
                        prefix={<Winicon src="outline/user interface/gear" size={"1.2rem"} />}
                        style={{ padding: "0.4rem 0.8rem", backgroundColor: "var(--neutral-main-background-color)", border: "var(--neutral-bolder-border)", borderRadius: "10rem", width: "fit-content" }}
                    />
                </div>
                <div className={`col ${styles['body']}`}>
                    <Select1Form
                        control={methods.control}
                        errors={methods.formState.errors}
                        name={`section[${i}].AssigneeId`}
                        placeholder="Set assignee to"
                        options={[]}
                    />
                    <SelectMultipleForm
                        control={methods.control}
                        errors={methods.formState.errors}
                        name={`section[${i}].CollaboratorId`}
                        placeholder="Add collaborators"
                        options={[]}
                    />
                    <TextFieldForm
                        name={`section[${i}].Duration`}
                        register={methods.register}
                        errors={methods.formState.errors}
                        placeholder="Set duration"
                        onBlur={(ev) => {
                            const _newDuration = ev.target.value.trim().length
                            if (_newDuration && regexTimeDuration.test(_newDuration)) {
                                let newItem = { ...item, Duration: _newDuration }
                                onUpdateSection(newItem, i)
                            } else ev.target.value = item.Duration ?? ""
                        }}
                    />
                    <Select1Form
                        control={methods.control}
                        errors={methods.formState.errors}
                        name={`section[${i}].TbName`}
                        placeholder="Set form data table"
                        options={tables.fields.map(e => { return { ...e, id: e.Id, name: e.Name } })}
                        onChange={(tbItem) => {
                            let newItem = { ...item }
                            if (tbItem) showPopupSelectForm({ i: i, newItem: newItem, tbItem: tbItem })
                            else {
                                delete newItem.FormId
                                onUpdateSection(newItem, i)
                            }
                        }}
                    />
                    {_sectionForm && <SettingSectionForm
                        key={item.FormId}
                        formItem={_sectionForm}
                        fields={methodsFormFields.watch(`__${_sectionForm.TbName}`)}
                        showPopupSelectForm={() => {
                            showPopupSelectForm({ i: i, newItem: { ...item }, formItem: _sectionForm, tbItem: methods.getValues("tables").find(e => e.Name === _sectionForm.TbName) })
                        }}
                    />}
                </div>
                <div className={`row ${styles['footer']}`}>
                    <Button
                        prefix={<Winicon src="fill/user interface/e-add" size={"1.4rem"} />}
                        label="Add"
                        style={{ backgroundColor: "transparent", padding: 0 }}
                        onClick={() => { }}
                    />
                    <div style={{ flex: 1 }} />
                    {i ? <Button
                        label="Back"
                        className="button-neutral button-text-3"
                        style={{ borderColor: "transparent" }}
                        onClick={(ev) => {
                            ev.stopPropagation()
                            const backItem = methods.getValues(`section[${i - 1}]`)
                            setSelectedSection(backItem)
                        }}
                    /> : null}
                    {(i + 1) === sections.fields.length ? null : <Button
                        label="Next"
                        className="button-primary button-text-3"
                        style={{ padding: "0.6rem 1.2rem" }}
                        onClick={(ev) => {
                            ev.stopPropagation()
                            const nextItem = methods.getValues(`section[${i + 1}]`)
                            setSelectedSection(nextItem)
                        }}
                    />}
                </div>
            </div>
        }) : Array.from({ length: 4 }).map((_, i) => <div key={i} className={`skeleton-loading ${styles['stage-section']}`} style={{ height: 480 }} />)}
        <Button
            prefix={<Winicon src="fill/user interface/e-add" size={"1.8rem"} />}
            label="Add section"
            className="button-text-1"
            style={{ padding: "1.6rem 2rem", backgroundColor: "var(--neutral-absolute-background-color)", height: "5.4rem", border: "var(--neutral-bolder-border)" }}
            onClick={addSection}
        />
    </div>
}

//#region SelectForm
const SelectFormByTable = forwardRef(function SelectFormByTable({ tbItem, onSelect, onCancel, item }, ref) {
    const _settingDataController = new SettingDataController("form")
    const _relController = new TableController("rel")
    const _colController = new TableController("column")
    const [data, setData] = useState({ data: [], totalCount: undefined })
    const [demoCols, setDemoCols] = useState([])
    const [selected, setSelected] = useState()

    const getData = async () => {
        const res = await _settingDataController.getListSimple({
            page: 1,
            size: 8,
            query: `@TbName:{${tbItem.Name}}`,
            sortby: { BY: "DateCreated" }
        })
        if (res.code === 200) setData({
            data: res.data.map(e => {
                return { ...e, Props: typeof e.Props === "string" ? JSON.parse(e.Props) : e.Props }
            }),
            totalCount: res.totalCount
        })
    }

    const getDemCols = async () => {
        const res = await _relController.getListSimple({ page: 1, size: 100, query: `@TableFK:{${tbItem.Name}}`, returns: ["Id", "Column", "TablePK", "TableFK", "Query", "Form"] })
        const colRes = await _colController.getListSimple({ page: 1, size: 100, query: `@TableName:{${tbItem.Name}} -@Name:{Id | DateCreated}`, returns: ["Id", "Name", "DataType", "Query", "Form"] })
        if (colRes.code === 200) {
            setDemoCols([
                ...colRes.data.filter(e => !e.Query).map((e) => {
                    return { ...e, Form: e.Form ? typeof e.Form === "string" ? JSON.parse(e.Form) : e.Form : { Required: true }, type: "col" }
                }),
                ...(res.data ?? []).filter(e => !e.Query).map((e) => {
                    return { ...e, Form: e.Form ? typeof e.Form === "string" ? JSON.parse(e.Form) : e.Form : { Required: true }, type: "rel" }
                })
            ])
        }
    }

    useEffect(() => {
        if (demoCols.length && !data.totalCount) getData()
    }, [demoCols.length, data.totalCount])

    useEffect(() => {
        getDemCols()
    }, [])

    useEffect(() => {
        if (item) setSelected(item)
    }, [item])

    return <div className="col" style={{ flex: 1, height: "100%", width: "100%", backgroundColor: "var(--neutral-absolute-background-color)", borderRadius: "0.8rem" }}>
        <div className="popup-header row" style={{ gap: "0.8rem" }}>
            <Text className='heading-6' style={{ flex: 1 }}>Select form</Text>
            <div className="row" style={{ gap: 4 }}>
                <button type="button" className="icon-button24 row" style={{ backgroundColor: 'var(--neutral-main-background-color)', borderRadius: "50%" }}>
                    <Winicon src="fill/user interface/menu-dots" />
                </button>
                <button type="button" className="icon-button24 row" style={{ backgroundColor: 'var(--neutral-main-background-color)', borderRadius: "50%" }}>
                    <Winicon src="fill/arrows/arrows-maximize" />
                </button>
                <button type="button" className="icon-button24 row" style={{ backgroundColor: 'var(--neutral-main-background-color)', borderRadius: "50%" }}>
                    <Winicon src="fill/arrows/refresh" />
                </button>
                <button type="button" className="icon-button24 row" style={{ backgroundColor: 'var(--neutral-main-background-color)', borderRadius: "50%" }} onClick={() => {
                    closePopup(ref)
                    onCancel()
                }}>
                    <Winicon src="fill/user interface/e-remove" />
                </button>
            </div>
        </div>
        <div className="row" style={{ padding: "1.6rem 2.4rem", flexWrap: "wrap", gap: "2.4rem", overflow: "hidden auto", flex: 1, height: "100%", alignContent: "start" }}>
            {data.data.map((item) => {
                return <div key={item.Id} className={`col col8 ${styles['option-form-item']} ${selected?.Id === item.Id ? styles['selected'] : ""}`} onClick={() => { setSelected(item) }}>
                    <CaptureElement className='col demo-card-container' style={{ width: "100%", height: "100%", flex: 1, padding: "1.6rem" }}>
                        <RenderFormByType
                            style={{ width: '100%', minWidth: "80rem", maxHeight: '60rem', height: '100%', flex: 1 }}
                            formItem={item}
                            columns={demoCols}
                        />
                    </CaptureElement>
                </div>
            })}
        </div>
        <div className="row popup-footer">
            <div style={{ flex: 1 }} />
            <Button
                label="Cancel"
                style={{ width: "7.2rem", borderRadius: '0.8rem', backgroundColor: "var(--neutral-main-background-color)", color: "var(--neutral-text-subtitle-color)" }}
                onClick={() => {
                    closePopup(ref)
                    onCancel()
                }}
            />
            <Button
                label="Apply"
                className={selected ? "button-primary" : "button-disabled"}
                style={{ width: "5.8rem", borderRadius: '0.8rem' }}
                onClick={() => {
                    closePopup(ref)
                    onSelect(selected)
                }}
            />
        </div>
    </div>
})
