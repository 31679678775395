import { ToastMessage } from 'wini-web-components'
import styles from '../assest.module.css'
import { SettingDataController } from '../../controller'
import { TableController } from '../../../wini/table/controller'
import { ChartCard } from '../../../../project-component/cards'
import { useEffect, useState } from 'react'
import { ComponentType } from '../../../wini/table/da'
import CaptureElement from '../../../../project-component/captureElement'

export default function TbAssetsChart({ tbName = "" }) {
    const _settingDataController = new SettingDataController("chart")
    const _relController = new TableController("rel")
    const [cards, setCards] = useState({ data: [] })
    const [relatives, setRelatives] = useState([])
    const _iframe = document.getElementById("iframe-render-page-view")

    const getCards = async () => {
        const res = await _settingDataController.getListSimple({ page: 1, size: 10, query: `@TbName:{${tbName}}` })
        if (res.code === 200) {
            const _relativeIds = res.data.map(e => e.RelativeId).filter(id => relatives.every(e => e.Id !== id))
            if (_relativeIds.length) {
                const relResponse = await _relController.getByListId(_relativeIds)
                if (relResponse.code === 200) {
                    var _newRels = [...relatives, ...relResponse.data].filter(e => e != undefined)
                    setRelatives(_newRels)
                }
            }
            setCards({
                data: [
                    ...cards.data,
                    ...res.data.map(e => {
                        e.TableFK ??= (_newRels ?? relatives).find((_rel) => _rel.Id === e.RelativeId)?.TableFK
                        return e
                    })
                ],
                totalCount: res.totalCount
            })
        } else ToastMessage.errors(res.message)
    }

    useEffect(() => {
        document.body.querySelector(".setting-page-sidebar.left").style.width = "448px"
        getCards()
    }, [])

    return <div className='row' style={{ flexWrap: "wrap", padding: '0.8rem 1.2rem', gap: '1.2rem', width: "100%" }}>
        {cards.data.map((e, j) => {
            return <div key={e.Id} draggable className={`col col12  ${styles['card-option-demo']} ${styles['component-options']}`}
                onDragStart={(ev) => {
                    ev.dataTransfer.setData("component-id", e.Id)
                    ev.dataTransfer.setData("component-type", ComponentType.chart)
                }}
                onDragEnd={() => {
                    _iframe.contentWindow.document.body.querySelector(`div[class*="demo-component-in-container"]`)?.remove()
                }}
            >
                <CaptureElement className='col demo-card-container' style={{ width: "100%", height: "100%", flex: 1 }}>
                    <ChartCard style={{ width: '40rem' }} item={e} timeRannge={120} />
                </CaptureElement>
            </div>
        })}
    </div>
}