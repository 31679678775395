import styles from './index.module.css'
import { useEffect, useState } from "react"
import { useForm } from 'react-hook-form'
import { Text, TextField, Winicon } from "wini-web-components"
import { CheckboxForm } from '../../../../../project-component/component-form'
import { cssRegex, onFocus } from '../functions/common'
import { FilledPaddingHorizontal, FilledPaddingLeft } from '../../../../../assets/icon'
import { ComponentType } from '../../../../wini/table/da'

export const LayoutProperties = ({ selected, onChange, docBody = document.body.querySelector(`div[class*="overview-container"]`) }) => {
    const methods = useForm({ shouldFocusError: false })
    const [isOpen, setIsOpen] = useState(true)
    const [nodeElement, setNode] = useState()
    const [togglePadding, setTogglePadding] = useState(false)

    useEffect(() => {
        const node = docBody.querySelector(`*[id="${selected.Id}"]`)
        if (node.id !== nodeElement?.id) setNode(node)
        if (node) {
            const _nodeStyle = window.getComputedStyle(node)
            const _direction = _nodeStyle.flexDirection
            methods.setValue('flex-direction', _direction)
            methods.setValue('flex-wrap', _nodeStyle.flexWrap)
            methods.setValue('flex-alignment', (_direction === "column" ? (_nodeStyle.justifyContent + "-" + _nodeStyle.alignItems) : (_nodeStyle.alignItems.replace("stretch", "start") + "-" + _nodeStyle.justifyContent)).replaceAll("normal", "start"))
            const { columnGap, rowGap, gap } = selected.Setting.style
            if (_nodeStyle.flexWrap === "wrap") {
                methods.setValue('column-gap', columnGap ?? gap ?? _nodeStyle['column-gap'])
                methods.setValue('row-gap', rowGap ?? gap ?? _nodeStyle['row-gap'])
            } else if (_direction === "row") methods.setValue('gap', columnGap ?? gap ?? _nodeStyle['column-gap'])
            else methods.setValue('gap', rowGap ?? gap ?? _nodeStyle['row-gap'])
            // 
            methods.setValue('padding-top', selected.Setting.style.padding?.split(" ")?.[0] ?? _nodeStyle.paddingTop)
            methods.setValue('padding-right', selected.Setting.style.padding?.split(" ")?.[1] ?? _nodeStyle.paddingRight)
            methods.setValue('padding-bottom', selected.Setting.style.padding?.split(" ")?.[2] ?? selected.Setting.style.padding?.split(" ")?.[0] ?? _nodeStyle.paddingBottom)
            methods.setValue('padding-left', selected.Setting.style.padding?.split(" ")?.[3] ?? selected.Setting.style.padding?.split(" ")?.[1] ?? _nodeStyle.paddingLeft)
            methods.setValue('paddingTB', selected.Setting.style.padding?.split(" ")?.[0] ?? _nodeStyle.padding.split(" ")[0])
            methods.setValue('paddingLR', selected.Setting.style.padding?.split(" ")?.[1] ?? selected.Setting.style.padding?.split(" ")?.[0] ?? _nodeStyle.padding.split(" ")[1] ?? _nodeStyle.padding.split(" ")[0])
            // 
            const _isScrollX = _nodeStyle.overflowX
            const _isScrollY = _nodeStyle.overflowY
            methods.setValue('isScroll', _isScrollY === 'scroll' || _isScrollY === 'auto' || _isScrollX === 'scroll' || _isScrollX === 'auto')
        }
    }, [selected])

    return <div className='col' style={{ borderBottom: 'var(--neutral-main-border)' }}>
        <div className="row" style={{ padding: '0.8rem 1.2rem' }}>
            <Text className="semibold1" style={{ flex: 1 }}>Layout</Text>
            <Winicon onClick={() => { setIsOpen(!isOpen) }} size={'1.4rem'} style={{ padding: "0.2rem" }} src={`outline/arrows/${isOpen ? "up" : "down"}-arrow`} />
        </div>
        <div className="col" style={{ padding: '0 1.2rem 0.8rem', gap: '0.8rem', display: isOpen ? 'flex' : 'none' }}>
            <div className="row" style={{ gap: '0.8rem' }}>
                <div className="col" style={{ gap: '0.8rem', flex: 1, height: '100%' }}>
                    <div className={`row ${styles['flex-direction-options']} ${selected.Type === ComponentType.container ? "" : styles['disabled']}`}>
                        <Winicon
                            src={"outline/arrows/arrow-down"}
                            size={'1.4rem'}
                            className={methods.watch("flex-direction") === 'column' ? styles['selected'] : ""}
                            onClick={() => {
                                let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style }, className: [...nodeElement.classList].filter(e => e !== "col" && e !== 'row' && e !== "layer-item").concat(["col"]).join(" ") } }
                                delete _tmp.Setting.style.flexWrap
                                delete _tmp.Setting.style.placeContent
                                if (_tmp.Setting.style)
                                    onChange(_tmp)
                            }}
                        />
                        <Winicon
                            src={"outline/arrows/arrow-right"}
                            size={'1.4rem'}
                            className={methods.watch("flex-direction") === 'row' && methods.watch("flex-wrap") !== 'wrap' ? styles['selected'] : ""}
                            onClick={() => {
                                let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style }, className: [...nodeElement.classList].filter(e => e !== "col" && e !== 'row' && e !== "layer-item").concat(["row"]).join(" ") } }
                                delete _tmp.Setting.style.flexWrap
                                delete _tmp.Setting.style.placeContent
                                onChange(_tmp)
                            }}
                        />
                        <Winicon
                            src='outline/arrows/curved-arrow-left'
                            size={'1.4rem'}
                            className={methods.watch("flex-wrap") === 'wrap' ? styles['selected'] : ""}
                            onClick={() => {
                                let _tmp = { ...selected, Setting: { ...selected.Setting, className: [...nodeElement.classList].filter(e => e !== "col" && e !== 'row' && e !== "layer-item").concat(["row"]).join(" "), style: { ...selected.Setting.style, flexWrap: 'wrap' } } }
                                if (_tmp.Setting.style.overflowX === 'scroll') {
                                    delete _tmp.Setting.style.overflowX
                                    _tmp.Setting.style.overflowY = "scroll"
                                }
                                onChange(_tmp)
                            }}
                        />
                    </div>
                    {methods.watch("flex-wrap") !== 'wrap' ? <TextField
                        onComplete={(ev) => ev.target.blur()}
                        className="regular1"
                        name="gap"
                        placeholder="gap"
                        onFocus={(ev) => onFocus(ev, methods)}
                        register={methods.register('gap', {
                            onBlur: (ev) => {
                                const _newV = ev.target.value.trim().toLowerCase()
                                if (cssRegex.test(_newV)) {
                                    let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, gap: _newV } } }
                                    if (_newV === "auto" || _newV === "none" || _newV === "normal") delete _tmp.Setting.style.gap
                                    onChange(_tmp)
                                } else ev.target.value = methods.getValues("_tmp")
                                methods.setValue('_tmp', null)
                            }
                        })}
                    /> : null}
                </div>
                <div className={`row ${styles['alignment-options']}`}>
                    <button type="button" className={`row ${methods.watch('flex-alignment') === 'start-start' ? styles.selected : ""}`}
                        onClick={() => {
                            let _v = { justifyContent: 'start', alignItems: 'start' }
                            if (methods.getValues("flex-wrap") === "wrap") _v['alignItems'] = "stretch"
                            let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, ..._v } } }
                            delete _tmp.Setting.style.placeContent
                            delete _tmp.Setting.style.justifyContent
                            onChange(_tmp)
                        }}
                    ><div /></button>
                    <button type="button" className={`row ${methods.watch('flex-alignment') === 'start-center' ? styles.selected : ""}`} style={{ justifyContent: 'center' }}
                        onClick={() => {
                            let _v = methods.getValues("flex-direction") === 'column' ? { justifyContent: 'start', alignItems: 'center' } : { justifyContent: 'center', alignItems: 'start' }
                            if (methods.getValues("flex-wrap") === "wrap") _v['placeContent'] = 'start center'
                            let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, ..._v } } }
                            onChange(_tmp)
                        }}
                    ><div /></button>
                    <button type="button" className={`row ${methods.watch('flex-alignment') === 'start-end' ? styles.selected : ""}`} style={{ justifyContent: 'end' }}
                        onClick={() => {
                            let _v = methods.getValues("flex-direction") === 'column' ? { justifyContent: 'start', alignItems: 'end' } : { justifyContent: 'end', alignItems: 'start' }
                            if (methods.getValues("flex-wrap") === "wrap") _v['placeContent'] = 'start end'
                            let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, ..._v } } }
                            onChange(_tmp)
                        }}
                    ><div /></button>
                    <button type="button" className={`row ${methods.watch('flex-alignment') === 'center-start' ? styles.selected : ""}`}
                        onClick={() => {
                            let _v = methods.getValues("flex-direction") === 'column' ? { justifyContent: 'center', alignItems: 'start' } : { justifyContent: 'start', alignItems: undefined }
                            if (methods.getValues("flex-wrap") === "wrap") _v['placeContent'] = 'center start'
                            let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, ..._v } } }
                            onChange(_tmp)
                        }}
                    ><div /></button>
                    <button type="button" className={`row ${methods.watch('flex-alignment') === 'center-center' ? styles.selected : ""}`} style={{ justifyContent: 'center' }}
                        onClick={() => {
                            let _v = { justifyContent: 'center', alignItems: methods.getValues("flex-direction") === 'column' ? 'center' : undefined }
                            if (methods.getValues("flex-wrap") === "wrap") _v['placeContent'] = 'center'
                            let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, ..._v } } }
                            onChange(_tmp)
                        }}
                    ><div /></button>
                    <button type="button" className={`row ${methods.watch('flex-alignment') === 'center-end' ? styles.selected : ""}`} style={{ justifyContent: 'end' }}
                        onClick={() => {
                            let _v = methods.getValues("flex-direction") === 'column' ? { justifyContent: 'center', alignItems: 'end' } : { justifyContent: 'end', alignItems: undefined }
                            if (methods.getValues("flex-wrap") === "wrap") _v['placeContent'] = 'center end'
                            let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, ..._v } } }
                            onChange(_tmp)
                        }}
                    ><div /></button>
                    <button type="button" className={`row ${methods.watch('flex-alignment') === 'end-start' ? styles.selected : ""}`}
                        onClick={() => {
                            let _v = methods.getValues("flex-direction") === 'column' ? { justifyContent: 'end', alignItems: 'start' } : { justifyContent: 'start', alignItems: 'end' }
                            if (methods.getValues("flex-wrap") === "wrap") _v['placeContent'] = 'end start'
                            let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, ..._v } } }
                            onChange(_tmp)
                        }}
                    ><div /></button>
                    <button type="button" className={`row ${methods.watch('flex-alignment') === 'end-center' ? styles.selected : ""}`} style={{ justifyContent: 'center' }}
                        onClick={() => {
                            let _v = methods.getValues("flex-direction") === 'column' ? { justifyContent: 'end', alignItems: 'center' } : { justifyContent: 'center', alignItems: 'end' }
                            if (methods.getValues("flex-wrap") === "wrap") _v['placeContent'] = 'end center'
                            let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, ..._v } } }
                            onChange(_tmp)
                        }}
                    ><div /></button>
                    <button type="button" className={`row ${methods.watch('flex-alignment') === 'end-end' ? styles.selected : ""}`} style={{ justifyContent: 'end' }}
                        onClick={() => {
                            let _v = { justifyContent: 'end', alignItems: 'end' }
                            if (methods.getValues("flex-wrap") === "wrap") _v['placeContent'] = 'end'
                            let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, ..._v } } }
                            onChange(_tmp)
                        }}
                    ><div /></button>
                </div>
                <Winicon src={"outline/user interface/gear"} size='1.4rem' onClick={() => { }} style={{ padding: "0.5rem" }} />
            </div>
            {methods.watch("flex-wrap") === "wrap" ? <div className="row" style={{ gap: '0.8rem' }}>
                <TextField
                    onComplete={(ev) => ev.target.blur()}
                    className="regular1"
                    name="column-gap"
                    placeholder="column-gap"
                    style={{ flex: 1 }}
                    onFocus={(ev) => onFocus(ev, methods)}
                    register={methods.register('column-gap', {
                        onBlur: (ev) => {
                            const _newV = ev.target.value.trim().toLowerCase()
                            if (cssRegex.test(_newV)) {
                                let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, columnGap: _newV } } }
                                if (_newV === "auto" || _newV === "none" || _newV === "normal") delete _tmp.Setting.style.columnGap
                                onChange(_tmp)
                            } else ev.target.value = methods.getValues("_tmp")
                            methods.setValue('_tmp', null)
                        }
                    })}
                />
                <TextField
                    onComplete={(ev) => ev.target.blur()}
                    className="regular1"
                    name="row-gap"
                    onFocus={(ev) => onFocus(ev, methods)}
                    placeholder="row-gap"
                    style={{ flex: 1 }}
                    register={methods.register('row-gap', {
                        onBlur: (ev) => {
                            const _newV = ev.target.value.trim().toLowerCase()
                            if (cssRegex.test(_newV)) {
                                let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, rowGap: _newV } } }
                                if (_newV === "auto" || _newV === "none" || _newV === "normal") delete _tmp.Setting.style.rowGap
                                onChange(_tmp)
                            } else ev.target.value = methods.getValues("_tmp")
                            methods.setValue('_tmp', null)
                        }
                    })}
                />
                <div style={{ width: '1.6rem' }} />
            </div> : null}
            <div className={`row ${styles['group-padding']}`}>
                {
                    togglePadding ? <>
                        <TextField
                            onComplete={(ev) => ev.target.blur()}
                            className="regular1"
                            name="padding-top"
                            prefix={<div style={{ scale: '1.4', transformOrigin: "center center", height: "1.6rem", width: "1.6rem" }}><FilledPaddingLeft size={'1.6rem'} /></div>}
                            onFocus={(ev) => onFocus(ev, methods)}
                            register={methods.register('padding-top', {
                                onBlur: (ev) => {
                                    let _newV = ev.target.value.trim().toLowerCase()
                                    if (cssRegex.test(_newV)) {
                                        if (_newV === "auto" || _newV === "none" || _newV === "normal") _newV = '0px'
                                        let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, padding: `${_newV} ${methods.getValues("padding-right")} ${methods.getValues("padding-bottom")} ${methods.getValues("padding-left")}` } } }
                                        delete _tmp.Setting.style.paddingTop
                                        onChange(_tmp)
                                    } else ev.target.value = methods.getValues("_tmp")
                                    methods.setValue('_tmp', null)
                                }
                            })}
                        />
                        <TextField
                            onComplete={(ev) => ev.target.blur()}
                            className="regular1"
                            name="padding-right"
                            prefix={<div style={{ scale: '1.4', transformOrigin: "center center", height: "1.6rem", width: "1.6rem", rotate: '90deg' }}><FilledPaddingLeft size={'1.6rem'} /></div>}
                            onFocus={(ev) => onFocus(ev, methods)}
                            register={methods.register('padding-right', {
                                onBlur: (ev) => {
                                    let _newV = ev.target.value.trim().toLowerCase()
                                    if (cssRegex.test(_newV)) {
                                        if (_newV === "auto" || _newV === "none" || _newV === "normal") _newV = '0px'
                                        let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, padding: `${methods.getValues("padding-top")} ${_newV} ${methods.getValues("padding-bottom")} ${methods.getValues("padding-left")}` } } }
                                        delete _tmp.Setting.style.paddingRight
                                        onChange(_tmp)
                                    } else ev.target.value = methods.getValues("_tmp")
                                    methods.setValue('_tmp', null)
                                }
                            })}
                        />
                        <Winicon
                            src={`fill/user interface/gear`}
                            className={styles['toggle-padding']}
                            size='1.4rem'
                            onClick={() => { setTogglePadding(!togglePadding) }}
                        />
                        <TextField
                            onComplete={(ev) => ev.target.blur()}
                            className="regular1"
                            name="padding-bottom"
                            prefix={<div style={{ scale: '1.4', transformOrigin: "center center", height: "1.6rem", width: "1.6rem", rotate: '180deg' }}><FilledPaddingLeft size={'1.6rem'} /></div>}
                            onFocus={(ev) => onFocus(ev, methods)}
                            register={methods.register('padding-bottom', {
                                onBlur: (ev) => {
                                    let _newV = ev.target.value.trim().toLowerCase()
                                    if (cssRegex.test(_newV)) {
                                        if (_newV === "auto" || _newV === "none" || _newV === "normal") _newV = '0px'
                                        let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, padding: `${methods.getValues("padding-top")} ${methods.getValues("padding-right")} ${_newV} ${methods.getValues("padding-left")}` } } }
                                        delete _tmp.Setting.style.paddingBottom
                                        onChange(_tmp)
                                    } else ev.target.value = methods.getValues("_tmp")
                                    methods.setValue('_tmp', null)
                                }
                            })}
                        />
                        <TextField
                            onComplete={(ev) => ev.target.blur()}
                            className="regular1"
                            name="padding-left"
                            prefix={<div style={{ scale: '1.4', transformOrigin: "center center", height: "1.6rem", width: "1.6rem", rotate: '-90deg' }}><FilledPaddingLeft size={'1.6rem'} /></div>}
                            onFocus={(ev) => onFocus(ev, methods)}
                            register={methods.register('padding-left', {
                                onBlur: (ev) => {
                                    let _newV = ev.target.value.trim().toLowerCase()
                                    if (cssRegex.test(_newV)) {
                                        if (_newV === "auto" || _newV === "none" || _newV === "normal") _newV = '0px'
                                        let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, padding: `${methods.getValues("padding-top")} ${methods.getValues("padding-right")} ${methods.getValues("padding-bottom")} ${_newV}` } } }
                                        delete _tmp.Setting.style.paddingLeft
                                        onChange(_tmp)
                                    } else ev.target.value = methods.getValues("_tmp")
                                    methods.setValue('_tmp', null)
                                }
                            })}
                        />
                    </> : <>
                        <TextField
                            onComplete={(ev) => ev.target.blur()}
                            className="regular1"
                            name="paddingTB"
                            prefix={<div style={{ scale: '1.4', transformOrigin: "center center", height: "1.6rem", width: "1.6rem", rotate: '90deg' }}><FilledPaddingHorizontal size={'1.6rem'} /></div>}
                            onFocus={(ev) => onFocus(ev, methods)}
                            register={methods.register('paddingTB', {
                                onBlur: (ev) => {
                                    const _newV = ev.target.value.trim().toLowerCase()
                                    if (cssRegex.test(_newV)) {
                                        const _lrPadding = methods.getValues('paddingLR')
                                        let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, padding: _lrPadding === _newV ? `${_newV}` : `${_newV} ${_lrPadding}` } } }
                                        if ((_newV === "auto" || _newV === "none" || _newV === "normal") && _lrPadding === "0px") delete _tmp.Setting.style.padding
                                        onChange(_tmp)
                                    } else ev.target.value = methods.getValues("_tmp")
                                    methods.setValue('_tmp', null)
                                }
                            })}
                        />
                        <TextField
                            onComplete={(ev) => ev.target.blur()}
                            className="regular1"
                            name="paddingLR"
                            prefix={<div style={{ scale: '1.4', transformOrigin: "center center", height: "1.6rem", width: "1.6rem" }}><FilledPaddingHorizontal size={'1.6rem'} /></div>}
                            onFocus={(ev) => onFocus(ev, methods)}
                            register={methods.register('paddingLR', {
                                onBlur: (ev) => {
                                    const _newV = ev.target.value.trim().toLowerCase()
                                    if (cssRegex.test(_newV)) {
                                        const _tbPadding = methods.getValues('paddingTB')
                                        let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, padding: _tbPadding === _newV ? `${_newV}` : `${_tbPadding} ${_newV}` } } }
                                        if ((_newV === "auto" || _newV === "none" || _newV === "normal") && _tbPadding === "0px") delete _tmp.Setting.style.padding
                                        onChange(_tmp)
                                    } else ev.target.value = methods.getValues("_tmp")
                                    methods.setValue('_tmp', null)
                                }
                            })}
                        />
                        <Winicon
                            src={`outline/user interface/gear`}
                            className={styles['toggle-padding']}
                            size='1.4rem'
                            onClick={() => { setTogglePadding(!togglePadding) }} style={{ borderColor: "transparent" }}
                        />
                    </>
                }
            </div>
            {selected.Type === ComponentType.container ? <div className="col" style={{ gap: '0.4rem' }}>
                <CheckboxForm label={<Text className="regular1">Scroll</Text>} name="isScroll" size={16} control={methods.control} onChange={(v) => {
                    const flexDirection = methods.getValues('flex-direction')
                    const flexWrap = methods.getValues('flex-wrap')
                    let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style } } }
                    if (v) {
                        delete _tmp.Setting.style.overflow
                        if (flexDirection === 'column' || flexWrap === 'wrap') {
                            delete _tmp.Setting.style.overflowX
                            _tmp.Setting.style.overflowY = "scroll"
                        } else {
                            delete _tmp.Setting.style.overflowY
                            _tmp.Setting.style.overflowX = "scroll"
                        }
                    } else {
                        delete _tmp.Setting.style.overflow
                        delete _tmp.Setting.style.overflowX
                        delete _tmp.Setting.style.overflowY
                    }
                    onChange(_tmp)
                }} />
            </div> : null}
        </div>
    </div>
}