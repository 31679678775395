import { forwardRef, useEffect, useRef, useState } from "react"
import styles from './funtions.module.css'
import { closePopup, Popup, showPopup, Text, TextField, Winicon } from "wini-web-components"
import { Ultis } from "../../../../../Utils"
import { DesignTokenType } from "../../../../wini/designToken/da"
import { useSelector } from "react-redux"

export const ColorPicker = ({ colorName, onDelete, style = {}, colorOpcaityName, register, colorValue = '', opacity, onChange, onSelectColorToken, token }) => {
    const colorPickerRef = useRef()
    const popupRef = useRef()

    useEffect(() => {
        const _colorPicker = colorPickerRef.current?.querySelector('input[type="color"]')
        if (colorValue && _colorPicker) _colorPicker.value = colorValue.substring(0, 7)
    }, [colorValue])

    const showPopupSelectToken = (ev) => {
        const _rect = ev.target.closest(".row:not(button)").getBoundingClientRect()
        showPopup({
            ref: popupRef,
            clickOverlayClosePopup: true,
            style: { position: 'absolute', top: _rect.bottom, left: _rect.x, width: _rect.width, maxHeight: "fit-content" },
            body: <PopupSelectTokens
                ref={popupRef}
                selected={token}
                type={DesignTokenType.color}
                onSelect={onSelectColorToken}
            />
        })
    }

    return <div ref={colorPickerRef} className={`row ${styles['color-picker-container']}`} style={{ gap: '1.2rem', width: style.width }}>
        <Popup ref={popupRef} />
        {token ? <button type='button' className={`row ${styles['design-token-tile']} ${styles['selected']}`} style={{ flex: 1 }} onClick={showPopupSelectToken}>
            <Winicon src={`outline/development/paint-brush`} size={"1.1rem"} />
            <Text className='label-5' style={{ flex: 1 }}>{token.replace("var(", "").replace(")", "")}</Text>
        </button> : <TextField
            className="regular1"
            name={colorName}
            onComplete={(ev) => ev.target.blur()}
            onFocus={(ev) => ev.target.select()}
            register={register(colorName, {
                onBlur: (ev) => {
                    const newVl = ev.target.value.replaceAll("#", "").substring(0, 6)
                    const _opacityValue = colorPickerRef.current.querySelector('input[type="number"]')
                    onChange({ colorValue: `#${newVl}${Ultis.percentToHex(parseFloat(_opacityValue.value))}`, opacity: opacity })
                }
            })}
            style={{ flex: 1, ...style, width: "100%" }}
            placeholder={colorName}
            maxLength={7}
            prefix={<label className={styles['color-picker']} style={{ backgroundColor: colorValue }}>
                <input
                    type='color'
                    style={{ visibility: 'hidden' }}
                    onChange={(ev) => {
                        const _opacityValue = colorPickerRef.current.querySelector('input[type="number"]')
                        onChange({ colorValue: `${ev.target.value}${Ultis.percentToHex(parseFloat(_opacityValue.value))}`, opacity: opacity })
                    }}
                />
            </label>}
            suffix={<>
                <input
                    name={colorOpcaityName}
                    type='number'
                    className={styles['color-opacity']}
                    onKeyDown={(ev) => ev.key === 'Enter' && ev.target.blur()}
                    {...register(colorOpcaityName, {
                        onBlur: (ev) => {
                            let _vl = parseFloat(ev.target.value)
                            if (isNaN(_vl)) _vl = 10
                            const _colorValue = colorPickerRef.current?.querySelector('input[type="color"]').value
                            onChange({ colorValue: `${_colorValue}${Ultis.percentToHex(_vl)}`, opacity: opacity })
                        }
                    })}
                />
                <Text className='regular1'>%</Text>
            </>}
        />}
        {onSelectColorToken && !token && <Winicon src="outline/business/coins" style={{ padding: "0.5rem" }} size='1.4rem' onClick={showPopupSelectToken} />}
        {opacity != undefined &&
            <Winicon src={`outline/user interface/${opacity ? "view" : "hide"}`} style={{ padding: "0.5rem" }} size='1.4rem' onClick={() => { onChange({ colorValue: colorValue, opacity: opacity ? 0 : 1 }) }} />}
        {onDelete &&
            <Winicon src={"fill/user interface/e-delete"} style={{ padding: "0.5rem" }} size='1.4rem' onClick={onDelete} />}
    </div>
}

export const listBreakpoint = ['remain', 'col0', 'col1', 'col2', 'col3', 'col4', 'col6', 'col8', 'col10', 'col12', 'col16', 'col18', 'col20', 'col24'];
export const tilteBreakpoints = ['MIN', 'SM', 'MD', 'LG', 'XL', 'XXL'];
// Function to extract the size, identifier (brp), and value from each string
export const getSizeValue = (str) => {
    // Loop through each size identifier
    for (let brp of tilteBreakpoints) {
        const lowerCaseBrp = brp.toLowerCase();
        // Check if the string contains the size identifier
        if (str.includes(lowerCaseBrp)) {
            if (str.split("-")[1] === "xxl") {
                return { value: str, brp: "XXL" };
            }
            return { value: str, brp };
        }
    }
    // Return null if no size identifier is found
    return null;
};

export const PopupSelectTokens = forwardRef(function PopupSelectTokens({ onSelect, selected, type }, ref) {
    const designTokens = useSelector((store) => store.designToken.data)
    const [data, setData] = useState([])
    const [searchValue, setSearchValue] = useState("")
    const scrollRef = useRef()

    useEffect(() => {
        if (designTokens?.length) {
            const tokens = designTokens.filter(e => e.Type === type)
            setData(designTokens.filter(e => tokens.some(f => f.ParentId === e.Id)).concat(tokens))
        }
    }, [designTokens?.length])

    useEffect(() => {
        if (data.length && selected && scrollRef.current) {
            const selectedTile = scrollRef.current.querySelector(`button[class*="selected"]`)
            if (selectedTile)
                scrollRef.current.scrollTo({
                    top: selectedTile.offsetTop - 96,
                    behavior: "smooth"
                })
        }
    }, [data.length, selected, scrollRef.current])

    return <div className='col' style={{ height: "36rem", backgroundColor: "var(--neutral-absolute-background-color)", borderRadius: "0.8rem" }}>
        <div className='row' style={{ padding: "0.8rem 1.6rem", borderBottom: "var(--neutral-bolder-border)" }}>
            <Text className='heading-8' style={{ flex: 1 }}>Design token</Text>
            <Winicon src={"fill/user interface/e-remove"} size={'1.4rem'} style={{ padding: "0.4rem 0 0.4rem 0.4rem" }} onClick={() => { closePopup(ref) }} />
        </div>
        <div ref={scrollRef} className='col' style={{ flex: 1, overflow: "hidden auto", scrollbarWidth: "none" }}>
            <div className='col' style={{ padding: "0.8rem 1.2rem", backgroundColor: "var(--neutral-absolute-background-color)", position: "sticky", top: 0 }}>
                <TextField
                    placeholder='Search'
                    className='regular1'
                    style={{ padding: "0.6rem 1.2rem" }}
                    onComplete={(ev) => { ev.target.blur() }}
                    onChange={(ev) => { setSearchValue(ev.target.value.trim().toLowerCase()) }}
                />
            </div>
            {data.filter(e => !e.ParentId).sort((_, b) => b.Type === DesignTokenType.group ? -1 : 1).map(item => {
                let children = item.Type === DesignTokenType.group ? data.filter(e => e.ParentId === item.Id) : []
                if (searchValue.length && !searchValue.includes(item.Name.toLowerCase()) && !children.some(e => e.Name.toLowerCase().includes(searchValue))) return <div key={item.Id} style={{ display: "none" }} />
                if (item.Type === DesignTokenType.group) {
                    return <GroupTile
                        key={item.Id}
                        item={item}
                        children={children}
                        selected={selected}
                        onSelect={(ev) => {
                            closePopup(ref)
                            onSelect(ev)
                        }}
                    />
                } else return <DesignTokenTile
                    key={item.Id}
                    item={item}
                    selected={selected}
                    onSelect={(ev) => {
                        closePopup(ref)
                        onSelect(ev)
                    }}
                />
            })}
        </div>
    </div>
})

const GroupTile = ({ item, children = [], onSelect, selected, type }) => {
    const [isOpen, setIsOpen] = useState(true)

    return <>
        <div className={`row ${styles['group-tokens-tile']}`} onClick={() => { setIsOpen(!isOpen) }}>
            <Winicon src={`fill/arrows/triangle-${isOpen ? "down" : "right"}`} size={"1.2rem"} style={{ padding: 2 }} />
            <Text className='heading-9' style={{ flex: 1 }}>{item.Name}</Text>
        </div>
        {isOpen ? children.map(e => <DesignTokenTile key={e.Id} item={e} onSelect={onSelect} selected={selected} type={type} />) : null}
    </>
}

const DesignTokenTile = ({ item, onSelect, selected, type }) => {
    return <button type='button' className={`row ${styles['design-token-tile']} ${selected?.includes(item.Name) ? styles['selected'] : ""}`} onClick={() => { onSelect(item) }}>
        <Winicon src={`outline/${type === DesignTokenType.color ? "development/paint-brush" : "text/typography"}`} size={"1.1rem"} />
        <Text className='label-4' style={{ flex: 1 }}>{item.Name}</Text>
    </button>
}