import { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import styles from './index.module.css';
import { useFieldArray, useForm } from "react-hook-form";
import { Button, closePopup, Popup, Select1, showPopup, Text, Winicon } from "wini-web-components";
import { ActionType, TriggerType } from "../../../da";
import { randomGID } from "../../../../../Utils";
import { Select1Form, TextFieldForm } from "../../../../../project-component/component-form";
import { TableController } from "../../../../wini/table/controller";
import { useDispatch, useSelector } from "react-redux";
import { ComponentType } from "../../../../wini/table/da";
import { regexUrl, regexUrlWithVariables } from "../../../config";
import { PageActions } from "../../reducer";

export default function InteractionBlock({ selected, onChange, cols = [] }) {
    const { control, setValue, getValues } = useForm({ shouldFocusError: false, defaultValues: { action: [] } })
    const actions = useFieldArray({
        control: control,
        name: "action",
        keyName: undefined
    })
    const _pageController = new TableController("page")
    const [pages, setPages] = useState([])
    const popupRef = useRef();

    useEffect(() => {
        if (selected.Setting.action?.length) setValue("action", selected.Setting.action)
        else setValue("action", [])
    }, [selected])

    const onUpdate = () => {
        onChange({
            ...selected, Setting: {
                ...selected.Setting,
                action: getValues("action").map(e => {
                    let _tmp = { ...e }
                    delete _tmp.id
                    return _tmp
                })
            }
        })
    }

    const showPopupSetting = (ev, item) => {
        const _rect = ev.target.closest("button").getBoundingClientRect()
        showPopup({
            ref: popupRef,
            clickOverlayClosePopup: true,
            style: { position: "absolute", top: _rect.y, right: `calc(100vw - ${_rect.x - 8}px)`, width: _rect.width + 60, maxHeight: "fit-content" },
            body: <PopupSettingInteraction ref={popupRef} item={item}
                onUpdate={(ev) => {
                    setValue("action", getValues("action").map(e => e.Id === ev.Id ? ev : e))
                    onUpdate()
                }} pages={pages} cols={cols} />
        })
    }

    useEffect(() => {
        _pageController.getListSimple({ page: 1, size: 100, returns: ["Id", "Name", "Url", "Params"] }).then(res => { if (res.code === 200) setPages(res.data) })
    }, [])

    return <div className="col" style={{ borderBottom: "var(--neutral-bolder-border)", gap: "0.4rem", paddingTop: "0.4rem" }}>
        <Popup ref={popupRef} />
        <div className="row" style={{ padding: '0.4rem 1.2rem', gap: '0.4rem' }}>
            <Text className="semibold1" style={{ flex: 1 }}>Interactions</Text>
            <Winicon src="fill/user interface/e-add" size={"1.4rem"} onClick={() => {
                const newAction = { Id: randomGID(), Type: TriggerType.click, Action: ActionType.navigate, Sort: actions.fields.length }
                actions.append(newAction)
                onUpdate()
            }} />
        </div>
        {actions.fields.map((item, i) => <InteractionTile
            key={item.Id}
            item={item}
            pages={pages}
            showPopupSetting={showPopupSetting}
            onDelete={() => {
                actions.remove(i)
                onUpdate()
            }}
        />)}
    </div>
}

const InteractionTile = ({ item, onDelete, showPopupSetting, pages = [] }) => {
    const { layers, layout } = useSelector((store) => store.page.data)
    const value = useMemo(() => {
        if (item.To) {
            switch (item.Action) {
                case ActionType.navigate:
                    if (regexUrlWithVariables.test(item.To)) return item.To
                    const pageUrl = pages.find(e => item.To.includes(e.Id))
                    if (pageUrl?.Url) return `${item.To.replace(pageUrl.Id, pageUrl.Url)}`
                    else return "none"
                case ActionType.reload:
                    return "reload"
                case ActionType.submit:
                    return [...layers, ...layout].find(e => e.Id === item.To)?.Name ?? "none"
                case ActionType.showPopup:
                    return layers.find(e => e.Id === item.To)?.Name ?? "none"
                default:
                    return ""
            }
        } else return "none"
    }, [item])

    const getIconByType = () => {
        if (item.To) {
            switch (item.Action) {
                case ActionType.navigate:
                    return "fill/arrows/arrow-right"
                case ActionType.reload:
                    return "fill/arrows/reload"
                case ActionType.showPopup:
                    return "fill/development/stack"
                default:
                    return "fill/development/cursor-pointer"
            }
        } else return "outline/user interface/ban"
    }

    return <>
        <div className={`row ${styles['interaction-tile']}`}>
            <button type="button" className="row" onClick={(ev) => showPopupSetting(ev, item)}>
                <Text className="label-4" style={{ maxWidth: "10rem" }}>{item.Type}</Text>
                <Winicon src={getIconByType()} size={"1.4rem"} style={{ paddingRight: "1.2rem" }} />
                <Text className="label-4" maxLine={1}>{value}</Text>
            </button>
            <Winicon src="fill/user interface/e-delete" size={"1.4rem"} onClick={onDelete} />
        </div>
        {item.Action === ActionType.showPopup && value !== "none" && <div className="row" style={{ gap: "0.8rem", padding: "0 1.2rem 0.4rem 1.2rem" }}>
            <Button
                label="Open popup"
                style={{ flex: 1, borderRadius: "0.4rem" }}
                onClick={() => {
                    const handlePopupLayerBtn = document.body.querySelector(`div[class*="layer-list-container"] > button.open-popup[hidden]`)
                    if (handlePopupLayerBtn) {
                        handlePopupLayerBtn.setAttribute("popupId", item.To)
                        handlePopupLayerBtn.click()
                    }
                }}
            />
            <Button
                label="Close popup"
                style={{ flex: 1, borderRadius: "0.4rem" }}
                onClick={() => {
                    const handlePopupLayerBtn = document.body.querySelector(`div[class*="layer-list-container"] > button.close-popup[hidden]`)
                    if (handlePopupLayerBtn) {
                        handlePopupLayerBtn.setAttribute("popupId", item.To)
                        handlePopupLayerBtn.click()
                    }
                }}
            />
        </div>}
    </>
}

//#region Popup Setting
const PopupSettingInteraction = forwardRef(function PopupSettingInteraction({ item, onUpdate, pages = [], cols = [] }, ref) {
    const { page, layers, layout } = useSelector((store) => store.page.data)
    const methods = useForm({ shouldFocusError: false })
    const [searchParams, setSearchParams] = useState([])
    const { setValue, watch } = useForm({ shouldFocusError: false, defaultValues: { params: [] } })
    const [toOtherDomain, setToOtherDomain] = useState(false)
    const dispatch = useDispatch()

    const selectDestination = () => {
        switch (methods.watch("Action")) {
            case ActionType.navigate:
                return <>
                    <div className="row" style={{ padding: "0.8rem 1.2rem", gap: "0.8rem" }}>
                        <Text className="medium1" style={{ width: "8.8rem" }}>Destination</Text>
                        {toOtherDomain ? <TextFieldForm
                            errors={methods.formState.errors}
                            name="To"
                            register={methods.register}
                            style={{ flex: 1 }}
                            textFieldStyle={{ padding: "0.4rem 0.8rem", height: "fit-content" }}
                            className="regular1"
                            onFocus={(ev) => { ev.target.select() }}
                            onBlur={(ev) => {
                                if (regexUrlWithVariables.test(ev.target.value.trim())) onUpdate({ ...methods.getValues(), To: ev.target.value.trim() })
                                else ev.target.value = ""
                            }}
                        /> : <Select1Form
                            control={methods.control}
                            errors={methods.formState.errors}
                            name="To"
                            options={[{ Id: "other", Name: "Other page" }, ...pages].map(v => { return { id: v.Id, name: v.Name } })}
                            style={{ flex: 1 }}
                            select1Style={{ padding: "0.4rem 0.8rem", height: "fit-content" }}
                            className="regular1"
                            onChange={(v) => {
                                if (v.id === "other") {
                                    methods.setValue("To", "")
                                    setToOtherDomain(true)
                                } else {
                                    onUpdate({ ...methods.getValues(), To: v.id })
                                }
                            }}
                        />}
                    </div>
                    {methods.watch("To") && !toOtherDomain && methods.watch("Action") === ActionType.navigate && <div className="col">
                        <div className="row" style={{ padding: "0.6rem 1.2rem", gap: "0.4rem" }}>
                            <Text className="medium1" style={{ fontSize: "1.3rem" }}>Query params</Text>
                            <Winicon src="outline/layout/circle-question" size={"1.4rem"} />
                        </div>
                        {searchParams.map((p) => {
                            return <div key={p.Key} className="row" style={{ padding: "0.4rem 1.2rem", gap: "0.8rem" }}>
                                <Text className="medium1" style={{ width: "8.8rem" }}>{p.Key}=</Text>
                                <Select1
                                    placeholder="Field name"
                                    style={{ flex: 1, padding: "0.4rem 0.8rem", height: "fit-content" }}
                                    className="body-3"
                                    value={watch("params").find(v => v.Key === p.Key)?.Value ?? undefined}
                                    options={cols.map(v => { return { id: v.Column ?? v.Name, name: `card.${v.Column ?? v.Name}` } })}
                                    suffix={<Winicon src="outline/user interface/c-remove" size={"1.4rem"} onClick={() => {
                                        const tmp = methods.getValues()
                                        let newListParams = watch("params") ?? []
                                        newListParams = newListParams.filter(e => e.Key !== p.Key)
                                        setValue("params", newListParams)
                                        onUpdate({ ...tmp, To: `${tmp.To}${newListParams.length ? `?${newListParams.map(e => `${e.Key}=${e.Value}`).join("&")}` : ""}` })
                                    }} />}
                                    onChange={(v) => {
                                        const tmp = methods.getValues()
                                        let newListParams = watch("params") ?? []
                                        if (newListParams.some(e => e.Key === p.Key)) {
                                            newListParams = newListParams.map(e => {
                                                if (e.Key === p.Key) return { ...e, Value: v.id }
                                                return e
                                            })
                                        } else newListParams.push({ Key: p.Key, Value: v.id })
                                        setValue("params", newListParams)
                                        onUpdate({ ...tmp, To: `${tmp.To}?${newListParams.map(e => `${e.Key}=${e.Value}`).join("&")}` })
                                    }}
                                />
                            </div>
                        })}
                    </div>}
                </>
            case ActionType.submit:
                return layers?.length && layout?.length && <div className="row" style={{ padding: "0.8rem 1.2rem", gap: "0.8rem" }}>
                    <Text className="medium1" style={{ width: "8.8rem" }}>Form</Text>
                    <Select1Form
                        control={methods.control}
                        errors={methods.formState.errors}
                        name="To"
                        placeholder="Choose form"
                        options={[...layers, ...layout].filter(e => e.Type === ComponentType.form).map(v => { return { id: v.Id, name: v.Name } })}
                        style={{ flex: 1 }}
                        select1Style={{ padding: "0.4rem 0.8rem", height: "fit-content" }}
                        className="regular1"
                        onChange={(v) => {
                            onUpdate({ ...methods.getValues(), To: v.id })
                        }}
                    />
                </div>
            case ActionType.showPopup:
                return layers?.length && layout?.length && <div className="row" style={{ padding: "0.8rem 1.2rem", gap: "0.8rem" }}>
                    <Text className="medium1" style={{ width: "8.8rem" }}>Popup</Text>
                    <Select1Form
                        control={methods.control}
                        errors={methods.formState.errors}
                        name="To"
                        placeholder="Choose popup"
                        options={[{ Id: 'new popup', Name: 'new popup', Type: ComponentType.popup }, ...layers, ...layout].filter(e => e.Type === ComponentType.popup).map(v => { return { id: v.Id, name: v.Name } })}
                        style={{ flex: 1 }}
                        select1Style={{ padding: "0.4rem 0.8rem", height: "fit-content" }}
                        className="regular1"
                        onChange={async (v) => {
                            if (v.id === "new popup") {
                                const newPopup = {
                                    Id: randomGID(),
                                    Name: 'New popup',
                                    PageId: page.Id,
                                    DateCreated: Date.now(),
                                    Type: ComponentType.popup,
                                    Setting: {}
                                }
                                const newContainer = {
                                    Id: randomGID(),
                                    Name: ComponentType.container,
                                    Type: ComponentType.container,
                                    PageId: page.Id,
                                    ParentId: newPopup.Id,
                                    DateCreated: Date.now(),
                                    Setting: { className: "col", style: { width: "68%", height: "68%", backgroundColor: "#ffffff", borderRadius: "0.8rem" } }
                                }
                                await PageActions.addLayers(dispatch, [newPopup, newContainer])
                                onUpdate({ ...methods.getValues(), To: newPopup.Id })
                                setTimeout(() => {
                                    const handlePopupLayerBtn = document.body.querySelector(`div[class*="layer-list-container"] > button.open-popup[hidden]`)
                                    if (handlePopupLayerBtn) {
                                        handlePopupLayerBtn.setAttribute("popupId", newPopup.Id)
                                        handlePopupLayerBtn.click()
                                    }
                                }, 200)
                            } else {
                                onUpdate({ ...methods.getValues(), To: v.id })
                            }
                        }}
                    />
                </div>
            default:
                break;
        }
    }

    useEffect(() => {
        if (item) Object.keys(item).forEach((key) => {
            if (key === "To") {
                if (regexUrl.test(item[key])) {
                    setToOtherDomain(true)
                    methods.setValue(key, item[key])
                } else {
                    const paramsIndex = item[key].indexOf("?")
                    methods.setValue(key, paramsIndex >= 0 ? item[key].slice(0, paramsIndex) : item[key])
                    if (paramsIndex >= 0) {
                        const paramValues = item[key].slice(paramsIndex + 1).split("&")
                        setValue("params", paramValues.map(e => { return { Key: e.split("=")[0], Value: e.split("=")[1] } }))
                    } else setValue("params", [])
                }
            } else methods.setValue(key, item[key])
        })
    }, [item])

    useEffect(() => {
        const toPage = methods.getValues("To")
        if (toPage && methods.getValues("Action") === ActionType.navigate) {
            const params = pages.find(e => e.Id === toPage)?.Params
            setSearchParams(params && typeof params === "string" ? JSON.parse(params).filter(e => !e.Value?.length) : [])
        } else setSearchParams([])
    }, [methods.watch("To"), methods.watch("Action")])

    return <div className="col" style={{ height: "36rem", backgroundColor: "var(--neutral-absolute-background-color)", borderRadius: "0.8rem" }}>
        <div className="row" style={{ padding: "0.6rem 1.2rem", borderBottom: "var(--neutral-bolder-border)" }}>
            <Text className="heading-8" style={{ flex: 1 }}>Interaction</Text>
            <Winicon src="fill/user interface/e-remove" size={"2rem"} onClick={() => { closePopup(ref) }} />
        </div>
        <div className="col" style={{ flex: 1, overflow: "hidden auto" }}>
            <div className="row" style={{ padding: "0.8rem 1.2rem", borderBottom: "var(--neutral-bolder-border)", gap: "0.8rem" }}>
                <Text className="medium1" style={{ width: "8.8rem" }}>Trigger</Text>
                <Select1Form
                    control={methods.control}
                    errors={methods.formState.errors}
                    name="Type"
                    options={Object.values(TriggerType).map(v => { return { id: v, name: v } })}
                    style={{ flex: 1 }}
                    select1Style={{ padding: "0.4rem 0.8rem", height: "fit-content" }}
                    className="regular1"
                    onChange={(v) => { onUpdate({ ...methods.getValues(), Type: v.id }) }}
                />
            </div>
            <div className="row" style={{ padding: "0.8rem 1.2rem", borderBottom: "var(--neutral-bolder-border)", gap: "0.8rem" }}>
                <Text className="medium1" style={{ width: "8.8rem" }}>Action</Text>
                <Select1Form
                    control={methods.control}
                    errors={methods.formState.errors}
                    name="Action"
                    options={Object.values(ActionType).map(v => { return { id: v, name: v } })}
                    style={{ flex: 1 }}
                    select1Style={{ padding: "0.4rem 0.8rem", height: "fit-content" }}
                    className="regular1"
                    onChange={(v) => { onUpdate({ ...methods.getValues(), Action: v.id }) }}
                />
            </div>
            {methods.watch("Action") && methods.watch("Action") !== ActionType.reload && <div className="col" style={{ borderBottom: "var(--neutral-bolder-border)" }}>
                {selectDestination()}
            </div>}
        </div>
    </div>
})