import { useEffect, useState } from 'react'
import { TextAreaForm, TextFieldForm } from '../../../../project-component/component-form'
import indexStyles from './index.module.css'
import { Select1, Text, TextField, Winicon } from 'wini-web-components'
import { ComponentType } from '../da'
import { LayoutProperties } from '../../../module/page/componentProperties/layout/layout'
import { BoxProperties } from '../../../module/page/componentProperties/dimension/dimension'
import { AppearanceProperties } from '../../../module/page/componentProperties/appearance/appearance'
import { BackgroundProperties } from '../../../module/page/componentProperties/background/background'
import { FontProperties } from '../../../module/page/componentProperties/font/font'
import { TableController } from '../controller'
import { useForm } from 'react-hook-form'
import { ImageProperties } from '../../../module/page/componentProperties/image/image'
import { IconProperties } from '../../../module/page/componentProperties/icon/icon'
import InteractionBlock from '../../../module/page/componentActions/interaction/interaction'

export default function CardInforContainer({ methods, methodsProps }) {
    const [tab, setTab] = useState(0)
    const _colController = new TableController("column")
    const _relController = new TableController("rel")
    const [cols, setCols] = useState([])
    const [rels, setRels] = useState([])

    const renderTab = () => {
        switch (tab) {
            case 0:
                return <CardInfor methods={methods} />
            case 1:
                return <CardLayerProperties methods={methodsProps} />
            case 2:
                return <CardLayerAction tbName={methods.watch("TbName")} methods={methodsProps} cols={cols} rels={rels} />
            default:
                return <div />
        }
    }

    useEffect(() => {
        const _tbName = methods.getValues("TbName")
        if (_tbName?.length) {
            _colController.getListSimple(({ page: 1, size: 200, query: `@TableName:{${_tbName}}`, returns: ["Id", "Name", "DataType"] })).then(res => {
                if (res.code === 200) setCols(res.data)
            })
            _relController.getListSimple(({ page: 1, size: 200, query: `(@TablePK:{${_tbName}}) | (@TableFK:{${_tbName}})`, returns: ["Id", "TablePK", "TableFK", "Column"] })).then(res => {
                if (res.code === 200) setRels(res.data)
            })
        }
    }, [methods.watch("TbName")])

    return <div className={`col ${indexStyles['infor-container']}`}>
        <div className={`row ${indexStyles['setting-card-tabbar']}`}>
            <Text onClick={() => { setTab(0) }} className={`heading-8 ${tab === 0 ? indexStyles['selected'] : ""}`}>Overview</Text>
            <Text onClick={() => { setTab(1) }} className={`heading-8 ${tab === 1 ? indexStyles['selected'] : ""}`}>Properties</Text>
            <Text onClick={() => { setTab(2) }} className={`heading-8 ${tab === 2 ? indexStyles['selected'] : ""}`}>Action</Text>
        </div>
        {renderTab()}
    </div>
}

//#region CardInfor 
const CardInfor = ({ methods }) => {
    return <div className={`col`} style={{ gap: "1.6rem", padding: "1.2rem" }}>
        <TextFieldForm
            required
            name='Name'
            register={methods.register}
            errors={methods.formState.errors}
            label='Card name'
            textFieldStyle={{ padding: "0.5rem 1rem" }}
        />
        <TextAreaForm
            name='Description'
            register={methods.register}
            errors={methods.formState.errors}
            label='Card description'
            textFieldStyle={{ padding: "0.5rem 1rem" }}
        />
    </div>
}

//#region CardLayerAction
const CardLayerAction = ({ methods, cols = [], rels = [], tbName }) => {
    const { watch, setValue } = useForm({ shouldFocusError: false })
    const [selected, setSelected] = useState()

    useEffect(() => {
        const _id = methods.getValues("selectedId")
        if (_id) setSelected(methods.getValues("layers").find(e => e.Id === _id))
        else setSelected(undefined)
    }, [methods.watch("layers").length, methods.watch("selectedId")])

    useEffect(() => {
        if (selected) {
            setValue("NameField", selected.NameField)
            setValue("RelativeId", selected.RelativeId)
        } else {
            setValue("NameField", undefined)
            setValue("RelativeId", undefined)
        }
    }, [selected])

    useEffect(() => { }, [])

    return selected && <div className='col'>
        <div className='col' style={{ padding: "1.2rem", gap: "0.8rem", borderBottom: "var(--neutral-bolder-border)" }}>
            <Text className="semibold1">Fill data</Text>
            <div className='row' style={{ gap: "1.2rem" }}>
                <Text className="label-5" style={{ width: "6rem" }}>Relative</Text>
                <Select1
                    readOnly
                    style={{ padding: "0.6rem 1rem", flex: 1 }}
                    className='regular1'
                    value={watch("RelativeId")}
                    placeholder='Choose relative table'
                    options={rels.map(e => { return { id: e.Id, name: tbName === e.TablePK ? e.TableFK : e.TablePK } })}
                    onChange={(v) => {
                        setValue("RelativeId", v.id)
                        methods.setValue("layers", methods.getValues("layers").map(e => e.Id === selected.Id ? { ...e, RelativeId: v.id } : e))
                    }}
                />
            </div>
            <div className='row' style={{ gap: "1.2rem" }}>
                <Text className="label-5" style={{ width: "6rem" }}>Field</Text>
                <Select1
                    readOnly
                    style={{ padding: "0.6rem 1rem", flex: 1 }}
                    className='regular1'
                    value={watch("NameField")}
                    placeholder='Choose field'
                    options={cols.map(e => { return { id: e.Name, name: e.Name } })}
                    onChange={(v) => {
                        setValue("NameField", v.id)
                        methods.setValue("layers", methods.getValues("layers").map(e => e.Id === selected.Id ? { ...e, NameField: v.id } : e))
                    }}
                />
            </div>
        </div>
        <InteractionBlock
            selected={selected}
            cols={[...cols, ...rels.filter(e => e.TableFK === tbName)]}
            onChange={(newValue) => {
                if (newValue) {
                    methods.setValue("layers", methods.getValues("layers").map(e => e.Id === newValue.Id ? newValue : e))
                    setSelected(newValue)
                    methods.setValue("reload", true)
                }
            }}
        />
    </div>
}

//#region CardLayerProperties
const CardLayerProperties = ({ methods }) => {
    const [selected, setSelected] = useState()

    useEffect(() => {
        const _id = methods.getValues("selectedId")
        if (_id) setSelected(methods.getValues("layers").find(e => e.Id === _id))
        else setSelected(undefined)
    }, [methods.watch("layers").length, methods.watch("selectedId")])

    const styleProperties = () => {
        switch (selected?.Type) {
            case ComponentType.container:
                return <PropertiesContainer methods={methods} selected={selected} />
            case ComponentType.text:
                return <ProppertiesText methods={methods} selected={selected} />
            case ComponentType.img:
                return <PropertiesImg methods={methods} selected={selected} />
            case ComponentType.icon:
                return <PropertiesIcon methods={methods} selected={selected} />
            default:
                return null
        }
    }

    return <div className='col'>
        {styleProperties()}
    </div>
}

const PropertiesContainer = ({ methods, selected }) => {
    const [item, setItem] = useState()

    useEffect(() => {
        setItem(selected)
    }, [selected])

    const props = {
        selected: item,
        onChange: (newValue) => {
            if (newValue) {
                methods.setValue("layers", methods.getValues("layers").map(e => e.Id === newValue.Id ? newValue : e))
                setItem(newValue)
                methods.setValue("reload", true)
            }
        }
    }

    return item && <div className='col'>
        <LayoutProperties {...props} />
        {selected.ParentId && <BoxProperties {...props} />}
        <AppearanceProperties {...props} />
        <BackgroundProperties {...props} />
    </div>
}

const ProppertiesText = ({ methods, selected }) => {
    const { register, setValue } = useForm({ shouldFocusError: false })
    const [isOpen, setIsOpen] = useState(true)
    const [item, setItem] = useState()

    useEffect(() => {
        setItem(selected)
        setValue("value", selected.Setting.value)
    }, [selected])

    const props = {
        selected: item,
        onChange: (newValue) => {
            if (newValue) {
                methods.setValue("layers", methods.getValues("layers").map(e => e.Id === newValue.Id ? newValue : e))
                setItem(newValue)
                methods.setValue("reload", true)
            }
        }
    }

    return item && <div className='col'>
        <div className="col" style={{ borderBottom: 'var(--neutral-main-border)' }}>
            <div className="row" style={{ padding: '0.8rem 1.2rem', gap: '0.4rem' }}>
                <Text className="semibold1" style={{ flex: 1 }}>Text</Text>
                <Winicon onClick={() => { setIsOpen(!isOpen) }} size={'1.4rem'} style={{ padding: "0.2rem" }} src={`outline/arrows/${isOpen ? "up" : "down"}-arrow`} />
            </div>
            <div className="col" style={{ padding: '0 1.2rem 0.8rem', gap: '0.8rem', display: isOpen ? 'flex' : 'none' }}>
                <TextField
                    onComplete={(ev) => ev.target.blur()}
                    className="regular1"
                    style={{ padding: "0.6rem 1.2rem", gap: "1.2rem", height: "fit-content" }}
                    name="value"
                    placeholder="value"
                    onFocus={(ev) => { ev.target.select() }}
                    prefix={<Winicon src={"outline/user interface/edit"} size={'1.6rem'} />}
                    register={register('value', {
                        onBlur: (ev) => {
                            const newValue = { ...item, Setting: { ...item.Setting, value: ev.target.value } }
                            methods.setValue("layers", methods.getValues("layers").map(e => e.Id === newValue.Id ? newValue : e))
                            setItem(newValue)
                            methods.setValue("reload", true)
                        }
                    })}
                />
            </div>
        </div>
        <BoxProperties {...props} />
        <FontProperties {...props} />
    </div>
}

const PropertiesImg = ({ methods, selected }) => {
    const [item, setItem] = useState()

    useEffect(() => {
        setItem(selected)
    }, [selected])

    const props = {
        selected: item,
        onChange: (newValue) => {
            if (newValue) {
                methods.setValue("layers", methods.getValues("layers").map(e => e.Id === newValue.Id ? newValue : e))
                setItem(newValue)
                methods.setValue("reload", true)
            }
        }
    }

    return item && <div className='col'>
        <ImageProperties {...props} />
        <BoxProperties {...props} />
        <AppearanceProperties {...props} />
    </div>
}

const PropertiesIcon = ({ methods, selected }) => {
    const [item, setItem] = useState()

    useEffect(() => {
        setItem(selected)
    }, [selected])

    const props = {
        selected: item,
        onChange: (newValue) => {
            if (newValue) {
                methods.setValue("layers", methods.getValues("layers").map(e => e.Id === newValue.Id ? newValue : e))
                setItem(newValue)
                methods.setValue("reload", true)
            }
        }
    }

    return item && <div className='col'>
        <IconProperties {...props} />
        <AppearanceProperties {...props} />
    </div>
}