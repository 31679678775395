import styles from './cards.module.css'
import React, { CSSProperties, ReactNode, useEffect, useState } from "react"
import { NavLink } from "react-router-dom"
import { Text, Winicon } from "wini-web-components"
import MapGroupData from "../screen/module/dashboard/mapGroupData"
import { ChartItem, EChartType } from "../screen/module/da"
import { DataController } from "../screen/module/controller"

export const CardInformationVertical = (params: {
    thumbnail?: string,
    media?: ReactNode,
    title?: string,
    titleElement?: ReactNode,
    content?: string,
    contentElement?: ReactNode,
    bottomAction?: ReactNode,
    to?: string,
    className?: string,
    style?: CSSProperties,
}) => {
    return <div className={`col ${styles['card-container']} ${params.className ?? ''}`} style={params.style}>
        {params.thumbnail ? params.to ? <NavLink to={params.to} className={`row ${styles['card-thumbnail-container']}`}>
            <img src={params.thumbnail} style={{ width: '100%', height: '100%' }} alt="" />
        </NavLink> : <div className={`row ${styles['card-thumbnail-container']}`}>
            <img src={params.thumbnail} style={{ width: '100%', height: '100%' }} alt="" />
        </div> : undefined}
        {params.media}
        <div className="col" style={{ gap: '0.4rem', textAlign: 'inherit' }}>
            {params.titleElement ?? <Text className="heading-6" maxLine={2} style={{ textAlign: 'inherit', color: params.style?.color ?? '#00204D' }}>{params.title}</Text>}
            {params.contentElement ?? <Text className="subtitle-2" maxLine={4} style={{ color: params.style?.color ?? '#00204D99', textAlign: 'inherit' }}>{params.content}</Text>}
        </div>
        {params.bottomAction ? params.bottomAction : params.to ? <NavLink to={params.to} className={'row'} style={{ gap: '0.8rem' }}>
            <Text className="button-text-3" style={{ color: 'var(--primary-main-color)' }}>Learn more</Text>
            <Winicon src={"fill/arrows/arrow-right"} size={"1.4rem"} color='var(--primary-main-color)' />
        </NavLink> : undefined}
    </div>
}

export const ChartCard = (params: { id: string, item: ChartItem, timeRannge?: number, className?: string, style?: CSSProperties }) => {
    const [_result, setResult] = useState([])
    const [cardItem, setCardItem] = useState<any>()
    const _groupByTimeChart = [EChartType.bar, EChartType.horizontalBar, EChartType.bubble, EChartType.line, EChartType.scatter, EChartType.radar]

    useEffect(() => {
        if (params.item) {
            const _reducers: Array<any> = [{ Name: params.item.Name, GroupBy: params.item.GroupBy, Reducer: params.item.Reducer, ReducerBy: params.item.ReducerBy }]
            if (params.item.Type && _groupByTimeChart.includes(params.item.Type)) {
                if ((params.timeRannge ?? 120) > 30) {
                    _reducers.push({ Name: "_month", GroupBy: "_month", Query: `"timefmt(month(@DateCreated/1000), '%m')"` })
                } else {
                    _reducers.push({ Name: "_day", GroupBy: "_day", Query: `"timefmt(@DateCreated/1000, '%d-%m')"` })
                }
            }
            const _tbRelController = new DataController((params.item as any).TableFK)
            const _now = new Date()
            const _startDate = new Date(_now.getFullYear(), _now.getMonth(), _now.getDate() - (params.timeRannge ?? 120))
            _tbRelController.groupBy({
                searchRaw: `@DateCreated:[${_startDate.getTime()} ${_now.getTime()}]`,
                reducers: _reducers
            }).then((res) => {
                if (res.code === 200) {
                    setResult(res.data)
                    setCardItem({ ...params.item, Setting: JSON.parse(params.item.Setting as any) })
                }
            })
        }
    }, [params.item, (params.timeRannge ?? 120)])

    return <div id={params.id} className={`col ${styles['chart-container']} ${params.className ?? ""}`} style={params.style}>
        <Text className='heading-7' style={{ flex: 1 }}>{cardItem?.Title}</Text>
        {
            cardItem && _result.length && <MapGroupData
                listData={_result}
                chartItem={cardItem}
                filterTime={(params.timeRannge ?? 120) > 30 ? "_month" : "_day"}
            />
        }
    </div>
}

