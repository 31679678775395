import styles from '../view.module.css'
import { ComponentStatus, Dialog, DialogAlignment, Popup, showDialog, showPopup, TextField, ToastMessage, Winicon } from "wini-web-components"
import { DataController, SettingDataController } from "../../../module/controller"
import { useEffect, useRef, useState } from "react"
import CaptureElement from '../../../../project-component/captureElement'
import { DrawerSettingCard } from './drawer'
import RenderCard from './renderCard'

export default function SettingCardTab({ item }) {
    const _settingDataController = new SettingDataController("card")
    const _dataController = new DataController(item.Name)
    const [data, setData] = useState({ data: [], totalCount: undefined })
    const [dataModel, setDataModel] = useState()
    const ref = useRef()
    const dialogRef = useRef()
    const [onEditId, setOnEditId] = useState()

    const showPopupAddCard = (cardItem) => {
        showPopup({
            ref: ref,
            hideButtonClose: true,
            style: { width: '100vw', height: '100dvh', maxWidth: '100vw', maxHeight: '100dvh' },
            body: <DrawerSettingCard
                ref={ref}
                dataModel={dataModel}
                TbName={item.Name}
                cardItem={cardItem}
                onSubmit={getData}
            />
        })
    }

    const getData = async (page) => {
        const res = await _settingDataController.getListSimple({ page: 1, size: 20, query: `@TbName:{${item.Name}}`, sortby: { BY: "DateCreated" } })
        if (res.code === 200) {
            setData({
                data: res.data,
                totalCount: res.totalCount
            })
        }
    }

    useEffect(() => {
        if (dataModel) getData()
    }, [dataModel])

    useEffect(() => {
        _dataController.getListSimple({ page: 1, size: 1 }).then(res => {
            if (res.code === 200) setDataModel(res.data[0])
        })
    }, [])

    return <>
        <Dialog ref={dialogRef} />
        <div className='row' style={{ padding: '2.4rem', width: '100%', flex: 1, height: '100%', flexWrap: 'wrap', overflow: 'hidden auto', gap: '2.4rem', alignItems: 'start' }}>
            <Popup ref={ref} />
            {data.data.map(e => {
                const _layers = e.Props ? JSON.parse(e.Props) : []
                return <div key={e.Id} className={`col col12 col8-xxl`} style={{ gap: "0.4rem", '--gutter': "2.4rem" }}>
                    <div onDoubleClick={() => { setOnEditId(e.Id) }}>
                        <TextField
                            autoFocus
                            defaultValue={e.Name}
                            className={`label-4 ${styles["input-card-name"]}`}
                            disabled={onEditId !== e.Id}
                            style={{ padding: '0.4rem' }}
                            onComplete={(ev) => ev.target.blur()}
                            onBlur={async (ev) => {
                                if (onEditId === e.Id) {
                                    const res = await _settingDataController.action("edit", {
                                        data: [{
                                            ...e,
                                            Name: ev.target.value,
                                            Props: typeof e.Props === "string" ? e.Props : JSON.stringify(e.Props)
                                        }]
                                    })
                                    if (res.code !== 200) return ToastMessage.errors(res.message)
                                    setData({ ...data, data: data.data.map(d => d.Id === e.Id ? { ...d, Name: ev.target.value } : d) })
                                    setOnEditId(undefined)
                                }
                            }}
                        />
                    </div>
                    <div className={`col ${styles["demo-container"]}`}>
                        <CaptureElement className='col demo-card-container' style={{ width: "100%", height: "100%", flex: 1, padding: "1.6rem" }}>
                            {dataModel && <RenderCard
                                cardItem={e}
                                dataModel={dataModel}
                                layers={Array.isArray(_layers) ? _layers : []}
                            />}
                        </CaptureElement>
                        <div className='row'>
                            <button type='button' className='row icon-button40' onClick={() => { showPopupAddCard(e) }}><Winicon src='fill/user interface/s-edit' size={'2.8rem'} /></button>
                            <button type='button' className='row icon-button40' onClick={() => {
                                showDialog({
                                    ref: dialogRef,
                                    alignment: DialogAlignment.center,
                                    status: ComponentStatus.WARNING,
                                    title: "Confirm delete",
                                    content: "Are you sure you want to delete this card",
                                    submitTitle: "Delete",
                                    onSubmit: () => {
                                        _settingDataController.action("delete", { ids: [e.Id] }).then(res => {
                                            if (res.code === 200) getData()
                                        })
                                    },
                                })
                            }}
                            >
                                <Winicon src={'fill/user interface/trash-can'} size={'2.8rem'} /></button>
                        </div>
                    </div>
                </div>
            })}
        </div>
        <button type="button" className={`row icon-button60 ${styles['button-add-chart']}`} style={{ '--color': 'var(--primary-main-color)' }} onClick={() => { showPopupAddCard() }}>
            <Winicon src={"fill/user interface/c-add"} size={'3.2rem'} />
        </button>
    </>
}

