import styles from './screen.module.css'
import { useEffect, useRef, useState } from 'react'
import { closePopup, Popup, showPopup, Switch, Text, TextField, ToastMessage, Winicon } from 'wini-web-components'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ComponentType } from '../../wini/table/da'
import { OutlineButton, OutlineContainer, OutlineInputMultiLine, OutlineInputPipe, OutlineWorkBadge } from '../../../assets/icon'
import AssetsTab from './assets'
import { TableController } from '../../wini/table/controller'
import { randomGID } from '../../../Utils'
import { PopupSettingPage } from './settingPopup/settingPage'
import { PageActions } from './reducer'

export default function LeftView({ listPage = [], onUpdatePage, methods }) {
    const _pageController = new TableController("page")
    const { page, layers, layout } = useSelector((store) => store.page.data)
    const [tab, setTab] = useState(0)
    const navigate = useNavigate()
    const popupRef = useRef()
    const layerScrollRef = useRef()
    const dispatch = useDispatch()

    const handleResizeH = (ev) => {
        ev.preventDefault()
        const pageListElement = ev.target.closest('.page-list-conatiner')
        function onResize(event) {
            const pageListRect = pageListElement.getBoundingClientRect()
            pageListElement.style.height = `${pageListRect.height - pageListRect.bottom + event.pageY}px`
        }
        document.body.addEventListener('mousemove', onResize)
        document.body.onmouseup = () => {
            document.body.removeEventListener('mousemove', onResize)
            document.body.onmouseup = undefined
        }
    }

    const handleResizeW = (ev) => {
        ev.preventDefault()
        const _sidebar = ev.target.closest('.setting-page-sidebar.left')
        methods.setValue("selectedId", undefined)
        function onResize(event) {
            document.body.querySelector(`div[class*="screen_setting-page-body"]`).style.pointerEvents = "none"
            const pageListRect = _sidebar.getBoundingClientRect()
            _sidebar.style.width = `${pageListRect.width - pageListRect.right + event.pageX}px`
        }
        document.body.addEventListener('mousemove', onResize)
        document.body.onmouseup = () => {
            document.body.querySelector(`div[class*="screen_setting-page-body"]`).style.pointerEvents = "auto"
            document.body.removeEventListener('mousemove', onResize)
            document.body.onmouseup = undefined
        }
    }

    const addPage = async () => {
        const _newPage = {
            Id: randomGID(),
            Name: 'new page',
            DateCreated: Date.now(),
            Sort: listPage.length,
            State: "[]",
            Params: "[]",
            Url: "/",
        }
        const addPageRes = await _pageController.add([_newPage])
        if (addPageRes.code !== 200) return ToastMessage.errors(addPageRes.message)
        methods.reset()
        onUpdatePage([...listPage, _newPage])
        const params = new URLSearchParams();
        params.append("pageid", _newPage.Id)
        navigate('?' + params.toString())
    }

    const deletePage = async (id) => {
        const deleteRes = await _pageController.delete([id])
        if (deleteRes.code !== 200) return ToastMessage.errors(deleteRes.message)
        const _layerController = new TableController("layer")
        let _index = listPage.findIndex(e => e.Id === id)
        const _newListPage = listPage.filter(e => e.Id !== id)
        if (page.Id === id) {
            _index = _index > 0 ? (_index - 1) : 0
            const params = new URLSearchParams();
            params.append("pageid", _newListPage[_index].Id)
            navigate('?' + params.toString())
        }
        onUpdatePage(_newListPage)
        const res = await _layerController.getListSimple({ page: 1, size: 1000, query: `@PageId:{${id}}`, returns: ["Id"] })
        if (res.code === 200) _layerController.delete(res.data.map(e => e.Id))
    }

    const showPopupSettingPage = () => {
        showPopup({
            ref: popupRef,
            hideButtonClose: true,
            style: { width: "72%", height: "100%", backgroundColor: "var(--neutral-absolute-background-color)" },
            body: <PopupSettingPage
                ref={popupRef}
                required={layers && layers.length === 0}
                listPage={listPage}
                onSubmit={async (ev) => {
                    methods.reset()
                    await PageActions.getPageDataById(dispatch, page.Id)
                    onUpdatePage(listPage.map(e => e.Id === ev.Id ? ev : e))
                }}
            />
        })
    }

    const renderTabView = () => {
        switch (tab) {
            case 0:
                return <>
                    <div className='col page-list-conatiner'>
                        <div className='row' style={{ padding: '0.8rem 0.8rem 0.8rem 1.2rem' }}>
                            <Text className='semibold1' style={{ flex: 1 }}>Pages</Text>
                            <button type='button' className='row icon-button20' onClick={addPage}>
                                <Winicon src={"fill/user interface/e-add"} />
                            </button>
                        </div>
                        <div className='col' style={{ padding: '0.4rem' }}>
                            {listPage.map((item) => <PageTile
                                key={item.Id}
                                item={item}
                                methods={methods}
                                onUpdate={(tmp) => {
                                    onUpdatePage(listPage.map(e => e.Id === tmp.Id ? tmp : e))
                                }}
                                showPopupSettingPage={showPopupSettingPage}
                                onDelete={() => deletePage(item.Id)}
                            />)}
                        </div>
                        <div className='row' onMouseDown={handleResizeH} />
                    </div>
                    <div className={`row`} style={{ padding: "1.2rem 0.8rem 1.2rem 1.2rem", backgroundColor: "#14181b", borderBottom: "1px solid #323b45" }}>
                        <Text className='semibold1' style={{ flex: 1 }}>Full layout</Text>
                        <Switch value={methods.watch("openLayout")} onChange={(v) => { methods.setValue("openLayout", v) }} />
                    </div>
                    <div ref={layerScrollRef} className={`col ${styles['layer-list-container']}`}>
                        <button hidden type='button' className='open-popup' onClick={(ev) => {
                            const popupId = ev.target.getAttribute("popupId")
                            if (!popupId) return false;
                            const popupComponent = document.getElementById("iframe-render-page-view").contentWindow.document.getElementById(popupId)
                            const openBtn = popupComponent?.querySelector(":scope > button.open")
                            if (openBtn) {
                                methods.setValue("popupId", popupId)
                                openBtn.click()
                            }
                        }} />
                        <button hidden type='button' className='close-popup' onClick={(ev) => {
                            const popupId = ev.target.getAttribute("popupId")
                            if (!popupId) return false;
                            const popupComponent = document.getElementById("iframe-render-page-view").contentWindow.document.getElementById(popupId)
                            const closePopup = popupComponent?.querySelector(":scope > button.close")
                            if (closePopup) {
                                methods.setValue("popupId", null)
                                closePopup.click()
                            }
                        }} />
                        {
                            methods.watch("popupId") ?
                                (layers ?? []).filter(e => e.Id === methods.watch("popupId") && e.Type === ComponentType.popup).map(e => <LayerTile key={e.Id} item={e} methods={methods} />) :
                                (layout && methods.watch("openLayout")) ?
                                    (layout ?? []).filter(e => !e.ParentId).map(e => <LayerTile key={e.Id} item={e} methods={methods} />) :
                                    (layers ?? []).filter(e => !e.ParentId && e.Type !== ComponentType.popup).map(e => <LayerTile key={e.Id} item={e} methods={methods} />)
                        }
                    </div>
                </>
            case 1:
                return <AssetsTab key={page.Id} />
            default:
                return <div />
        }
    }

    useEffect(() => {
        const _selectedId = methods.getValues("selectedId")
        if (layerScrollRef.current && _selectedId) {
            const _scrollRect = layerScrollRef.current.getBoundingClientRect()
            const _layerElement = layerScrollRef.current.querySelector(`.layer-tile-container[id="${_selectedId}"]`)
            if (_layerElement) {
                const _layerRect = _layerElement.getBoundingClientRect()
                if (_layerRect.bottom <= _scrollRect.y || _layerRect.y >= _scrollRect.bottom) {
                    layerScrollRef.current.scrollTo({
                        top: _layerElement.offsetTop - layerScrollRef.current.offsetTop - 40,
                        behavior: "smooth"
                    })
                }
            }
        }
    }, [methods.watch("selectedId")])

    return <div className="row setting-page-sidebar left" style={{ pointerEvents: layers?.length ? undefined : "none" }}>
        <Popup ref={popupRef} />
        <div className='col' style={{ flex: 1, height: '100%' }}>
            <div className='row action-tab'>
                <button type='button' className={`row ${tab === 0 ? "selected" : ""}`} onClick={() => { setTab(0) }}>
                    <Text className='semibold1'>Screen</Text>
                </button>
                <button type='button' className={`row ${tab === 1 ? "selected" : ""}`} onClick={() => { setTab(1) }}>
                    <Text className='semibold1'>Assets</Text>
                </button>
                <div style={{ flex: 1 }} />
                <button type='button' className={`row icon-button20 ${tab === 2 ? "selected" : ""}`} onClick={() => { setTab(2) }}>
                    <Winicon src={"fill/development/zoom"} size={"1.2rem"} />
                </button>
            </div>
            {renderTabView()}
        </div>
        <div className='col' onMouseDown={handleResizeW} />
    </div>
}

//#region PageTile
const PageTile = ({ item, methods, onUpdate, onDelete, showPopupSettingPage }) => {
    const { page, layout } = useSelector((store) => store.page.data)
    const [onEdit, setOnEdit] = useState(false)
    const popupRef = useRef()
    const navigate = useNavigate()

    const handleRightClick = (ev) => {
        switch (ev.type) {
            case 'contextmenu':
                ev.preventDefault()
                showPopup({
                    ref: popupRef,
                    clickOverlayClosePopup: true,
                    style: { left: ev.pageX, top: ev.pageY, borderRadius: '0.4rem' },
                    body: <div className="col popup-actions" style={{ maxHeight: 'fit-content' }}>
                        <button type="button" onClick={() => {
                            closePopup(popupRef)
                            setOnEdit(true)
                        }}>
                            <Text className="button-text-3">Change name</Text>
                        </button>
                        <button type="button" disabled={onDelete === undefined} onClick={() => {
                            closePopup(popupRef)
                            onDelete()
                        }}>
                            <Text className="button-text-3">Delete</Text>
                        </button>
                    </div>
                })
                break;
            default:
                break;
        }
    }

    return <>
        <Popup ref={popupRef} />
        <div className={`row ${styles['page-tile']} ${page?.Id === item.Id ? styles["selected"] : ""}`}
            onClick={() => {
                const params = new URLSearchParams();
                methods.reset()
                params.append("pageid", item.Id)
                navigate('?' + params.toString())
            }}
            onContextMenu={handleRightClick}
        >
            {onEdit ?
                <TextField
                    autoFocus
                    className='semibold1'
                    style={{ padding: "0.4rem" }}
                    defaultValue={item.Name}
                    onComplete={(ev) => { ev.target.blur() }}
                    onBlur={(ev) => {
                        const pageController = new TableController("page")
                        const _tmp = { ...item, Name: ev.target.value.trim() }
                        pageController.edit([_tmp]).then(res => {
                            if (res.code === 200) {
                                setOnEdit(false)
                                onUpdate(_tmp)
                            }
                        })
                    }}
                /> :
                <Text className='semibold1' style={{ padding: "0.5rem" }}>{item.Name}</Text>}
            {page && layout && page.Id === item.Id && <button type='button' className='row icon-button24' onClick={showPopupSettingPage}
                ref={btn => {
                    if (layout?.length === 0 && btn) {
                        btn.click()
                        btn.disabled = true
                    }
                }}
            >
                <Winicon src='outline/user interface/setup-preferences' />
            </button>}
        </div>
    </>
}

//#region LayerTile
const LayerTile = ({ item, space = 0.4, display = "flex", methods }) => {
    const ref = useRef()
    const { layers, layout } = useSelector((store) => store.page.data)
    const children = item.Setting?.className?.includes("layout-body") ? layers.filter(e => !e.ParentId) : (item.PageId ? layers : layout).filter(e => e.ParentId === item.Id)
    const [isOpen, setIsOpen] = useState(true)
    const [editName, setEditName] = useState(false)
    const dispatch = useDispatch()

    const _icon = () => {
        switch (item.Type) {
            case ComponentType.text:
                return <Winicon src='fill/text/text' size={'1.4rem'} />
            case ComponentType.img:
                return <Winicon src={"fill/development/image"} size={'1.4rem'} />
            case ComponentType.checkbox:
                return <Winicon src={"outline/user interface/checkbox-btn-checked"} size={'1.4rem'} />
            case ComponentType.radio:
                return <Winicon src={"outline/user interface/radio-btn-checked"} size={'1.4rem'} />
            case ComponentType.switch:
                return <Winicon src={"outline/user interface/toggle"} size={'1.4rem'} />
            case ComponentType.textField:
                return <OutlineInputPipe color={'var(--neutral-text-title-color)'} size={'1.8rem'} />
            case ComponentType.textArea:
                return <OutlineInputMultiLine color={'var(--neutral-text-title-color)'} size={'1.8rem'} />
            case ComponentType.button:
                return <OutlineButton color={'var(--neutral-text-title-color)'} size={'1.8rem'} />
            case ComponentType.container:
                return <OutlineContainer color={'var(--neutral-text-title-color)'} size={'1.8rem'} />
            case ComponentType.chart:
                return <Winicon src={"outline/business/chart-bar-33"} size='1.4rem' />
            case ComponentType.icon:
                return <Winicon src={"fill/development/icon"} size='1.4rem' />
            case ComponentType.form:
                return <Winicon src={"outline/development/window-paragraph"} size={'1.4rem'} />
            case ComponentType.popup:
                return <Winicon src={"outline/development/stack"} size={'1.4rem'} />
            case ComponentType.card:
                return <OutlineWorkBadge color={'var(--neutral-text-title-color)'} style={{ fontSize: '1.8rem' }} />
            default:
                return null
        }
    }

    return <div id={item.Id} ref={ref} className='col layer-tile-container' style={{ gap: '0.4rem', order: item.Setting?.style?.order, display: display }}>
        <div className={`row ${styles['layer-tile']} ${item.Id === methods.watch("selectedId") ? styles["selected"] : ""}`} style={{ paddingLeft: `${space}rem` }} onMouseEnter={() => methods.setValue("hoverId", item.Id)} onMouseOut={() => methods.setValue("hoverId", undefined)} onClick={() => { methods.setValue("selectedId", item.Id) }}>
            {children.length ? <button type='button' className='row icon-button20' onClick={() => { setIsOpen(!isOpen) }}><Winicon src={`fill/arrows/triangle-${isOpen ? "down" : "right"}`} size='1.2rem' /></button> : null}
            <div className='row icon-button24'>{_icon()}</div>
            {editName ? <TextField
                autoFocus
                defaultValue={item.Name}
                className='semibold1'
                onFocus={(ev) => { ev.target.select() }}
                onComplete={(ev) => { ev.target.blur() }}
                onBlur={(ev) => {
                    PageActions.updateLayerAll(dispatch, [{ ...item, Name: ev.target.value.trim() }])
                }}
            /> :
                <div className='semibold1 comp-text' style={{ flex: 1, padding: `calc(0.4rem + 1px)`, "--max-line": 1 }} onDoubleClick={() => { setEditName(true) }}>{item.Name}</div>
            }
        </div>
        {children.map(e => <LayerTile key={e.Id} item={e} space={space + 2.8} display={isOpen ? "flex" : "none"} methods={methods} />)}
    </div>
}