import { Dispatch, PayloadAction, UnknownAction, createSlice } from '@reduxjs/toolkit'
import { MenuItem } from '../wini/table/da'

interface ModuleSimpleResponse {
    data?: MenuItem,
    onLoading?: boolean,
    type?: string
}

const initState: ModuleSimpleResponse = {
    data: undefined,
    onLoading: true
}

export const moduleSlice = createSlice({
    name: 'module',
    initialState: initState,
    reducers: {
        handleActions: (state, action: PayloadAction<any>) => {
            switch (action.payload.type) {
                case 'SETM':
                    let tmp = { ...action.payload.data }
                    if (tmp.Setting && typeof tmp.Setting === 'string') tmp.Setting = JSON.parse(tmp.Setting)
                    state.data = tmp
                    break;
                case 'RESET':
                    state.data = undefined
                    break;
                default:
                    break;
            }
            state.onLoading = false
        },
        onFetching: (state) => {
            state.onLoading = true
        },
    },
})

const { handleActions, onFetching } = moduleSlice.actions

export default moduleSlice.reducer

export class ModuleActions {
    static setSelectedM = (dispatch: Dispatch<UnknownAction>, item: MenuItem) => {
        dispatch(onFetching())
        setTimeout(() => {
            dispatch(handleActions({
                type: 'SETM',
                data: item,
            }))
        }, 200)
    }

    static reset = (dispatch: Dispatch<UnknownAction>) => {
        dispatch(handleActions({ type: 'RESET' }))
    }
}