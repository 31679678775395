import { useEffect, useRef, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { closePopup, Popup, Select1, showPopup, Text, TextField, Winicon } from "wini-web-components";
import { randomGID } from "../../../../../Utils";
import { FilterType } from "../../../da";
import { SettingDataController } from "../../../controller";
import { TableController } from "../../../../wini/table/controller";
import { useSelector } from "react-redux";

export default function FilterBlock({ selected, onChange }) {
    const { control, setValue, getValues, watch } = useForm({ shouldFocusError: false, defaultValues: { filter: [], col: [], rel: [] } })
    const _filter = useFieldArray({
        control: control,
        name: "filter",
        keyName: undefined
    })
    const popupRef = useRef()
    const [cardItem, setCardItem] = useState()

    const onUpdate = () => {
        onChange({
            ...selected, Setting: {
                ...selected.Setting, filter: getValues("filter").map(e => {
                    let _tmp = { ...e }
                    delete _tmp.id
                    return _tmp
                })
            }
        })
    }

    useEffect(() => {
        if (selected.Setting.cardId) {
            const _settingDataController = new SettingDataController("card")
            _settingDataController.getByIds([selected.Setting.cardId]).then(async (res) => {
                if (res.code === 200) {
                    let _cardItem = res.data[0]
                    if (_cardItem.Props && typeof _cardItem.Props === "string") _cardItem.Props = JSON.parse(_cardItem.Props)
                    setCardItem(_cardItem)
                }
            })
            setValue("filter", selected.Setting.filter ?? [])
        } else setValue("filter", [])
    }, [selected.Setting.cardId])

    useEffect(() => {
        if (cardItem) {
            const _colController = new TableController("column")
            _colController.getListSimple({ page: 1, size: 100, query: `@TableName:{${cardItem.TbName}}`, returns: ["Id", "Name", "DataType", "Form"] }).then(res => {
                if (res.code === 200) {
                    const _colRes = res.data.map((e, i) => {
                        e.Setting = e.Setting ? JSON.parse(e.Setting) : { Title: e.Name, Sort: i }
                        e.Form = e.Form ? JSON.parse(e.Form) : {}
                        return e
                    }).sort((a, b) => a.Name === "Name" ? -1 : b.Name === "Name" ? 1 : a.Setting.Sort - b.Setting.Sort)
                    setValue("col", _colRes.map((e, i) => { return { ...e, Setting: { ...e.Setting, Sort: i } } }))
                }
            })
            const _relController = new TableController("rel")
            _relController.getListSimple({ page: 1, size: 100, query: `@TableFK:{${cardItem.TbName}}`, returns: ["Id", "Column", "TablePK", "Form"] }).then(res => {
                if (res.code === 200) {
                    setValue("rel", res.data.map((e, i) => {
                        e.Setting = e.Setting ? JSON.parse(e.Setting) : { Title: e.Name, Sort: i }
                        e.Form = e.Form ? JSON.parse(e.Form) : {}
                        return e
                    }).sort((a, b) => a.Setting.Sort - b.Setting.Sort))
                }
            })
        }
    }, [cardItem])

    return <div className="col" style={{ borderBottom: "var(--neutral-bolder-border)" }}>
        <Popup ref={popupRef} />
        <div className="row" style={{ padding: '0.8rem 1.2rem', gap: '0.4rem' }}>
            <Text className="semibold1" style={{ flex: 1 }}>Filter</Text>
            <Winicon src="fill/user interface/e-add" size={"1.4rem"} onClick={(ev) => {
                const _rect = ev.target.closest(".row").getBoundingClientRect()
                showPopup({
                    ref: popupRef,
                    clickOverlayClosePopup: true,
                    style: { position: 'absolute', right: "1.2rem", top: _rect.bottom },
                    body: <div className="col popup-actions">
                        {_filter.fields.some(e => e.Type === FilterType.limit) ? null : <button type="button" className="row" onClick={() => {
                            const newFilter = { Id: randomGID(), Type: FilterType.limit, Value: 8 }
                            _filter.append(newFilter)
                            closePopup(popupRef)
                            onUpdate()
                        }}>
                            <Text className="button-text-4">Limit</Text>
                        </button>}
                        <button type="button" className="row" onClick={() => {
                            const newFilter = { Id: randomGID(), Type: FilterType.params, Name: "Name" }
                            _filter.append(newFilter)
                            closePopup(popupRef)
                        }}>
                            <Text className="button-text-4">Params</Text>
                        </button>
                        <button type="button" className="row" onClick={() => {
                            const newFilter = { Id: randomGID(), Type: FilterType.fixed, Name: "Name" }
                            _filter.append(newFilter)
                            closePopup(popupRef)
                        }}>
                            <Text className="button-text-4">Fixed</Text>
                        </button>
                    </div>
                })
            }} />
        </div>
        <div className="col">
            {_filter.fields.map((item, i) => <FilterTile
                key={item.Id}
                item={item}
                cols={watch("col")}
                rels={watch("rel")}
                onUpdate={(ev) => {
                    _filter.update(i, ev)
                    onUpdate()
                }}
                onDelete={() => {
                    _filter.remove(i)
                    onUpdate()
                }}
            />)}
        </div>
    </div>
}

const FilterTile = ({ item, onDelete, onUpdate, cols = [], rels = [], }) => {
    const { page } = useSelector((store) => store.page.data)

    const setValueByType = () => {
        switch (item.Type) {
            case FilterType.limit:
                return <TextField
                    defaultValue={item.Value}
                    style={{ flex: 1 }}
                    className="regular1"
                    type="number"
                    onComplete={(ev) => { ev.target.blur() }}
                    onBlur={(ev) => {
                        onUpdate({ ...item, Value: parseInt(ev.target.value) })
                    }}
                />
            case FilterType.params:
                return <Select1
                    readOnly
                    value={item.Name}
                    options={(page.Params && typeof page.Params === "string" ? JSON.parse(page.Params) : []).map(e => { return { id: e.Key, name: e.Key } })}
                    placeholder="name field"
                    style={{ flex: 1 }}
                    className="regular1"
                    onChange={(ev) => {
                        onUpdate({ ...item, Name: ev.id })
                    }}
                />
            case FilterType.fixed:
                return <Select1
                    readOnly
                    value={item.Name}
                    options={[...cols, ...rels].map(e => { return { id: e.Column ?? e.Name, name: e.Column ?? e.Name } })}
                    placeholder="name field"
                    style={{ flex: 1 }}
                    className="regular1"
                />
            default:
                return <div />
        }
    }

    return <div className="row" style={{ gap: "1.2rem", padding: "0.8rem 0.6rem 0.8rem 1.2rem" }}>
        <Text className="label-4" style={{ width: "6rem" }}>{item.Type}</Text>
        {setValueByType()}
        <Winicon src="fill/user interface/e-delete" size={"1.4rem"} onClick={onDelete} style={{ padding: "0.6rem" }} />
    </div>
}