import { ComponentType } from "../../screen/wini/table/da";

export const HeaderOnly = [
    {
        "Id": "main-layout",
        "Name": "Main-layout",
        "Type": ComponentType.container,
        "Setting": {
            "className": "col main-layout",
            "style": { "backgroundColor": "#fff" }
        }
    },
    {
        "Id": "header",
        "Name": "Header",
        "ParentId": "main-layout",
        "Type": ComponentType.container,
        "Setting": {
            "className": "row header",
            "style": { "order": 0, "width": "100%", "height": "5.6rem", "padding": "1.2rem 2.4rem", "borderBottom": "1px solid #D7D7DB", "backgroundColor": "#fff", "position": "sticky", "top": "0px", "zIndex": 9 }
        }
    },
    {
        "Id": "Heading text",
        "ParentId": "header",
        "Name": "Heading text",
        "Type": ComponentType.text,
        "Setting": {
            "className": "heading-6",
            "value": "Heading text",
            "style": { "order": 0, "color": "#18181B" }
        }
    },
    {
        "Id": "body",
        "Name": "layout-body",
        "ParentId": "main-layout",
        "Type": ComponentType.container,
        "Setting": {
            "className": "col layout-body",
            "style": { "order": 1, "width": "100%", "padding": "1.2rem 2.4rem" }
        }
    }
]

export const HeaderWithLeftSidebar = [
    {
        "Id": "main-layout",
        "Name": "Main-layout",
        "Type": ComponentType.container,
        "Setting": {
            "className": "row main-layout",
            "style": { "backgroundColor": "#fff" }
        }
    },
    {
        "Id": "header",
        "Name": "Header",
        "ParentId": "main-layout",
        "Type": ComponentType.container,
        "Setting": {
            "className": "row header",
            "style": { "order": 0, "width": "100%", "height": "5.6rem", "padding": "1.2rem 2.4rem", "borderBottom": "1px solid #D7D7DB", "backgroundColor": "#fff", "position": "sticky", "top": "0px", "zIndex": 9 }
        }
    },
    {
        "Id": "sidebar",
        "Name": "Sidebar",
        "ParentId": "main-layout",
        "Type": ComponentType.container,
        "Setting": {
            "className": "col sidebar",
            "style": { "order": 1, "width": "29.6rem", "height": "calc(100dvh - 5.6rem)", "padding": "1.6rem", "borderRight": "1px solid #D7D7DB", "backgroundColor": "#fff", "position": "sticky", "top": "5.6rem", "zIndex": 8 }
        }
    },
    {
        "Id": "Sidebar text",
        "ParentId": "sidebar",
        "Name": "Sidebar text",
        "Type": ComponentType.text,
        "Setting": {
            "className": "label-3",
            "value": "Sidebar text",
            "style": { "order": 0, "color": "#313135" }
        }
    },
    {
        "Id": "Heading text",
        "ParentId": "header",
        "Name": "Heading text",
        "Type": ComponentType.text,
        "Setting": {
            "className": "heading-6",
            "value": "Heading text",
            "style": { "order": 0, "color": "#18181B" }
        }
    },
    {
        "Id": "body",
        "Name": "layout-body",
        "ParentId": "main-layout",
        "Type": ComponentType.container,
        "Setting": {
            "className": "col layout-body",
            "style": { "order": 2, "width": "100%", "padding": "1.2rem 2.4rem", "flex": 1 }
        }
    }
]

export const HeaderWithLeftRightSidebar = [
    {
        "Id": "main-layout",
        "Name": "Main-layout",
        "Type": ComponentType.container,
        "Setting": {
            "className": "row main-layout",
            "style": { "backgroundColor": "#fff" }
        }
    },
    {
        "Id": "header",
        "Name": "Header",
        "ParentId": "main-layout",
        "Type": ComponentType.container,
        "Setting": {
            "className": "row header",
            "style": { "order": 0, "width": "100%", "height": "5.6rem", "padding": "1.2rem 2.4rem", "borderBottom": "1px solid #D7D7DB", "backgroundColor": "#fff", "position": "sticky", "top": "0px", "zIndex": 9 }
        }
    },
    {
        "Id": "Heading text",
        "ParentId": "header",
        "Name": "Heading text",
        "Type": ComponentType.text,
        "Setting": {
            "className": "heading-6",
            "value": "Heading text",
            "style": { "order": 0, "color": "#18181B" }
        }
    },
    {
        "Id": "body",
        "Name": "layout-body",
        "ParentId": "main-layout",
        "Type": ComponentType.container,
        "Setting": {
            "className": "col layout-body",
            "style": { "order": 2, "width": "100%", "padding": "1.2rem 2.4rem", "flex": 1 }
        }
    },
    {
        "Id": "left sidebar",
        "Name": "Left Sidebar",
        "ParentId": "main-layout",
        "Type": ComponentType.container,
        "Setting": {
            "className": "col sidebar",
            "style": { "order": 1, "width": "29.6rem", "height": "calc(100dvh - 5.6rem)", "padding": "1.6rem", "borderRight": "1px solid #D7D7DB", "backgroundColor": "#fff", "position": "sticky", "top": "5.6rem", "zIndex": 8 }
        }
    },
    {
        "Id": "left Sidebar text",
        "ParentId": "left sidebar",
        "Name": "Left Sidebar text",
        "Type": ComponentType.text,
        "Setting": {
            "className": "label-3",
            "value": "Left Sidebar text",
            "style": { "order": 0, "color": "#313135" }
        }
    },
    {
        "Id": "right sidebar",
        "Name": "Right Sidebar",
        "ParentId": "main-layout",
        "Type": ComponentType.container,
        "Setting": {
            "className": "col sidebar",
            "style": { "order": 3, "width": "29.6rem", "height": "calc(100dvh - 5.6rem)", "padding": "1.6rem", "borderLeft": "1px solid #D7D7DB", "backgroundColor": "#fff", "position": "sticky", "top": "5.6rem", "zIndex": 8 }
        }
    },
    {
        "Id": "right Sidebar text",
        "ParentId": "right sidebar",
        "Name": "Right Sidebar text",
        "Type": ComponentType.text,
        "Setting": {
            "className": "label-3",
            "value": "Right Sidebar text",
            "style": { "order": 0, "color": "#313135" }
        }
    }
]

export const LeftSidebarOnly = [
    {
        "Id": "main-layout",
        "Name": "Main-layout",
        "Type": ComponentType.container,
        "Setting": {
            "className": "row main-layout",
            "style": { "backgroundColor": "#fff" }
        }
    },
    {
        "Id": "sidebar",
        "Name": "Sidebar",
        "ParentId": "main-layout",
        "Type": ComponentType.container,
        "Setting": {
            "className": "col sidebar",
            "style": { "order": 1, "width": "29.6rem", "height": "100dvh", "padding": "1.6rem", "borderRight": "1px solid #D7D7DB", "backgroundColor": "#fff", "position": "sticky", "top": "0px", "zIndex": 8 }
        }
    },
    {
        "Id": "Sidebar text",
        "ParentId": "sidebar",
        "Name": "Sidebar text",
        "Type": ComponentType.text,
        "Setting": {
            "className": "label-3",
            "value": "Sidebar text",
            "style": { "order": 0, "color": "#313135" }
        }
    },
    {
        "Id": "body",
        "Name": "layout-body",
        "ParentId": "main-layout",
        "Type": ComponentType.container,
        "Setting": {
            "className": "col layout-body",
            "style": { "order": 2, "width": "100%", "padding": "1.2rem 2.4rem", "flex": 1 }
        }
    }
]