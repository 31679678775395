import { FEDataType } from "../wini/table/da"

// report module
export interface ReportModelItem {
    Id: string,
    Name: string,
    TbName: string,
    DateCreated: number,
    Description?: string,
    Columns: string
}

export enum Reducers {
    COUNT = "COUNT",
    COUNT_DISTINCT = "COUNT_DISTINCT",
    COUNT_DISTINCTISH = "COUNT_DISTINCTISH",
    SUM = "SUM",
    MIN = "MIN",
    MAX = "MAX",
    AVG = "AVG",
    STDDEV = "STDDEV",
    QUANTILE = "QUANTILE",
    TOLIST = "TOLIST",
    FIRST_VALUE = "FIRST_VALUE",
    RANDOM_SAMPLE = "RANDOM_SAMPLE"
}

export interface ReducerItem {
    Id: string,
    Name: string,
    Title?: string,
    ReducerBy?: string,
    Query?: string,
    Reducer: string | Reducers,
    DataType: FEDataType,
    ModelId: string,
    RelativeId: string,
}


// dashboard module
export enum EChartType {
    // "area" | "line" | "donut" | "pie" | "radar" | "bar" | "scatter" | "bubble" | "polarArea" | "radialBar" | "heatmap" | "candlestick" | "boxPlot" | "rangeBar" | "rangeArea" | "treemap" | undefined
    line = 'line',
    bar = 'bar',
    horizontalBar = 'horizontal bar',
    scatter = 'scatter',
    bubble = 'bubble',
    radar = 'radar',
    area = 'area',
    doughnut = 'doughnut',
    pie = 'pie',
}

export enum FormType {
    leftLabelCol = "left-label col",
    topLabelCol = "top-label col",
}

export interface ChartItem {
    Id: string,
    Name: string,
    TbName: string,
    Title: string,
    Type?: EChartType,
    RelativeId: string,
    GroupBy?: string,
    ReducerBy?: string,
    DateCreated: number,
    Sort: number,
    Reducer: string | Reducers,
    DataType: FEDataType,
    Setting: string | { Datasets: Array<{ Id: string | number, Label?: string, BackgroundColor?: string }>, BorderWidth?: number, Legend?: 'top' | 'right' | 'bottom' | 'left' }
}

export interface FormItem {
    Id: string,
    Name: string,
    TbName: string,
    DateCreated: number,
    Description: string,
    Type: FormType,
    Props: string | { [p: string]: any },
}

export interface CardItem {
    Id: string,
    Name: string,
    TbName: string,
    DateCreated: number,
    Description: string,
    Props: string | { [p: string]: any },
}

export interface PageItem {
    Id: string,
    Name: string,
    DateCreated: number,
    Sort: number,
    Url?: string,
    Params?: string,
    LayoutId?: string,
}

export interface LayerItem {
    Id: string,
    Name: string,
    DateCreated: number,
    Type: string,
    ParentId?: string,
    Setting?: string,
    PageId: string,
    ProjectId?: string
    LayoutId?: string,
}

export enum RoleType {
    create = "create",
    read = "read",
    edit = "edit",
    delete = "delete",
}

export enum TriggerType {
    click = "click",
    dbclick = "dbclick",
    hover = "hover",
    keydown = "keydown",
    mouseenter = "mouseenter",
    mouseleave = "mouseleave",
    mousedown = "mousedown",
    mouseup = "mouseup",
}

export enum ActionType {
    navigate = "navigate",
    reload = "reload",
    back = "back",
    scrollTo = "scrollTo",
    showPopup = "showPopup",
    toastMessage = "toastMessage",
    closePopup = "closePopup",
    mousedown = "mousedown",
    mouseup = "mouseup",
    submit = "submit",
}

export enum FilterType {
    limit = "limit",
    params = "params",
    fixed = "fixed",
}