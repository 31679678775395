import { forwardRef, useEffect, useRef, useState } from "react"
import styles from './index.module.css'
import { useDispatch, useSelector } from "react-redux"
import { TableController } from "../../../wini/table/controller"
import { Button, closePopup, ComponentStatus, Dialog, showDialog, Switch, Text, ToastMessage, Winicon } from "wini-web-components"
import CaptureElement from "../../../../project-component/captureElement"
import RenderPageByLayers from "../renderPageView"
import * as ListLayout from '../../../../assets/layout/json'
import { randomGID, Ultis } from "../../../../Utils"
import { PageActions } from "../reducer"
import { ProjectController } from "../../../wini/project/controller"
import { useFieldArray, useForm } from "react-hook-form"
import { Select1Form, TextFieldForm } from "../../../../project-component/component-form"
import ConfigApi from "../../../../da/configApi"
import { FEDataType } from "../../../wini/table/da"

export const PopupSettingPage = forwardRef(function PopupSettingPage({ required = false, onSubmit, listPage = [] }, ref) {
    const methods = useForm({ shouldFocusError: false, defaultValues: { Params: [] } })
    const [tab, setTab] = useState(0)
    const { page } = useSelector((store) => store.page.data)
    const _layerController = new TableController("layer")
    const [layouts, setLayouts] = useState([])
    const [selectedLayout, setSelectedLayout] = useState()
    const dispatch = useDispatch()
    const dialogRef = useRef()

    const renderTabView = () => {
        switch (tab) {
            case 0:
                return <SettingUrlTab methods={methods} />
            case 1:
                return <div className='row' style={{ flexWrap: "wrap", padding: "1.6rem 2.4rem", gap: "1.6rem", flex: 1, overflow: "hidden auto", alignItems: "start", alignContent: "start" }}>
                    {layouts.filter(e => !e.ParentId).map(e => {
                        const _children = layouts.filter(el => el.LayoutId === e.Id)
                        return <div key={e.Id} className={`col col8 ${styles['layout-model']} ${selectedLayout === e.Id ? styles["selected"] : ""}`} onClick={() => { setSelectedLayout(e.Id) }}>
                            <Winicon src="fill/user interface/trash-can" size={"1.8rem"} onClick={(ev) => {
                                ev.stopPropagation()
                                ev.preventDefault()
                                showDialog({
                                    ref: dialogRef,
                                    title: "Delete layout",
                                    status: ComponentStatus.WARNING,
                                    content: "Are you sure you want to delete this layout?",
                                    onSubmit: async () => {
                                        const res = await _layerController.delete([e.Id, ..._children.map(el => el.Id)])
                                        if (res.code === 200) {
                                            setLayouts(layouts.filter(el => el.Id !== e.Id && el.LayoutId !== e.Id))
                                            setSelectedLayout(page.LayoutId)
                                        }
                                    }
                                })
                            }} style={{ display: listPage.some(el => el.LayoutId === e.Id) ? "none" : undefined }} />
                            <Text className='label-3'>{e.Name}</Text>
                            <CaptureElement className='col' style={{ pointerEvents: "none" }}>
                                <RenderPageByLayers onDemo listLayout={[e, ..._children]} listLayers={[]} />
                            </CaptureElement>
                        </div>
                    })}
                    {Object.keys(ListLayout).map((v) => {
                        const _layers = ListLayout[v]
                        return <div key={v} className={`col col8 ${styles['layout-model']} ${selectedLayout === v ? styles["selected"] : ""}`} onClick={() => { setSelectedLayout(v) }}>
                            <div />
                            <Text className='label-3'>{v}</Text>
                            <CaptureElement className='col'>
                                <RenderPageByLayers onDemo listLayout={_layers} listLayers={[]} />
                            </CaptureElement>
                        </div>
                    })}
                </div>
            case 2:
                return <SettingStateTab methods={methods} />
            default:
                return <div />
        }
    }

    const _onSubmit = async (ev) => {
        let _layoutId = undefined
        if (selectedLayout.length === 32) {
            _layoutId = selectedLayout
        } else {
            let _layers = ListLayout[selectedLayout].map(e => {
                return { ...e, id: randomGID(), ProjectId: ConfigApi.currentPid ?? Ultis.getCookie("pid"), DateCreated: Date.now(), Setting: typeof e.Setting === "string" ? e.Setting : JSON.stringify(e.Setting) }
            })
            _layoutId = _layers.find(e => !e.ParentId)?.id
            _layers = _layers.map(e => {
                let _tmp = { ...e }
                if (e.ParentId) {
                    _tmp.ParentId = _layers.find(el => el.Id === e.ParentId)?.id
                    _tmp.LayoutId = _layoutId
                }
                _tmp.Id = _tmp.id
                delete _tmp.id
                return _tmp
            })
            const res = await _layerController.add(_layers)
            if (res.code !== 200) return ToastMessage.errors(res.message)
        }
        if (_layoutId) {
            const _pageController = new TableController("page")
            const editPage = {
                ...ev,
                Url: ev.Url.slice(0, ev.Url.indexOf("?")),
                Params: ev.Params?.length ? JSON.stringify(ev.Params.filter(e => e.Key?.length).map(e => {
                    let pTmp = { ...e }
                    delete pTmp.id
                    return pTmp
                })) : "[]",
                State: ev.State?.length ? JSON.stringify(ev.State.filter(e => e.Name?.length).map(e => {
                    let pTmp = { ...e }
                    delete pTmp.id
                    return pTmp
                })) : "[]",
                LayoutId: _layoutId
            }
            const _pageRes = await _pageController.edit([editPage])
            if (_pageRes.code !== 200) return ToastMessage.errors(_pageRes.message)
            closePopup(ref)
            if (required) await PageActions.addDefaultLayers(dispatch, page.Id)
            onSubmit(editPage)
        }
    }

    useEffect(() => {
        if (page) {
            _layerController.group({ searchRaw: "*", reducers: `LOAD * APPLY exists(@ParentId) AS __exist APPLY exists(@PageId) AS __existP FILTER (@__exist == 0 && @__existP == 0)` }).then(async (res) => {
                if (res.code === 200) {
                    const childrenRes = await _layerController.getListSimple({ page: 1, size: 1000, query: `@LayoutId:{${res.data.map(e => e.Id).join(" | ")}}` })
                    if (childrenRes.code === 200)
                        setLayouts(
                            res.data.map(e => {
                                let tmp = { ...e, Setting: typeof e.Setting === "string" ? JSON.parse(e.Setting) : e.Setting }
                                delete tmp["__exist"]
                                delete tmp["__existP"]
                                return tmp
                            }).concat(childrenRes.data.map(e => {
                                let tmp = { ...e, Setting: typeof e.Setting === "string" ? JSON.parse(e.Setting) : e.Setting }
                                return tmp
                            }))
                        )
                }
            })
            setSelectedLayout(page.LayoutId)
            const _params = page["Params"] && typeof page["Params"] === "string" ? JSON.parse(page["Params"]) : []
            const _state = page["State"] && typeof page["State"] === "string" ? JSON.parse(page["State"]) : []
            Object.keys(page).forEach(p => {
                if (p === "Params") methods.setValue(p, _params)
                else if (p === "State") methods.setValue(p, _state)
                else if (p === "Url") methods.setValue(p, `${page[p]}${_params.length ? `?${_params.map(e => `${e.Key ?? ""}=${e.Value ?? ""}`).join("&")}` : ""}`)
                else methods.setValue(p, page[p])
            })
        }
    }, [page])

    return <div className="popup-select-layout col" style={{ flex: 1 }}>
        <Dialog ref={dialogRef} />
        <div className='row popup-header' style={{ padding: '1.2rem 1.2rem 1.2rem 2.4rem' }}>
            <button type='button' className={`row semibold2 ${tab === 0 ? styles["selected"] : ""} ${styles['btn-tab']}`} onClick={() => { setTab(0) }}>Url</button>
            <button type='button' className={`row semibold2 ${tab === 1 ? styles["selected"] : ""} ${styles['btn-tab']}`} onClick={() => { setTab(1) }}>Layout</button>
            <button type='button' className={`row semibold2 ${tab === 2 ? styles["selected"] : ""} ${styles['btn-tab']}`} onClick={() => { setTab(2) }}>State</button>
            <div style={{ flex: 1 }} />
            {required ? null : <button type='button' className='row icon-button40' onClick={() => { closePopup(ref) }}>
                <Winicon src={"fill/user interface/e-remove"} />
            </button>}
        </div>
        {renderTabView()}
        <div className='row popup-footer'>
            {required ? null : <Button
                label="Cancel"
                style={{ width: "7.2rem", borderRadius: '0.4rem', backgroundColor: "var(--neutral-main-background-color)", color: "var(--neutral-text-subtitle-color)" }}
                onClick={() => { closePopup(ref) }}
            />}
            <Button
                label="Save"
                disabled={!selectedLayout}
                className={selectedLayout ? 'button-primary' : ""}
                style={{ width: "5.8rem", borderRadius: '0.4rem' }}
                onClick={methods.handleSubmit(_onSubmit)}
            />
        </div>
    </div>
})

//#region setting url tab
const SettingUrlTab = ({ methods }) => {
    const searchParams = useFieldArray({
        name: "Params",
        control: methods.control,
    })
    const [project, setProject] = useState()

    useEffect(() => {
        ProjectController.getByIds([ConfigApi.currentPid ?? Ultis.getCookie("pid")]).then(res => {
            if (res.code === 200) setProject(res.data[0])
        })
    }, [])

    const onChangeParams = () => {
        const currentUrl = methods.getValues("Url")
        const currentParams = methods.getValues("Params")
        methods.setValue("Url", currentUrl.slice(0, currentUrl.indexOf("?")) + (currentParams.length ? `?${currentParams.map(e => `${e.Key ?? ""}=${e.Value ?? ""}`).join("&")}` : ""))
    }

    return <div className="col" style={{ flex: 1, overflow: "hidden auto" }}>
        {project && <div className="row" style={{ padding: "1.2rem 2.4rem", gap: "1.2rem" }}>
            <TextFieldForm
                placeholder="page url"
                name="Url"
                style={{ flex: 1 }}
                errors={methods.formState.errors}
                register={methods.register}
                textFieldStyle={{ gap: "1.6rem", borderRadius: 4 }}
                prefix={<Text className="label-2" style={{ paddingRight: "2rem", borderRight: "var(--neutral-bolder-border)", minWidth: 180 }}>https://{project?.Domain}</Text>}
                onBlur={(ev) => {
                    let _params = ev.target.value.indexOf("?") >= 0 ? ev.target.value.slice(ev.target.value.indexOf("?")) : ""
                    if (_params.length) {
                        _params = _params.slice(1).split("&").map(e => { return { Key: e.split("=")[0], Value: e.split("=")[1] } })
                        methods.setValue("Params", _params)
                    } else methods.setValue("Params", [])
                }}
            />
            <Button
                label="Add param"
                prefix={<Winicon src={"outline/user interface/e-add"} />}
                style={{ height: "4rem", padding: "0 1.6rem" }}
                onClick={() => {
                    searchParams.append({ Name: '', Value: '', Description: '' })
                    onChangeParams()
                }}
            />
        </div>}
        <Text className="heading-8" style={{ padding: "1.2rem 2.4rem" }}>Query params</Text>
        <div className="col" style={{ border: "var(--neutral-bolder-border)", margin: "0 2.4rem", width: "calc(100% - 4.8rem)", borderRadius: 4 }}>
            <div className="row" style={{ alignItems: "stretch" }}>
                <div className="row" style={{ width: "5.6rem" }}></div>
                <div className="row" style={{ flex: 1, padding: "0.8rem 1.2rem", borderLeft: "var(--neutral-bolder-border)" }}>
                    <Text className="heading-8">Key</Text>
                </div>
                <div className="row" style={{ flex: 1, padding: "0.8rem 1.2rem", borderLeft: "var(--neutral-bolder-border)" }}>
                    <Text className="heading-8">Value</Text>
                </div>
                <div className="row" style={{ flex: 1, padding: "0.8rem 1.2rem", borderLeft: "var(--neutral-bolder-border)" }}>
                    <Text className="heading-8">Description</Text>
                </div>
                <div className="row" style={{ width: "5.6rem", borderLeft: "var(--neutral-bolder-border)" }}></div>
            </div>
            {searchParams.fields.map((item, i) => {
                return <div key={item.id} className={`row ${styles["params-row-item"]}`}>
                    <div className="row" style={{ width: "5.6rem", justifyContent: "center" }}>
                        <Winicon src="fill/layout/grip-dots-vertical" />
                    </div>
                    <div className="row">
                        <TextFieldForm
                            name={`Params.${i}.Key`}
                            errors={methods.formState.errors}
                            register={methods.register}
                            placeholder="key"
                            textFieldStyle={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderRadius: 0, padding: 0 }}
                            onBlur={onChangeParams}
                        />
                    </div>
                    <div className="row">
                        <TextFieldForm
                            name={`Params.${i}.Value`}
                            errors={methods.formState.errors}
                            register={methods.register}
                            placeholder="auto from variable"
                            textFieldStyle={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderRadius: 0, padding: 0 }}
                            onBlur={onChangeParams}
                        />
                    </div>
                    <div className="row">
                        <TextFieldForm
                            name={`Params.${i}.Description`}
                            errors={methods.formState.errors}
                            register={methods.register}
                            placeholder="description"
                            textFieldStyle={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderRadius: 0, padding: 0 }}
                        />
                    </div>
                    <div className="row" style={{ width: "5.6rem", borderLeft: "var(--neutral-bolder-border)", justifyContent: "center" }}>
                        <Winicon src="outline/user interface/trash-can" onClick={(ev) => {
                            ev.preventDefault()
                            searchParams.remove(i)
                            onChangeParams()
                        }} />
                    </div>
                </div>
            })}
        </div>
    </div>
}

//#region setting state tab
const SettingStateTab = ({ methods }) => {
    const pageState = useFieldArray({
        name: "State",
        control: methods.control,
    })

    return <div className="col" style={{ flex: 1, overflow: "hidden auto" }}>
        <div className="row" style={{ padding: "1.2rem 2.4rem" }}>
            <Text className="heading-8" style={{ flex: 1 }}>State variables</Text>
            <Button
                label="Add variables"
                prefix={<Winicon src={"outline/user interface/e-add"} />}
                onClick={() => { pageState.append({ Name: '', Type: FEDataType.STRING }) }}
            />
        </div>
        <div className="col" style={{ border: "var(--neutral-bolder-border)", margin: "0 2.4rem", width: "calc(100% - 4.8rem)", borderRadius: 4 }}>
            <div className="row" style={{ alignItems: "stretch" }}>
                <div className="row" style={{ width: "5.6rem" }}></div>
                <div className="row" style={{ flex: 1, padding: "0.8rem 1.2rem", borderLeft: "var(--neutral-bolder-border)" }}>
                    <Text className="heading-8">Key</Text>
                </div>
                <div className="row" style={{ flex: 1, padding: "0.8rem 1.2rem", borderLeft: "var(--neutral-bolder-border)" }}>
                    <Text className="heading-8">Data type</Text>
                </div>
                <div className="row" style={{ flex: 1, padding: "0.8rem 1.2rem", borderLeft: "var(--neutral-bolder-border)" }}>
                    <Text className="heading-8">Default value</Text>
                </div>
                <div className="row" style={{ width: "5.6rem", borderLeft: "var(--neutral-bolder-border)" }}></div>
            </div>
            {pageState.fields.map((item, i) => {
                return <div key={item.id} className={`row ${styles["params-row-item"]}`}>
                    <div className="row" style={{ width: "5.6rem", justifyContent: "center" }}>
                        <Winicon src="fill/layout/grip-dots-vertical" />
                    </div>
                    <div className="row">
                        <TextFieldForm
                            name={`State.${i}.Name`}
                            errors={methods.formState.errors}
                            register={methods.register}
                            placeholder="key"
                            textFieldStyle={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderRadius: 0, padding: 0 }}
                        />
                    </div>
                    <div className="row">
                        <Select1Form
                            required
                            readonly
                            control={methods.control}
                            errors={methods.formState.errors}
                            name={`State.${i}.Type`}
                            options={[FEDataType.STRING, FEDataType.NUMBER, FEDataType.BOOLEAN].map(v => { return { id: v, name: v } })}
                            style={{ flex: 1 }}
                            select1Style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderRadius: 0, padding: 0, height: "fit-content" }}
                        />
                    </div>
                    <div className="row">
                        {methods.watch("State")[i].Type === FEDataType.BOOLEAN ?
                            <Switch
                                value={methods.watch("State")[i].Value}
                                onChange={(v) => { methods.setValue(`State.${i}.Value`, v) }}
                            /> : <TextFieldForm
                                name={`State.${i}.Value`}
                                errors={methods.formState.errors}
                                register={methods.register}
                                placeholder="default value"
                                textFieldStyle={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderRadius: 0, padding: 0 }}
                            />}
                    </div>
                    <div className="row" style={{ width: "5.6rem", borderLeft: "var(--neutral-bolder-border)", justifyContent: "center" }}>
                        <Winicon src="outline/user interface/trash-can" onClick={() => { pageState.remove(i) }} />
                    </div>
                </div>
            })}
        </div>
    </div>
}