import styles from '../view.module.css'
import { useEffect, useRef, useState } from "react"
import { ComponentType } from "../da"
import { OutlineButton, OutlineContainer } from "../../../../assets/icon"
import { Text, Winicon } from "wini-web-components"

export default function LayerTile({ item, space = 0.8, layers = [], methods, display = "flex" }) {
    const ref = useRef()
    const [children, setChildren] = useState([])
    const [isOpen, setIsOpen] = useState(true)

    useEffect(() => {
        if (layers) setChildren(layers.filter(e => e.ParentId === item.Id))
    }, [layers])

    const _icon = () => {
        switch (item.Type) {
            case ComponentType.container:
                return <OutlineContainer size={'2.4rem'} />
            case ComponentType.text:
                return <Winicon src='fill/text/text' />
            case ComponentType.button:
                return <OutlineButton size={'2.8rem'} />
            case ComponentType.img:
                return <Winicon src={"fill/development/image"} />
            case ComponentType.icon:
                return <Winicon src={"fill/development/icon"} />
            case ComponentType.navLink:
                return <Winicon src={"fill/user interface/link"} />
            default:
                return null
        }
    }

    return <div id={item.Id} ref={ref} className='col layer-tile-container' style={{ gap: '0.4rem', order: item.Setting?.style?.order, display: display }}>
        <div className={`row ${styles['layer-tile']} ${item.Id === methods.watch("selectedId") ? styles["selected"] : ""}`} style={{ paddingLeft: `${space}rem` }} onMouseEnter={() => { methods.setValue("hoverId", item.Id) }} onMouseOut={() => { methods.setValue("hoverId", undefined) }} onClick={() => {
            methods.setValue("reload", true)
            methods.setValue("selectedId", item.Id)
        }}>
            {children.length ? <button type='button' className='row icon-button20' onClick={() => { setIsOpen(!isOpen) }}><Winicon src={`fill/arrows/triangle-${isOpen ? "down" : "right"}`} size={'1.2rem'} className={styles[isOpen ? "open" : "close"]} /></button> : null}
            <div className='row icon-button24'>{_icon()}</div>
            <Text className='semibold1' style={{ flex: 1 }}>{item.Name}</Text>
        </div>
        {children.map(e => <LayerTile key={e.Id} item={e} space={space + 2.8} layers={layers} methods={methods} display={isOpen ? "flex" : "none"} />)}
    </div >
}