import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { Select1, Text, TextField, Winicon } from "wini-web-components"
import { BaseDA } from "../../../../../da/baseDA"
import ConfigApi from "../../../../../da/configApi"

export const ImageProperties = ({ selected, onChange, docBody = document.body.querySelector(`div[class*="overview-container"]`) }) => {
    const methods = useForm({ shouldFocusError: false })
    const [isOpen, setIsOpen] = useState(true)
    const [nodeElement, setNode] = useState()

    useEffect(() => {
        const node = docBody.querySelector(`*[id="${selected.Id}"]`)
        if (node.id !== nodeElement?.id) setNode(node)
        if (node) {
            const _nodeStyle = window.getComputedStyle(node)
            methods.setValue('src', selected.Setting.src ?? node.src)
            methods.setValue('object-fit', _nodeStyle.objectFit)
            const _position = _nodeStyle.objectPosition.split(" ")
            if (_position.every(v => v === "50%")) methods.setValue('object-position', "center")
            else if (_position[0] === "0%") methods.setValue('object-position', "left")
            else if (_position[0] === "100%") methods.setValue('object-position', "right")
            else if (_position[1] === "0%") methods.setValue('object-position', "top")
            else if (_position[1] === "100%") methods.setValue('object-position', "bottom")
        }
    }, [selected])

    return <div className="col" style={{ borderBottom: 'var(--neutral-main-border)' }}>
        <div className="row" style={{ padding: '0.8rem 1.2rem', gap: '0.4rem' }}>
            <Text className="semibold1" style={{ flex: 1 }}>Image</Text>
            <Winicon onClick={() => { setIsOpen(!isOpen) }} size={'1.4rem'} style={{ padding: "0.2rem" }} src={`outline/arrows/${isOpen ? "up" : "down"}-arrow`} />
        </div>
        <div className="col" style={{ padding: '0 1.2rem 0.8rem', gap: '0.8rem', display: isOpen ? 'flex' : 'none' }}>
            <TextField
                onComplete={(ev) => ev.target.blur()}
                className="regular1"
                name="src"
                placeholder="src"
                onFocus={(ev) => { ev.target.select() }}
                prefix={<label className="row">
                    <img src={methods.watch("src")} style={{ width: '2.4rem', height: '1.6rem', objectFit: 'cover', borderRadius: "0.2rem" }} />
                    <input type="file" accept="image/*" hidden onChange={async (ev) => {
                        if (ev.target.files && ev.target.files[0]) {
                            const res = await BaseDA.uploadFiles(ev.target.files)
                            if (res) onChange({ ...selected, Setting: { ...selected.Setting, src: ConfigApi.imgUrl + res[0].url } })
                        }
                    }} />
                </label>}
                register={methods.register('src', {
                    onBlur: (ev) => {
                        onChange({ ...selected, Setting: { ...selected.Setting, src: ev.target.value } })
                    }
                })}
            />
            <div className="row" style={{ gap: "0.8rem" }}>
                <Select1
                    readOnly
                    style={{ flex: 1 }}
                    prefix={<Winicon src="outline/user interface/fullsize" size='1.2rem' />}
                    className="regular1"
                    name="object-fit"
                    placeholder="object-fit"
                    value={methods.watch('object-fit')}
                    options={["fill", "scale-down", "contain", "cover"].map(e => {
                        return { id: e, name: e }
                    })}
                    onChange={(v) => {
                        if (v.id !== methods.getValues('object-fit')) {
                            let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, objectFit: v.id } } }
                            onChange(_tmp)
                        }
                    }}
                />
                <Select1
                    readOnly
                    style={{ flex: 1 }}
                    prefix={<Winicon src="outline/development/align-center-vertical" size='1.2rem' />}
                    className="regular1"
                    name="object-position"
                    placeholder="object-position"
                    value={methods.watch('object-position')}
                    options={["center", "top", "left", "right", "bottom"].map(e => {
                        return { id: e, name: e }
                    })}
                    onChange={(v) => {
                        if (v.id !== methods.getValues('object-position')) {
                            let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, objectPosition: v.id } } }
                            if (v.id === "auto") delete _tmp.Setting.style.objectPosition
                            onChange(_tmp)
                        }
                    }}
                />
            </div>
        </div>
    </div>
}
