import styles from './index.module.css'
import { useEffect, useRef, useState } from "react"
import { useFieldArray, useForm } from "react-hook-form"
import { SelectMultiple, Text, Winicon } from "wini-web-components"
import { ComponentType, FEDataType, ValidateType } from "../da"
import { CheckboxForm, GroupCheckboxForm, RadioButtonForm, Select1Form, SelectMultipleForm, SwitchForm, TextFieldForm } from "../../../../project-component/component-form"
import { randomGID } from "../../../../Utils"
import { RenderComponentByType, validateForm } from "../../../module/config"

//#region SettingKeysForm
export default function SettingKeysForm({ methods }) {
    const demoMethods = useForm({ shouldFocusError: false })
    const columns = useFieldArray({
        control: methods.control,
        name: 'column',
        keyName: undefined,
    })
    const relatives = useFieldArray({
        control: methods.control,
        name: 'rel',
        keyName: undefined,
    })
    const [selectedId, setSelectedId] = useState()
    const formRef = useRef()

    const clickDown = (id) => {
        const formChildren = [...formRef.current.children].sort((a, b) => parseInt(window.getComputedStyle(a).order) - parseInt(window.getComputedStyle(b).order))
        const _index = formChildren.findIndex(e => e.id === id)
        let _tmp = []
        if ((_index + 1) === formChildren.length) {
            _tmp = [
                {
                    id: formChildren[_index].id,
                    sort: 0,
                },
                ...formChildren.slice(0, _index).map((e, i) => {
                    return {
                        id: e.id,
                        sort: i + 1,
                    }
                })
            ]
        } else {
            _tmp = [...formChildren.slice(0, _index), formChildren[_index + 1], formChildren[_index], ...formChildren.slice(_index + 2)].map((e, i) => {
                return {
                    id: e.id,
                    sort: i,
                }
            })
        }
        methods.setValue("column", methods.getValues("column").map(e => {
            return { ...e, Form: { ...e.Form, Sort: _tmp.find(el => el.id === e.Id)?.sort ?? 0 } }
        }))
        methods.setValue("rel", methods.getValues("rel").map(e => {
            return { ...e, Form: { ...e.Form, Sort: _tmp.find(el => el.id === e.Id)?.sort ?? 0 } }
        }))
    }

    const clickUp = (id) => {
        const formChildren = [...formRef.current.children].sort((a, b) => parseInt(window.getComputedStyle(a).order) - parseInt(window.getComputedStyle(b).order))
        const _index = formChildren.findIndex(e => e.id === id)
        let _tmp = []
        if (_index === 0) {
            _tmp = [
                ...formChildren.slice(1).map((e, i) => {
                    return {
                        id: e.id,
                        sort: i,
                    }
                }),
                {
                    id: formChildren[_index].id,
                    sort: formChildren.length - 1,
                }
            ]
        } else {
            _tmp = [...formChildren.slice(0, _index - 1), formChildren[_index], ...formChildren.slice(_index - 1).filter(e => e.id !== id)].map((e, i) => {
                return {
                    id: e.id,
                    sort: i,
                }
            })
        }
        methods.setValue("column", methods.getValues("column").map(e => {
            return { ...e, Form: { ...e.Form, Sort: _tmp.find(el => el.id === e.Id)?.sort ?? 0 } }
        }))
        methods.setValue("rel", methods.getValues("rel").map(e => {
            return { ...e, Form: { ...e.Form, Sort: _tmp.find(el => el.id === e.Id)?.sort ?? 0 } }
        }))
    }

    useEffect(() => {
        if (columns.fields.length) {
            let _selectedId = undefined
            methods.setValue("column", columns.fields.map((e, i) => {
                if (e.Name === "Name") _selectedId = e.Id
                e.Form = e.Form ? typeof e.Form === "string" ? JSON.parse(e.Form) : e.Form : { Required: true }
                e.Form.Sort ??= i
                if (!e.Form.Placeholder?.length) e.Form.Label ??= e.Name
                switch (e.DataType) {
                    case FEDataType.GID:
                        e.Form.ComponentType ??= ComponentType.textField
                        break;
                    case FEDataType.STRING:
                        e.Form.ComponentType ??= ComponentType.textField
                        break;
                    case FEDataType.BOOLEAN:
                        e.Form.ComponentType ??= ComponentType.checkbox
                        break;
                    case FEDataType.NUMBER:
                        e.Form.ComponentType ??= ComponentType.textField
                        break;
                    case FEDataType.DATE:
                        e.Form.ComponentType = ComponentType.datePicker
                        break;
                    case FEDataType.DATETIME:
                        e.Form.ComponentType = ComponentType.dateTimePicker
                        break;
                    case FEDataType.MONEY:
                        e.Form.ComponentType ??= ComponentType.textField
                        break;
                    case FEDataType.PASSWORD:
                        e.Form.ComponentType ??= ComponentType.textField
                        break;
                    case FEDataType.HTML:
                        e.Form.ComponentType ??= ComponentType.ckEditor
                        break;
                    case FEDataType.FILE:
                        e.Form.ComponentType = ComponentType.upload
                        break;
                    default:
                        break;
                }
                return e
            }))
            setSelectedId(_selectedId)
        }
    }, [columns.fields.length])

    useEffect(() => {
        if (relatives.fields.length) {
            methods.setValue("rel", relatives.fields.map((e, i) => {
                e.Form = e.Form ? typeof e.Form === "string" ? JSON.parse(e.Form) : e.Form : { Label: e.Column, ComponentType: ComponentType.select1, Required: e.Column !== "ParentId" }
                e.Form.Sort ??= i
                return e
            }).sort((a, b) => a.Form.Sort - b.Form.Sort))
        }
    }, [relatives.fields.length])

    return <div className="row" style={{ flex: 1, width: '100%', height: '100%', alignItems: "stretch" }}>
        {selectedId && <>
            <div ref={formRef} className={`col ${styles['form-setting-container']}`} style={{ flex: 2, width: '100%', height: '100%', padding: '2.4rem', gap: '1.6rem', overflow: 'hidden auto', borderRight: "var(--neutral-bolder-border)" }}>
                {columns.fields.map((_col, i) => {
                    if (_col.Name === "Id" || _col.Name === "DateCreated" || _col.Query) return undefined
                    let _listCom = []
                    switch (_col.DataType) {
                        case FEDataType.GID:
                            _listCom = [ComponentType.textField, ComponentType.select1, ComponentType.selectMultiple]
                            break;
                        case FEDataType.STRING:
                            _listCom = [ComponentType.textField, ComponentType.select1, ComponentType.textArea, ComponentType.selectMultiple, ComponentType.radio, ComponentType.ckEditor]
                            break;
                        case FEDataType.BOOLEAN:
                            _listCom = [ComponentType.checkbox, ComponentType.switch, ComponentType.radio, ComponentType.select1]
                            break;
                        case FEDataType.NUMBER:
                            _listCom = [ComponentType.textField, ComponentType.select1, ComponentType.rate]
                            break;
                        case FEDataType.DATE:
                            _listCom = [ComponentType.datePicker]
                            break;
                        case FEDataType.DATETIME:
                            _listCom = [ComponentType.dateTimePicker]
                            break;
                        case FEDataType.MONEY:
                            _listCom = [ComponentType.textField, ComponentType.select1]
                            break;
                        case FEDataType.PASSWORD:
                            _listCom = [ComponentType.textField]
                            break;
                        case FEDataType.HTML:
                            _listCom = [ComponentType.textField, ComponentType.ckEditor]
                            break;
                        default:
                            break;
                    }
                    return <div key={_col.Id} id={_col.Id} className={`${styles['component-form-setting-block']} ${_col.Id === selectedId ? styles["selected"] : ""} col`} setting-type="column" style={{ order: _col.Form.Sort }} onClick={() => { setSelectedId(_col.Id) }}>
                        <RenderComponentByType fieldItem={_col} methods={demoMethods} />
                        {
                            _col.Id === selectedId ? <>
                                <div className='col divider' />
                                <div className={`row ${styles['simple-setting']}`} style={{ gap: '0.8rem' }}>
                                    <SwitchForm
                                        control={methods.control}
                                        name={`column[${i}].Form.Required`}
                                        label='Required'
                                        size={'1.8rem'}
                                        disabled={_col.Name === "Name"}
                                        onChange={(v) => { columns.update(i, { ..._col, Form: { ..._col.Form, Required: v } }) }}
                                    />
                                    <div style={{ flex: 1 }} />
                                    <button type='button' className='row icon-button20' onClick={() => { clickUp(_col.Id) }}>
                                        <Winicon src={"fill/arrows/arrow-up"} size={"1.6rem"} />
                                    </button>
                                    <button type='button' className='row icon-button20' onClick={() => { clickDown(_col.Id) }}>
                                        <Winicon src={"fill/arrows/arrow-down"} size={"1.6rem"} />
                                    </button>
                                    {_listCom.length ? <Select1Form
                                        required
                                        readonly
                                        control={methods.control}
                                        disabled={_col.Name === "Name" || _listCom.length === 1}
                                        style={{ width: '15rem', padding: '0.4rem', textAlign: "center" }}
                                        errors={methods.formState.errors}
                                        name={`column[${i}].Form.ComponentType`}
                                        options={_listCom.map(e => {
                                            return { id: e, name: e }
                                        })}
                                        onChange={(v) => {
                                            let _tmp = { ..._col.Form, ComponentType: v.id }
                                            if ([ComponentType.radio, ComponentType.select1, ComponentType.selectMultiple].includes(v.id) && !_tmp.Options?.length) _tmp.Options = [{ key: randomGID() }]
                                            columns.update(i, { ..._col, Form: _tmp })
                                        }}
                                    /> : null}
                                </div>
                            </> : null
                        }
                    </div>
                })}
                {relatives.fields.map((_rel, i) => {
                    switch (_rel.ComponentType) {
                        case ComponentType.selectMultiple:
                            var _component = <SelectMultipleForm
                                required={_rel.Form.Required}
                                control={demoMethods.control}
                                errors={demoMethods.formState.errors}
                                name={_rel.Column}
                                label={_rel.Form.Label ?? _rel.Column}
                                placeholder={_rel.Form.Placeholder}
                                options={[]}
                            />
                            break;
                        default:
                            _component = <Select1Form
                                required={_rel.Form.Required}
                                control={demoMethods.control}
                                errors={demoMethods.formState.errors}
                                name={_rel.Column}
                                label={_rel.Form.Label ?? _rel.Column}
                                placeholder={_rel.Form.Placeholder}
                                options={[]}
                            />
                            break;
                    }
                    return <div key={_rel.Id} id={_rel.Id} className={`${styles['component-form-setting-block']} ${_rel.Id === selectedId ? styles["selected"] : ""} col`} setting-type="relative" style={{ order: _rel.Form.Sort }} onClick={() => { setSelectedId(_rel.Id) }}>
                        {_component}
                        {
                            _rel.Id === selectedId ? <>
                                <div className='col divider' />
                                <div className='row' style={{ gap: '0.8rem' }}>
                                    <SwitchForm
                                        control={methods.control}
                                        name={`rel[${i}].Form.Required`}
                                        label='Required'
                                        size={'1.8rem'}
                                        onChange={(v) => { relatives.update(i, { ..._rel, Form: { ..._rel.Form, Required: v } }) }}
                                    />
                                    <div style={{ flex: 1 }} />
                                    <button type='button' className='row icon-button20' onClick={() => { clickUp(_rel.Id) }}>
                                        <Winicon src={"fill/arrows/arrow-up"} size={"1.6rem"} />
                                    </button>
                                    <button type='button' className='row icon-button20' onClick={() => { clickDown(_rel.Id) }}>
                                        <Winicon src={"fill/arrows/arrow-down"} size={"1.6rem"} />
                                    </button>
                                    <Select1Form
                                        required
                                        disabled={_rel.TablePK === _rel.TableFK}
                                        readonly
                                        control={methods.control}
                                        placeholder={ComponentType.select1}
                                        style={{ width: '15rem', padding: '0.4rem', textAlign: "center" }}
                                        errors={methods.formState.errors}
                                        name={`rel[${i}].Form.ComponentType`}
                                        options={[ComponentType.selectMultiple, ComponentType.select1].map(e => {
                                            return { id: e, name: e }
                                        })}
                                    />
                                </div>
                            </> : null
                        }
                    </div>
                })}
            </div>
            <div className='col' style={{ flex: 1, width: '100%', height: '100%', padding: '2.4rem', gap: '1.6rem', overflow: 'hidden auto' }}>
                {selectedId && <SettingByType
                    key={selectedId}
                    item={[...columns.fields, ...relatives.fields].find((e) => e.Id === selectedId)}
                    onUpdate={(newForm, type = "column") => {
                        let _index = -1
                        let item = methods.getValues(type).find((e, i) => {
                            const check = e.Id === selectedId
                            if (check) _index = i
                            return check
                        })
                        if (type === "column") {
                            columns.update(_index, { ...item, Form: newForm })
                        } else {
                            relatives.update(_index, { ...item, Form: newForm })
                        }
                    }}
                />}
            </div>
        </>}
    </div>
}

//#region SettingByType
const SettingByType = ({ item, onUpdate }) => {
    const methods = useForm({ shouldFocusError: false, defaultValues: { Validate: [], Options: [] } })
    const _validate = useFieldArray({
        name: "Validate",
        control: methods.control,
        keyName: undefined,
    })
    const _options = useFieldArray({
        name: "Options",
        control: methods.control,
        keyName: "key",
    })
    const _optionsComponent = [ComponentType.radio, ComponentType.select1, ComponentType.selectMultiple]

    const onChange = () => {
        const _tmp = methods.getValues()
        onUpdate({
            ..._tmp,
            DefaultValue: Array.isArray(_tmp.DefaultValue) && item.DataType === FEDataType.STRING ? _tmp.DefaultValue.join(",") : _tmp.DefaultValue,
            Validate: _tmp.Validate?.map(e => {
                let val = { ...e }
                delete val.id
                return val
            }),
            Options: _tmp.Options?.filter(e => e.id !== undefined).map(e => {
                let op = { ...e }
                delete op.key
                return op
            }),
        }, item.Column ? "rel" : "column")
    }

    useEffect(() => {
        Object.keys(item.Form).forEach((k) => {
            if (item.Form[k] !== undefined) {
                if (k === "DefaultValue" && item.Form.Options?.length && item.Form.ComponentType !== ComponentType.select1) {
                    methods.setValue(k, item.Form[k] && typeof item.Form[k] === "string" ? item.Form[k].split(",") : item.Form[k])
                } else methods.setValue(k, item.Form[k])
            }
        })
    }, [item.Form.ComponentType])

    if (item.Column) {
        return <>
            <Text className='semibold3' style={{ borderBottom: 'var(--neutral-bolder-border)', paddingBottom: '0.8rem' }}>{methods.watch("ComponentType")}</Text>
            <TextFieldForm
                name={`Label`}
                label="Label"
                errors={methods.formState.errors}
                register={methods.register}
                onBlur={onChange}
            />
            <TextFieldForm
                name={`Placeholder`}
                label="Placeholder"
                errors={methods.formState.errors}
                register={methods.register}
                onBlur={onChange}
            />
        </>
    } else {
        switch (item.Form.ComponentType) {
            case ComponentType.textField:
                var _expand = <>
                    <SelectDefaultValueByType item={item} methods={methods} onChange={onChange} />
                    <div className='col' style={{ gap: '0.8rem' }}>
                        <Text className='label-3'>Validate</Text>
                        <SelectMultiple
                            value={methods.watch("Validate").map(e => e.type)}
                            options={Object.keys(ValidateType).filter(key => isNaN(parseInt(key))).map(key => {
                                return {
                                    id: ValidateType[key],
                                    name: key
                                }
                            })}
                            placeholder='Choose validate type'
                            onChange={(v) => {
                                const _tmp = methods.getValues('Validate')
                                methods.setValue('Validate', v.map(vl => {
                                    let _val = _tmp.find(e => e.type === vl)
                                    if (!_val) {
                                        _val = { type: vl }
                                        switch (vl) {
                                            case ValidateType.email:
                                                _val.message ??= 'Email is not valid'
                                                break;
                                            case ValidateType.minLength:
                                                _val.value ??= 3
                                                _val.message ??= `Minimum ${_val.value} characters`
                                                break;
                                            case ValidateType.maxLength:
                                                _val.value ??= 8
                                                _val.message ??= `Maximum ${_val.value} characters`
                                                break;
                                            case ValidateType.number:
                                                _val.message ??= `Only number characters`
                                                break;
                                            case ValidateType.phone:
                                                _val.message ??= `Phone number is not valid`
                                                break;
                                            case ValidateType.greaterThan:
                                                _val.value ??= 0
                                                _val.message ??= `Value must be greater than ${_val.value}`
                                                break;
                                            case ValidateType.greaterThanOrEqualTo:
                                                _val.value ??= 0
                                                _val.message ??= `Value must be not less than ${_val.value}`
                                                break;
                                            case ValidateType.lessThan:
                                                _val.value ??= 10
                                                _val.message ??= `Value must be less than ${_val.value}`
                                                break;
                                            case ValidateType.lessThanOrEqualTo:
                                                _val.value ??= 10
                                                _val.message ??= `Value must be not greater than ${_val.value}`
                                                break;
                                            default:
                                                break;
                                        }
                                    }
                                    return _val
                                }))
                                onChange()
                            }}
                        />
                        {_validate.fields.length ? <div className='col' style={{ gap: '0.8rem', borderRadius: '0.8rem', backgroundColor: "#C0CBD83D", padding: '1.6rem ' }}>
                            {_validate.fields.map((e, valIndex) => {
                                return <div key={e.type} className='col' style={{ padding: '0.4rem 0', gap: '0.4rem' }}>
                                    <Text className='semibold1'>{Object.keys(ValidateType).find(key => ValidateType[key] === e.type)}</Text>
                                    <TextFieldForm
                                        required
                                        label='Message'
                                        name={`Validate[${valIndex}].message`}
                                        className='row'
                                        register={methods.register}
                                        errors={methods.formState.errors}
                                        style={{ flex: 1, maxWidth: '24rem' }}
                                        labelStyle={{ width: "8.4rem" }}
                                        onBlur={onChange}
                                    />
                                    {
                                        e.value != undefined ? <TextFieldForm
                                            required
                                            label='Value'
                                            name={`Validate[${valIndex}].value`}
                                            className='row'
                                            register={methods.register}
                                            errors={methods.formState.errors}
                                            style={{ flex: 1, maxWidth: '24rem' }}
                                            labelStyle={{ width: "8.4rem" }}
                                            onBlur={onChange}
                                        /> : null
                                    }
                                </div>
                            })}
                        </div> : null}
                    </div>
                </>
                break;
            case ComponentType.switch:
                _expand = <TextFieldForm
                    name={`Title`}
                    label="Title"
                    errors={methods.formState.errors}
                    register={methods.register}
                    onBlur={onChange}
                />
                break;
            case ComponentType.rate:
                _expand = <TextFieldForm
                    name={`Title`}
                    label="Title"
                    errors={methods.formState.errors}
                    register={methods.register}
                    onBlur={onChange}
                />
                break;
            default:
                if (item.DataType !== FEDataType.BOOLEAN) _optionsComponent.push(ComponentType.checkbox)
                if (_optionsComponent.includes(methods.watch("ComponentType"))) {
                    _expand = <>
                        <div className='row' style={{ gap: '0.8rem' }}>
                            <Text className='label-3'>Options</Text>
                            <button type='button' className='row icon-button20' onClick={() => { _options.append({}) }}>
                                <Winicon src={"outline/user interface/c-add"} size={"1.6rem"} />
                            </button>
                        </div>
                        {methods.watch("Options")?.length ? <SelectDefaultValueByType item={item} methods={methods} onChange={onChange} /> : null}
                        <div className='row' style={{ gap: '1.6rem' }}>
                            <Text className='semibold1' style={{ flex: 2, textAlign: "center" }}>Title</Text>
                            <Text className='semibold1' style={{ flex: 2, textAlign: "center" }}>Value</Text>
                            <Text className='semibold1' style={{ flex: 1, textAlign: "center" }}>Disabled</Text>
                            <div style={{ width: '2rem' }} />
                        </div>
                        {_options.fields.map((e, _opIndex) => {
                            return <div key={e.key} className='row' style={{ gap: '1.6rem', overflow: "visible", padding: '0.6rem 0' }}>
                                <TextFieldForm
                                    register={methods.register}
                                    name={`Options[${_opIndex}].name`}
                                    errors={methods.formState.errors}
                                    style={{ flex: 2 }}
                                    onBlur={onChange}
                                />
                                <TextFieldForm
                                    autoFocus={!e.id}
                                    register={methods.register}
                                    name={`Options[${_opIndex}].id`}
                                    errors={methods.formState.errors}
                                    type={item.DataType === FEDataType.NUMBER ? "number" : item.DataType === FEDataType.MONEY ? "money" : "text"}
                                    style={{ flex: 2 }}
                                    onBlur={(ev) => {
                                        const _v = item.DataType === FEDataType.NUMBER || item.DataType === FEDataType.MONEY ? parseFloat(ev.target.value.trim().replaceAll(",", "")) : ev.target.value.trim()
                                        if (methods.watch("Options").some((el, i) => el.id === _v && i !== _opIndex)) {
                                            methods.setError(`Form.Options[${_opIndex}].id`, { message: "Vaue is existed in option list!" })
                                        } else onChange()
                                    }}
                                />
                                <CheckboxForm
                                    control={methods.control}
                                    name={`Options[${_opIndex}].disabled`}
                                    style={{ flex: 1, justifyContent: "center" }}
                                    onChange={onChange}
                                />
                                <button type='button' className='row icon-button20' onClick={() => {
                                    _options.remove(_opIndex)
                                    onChange()
                                }}>
                                    <Winicon src={"outline/user interface/c-delete"} size={"1.6rem"} />
                                </button>
                            </div>
                        })}
                    </>
                }
                break;
        }
        return <>
            <Text className='semibold3' style={{ borderBottom: 'var(--neutral-bolder-border)', paddingBottom: '0.8rem' }}>{item.Form.ComponentType}</Text>
            <TextFieldForm
                name={`Label`}
                label="Label"
                errors={methods.formState.errors}
                register={methods.register}
                onBlur={onChange}
            />
            <TextFieldForm
                name={`Placeholder`}
                label="Placeholder"
                errors={methods.formState.errors}
                register={methods.register}
                onBlur={onChange}
            />
            {_expand}
        </>
    }
}

//#region set DefaultValue
const SelectDefaultValueByType = ({ methods, item, onChange }) => {
    switch (item.Form.ComponentType) {
        case ComponentType.textField:
            return <TextFieldForm
                name='DefaultValue'
                label="DefaultValue"
                errors={methods.formState.errors}
                register={methods.register}
                onBlur={async (ev) => {
                    if (methods.getValues("Validate")?.length) {
                        const _val = await validateForm({
                            list: [{ Name: item.Name, Validate: methods.getValues("Validate") }],
                            formdata: { [item.Name]: ev.target.value }
                        })
                        if (_val) return methods.setError("DefaultValue", _val[item.Name])
                        else methods.clearErrors("DefaultValue")
                    }
                    onChange()
                }}
            />
        case ComponentType.checkbox:
            return <GroupCheckboxForm
                name='DefaultValue'
                label="DefaultValue"
                control={methods.control}
                dataType={item.DataType}
                options={methods.watch("Options")}
                onChange={onChange}
            />
        case ComponentType.selectMultiple:
            return <SelectMultipleForm
                name='DefaultValue'
                label="DefaultValue"
                control={methods.control}
                options={methods.watch("Options")}
                errors={methods.formState.errors}
                onChange={onChange}
            />
        case ComponentType.select1:
            return <Select1Form
                name='DefaultValue'
                label="DefaultValue"
                control={methods.control}
                options={methods.watch("Options")}
                errors={methods.formState.errors}
                onChange={onChange}
            />
        case ComponentType.radio:
            return <div className={"col"} style={{ gap: '0.8rem', width: '100%' }}>
                <Text className="label-3">DefaultValue</Text>
                <div className="row" style={{ gap: '1.6rem', flex: 1, flexWrap: 'wrap' }}>
                    {methods.watch("Options")?.map(e => {
                        return <RadioButtonForm
                            key={e.id}
                            label={e.name}
                            name={"DefaultValue"}
                            value={e.id}
                            register={methods.register}
                            size={'1.8rem'}
                            onChange={onChange}
                        />
                    })}
                </div>
            </div>
        default:
            return null
    }
}