import { useEffect, useState } from "react";
import { FEDataType } from "../../wini/table/da";
import RenderChartByType from "./chartByType";
import { checkTimeChartType } from "../../../Utils";

// filterTime: _day, _month
export default function MapGroupData({ listData = [], chartItem, filterTime = "_day" }) {
    const [chartProps, setChartProps] = useState()

    useEffect(() => {
        if (listData.length && chartItem) {
            const _datasets = []
            let _times = []
            listData.forEach((e) => {
                if (e[filterTime] && !_times.includes(e[filterTime])) _times.push(e[filterTime])
                //
                switch (chartItem.DataType) {
                    case FEDataType.BOOLEAN:
                        e[chartItem.Name] = e[chartItem.Name] ? "true" : "false"
                        break;
                    case FEDataType.NUMBER:
                        e[chartItem.Name] = e[chartItem.Name] ? typeof e[chartItem.Name] === 'string' ? parseFloat(e[chartItem.Name]) : e[chartItem.Name] : undefined
                        break;
                    case FEDataType.DATE:
                        e[chartItem.Name] = e[chartItem.Name] ? typeof e[chartItem.Name] === 'string' ? parseFloat(e[chartItem.Name]) : e[chartItem.Name] : undefined
                        break;
                    case FEDataType.DATETIME:
                        e[chartItem.Name] = e[chartItem.Name] ? typeof e[chartItem.Name] === 'string' ? parseFloat(e[chartItem.Name]) : e[chartItem.Name] : undefined
                        break;
                    case FEDataType.MONEY:
                        e[chartItem.Name] = e[chartItem.Name] ? typeof e[chartItem.Name] === 'string' ? parseFloat(e[chartItem.Name]) : e[chartItem.Name] : undefined
                        break;
                    default:
                        break;
                }
            })

            if (checkTimeChartType(chartItem.Type)) {
                _times = _times.sort((a, b) => {
                    const _aSplt = a.split("-").map(e => parseInt(e))
                    const _bSplt = b.split("-").map(e => parseInt(e))
                    if (_aSplt[1]) {
                        const _tmp = _aSplt[1] - _bSplt[1]
                        if (_tmp === 0) return _aSplt[0] - _bSplt[0]
                        else return _tmp
                    } else return _aSplt[0] - _bSplt[0]
                })
                const _dataFilter = _times.map((m) => {
                    if (filterTime === "_month") {
                        switch (typeof m === "string" ? parseInt(m) : m) {
                            case 0:
                                var monthName = "Jan"
                                break;
                            case 1:
                                monthName = "Feb"
                                break;
                            case 2:
                                monthName = "Mar"
                                break;
                            case 3:
                                monthName = "Apr"
                                break;
                            case 4:
                                monthName = "May"
                                break;
                            case 5:
                                monthName = "Jun"
                                break;
                            case 6:
                                monthName = "Jul"
                                break;
                            case 7:
                                monthName = "Aug"
                                break;
                            case 8:
                                monthName = "Sep"
                                break;
                            case 9:
                                monthName = "Oct"
                                break;
                            case 10:
                                monthName = "Nov"
                                break;
                            case 11:
                                monthName = "Dec"
                                break;
                            default:
                                break;
                        }
                    }
                    return [
                        monthName ?? m,
                        ...listData.filter(e => e[filterTime] === m).map(e => e?.[chartItem.Name] ?? 0)
                    ];
                })
                setChartProps({
                    labels: chartItem.Setting.Datasets.map(e => e.Label),
                    datasets: _dataFilter,
                    color: chartItem.Setting.Datasets.map(e => e.BackgroundColor),
                })
            } else {
                setChartProps({
                    labels: chartItem.Setting.Datasets.map(e => e.Label),
                    datasets: listData.map(e => e[chartItem.Name]),
                    color: chartItem.Setting.Datasets.map(e => e.BackgroundColor),
                })
            }
        }
    }, [listData, chartItem])

    return <RenderChartByType
        labels={chartProps?.labels}
        datasets={chartProps?.datasets ?? []}
        pieColors={chartProps?.color}
        type={chartItem.Type}
        title={chartItem.Title}
        legendPosition={chartItem.Setting.Legend}
    />
}

