import { Route, Routes } from "react-router-dom";
import CreateToilet from "./page/create";
import RepairToilet from "./page/repair";
import CreateFlow from "./page/createFlow";

export default function DigitalKTXRouter() {
    return <Routes>
        <Route
            path={'/create'}
            element={<CreateToilet />}
            exact
        />
        <Route
            path={'/create-flow'}
            element={<CreateFlow />}
            exact
        />
        <Route
            path={'/repair'}
            element={<RepairToilet />}
            exact
        />
    </Routes>
}