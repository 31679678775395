import { BaseDA } from "../../da/baseDA";
import ConfigApi from "../../da/configApi";
import { Ultis } from "../../Utils";
import { DataType } from "../wini/table/da";
import { CardItem, ChartItem, FormItem, ReducerItem, Reducers, ReportModelItem } from "./da";

export class DataController {
    private pid: string;
    private module: string;
    constructor(module: string) {
        this.pid = ConfigApi.currentPid ?? Ultis.getCookie("pid")
        this.module = module
    }

    async getAll() {
        const res = await BaseDA.get(ConfigApi.url + 'data/getAll', {
            headers: {
                pid: this.pid,
                module: this.module,
            },
        })
        return res
    }

    async getProperties() {
        const res = await BaseDA.get(ConfigApi.url + `setting/getProperties?name=${this.module}`, {
            headers: {
                pid: this.pid,
                module: 'column'
            }
        })
        return res
    }

    async aggregateList(options: { page?: number, size?: number, searchRaw?: string, filter?: string, sortby?: Array<{ prop: string, direction?: "ASC" | "DESC" }> } | undefined) {
        const res = await BaseDA.post(ConfigApi.url + 'data/aggregateList', {
            headers: {
                pid: this.pid,
                module: this.module,
            },
            body: options
        })
        return res
    }

    async filterByEmptyKey(options: { page?: number, size?: number, searchRaw?: string, key: string, sortby?: Array<{ prop: string, direction?: "ASC" | "DESC" }> } | undefined) {
        const res = await BaseDA.post(ConfigApi.url + 'data/filterByEmptyKey', {
            headers: {
                pid: this.pid,
                module: this.module,
            },
            body: options
        })
        return res
    }

    async group(options: { searchRaw?: string, reducers: string }) {
        const res = await BaseDA.post(ConfigApi.url + 'data/group', {
            headers: {
                pid: this.pid,
                module: this.module,
            },
            body: options
        })
        return res
    }

    async groupByIds(params: { reducers: Array<{ Name: string, Reducer: Reducers, ReducerBy?: string, TbName: string, Column: string, Query?: string }>, ids: Array<string> }) {
        if (params.reducers.length) {
            const groupReducers: Array<{ tbName: string, query: string, searchRaw?: string }> = []
            const groupNames: Array<{ name: string, query?: string }> = []
            for (const re of params.reducers) {
                if (groupNames.every(e => e.name !== re.TbName && e.query !== re.Query)) groupNames.push({ name: re.TbName, query: re.Query })
            }
            for (let _tb of groupNames) {
                const _tmp = params.reducers.filter((e) => e.TbName === _tb.name && e.Query === _tb.query)
                const _reduceQuery = _tmp.map((e) => `REDUCE ${e.Reducer} ${e.ReducerBy ? `1 @${e.ReducerBy}` : 0} AS ${e.Name}`).join(" ")
                const _colName = _tmp[0].Column
                const _groupName = `_${_colName}`
                groupReducers.push({
                    tbName: _tb.name,
                    searchRaw: params.ids.length ? `@${_colName}:{${params.ids.map((_id) => `${_id}*`).join(" | ")}}${_tb.query ? ` ${_tb.query}` : ""}` : (_tb.query ?? " * "),
                    query: `APPLY @${_colName} AS ${_groupName}` + ` GROUPBY 1 @${_groupName} ${_reduceQuery}`
                })
            }
            const res = await BaseDA.post(ConfigApi.url + 'data/groupByIds', {
                headers: { pid: this.pid },
                body: { reducers: groupReducers }
            })
            return res
        }
        return []
    }

    async getListSimple(options: { page?: number, size?: number, query?: string, returns?: Array<string>, sortby?: { BY: string, DIRECTION?: "ASC" | "DESC" } } | undefined) {
        const res = await BaseDA.post(ConfigApi.url + 'data/getListSimple', {
            headers: {
                pid: this.pid,
                module: this.module,
            },
            body: { searchRaw: options?.query?.length ? options?.query : "*", page: options?.page ?? 1, size: options?.size ?? 10, returns: options?.returns, sortby: options?.sortby }
        })
        return res
    }

    async getById(id: string) {
        const res = await BaseDA.post(ConfigApi.url + `data/getById?id=${id}`, {
            headers: {
                pid: this.pid,
                module: this.module,
            },
        })
        return res
    }

    async getByListId(ids: Array<string>) {
        const res = await BaseDA.post(ConfigApi.url + 'data/getByIds', {
            headers: {
                pid: this.pid,
                module: this.module,
            },
            body: { ids: ids }
        })
        return res
    }

    async add(data: Array<{ [p: string]: any }>) {
        const res = await BaseDA.post(ConfigApi.url + 'data/action?action=add', {
            headers: {
                pid: this.pid,
                module: this.module
            },
            body: { data: data }
        })
        return res
    }

    async edit(data: Array<{ [p: string]: any }>) {
        const res = await BaseDA.post(ConfigApi.url + 'data/action?action=edit', {
            headers: {
                pid: this.pid,
                module: this.module
            },
            body: { data: data }
        })
        return res
    }

    async delete(ids: Array<string>) {
        const res = await BaseDA.post(ConfigApi.url + 'data/action?action=delete', {
            headers: {
                pid: this.pid,
                module: this.module
            },
            body: { ids: ids }
        })
        return res
    }

    async buildIndex(properties: { [p: string]: DataType }) {
        const res = await BaseDA.post(ConfigApi.url + 'data/buildIndex', {
            headers: {
                pid: this.pid,
                module: this.module
            },
            body: { properties: properties }
        })
        return res
    }

    async groupBy(params: { reducers: Array<{ Name: string, Reducer: Reducers, ReducerBy?: string, GroupBy: string, Query?: string }>, searchRaw?: string }) {
        const res = await BaseDA.post(ConfigApi.url + `data/groupBy`, {
            headers: {
                pid: this.pid,
                module: this.module,
            },
            body: params
        })
        return res
    }
}

export class SettingDataController {
    private pid: string;
    private setting: "model" | "reducer" | "chart" | "form" | "card";
    private type: string;
    constructor(setting: "model" | "reducer" | "chart" | "form" | "card") {
        this.pid = Ultis.getCookie("pid")
        this.setting = setting
        if (setting === "model" || setting === "reducer") this.type = "report"
        else this.type = setting
    }

    async action(action: "add" | "edit" | "delete", options: { data?: Array<ChartItem> | Array<ReportModelItem> | Array<ReducerItem> | Array<FormItem> | Array<CardItem>, ids?: Array<string> }) {
        const res = await BaseDA.post(ConfigApi.url + `data/${this.type === "report" ? `${this.type}/${this.setting}` : this.type}/action?action=${action}`, {
            headers: { pid: this.pid },
            body: { data: options.data, ids: options.ids }
        })
        return res
    }

    async getListSimple(options: { page?: number, size?: number, query?: string, returns?: Array<string>, sortby?: { BY: string, DIRECTION?: "ASC" | "DESC" } } | undefined) {
        const res = await BaseDA.post(ConfigApi.url + `data/${this.type === "report" ? `${this.type}/${this.setting}` : this.type}/getListSimple`, {
            headers: { pid: this.pid },
            body: { searchRaw: options?.query?.length ? options?.query : "*", page: options?.page ?? 1, size: options?.size ?? 10, returns: options?.returns, sortby: options?.sortby }
        })
        return res
    }

    async getByIds(ids: Array<string>) {
        const res = await BaseDA.post(ConfigApi.url + `data/${this.type === "report" ? `${this.type}/${this.setting}` : this.type}/getByIds`, {
            headers: { pid: this.pid },
            body: { ids: ids }
        })
        return res
    }
}