import { createSlice, Dispatch, PayloadAction, UnknownAction } from "@reduxjs/toolkit"
import { DesignTokenItem } from "./da"
import { TableController } from "../table/controller"
import { ToastMessage } from "wini-web-components"

interface DesignTokenSimpleResponse {
    data: Array<DesignTokenItem>,
    onLoading?: boolean,
    type?: string
}

const initState: DesignTokenSimpleResponse = {
    data: [],
    onLoading: false
}

export const designTokenSlice = createSlice({
    name: 'designToken',
    initialState: initState,
    reducers: {
        handleActions: (state, action: PayloadAction<any>) => {
            switch (action.payload.type) {
                case 'GETDATA':
                    state.data = action.payload.data
                    break;
                case 'ADD':
                    state.data = [...state.data, ...action.payload.data]
                    break;
                case 'EDIT':
                    state.data = state.data.map(e => {
                        const _tmp = action.payload.data.find((el: DesignTokenItem) => el.Id === e.Id)
                        return _tmp ?? e
                    })
                    break;
                case "DELETE":
                    state.data = state.data.filter(e => action.payload.data.every((id: string) => e.Id !== id))
                    break;
                default:
                    break;
            }
            state.onLoading = false
        },
        onFetching: (state) => {
            state.onLoading = true
        },
        onReset: (state) => {
            state.data = []
            state.onLoading = true
        },
    },
})

const { handleActions, onFetching, onReset } = designTokenSlice.actions

export default designTokenSlice.reducer

export class DesignTokenActions {
    static getData = async (dispatch: Dispatch<UnknownAction>) => {
        dispatch(onFetching())
        const _desginTokenController = new TableController("designtoken")
        const res = await _desginTokenController.getAll()
        if (res.code !== 200) return ToastMessage.errors(res.message)
        dispatch(handleActions({
            type: 'GETDATA',
            data: (res.data ?? []).map((e: any) => {
                return { ...e, Value: typeof e.Value === "string" ? JSON.parse(e.Value) : e.Value }
            }),
        }))
    }

    static add = async (dispatch: Dispatch<UnknownAction>, props: { data: Array<DesignTokenItem> }) => {
        const _desginTokenController = new TableController("designtoken")
        const res = await _desginTokenController.add(props.data.map(e => {
            return { ...e, Value: typeof e.Value === "string" ? e.Value : JSON.stringify(e.Value) }
        }))
        if (res.code !== 200) return ToastMessage.errors(res.message)
        dispatch(handleActions({
            type: 'ADD',
            data: props.data.map((e) => {
                return { ...e, Value: typeof e.Value === "string" ? JSON.parse(e.Value) : e.Value }
            }),
        }))
    }

    static edit = async (dispatch: Dispatch<UnknownAction>, props: { data: Array<DesignTokenItem> }) => {
        const _desginTokenController = new TableController("designtoken")

        const res = await _desginTokenController.edit(props.data.map(e => {
            return { ...e, Value: typeof e.Value === "string" ? e.Value : JSON.stringify(e.Value) }
        }))
        if (res.code !== 200) return ToastMessage.errors(res.message)
        dispatch(handleActions({
            type: 'EDIT',
            data: props.data.map((e) => {
                return { ...e, Value: typeof e.Value === "string" ? JSON.parse(e.Value) : e.Value }
            }),
        }))
    }

    static delete = async (dispatch: Dispatch<UnknownAction>, props: { data: Array<string> }) => {
        const _desginTokenController = new TableController("designtoken")

        const res = await _desginTokenController.delete(props.data)
        if (res.code !== 200) return ToastMessage.errors(res.message)
        dispatch(handleActions({
            type: 'DELETE',
            data: props.data,
        }))
    }


    static reset = (dispatch: Dispatch<UnknownAction>) => {
        dispatch(onReset())
    }
}