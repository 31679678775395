import styles from './view.module.css'
import { NavLink, useNavigate } from "react-router-dom"
import { closePopup, ComponentStatus, Dialog, DialogAlignment, Popup, showDialog, showPopup, Text, TextField, ToastMessage, Winicon } from "wini-web-components"
import { ProjectController } from "./controller"
import { useEffect, useRef, useState } from "react"
import { randomGID, Ultis } from "../../../Utils"
import ConfigApi from "../../../da/configApi"
import { BaseDA } from "../../../da/baseDA"
import { TableController } from "../table/controller"
import PopupDesignToken from "../designToken/view"
import { useSelector } from "react-redux"

export function ListProject() {
    const user = useSelector((store) => store.customer.data)
    const [data, setData] = useState({ data: [], totalCount: undefined })
    const [selected, setSelected] = useState()
    const dialogRef = useRef()

    useEffect(() => {
        if (user) {
            ProjectController.getListSimple({ page: 1, size: 10, query: `@CustomerId:{${user.Id}}` }).then(res => {
                if (res.code === 200) setData(res)
            })
        }
    }, [user])

    return data.data.length ? <div className="col view" style={{ gap: '2.4rem', padding: '2.4rem' }}>
        <Dialog ref={dialogRef} />
        <div className="row" style={{ paddingTop: '0.8rem' }}>
            <Text className="heading-6" style={{ flex: 1 }}>Project manager</Text>
            <NavLink to={'/setting'} className="row button-primary" >
                <Text className="button-text-3">Create new project</Text>
            </NavLink>
        </div>
        <div className="row" style={{ flexWrap: 'wrap', gap: '2.4rem' }}>
            {data.data.map((item) => <ProjectCard
                key={item.Id}
                item={item}
                style={selected === item.Id ? { borderColor: 'var(--primary-main-color)' } : undefined}
                onClick={() => setSelected(item.Id)}
                onDelete={() => {
                    showDialog({
                        ref: dialogRef,
                        alignment: DialogAlignment.center,
                        status: ComponentStatus.WARNING,
                        title: 'Confirm delete',
                        content: 'Are you sure you want to delete this project?',
                        onSubmit: async () => {
                            const res = await ProjectController.delete([item.Id])
                            if (res.code !== 200) return ToastMessage.errors(res.message)
                            setData({ data: data.data.filter(e => e.Id !== item.Id), totalCount: data.totalCount - 1 })
                        }
                    })
                }}
                onEdit={async (_item) => {
                    const res = await ProjectController.edit([item])
                    if (res.code !== 200) ToastMessage.errors(res.message)
                    setData({
                        data: data.data.map(e => {
                            if (e.Id === _item.Id) return _item
                            else return e
                        }),
                        totalCount: data.totalCount
                    })
                }}
            />)}
        </div>
    </div> : <div className="col view" style={{ alignItems: 'center', justifyContent: 'center', height: '100%', gap: '2.4rem' }}>
        <img alt="Empty-project" />
        <Text className="semibold3">You don't have any project</Text>
        <NavLink to={'/create-project'} className="row button-primary" >
            <Text className="button-text-3">Create new project</Text>
        </NavLink>
    </div>
}

const ProjectCard = ({ item, onEdit, onClick, style, onDelete }) => {
    const filePickerRef = useRef()
    const popupRef = useRef()
    const popupDesignTokenRef = useRef()
    const [onEditting, setOnEdit] = useState()
    const [data, setData] = useState()
    const navigate = useNavigate()

    const pickLogo = async (ev, item) => {
        if (ev.target.files && ev.target.files[0]) {
            const res = await BaseDA.uploadFiles(ev.target.files)
            if (res) {
                item.LogoId = res[0].id
                onEdit(item)
            }
        }
    }

    const showPopupAction = (ev) => {
        ev.preventDefault()
        showPopup({
            ref: popupRef,
            clickOverlayClosePopup: true,
            style: { position: 'absolute', top: ev.pageY, left: ev.pageX },
            body: <div className="col popup-actions" style={{ width: '14rem', borderRadius: '0.8rem' }}>
                <button type="button" onClick={() => { navigate("/setting?id=" + item.Id) }}>
                    <Text className="button-text-3">Edit</Text>
                </button>
                <button type="button" onClick={async () => {
                    closePopup(popupRef)
                    Ultis.setCookie("pid", data.Id)
                    ConfigApi.currentPid = data.Id
                    const _pageController = new TableController("page")
                    const res = await _pageController.getListSimple({
                        page: 1,
                        size: 1,
                        returns: ["Id"],
                    })
                    let _pageid = res.data?.[0]?.Id
                    if (!_pageid) {
                        const _newPage = {
                            Id: randomGID(),
                            Name: 'Page 1',
                            DateCreated: Date.now(),
                            Sort: 0,
                            State: "[]",
                            Params: "[]",
                            Url: "/",
                        }
                        const addPageRes = await _pageController.add([_newPage])
                        if (addPageRes.code === 200) _pageid = _newPage.Id
                        else return ToastMessage.errors(addPageRes.message)
                    }
                    navigate(`/pages/${data.Name}?pageid=${_pageid}`)
                }}>
                    <Text className="button-text-3">Setting page</Text>
                </button>
                <button type="button" onClick={async () => {
                    closePopup(popupRef)
                    ConfigApi.currentPid = data.Id
                    showPopup({
                        ref: popupDesignTokenRef,
                        hideButtonClose: true,
                        style: { width: '100%', height: '100%', maxHeight: '100%', maxWidth: '100%' },
                        body: <PopupDesignToken ref={popupDesignTokenRef} />
                    })
                }}>
                    <Text className="button-text-3">Design token</Text>
                </button>
                <button type="button" onClick={() => {
                    closePopup(popupRef)
                    filePickerRef.current.click()
                }}>
                    <Text className="button-text-3">Change logo</Text>
                </button>
                <button type="button" onClick={() => {
                    closePopup(popupRef)
                    onDelete(item)
                }}>
                    <Text className="button-text-3">Delete</Text>
                </button>
            </div>
        })
    }

    useEffect(() => {
        setData(item)
    }, [item])

    useEffect(() => {
        if (onEditting) onEditting.focus()
    }, [onEditting])

    return <>
        <Popup ref={popupRef} />
        <Popup ref={popupDesignTokenRef} />
        <div className={`col col6 ${styles['project-card']}`} onClick={onClick} style={style} onContextMenu={showPopupAction}>
            <button type="button" onDoubleClick={() => {
                Ultis.setCookie("pid", data?.Id)
                ConfigApi.currentPid = data?.Id
                navigate(`/admin`)
            }} className={`row ${styles['logo-img']}`}>
                {data?.LogoId && <img src={ConfigApi.imgUrlId + data?.LogoId} alt='' style={{ height: "4.8rem", width: "100%", objectFit: "contain" }} />}
            </button>
            <div style={{ padding: '0.8rem 1.6rem' }}>
                <div style={{ padding: '0.4rem 0' }} onDoubleClick={(ev) => setOnEdit(ev.target.querySelector("input"))}>
                    <TextField
                        disabled={!onEditting}
                        className="semibold3"
                        defaultValue={item.Name}
                        onBlur={async (ev) => {
                            if (ev.target.value.trim() !== data?.Name) {
                                data.Name = ev.target.value.trim()
                                onEdit(data)
                            }
                            setOnEdit(false)
                        }}
                    />
                </div>
                <Text className="subtitle-4" maxLine={2}>{Ultis.datetoString(new Date(data?.DateCreated))}</Text>
            </div>
            <input accept={'image/svg+xml'} ref={filePickerRef} type='file' hidden onChange={(ev) => { pickLogo(ev, data) }} />
            <Winicon src={"fill/text/menu-dots"} style={{ rotate: '90deg' }} onClick={showPopupAction} />
        </div>
    </>
}