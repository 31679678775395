import { useEffect, useRef, useState } from "react"
import styles from "./index.module.css"
import ReactDOM from 'react-dom'
import { Navigate, Route, Routes, useLocation } from "react-router-dom"
import { ListProject } from "../screen/wini/project/view"
import CreateProject from "../screen/wini/project/create"
import ModuleListView from "../screen/module/manager/view"
import { useDispatch, useSelector } from "react-redux"
import ActionSidebar from "./menu"
import SettingPageView from "../screen/module/page/view"
import ListTable from "../screen/wini/table/view"
import { TableController } from "../screen/wini/table/controller"
import { PageActions } from "../screen/module/page/reducer"
import RenderPageByLayers from "../screen/module/page/renderPageView"
import Header from './header'
import { DesignTokenActions } from "../screen/wini/designToken/reducer"
import { DesignTokenType } from "../screen/wini/designToken/da"
import { socket } from "../socket"
import WorkflowPage from "../screen/wini/workflow/view"
import ConfigApi, { ConfigDomain } from "../da/configApi"
import { Ultis } from "../Utils"
import DigitalKTXRouter from "../custom/digitalKTX/router"

export const Home = () => {
    return <div className="row main-layout">
        <Routes>
            <Route
                path={'/manager/*'}
                element={<MainRoute body={<ManagerView />} />}
                exact
            />
            {ConfigDomain.includes(window.location.hostname) ?
                <>
                    <Route
                        path={'/'}
                        element={<MainRoute body={<ListProject />} />}
                        exact
                    />
                    <Route
                        path={'/pages/:pName'}
                        element={<SettingPageView />}
                        exact
                    />
                    <Route
                        path={'/admin'}
                        element={<MainRoute body={<ListTable />} />}
                        exact
                    />
                    <Route
                        path={'/workflow'}
                        element={<MainRoute body={<WorkflowPage />} />}
                        exact
                    />
                    <Route
                        path={'/setting'}
                        element={<MainRoute body={<CreateProject />} />}
                        exact
                    />
                    <Route path="/*" element={<Navigate to={"/"} replace />} />
                </> : <>
                    <Route
                        path={'/setting'}
                        element={<MainRoute body={<CreateProject />} />}
                        exact
                    />
                    <Route path="/*" element={<RenderPageView />} />
                </>
            }
        </Routes>
    </div>
}

const MainRoute = ({ body }) => {
    const designTokens = useSelector((store) => store.designToken.data)
    const location = useLocation()
    const dispatch = useDispatch()

    useEffect(() => {
        if (location.pathname === "/") DesignTokenActions.reset(dispatch)
        else if (!designTokens.length || ConfigApi.currentPid !== Ultis.getCookie("pid")) DesignTokenActions.getData(dispatch)
    }, [location.pathname])

    useEffect(() => {
        if (designTokens.length && location.pathname !== "/") {
            const tokenValues = designTokens.filter(e => e.Type !== DesignTokenType.group)
            let styleElement = document.head.querySelector(":scope > .designTokens") ?? document.createElement('style');
            styleElement.type = 'text/css';
            const _innerHTML = `html { \n${tokenValues.map(e => e.Value?.lightMode ? `${e.Name}: ${e.Value.lightMode};` : "").join('\n')}\n }\n\nhtml.dark { \n${tokenValues.map(e => e.Value?.darkMode ? `${e.Name}: ${e.Value.darkMode};` : "").join('\n')}\n }`
            styleElement.innerHTML = _innerHTML;
            if (!styleElement.classList.contains("designTokens")) {
                styleElement.classList.add("designTokens")
                document.head.appendChild(styleElement)
            }
        }
    }, [designTokens.length])

    return <>
        <Header />
        <ActionSidebar />
        {body}
    </>
}

const ManagerView = () => {
    const { data } = useSelector((store) => store.module)

    if (!data?.TableId) return <div />
    else if (data.TableId.length === 32) return <PageView pageId={data.TableId} />
    else return <ModuleListView module={data.TableId} />
}

const PageView = ({ pageId }) => {
    const onLoading = useSelector((store) => store.page.onLoading)
    const dispatch = useDispatch()

    useEffect(() => {
        PageActions.onReset(dispatch)
        const _pageController = new TableController("page")
        _pageController.getByListId([pageId]).then(res => {
            if (res.code === 200) PageActions.setSelectedPage(dispatch, res.data[0])
        })
    }, [pageId])

    return onLoading ? <div /> : <RenderPageByLayers />
}

export const PageViewDemo = () => {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const pageId = searchParams.get("pageid")
    const { layers, layout } = useSelector((store) => store.page.data)
    const designTokens = useSelector((store) => store.designToken.data)
    const dispatch = useDispatch()
    const canvasRef = useRef()
    const [selectedId, setSelectedId] = useState()
    const [hoverId, setHoverId] = useState()
    const [openLayout, setOpenLayout] = useState(false)

    useEffect(() => {
        if (socket.connected && pageId) {
            socket.emit('client-init', { pageid: pageId })
            function onMessage(data) {
                switch (data.type) {
                    case 'SYNC':
                        PageActions.syncSocket(dispatch, data.data)
                        break;
                    case 'SELECTID':
                        setSelectedId(data.data)
                        break;
                    case 'HOVERID':
                        setHoverId(data.data)
                        break;
                    case 'openLayout':
                        setOpenLayout(data.data)
                        break;
                    default:
                        break;
                }
            }
            socket.on('page-demo', onMessage);
            return () => {
                socket.off('page-demo', onMessage);
            };
        }
    }, [socket.connected, pageId])

    useEffect(() => {
        DesignTokenActions.getData(dispatch)
    }, [])

    useEffect(() => {
        if (canvasRef.current && layers?.length && layout?.length) {
            canvasRef.current.width = canvasRef.current.parentElement.offsetWidth
            canvasRef.current.height = canvasRef.current.parentElement.offsetHeight
            let ctx = canvasRef.current.getContext("2d");
            ctx.clearRect(0, 0, canvasRef.current.offsetWidth, canvasRef.current.offsetHeight)
            ctx.font = "1.4rem Inter";
            let _selectedItem;
            let _hoverItem;
            if (selectedId || hoverId) {
                for (const e of [...layers, ...layout]) {
                    if (e.Id === selectedId) _selectedItem = e
                    if (e.Id === hoverId && selectedId !== hoverId) _hoverItem = e
                }
            }
            if (_selectedItem) {
                const _layerRect = document.getElementById(selectedId)?.getBoundingClientRect()
                if (_layerRect) {
                    ctx.strokeStyle = '#349c90'
                    ctx.lineWidth = 2
                    ctx.strokeRect(_layerRect.x, _layerRect.y, _layerRect.width, _layerRect.height)
                    ctx.fillStyle = '#349c90'
                    const textWH = ctx.measureText(_selectedItem.Name)
                    ctx.fillRect(_layerRect.x, _layerRect.y - 18, textWH.width + 4, 17)
                    ctx.fillStyle = '#fff'
                    ctx.fillText(_selectedItem.Name, _layerRect.x + 2, _layerRect.y - 4)
                }
            }
            if (_hoverItem) {
                const _layerRect = document.getElementById(hoverId)?.getBoundingClientRect()
                if (_layerRect) {
                    ctx.lineWidth = 2
                    ctx.strokeStyle = '#9c6334'
                    ctx.strokeRect(_layerRect.x, _layerRect.y, _layerRect.width, _layerRect.height)
                    ctx.fillStyle = '#9c6334'
                    const textWH = ctx.measureText(_hoverItem.Name)
                    ctx.fillRect(_layerRect.x, _layerRect.y - 18, textWH.width + 4, 17)
                    ctx.fillStyle = '#fff'
                    ctx.fillText(_hoverItem.Name, _layerRect.x + 2, _layerRect.y - 4)
                }
            }
        }
    }, [canvasRef, selectedId, hoverId, layers, layout])

    useEffect(() => {
        if (designTokens.length) {
            const tokenValues = designTokens.filter(e => e.Type !== DesignTokenType.group)
            let styleElement = document.head.querySelector(":scope > .designTokens") ?? document.createElement('style');
            styleElement.type = 'text/css';
            const _innerHTML = `html { \n${tokenValues.map(e => e.Value?.lightMode ? `${e.Name}: ${e.Value.lightMode};` : "").join('\n')}\n }\n\nhtml.dark { \n${tokenValues.map(e => e.Value?.darkMode ? `${e.Name}: ${e.Value.darkMode};` : "").join('\n')}\n }`
            styleElement.innerHTML = _innerHTML;
            if (!styleElement.classList.contains("designTokens")) {
                styleElement.classList.add("designTokens")
                document.head.appendChild(styleElement)
            }
        }
    }, [designTokens.length])

    useEffect(() => {
        if (document.body.querySelector(".main-layout")) {
            const onScroll = () => {
                let ctx = canvasRef.current.getContext("2d")
                ctx.clearRect(0, 0, canvasRef.current.offsetWidth, canvasRef.current.offsetHeight)
            }
            document.body.querySelector(".main-layout").onscroll = onScroll
        }
    }, [openLayout])

    return layers?.length && layout?.length ? <>
        <RenderPageByLayers onDemo={true} showLayout={openLayout} />
        {ReactDOM.createPortal(<canvas ref={canvasRef} className={styles['handle-demo-page']} />, document.body)}
    </> : <div className={styles["loading-view"]}>
        <div className={`col ${styles["loading-container"]}`}>
            <img className={styles["app-logo"]} src="https://cdn.jsdelivr.net/gh/thuno/goline2@fb161a4/assets/logo.svg" alt="" />
            <div className={styles["process-bar"]}><div className={styles["process-value"]} /></div>
            <div id="loading" className={`semibold2 row ${styles["text-loading"]}`}>
                Loading.
                <div id="dot1">.</div>
                <div id="dot2">.</div>
            </div>
        </div>
    </div>
}

export const RenderPageView = () => {
    const location = useLocation()
    const onLoading = useSelector((store) => store.page.onLoading)
    const page = useSelector((store) => store.page.data?.page)
    const designTokens = useSelector((store) => store.designToken.data)
    const searchParams = new URLSearchParams(location.search)
    const pid = searchParams.get("pid")
    const dispatch = useDispatch()

    useEffect(() => {
        if (pid) ConfigApi.currentPid = pid
        PageActions.onReset(dispatch)
        PageActions.getPageDataByPathName(dispatch)
        DesignTokenActions.getData(dispatch)
    }, [pid, location.pathname])

    useEffect(() => {
        if (designTokens.length) {
            const tokenValues = designTokens.filter(e => e.Type !== DesignTokenType.group)
            let styleElement = document.head.querySelector(":scope > .designTokens") ?? document.createElement('style');
            styleElement.type = 'text/css';
            const _innerHTML = `html { \n${tokenValues.map(e => e.Value?.lightMode ? `${e.Name}: ${e.Value.lightMode};` : "").join('\n')}\n }\n\nhtml.dark { \n${tokenValues.map(e => e.Value?.darkMode ? `${e.Name}: ${e.Value.darkMode};` : "").join('\n')}\n }`
            styleElement.innerHTML = _innerHTML;
            if (!styleElement.classList.contains("designTokens")) {
                styleElement.classList.add("designTokens")
                document.head.appendChild(styleElement)
            }
        }
    }, [designTokens.length])

    return onLoading ?
        <div /> :
        page ?
            <RenderPageByLayers />
            : <DigitalKTXRouter />
}