import styles from './index.module.css'
import { forwardRef, useEffect, useRef, useState } from 'react';
import { Button, Popup, Select1, showPopup, closePopup, Text, TextField, Winicon } from "wini-web-components";
import { NavLink, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { RadioButtonForm, TextFieldForm } from '../../../project-component/component-form';
import { DataController } from '../../../screen/module/controller';
import ConfigApi from '../../../da/configApi';

export default function CreateFlow() {
    const navigate = useNavigate()
    const [step, setStep] = useState(0)

    const returnStep = () => {
        switch (step) {
            case 0:
                return <SelectProductStep />
            case 1:
                return <SelectFeatureStep />
            case 2:
                return <InputContactStep />
            case 3:
                return <LastStep />
            default:
                return <div />
        }
    }

    return <div className="row main-layout">
        <div className={`row ${styles["header"]}`}>
            <Winicon src="outline/user interface/home" size={"1.6rem"} style={{ padding: "0.2rem" }} />
            <div className="row" style={{ flex: 1, gap: "0.4rem" }}>
                <Text className="button-text-6" style={{ color: "var(--neutral-text-subtitle-color)" }}>Home /</Text>
                <Text className="button-text-6" style={{ color: "var(--neutral-text-title-color)" }}>Process something</Text>
            </div>
            <Button label="Help center" />
            <Button label="Save & Close" />
        </div>
        <div className='col' style={{ height: "calc(100% - 7.6rem - 8.8rem - 8px)", width: "100vw" }}>
            {returnStep()}
        </div>
        {step < 3 ? <>
            <div className={`row ${styles["step-value"]}`}>{Array.from({ length: 4 }).map((_, i) => <div key={i} style={{ backgroundColor: i === step ? "var(--neutral-absolute-reverse-background-color)" : undefined }} />)}</div>
            <div className={`row ${styles["footer"]}`}>
                <Button
                    label="Back"
                    style={{ padding: "1rem 1.6rem", height: "4rem" }}
                    onClick={() => {
                        if (step) setStep(step - 1)
                        else navigate(-1)
                    }}
                />
                <div style={{ flex: 1 }} />
                <Button
                    className='button-primary'
                    label={step === 2 ? "Send information" : "Next"}
                    style={{ padding: "1rem 1.6rem", height: "4rem" }}
                    onClick={() => { setStep(step + 1) }}
                />
            </div>
        </> : null}
    </div>
}

//#region Select Product
const SelectProductStep = () => {
    const methods = useForm({ shouldFocusError: false })
    const [listOrder, setListOrder] = useState([])
    const [selectedCate, setSelectedCate] = useState()
    const [cate, setCate] = useState([])
    const scrollRef = useRef()
    const popupRef = useRef()

    const showPopupSelectProduct = (item) => {
        showPopup({
            ref: popupRef,
            hideButtonClose: true,
            content: <PopupSelectProduct ref={popupRef} item={item} />
        })
    }

    useEffect(() => {
        const cateController = new DataController("Category")
        cateController.filterByEmptyKey({ page: 1, size: 100, key: "ParentId", searchRaw: "*" }).then(res => {
            if (res.code === 200) {
                setCate(res.data)
                setSelectedCate(res.data[0]?.Id)
            }
        })
    }, [])

    useEffect(() => {
        if (selectedCate) {
            const cateController = new DataController("Category")
            cateController.aggregateList({ page: 1, size: 20, searchRaw: `@ParentId:{${selectedCate}}` }).then(res => {
                if (res.code === 200) methods.setValue(selectedCate, { data: res.data, totalCount: res.totalCount })
            })
        }
    }, [selectedCate])

    return <div className='row' style={{ justifyContent: "center", alignItems: "start", padding: "4rem 2.4rem", width: "100%", flex: 1, overflow: "hidden auto" }}>
        <Popup ref={popupRef} />
        <div className='row col24-min col24-sm col24-md' style={{ width: '60.4vw', gap: '4rem', '--gutter': '0px', alignItems: "stretch", flexWrap: "wrap" }}>
            <div className='col col24-min col24-sm' style={{ '--gutter': "4rem", width: "31.2rem", gap: "2.4rem" }}>
                <div className='col' style={{ gap: "1.2rem" }}>
                    <Text className='heading-6'>Chọn sản phẩm</Text>
                    <Text className='body-3'>
                        Chọn một hoặc nhiều sản phẩm. Bạn có thể thay đổi, bổ sung khi đến giai đoạn ký hợp đồng cũng được
                    </Text>
                </div>
                <div className='col' style={{ gap: 4 }}>
                    <Text className='heading-6'>0.00</Text>
                    <Text className='heading-6'>0/7 sản phẩm đã chọn</Text>
                </div>
            </div>
            <div className='col' style={{ '--gutter': "4rem", flex: 1 }}>
                <div className='row' style={{ gap: 8, paddingBottom: "2.4rem", borderBottom: "var(--neutral-main-border)", overflow: "auto hidden", scrollbarWidth: "none" }}>
                    {cate.map((item) => {
                        return <button key={item.Id} type='button' className={`row ${styles['category-item']} ${selectedCate === item.Id ? styles['selected'] : ""}`} onClick={() => { setSelectedCate(item.Id) }}>
                            <Text className="label-4">{item.Name}</Text>
                        </button>
                    })}
                </div>
                {methods.watch(selectedCate)?.data?.map((item) => {
                    return <div key={item.Id} className={`row ${styles['order-detail-item']}`}>
                        <img src={ConfigApi.imgUrlId + item.Img} alt='' style={{ width: "3.2rem", height: "3.2rem", objectFit: "contain" }} />
                        <div className='col' style={{ flex: 1, gap: 4 }}>
                            <Text className='heading-8'>{item.Name}</Text>
                            <Text className='subtitle-4'>Chọn sản phẩm</Text>
                        </div>
                        <Winicon src='outline/user interface/e-add' size={"1.4rem"} onClick={() => { showPopupSelectProduct(item) }} />
                    </div>
                })}
            </div>
        </div>
    </div>
}

const PopupSelectProduct = forwardRef(function PopupSelectProduct({ item }, ref) {
    const [attribute, setAttribute] = useState(["Xanh", "Brand", "Color", "Nhà cung cấp"])
    const [products, setProducts] = useState({ data: [{}, {}, {}, {}, {}], totalCount: undefined })

    return <div className='row' style={{ width: "100vw", height: "100dvh", justifyContent: "end", alignItems: "start" }}>
        <div className='col col24-min col24-sm col20-md' style={{ "--gutter": "0px", height: "100%", width: "54%", maxWidth: 1044, backgroundColor: "var(--neutral-absolute-background-color)" }}>
            <div className='row popup-header' style={{ borderBottom: "var(--neutral-main-border)" }}>
                <Text className='heading-7' style={{ flex: 1 }}>Chọn bồn cầu</Text>
                <Winicon src='outline/user interface/e-remove' size={"1.2rem"} style={{ padding: "0.6rem", borderRadius: "50%", backgroundColor: "var(--neutral-main-background-color)" }} onClick={() => { closePopup(ref) }} />
            </div>
            <div className='row' style={{ padding: "2rem 2.4rem", borderBottom: "var(--neutral-main-border)", gap: "0.8rem" }}>
                {attribute.map((item, index) => {
                    return <Select1
                        key={item}
                        className='button-text-3'
                        style={{ width: index === 3 ? "14.2rem" : "10rem", padding: "0.4rem 1.2rem" }}
                        placeholder={item}
                        options={[]}
                    />
                })}
                <div style={{ flex: 1 }} />
                <TextField
                    prefix={<Winicon src='outline/development/zoom' size={"1.4rem"} />}
                    className='placeholder-2'
                    style={{ width: "26.8rem", padding: "0.4rem 1.6rem" }}
                    placeholder='Search'
                />
                <button type='button' className='row' style={{ padding: "0.6rem 1.2rem", gap: "0.8rem" }}>
                    <Winicon src='outline/user interface/setup-preferences' />
                    <Text className='button-text-3' style={{ color: "var(--neutral-text-subtitle-color)" }}>Filter</Text>
                </button>
            </div>
            <div className='row' style={{ flex: 1, height: "100%", width: "100%", flexWrap: "wrap", gap: "4.8rem 2.4rem", overflow: "hidden auto", padding: "2.4rem" }}>
                {products.data.map((item, index) => {
                    return <div key={index} className={`col col8 col12-min col12-sm ${styles["product-item"]}`}>
                        <NavLink to={"https://ktxgroup.com.vn/"}><img src='https://file-mamager.wini.vn/api/SystemFile/img/cb33c42f-8987-4d86-b64f-086d061c36c5' alt='' /></NavLink>
                        <div className='col' style={{ gap: "1.6rem" }}>
                            <div className='col' style={{ gap: 4 }}>
                                <Text className='heading-7' maxLine={1}>Lorem Ipsum is simply dummy text</Text>
                                {Array.from({ length: 3 }).map((_, i) => {
                                    return <div key={"sdf" + i} className='row' style={{ gap: "0.8rem", borderBottom: "var(--neutral-main-border)", padding: "1.2rem 0" }}>
                                        <Winicon src='outline/development/palette' size={"1.2rem"} style={{ padding: "0.2rem" }} />
                                        <Text className='label-4' style={{ flex: 1 }}>Green</Text>
                                    </div>
                                })}
                            </div>
                            <div className='col' style={{ gap: 4 }}>
                                <Text className='heading-6'>$680.00</Text>
                                <Text className='subtitle-4'>680 reviews</Text>
                                <div className='row' style={{ gap: 4 }}>
                                    <Winicon src='fill/user interface/star' color='var(--secondary1-main-color)' size={"1.6rem"} style={{ padding: "0.2rem" }} />
                                    <Text className='label-4' style={{ flex: 1 }}>3,5</Text>
                                </div>
                            </div>
                        </div>
                    </div>
                })}
            </div>
            <div className='row popup-footer' style={{ borderTop: "var(--neutral-main-border)" }}>
                <Button
                    label="Clear"
                    style={{ width: "6rem", color: "var(--neutral-text-subtitle-color)" }}
                    onClick={() => { }}
                />
                <div style={{ flex: 1 }} />
                <Button
                    label="Cancel"
                    style={{ width: "7.2rem", color: "var(--neutral-text-subtitle-color)" }}
                    onClick={() => {
                    }}
                />
                <Button
                    label="Save"
                    className={"button-primary"}
                    style={{ width: "6rem" }}
                    onClick={() => { }}
                />
            </div>
        </div>
    </div>
})

//#region Select Feature
const SelectFeatureStep = () => {
    const methods = useForm({ shouldFocusError: false })
    return <div className='row' style={{ justifyContent: "center", alignItems: "start", padding: "4rem 2.4rem", width: "100%", flex: 1, overflow: "hidden auto" }}>
        <div className='col col24-min col24-sm col20-md col8' style={{ '--gutter': '0px', gap: "4rem" }}>
            <div className='col' style={{ gap: "0.8rem" }}>
                <Text className='heading-6'>Thông tin nhà vệ sinh</Text>
                <Text className='body-3'>Giúp chúng tôi hiểu rõ hơn mong muốn của bạn</Text>
            </div>
            <div className='col' style={{ gap: "1.6rem" }}>
                <Text className='heading-7'>Loại nhà vệ sinh</Text>
                <div className='row' style={{ gap: "0.8rem", flexWrap: "wrap" }}>
                    {Array.from({ length: 4 }).map((_, i) => {
                        return <div key={i} className='row col12 col24-min' style={{ "--gutter": "0.8rem", cursor: "pointer" }}
                        // onClick={() => { methods.setValue() }}
                        >
                            <RadioButtonForm
                                name='type'
                                value={`${i + 1}`}
                                register={methods.register}
                                label='Cho người lao động/Sản xuất'
                                style={{ flex: 1, gap: "1.2rem", padding: "1.6rem 2.4rem", borderRadius: "0.8rem", border: "var(--neutral-main-border)" }}
                            />
                        </div>
                    })}
                </div>
            </div>
        </div>
    </div>
}

//#region Input Contact
const InputContactStep = () => {
    const methods = useForm({ shouldFocusError: false })

    return <div className='row' style={{ justifyContent: "center", alignItems: "start", padding: "4rem 2.4rem", width: "100%", flex: 1, overflow: "hidden auto" }}>
        <div className='col col24-min col24-sm col20-md col8' style={{ '--gutter': '0px', gap: "3.2rem" }}>
            <div className='col' style={{ gap: "0.8rem" }}>
                <Text className='heading-6'>Thông tin liên hệ</Text>
                <Text className='body-3'>Chúng tôi sẽ dùng thông tin này để liên lạc lại</Text>
            </div>
            <div className='col' style={{ gap: "2.4rem" }}>
                <TextFieldForm
                    name='Name'
                    label='Họ tên'
                    errors={methods.formState.errors}
                    register={methods.register}
                />
                <TextFieldForm
                    name='Mobile'
                    label='Số điện thoại'
                    errors={methods.formState.errors}
                    register={methods.register}
                />
                <div className='col' style={{ gap: "0.8rem" }}>
                    <Text className='label-3'>Địa chỉ của bạn</Text>
                    <div></div>
                </div>
            </div>
        </div>
    </div>
}

//#region Last step
const LastStep = () => {
    return <div className='row' style={{ justifyContent: "center", alignItems: "start", padding: "4rem 2.4rem", width: "100%", flex: 1, overflow: "hidden auto" }}>
        <div className='col col24-min' style={{ '--gutter': '0px', padding: "4rem 0", alignItems: "center", gap: "1.6rem", width: "42rem" }}>
            <Winicon src='outline/technology/mobile-chat' size={"5.6rem"} />
            <div className='col' style={{ gap: "0.8rem", alignItems: "center" }}>
                <Text className='heading-5'>Nhập mã otp</Text>
                <Text className='body-3'>Nhập mã otp được gửi đến số điện thoại"08434xxxxxx"</Text>
            </div>
            <div className='row' style={{ padding: "1.6rem 4rem", gap: "0.8rem", width: "100%", justifyContent: "center" }}
                onMouseDown={(ev) => {
                    ev.stopPropagation()
                    ev.preventDefault()
                    const inputList = [...ev.target.closest("div").childNodes]
                    for (const [index, input] of inputList.entries()) {
                        if (!input.value.length || index === (inputList.length - 1)) {
                            input.focus()
                            break;
                        }
                        continue;
                    }
                }}>
                {Array.from({ length: 6 }).map((_, i) => <input
                    key={"opt-" + i}
                    className='body-1'
                    style={{ border: "var(--neutral-bolder-border)", flex: 1, height: "4.8rem", borderRadius: "0.4rem", textAlign: "center", minWidth: "4.8rem" }}
                    onKeyDown={(ev) => {
                        const key = ev.key.toLowerCase()
                        switch (key) {
                            case "backspace":
                                if (ev.target.value.length) ev.target.value = ""
                                else if (ev.target.previousSibling?.localName === "input") ev.target.previousSibling.focus()
                                else ev.target.blur()
                                break;
                            case "delete":
                                ev.target.value = ""
                                break;
                            default:
                                const numberCheck = /[0-9]/g
                                if (numberCheck.test(key)) {
                                    if (!ev.target.value.length) ev.target.value = key
                                    if (ev.target.nextSibling?.localName === "input" && !ev.target.nextSibling.value.length) ev.target.nextSibling.focus()
                                    else ev.target.blur()
                                } else ev.preventDefault()
                                break;
                        }
                    }}
                />)}
            </div>
            <Button
                label='Xác nhận'
                className='button-primary button-text-1'
                style={{ width: "100%", height: "4.8rem" }}
            />
        </div>
    </div>
}