import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import InteractionBlock from "./interaction/interaction"
import { PageActions } from "../reducer"
import { ComponentType } from "../../../wini/table/da"
import FilterBlock from "./filter/filter"

export default function ActionTab({ methods }) {
    const { layers, layout } = useSelector((store) => store.page.data)
    const [selected, setSelected] = useState()
    const dispatch = useDispatch()

    useEffect(() => {
        if (layers?.length && layout?.length) {
            const _id = methods.getValues("selectedId")
            if (_id) {
                if (selected?.Id === _id) {
                    setTimeout(() => {
                        setSelected([...layers, ...layout].find(e => e.Id === _id))
                    }, 500)
                } else setSelected([...layers, ...layout].find(e => e.Id === _id))
            } else setSelected(undefined)
        }
    }, [layers, layout, methods.watch("selectedId")])

    return selected?.Setting && <div className="col" style={{ backgroundColor: 'var(--neutral-absolute-background-color)', flex: 1, overflow: 'hidden auto', scrollbarWidth: "none" }}>
        {selected?.Type === ComponentType.card && <FilterBlock
            selected={selected}
            onChange={(newValue) => {
                if (newValue) PageActions.updateLayerAll(dispatch, [newValue])
            }}
        />}
        {selected && <InteractionBlock
            selected={selected}
            onChange={(newValue) => {
                if (newValue) PageActions.updateLayerAll(dispatch, [newValue])
            }}
        />}
    </div>
}


