import styles from '../view.module.css'
import indexStyles from './index.module.css'
import { forwardRef, useEffect, useRef } from "react"
import { SettingDataController } from "../../../module/controller"
import { useFieldArray, useForm } from "react-hook-form"
import { randomGID, Ultis } from "../../../../Utils"
import { ComponentType } from "../da"
import { Button, closePopup, ComponentStatus, Dialog, DialogAlignment, showDialog, Text, ToastMessage, Winicon } from "wini-web-components"
import { OutlineButton, OutlineContainer } from "../../../../assets/icon"
import LayerTile from "./layerTile"
import RenderCard from './renderCard'
import CardInforContainer from './cardInfor'

export const DrawerSettingCard = forwardRef(function DrawerSettingCard(data, ref) {
    const _settingDataController = new SettingDataController("card")
    const methods = useForm({ shouldFocusError: false, defaultValues: { TbName: data.TbName } })
    const methodProps = useForm({
        shouldFocusError: false, defaultValues: {
            selectedId: undefined,
            hoverId: undefined,
            layers: [{ Id: randomGID(), Type: ComponentType.container, DateCreated: Date.now(), ProjectId: Ultis.getCookie("pid"), Name: "New card", Setting: { style: { backgroundColor: "#fff" }, className: "col" } }]
        }
    })
    const _layers = useFieldArray({
        name: "layers",
        control: methodProps.control,
        keyName: undefined
    })
    const dialogRef = useRef()
    const demoCardRef = useRef()
    const canvasRef = useRef()
    const componentType = [ComponentType.container, ComponentType.text, ComponentType.img, ComponentType.icon]

    const _onSubmit = (ev) => {
        showDialog({
            ref: dialogRef,
            alignment: DialogAlignment.center,
            status: ComponentStatus.WARNING,
            title: 'Confirm save',
            content: "Every changes will be applied on this card",
            submitTitle: "Submit",
            onSubmit: async () => {
                let _cardData = { ...ev, Props: methodProps.getValues("layers") }
                _cardData.Props = JSON.stringify(_cardData.Props.map(e => {
                    if (e.Setting.className?.length) {
                        e.Setting.className = e.Setting.className.split(" ").filter(e => e !== "layer-item").join(" ")
                    }
                    return e
                }))
                delete _cardData.id
                _cardData.Id ??= randomGID()
                _cardData.DateCreated ??= Date.now()
                const res = await _settingDataController.action("add", { data: [_cardData] })
                if (res.code !== 200) return ToastMessage.errors(res.message)
                data.onSubmit()
                closePopup(ref)
            }
        })
    }

    useEffect(() => {
        if (data.cardItem) {
            Object.keys(data.cardItem).forEach((p) => {
                if (data.cardItem[p] !== undefined) {
                    methods.setValue(p, data.cardItem[p])
                    if (p === "Props") {
                        const _props = typeof data.cardItem[p] === "string" ? JSON.parse(data.cardItem[p]) : data.cardItem[p]
                        methodProps.setValue("layers", _props.map(e => {
                            if (!e.ParentId) {
                                delete e.Setting.style.width
                                delete e.Setting.style.height
                            }
                            return e
                        }))
                    }
                }
            })
        }
    }, [])

    const handleDragToTarget = (ev) => {
        ev.preventDefault()
        const _parent = ev.target.closest('div[component-type="Container"]')
        let _demo = demoCardRef.current.querySelector(`div[class*="demo-component-in-container"]`)
        if (_parent?.id?.length === 32) {
            let _children = [..._parent.children].filter(e => !e.classList.contains(styles['demo-component-in-container'])).sort((a, b) => parseInt(window.getComputedStyle(a).order ?? 0) - parseInt(window.getComputedStyle(b).order ?? 0))
            if (!_demo) {
                _demo = document.createElement("div")
                _demo.className = styles['demo-component-in-container']
            }
            const _direction = window.getComputedStyle(_parent).flexDirection
            let _order = 0
            let _distance = 0
            if (_direction === "column") {
                _demo.style.height = '0.3rem'
                _demo.style.width = "2rem"
                _demo.style.maxWidth = "100%"
                if (_children.length) {
                    let closestHTML = [..._children].sort((aHTML, bHTML) => {
                        let aRect = aHTML.getBoundingClientRect()
                        let bRect = bHTML.getBoundingClientRect()
                        let a_center_oy = Math.abs(ev.pageY - (aRect.y + aRect.height / 2))
                        let b_center_oy = Math.abs(ev.pageY - (bRect.y + bRect.height / 2))
                        return a_center_oy - b_center_oy
                    })[0]
                    if (closestHTML) {
                        let htmlRect = closestHTML.getBoundingClientRect()
                        _order = closestHTML.style.order
                        _distance = ev.pageY - (htmlRect.y + htmlRect.height / 2)
                        if (_distance < 0) _order--
                    } else _order = _children.length - 1
                }
            } else {
                _demo.style.width = '0.3rem'
                _demo.style.height = "2rem"
                _demo.style.maxHeight = "100%"
                if (_children.length) {
                    let isWrap = window.getComputedStyle(_parent).flexWrap === 'wrap'
                    let closestHTML = [..._children].sort((aHTML, bHTML) => {
                        let aRect = aHTML.getBoundingClientRect()
                        let bRect = bHTML.getBoundingClientRect()
                        let a_center_ox = Math.abs(ev.pageX - (aRect.x + aRect.width / 2))
                        let b_center_ox = Math.abs(ev.pageX - (bRect.x + bRect.width / 2))
                        if (isWrap) {
                            a_center_ox = Math.sqrt(Math.pow(ev.pageX - (aRect.x + aRect.width / 2), 2) + Math.pow(ev.pageY - (aRect.y + aRect.height / 2), 2))
                            b_center_ox = Math.sqrt(Math.pow(ev.pageX - (bRect.x + bRect.width / 2), 2) + Math.pow(ev.pageY - (bRect.y + bRect.height / 2), 2))
                        }
                        return a_center_ox - b_center_ox
                    })[0]
                    if (isWrap) closestHTML = _children.find(childHTML => childHTML.getBoundingClientRect().bottom >= ev.pageY)
                    if (closestHTML) {
                        let htmlRect = closestHTML.getBoundingClientRect()
                        _order = closestHTML.style.order
                        _distance = ev.pageX - (htmlRect.x + htmlRect.width / 2)
                        if (_distance < 0) _order--
                    } else _order = _children.length - 1
                }
            }
            _demo.style.order = _order
            if (_demo.parentElement !== _parent) _parent.appendChild(_demo)
        } else if (_demo) _demo.parentElement.removeChild(_demo)
    }

    const handleOnMouseMove = (ev) => {
        ev.stopPropagation()
        ev.preventDefault()
        const _layerElement = demoCardRef.current.querySelectorAll(`.layer-item[id="${methodProps.getValues("selectedId")}"]`)
        const _selectedLayer = _layerElement[0]
        if (ev.buttons === 1) {
            if (!demoCardRef.current.getAttribute("on-drag")) return demoCardRef.current.setAttribute("on-drag", "true")
            let _layerItem = demoCardRef.current.querySelector(".dragging.layer-item")
            if (_layerItem?.parentElement === demoCardRef.current || _selectedLayer?.parentElement === demoCardRef.current) return false
            if (_layerItem) handleDragToTarget(ev, true)
            else if (demoCardRef.current.getAttribute("on-resize")) onResize(ev, _layerElement)
            else _layerElement.forEach((e) => e.classList.add('dragging'))
        } else {
            if (_selectedLayer) {
                const _selectedRect = _selectedLayer.getBoundingClientRect()
                var resizeType = checkResize(ev, _selectedRect)
            }
            if (resizeType) return demoCardRef.current.setAttribute("on-resize", resizeType)
            else demoCardRef.current.removeAttribute("on-resize")
            const _layerItem = ev.target.closest('.layer-item')
            if (_layerItem && _layerItem.id !== _selectedLayer?.id) methodProps.setValue("hoverId", _layerItem.id)
        }
    }

    const onResize = (ev, _layerElement) => {
        switch (demoCardRef.current.getAttribute("on-resize")) {
            case "top":
                _layerElement.forEach((element, i) => {
                    if (!element.tmpY) element.tmpY = ev.pageY
                    if (!element.tmpH) element.tmpH = element.offsetHeight
                    element.style.height = `${element.tmpH - (ev.pageY - element.tmpY)}px`
                    if (i === 0) demoCardRef.current.style.setProperty('--selectedH', `${element.offsetHeight}px`)
                })
                break;
            case "right":
                _layerElement.forEach((element, i) => {
                    if (!element.tmpX) element.tmpX = ev.pageX
                    if (!element.tmpW) element.tmpW = element.offsetWidth
                    element.style.width = `${element.tmpW + (ev.pageX - element.tmpX)}px`
                    if (i === 0) demoCardRef.current.style.setProperty('--selectedW', `${element.offsetWidth}px`)
                })
                break;
            case "bottom":
                _layerElement.forEach((element, i) => {
                    if (!element.tmpY) element.tmpY = ev.pageY
                    if (!element.tmpH) element.tmpH = element.offsetHeight
                    element.style.height = `${element.tmpH + (ev.pageY - element.tmpY)}px`
                    if (i === 0) demoCardRef.current.style.setProperty('--selectedH', `${element.offsetHeight}px`)
                })
                break;
            case "left":
                _layerElement.forEach((element, i) => {
                    if (!element.tmpX) element.tmpX = ev.pageX
                    if (!element.tmpW) element.tmpW = element.offsetWidth
                    element.style.width = `${element.tmpW - (ev.pageX - element.tmpW)}px`
                    if (i === 0) demoCardRef.current.style.setProperty('--selectedW', `${element.offsetWidth}px`)
                })
                break;
            case "topleft":
                _layerElement.forEach((element, i) => {
                    if (!element.tmpY) element.tmpY = ev.pageY
                    if (!element.tmpH) element.tmpH = element.offsetHeight
                    element.style.height = `${element.tmpH - (ev.pageY - element.tmpY)}px`
                    if (!element.tmpX) element.tmpX = ev.pageX
                    if (!element.tmpW) element.tmpW = element.offsetWidth
                    element.style.width = `${element.tmpW - (ev.pageX - element.tmpW)}px`
                    if (i === 0) {
                        demoCardRef.current.style.setProperty('--selectedW', `${element.offsetWidth}px`)
                        demoCardRef.current.style.setProperty('--selectedH', `${element.offsetHeight}px`)
                    }
                })
                break;
            case "topright":
                _layerElement.forEach((element, i) => {
                    if (!element.tmpY) element.tmpY = ev.pageY
                    if (!element.tmpH) element.tmpH = element.offsetHeight
                    element.style.height = `${element.tmpH - (ev.pageY - element.tmpY)}px`
                    if (!element.tmpX) element.tmpX = ev.pageX
                    if (!element.tmpW) element.tmpW = element.offsetWidth
                    element.style.width = `${element.tmpW + (ev.pageX - element.tmpX)}px`
                    if (i === 0) {
                        demoCardRef.current.style.setProperty('--selectedW', `${element.offsetWidth}px`)
                        demoCardRef.current.style.setProperty('--selectedH', `${element.offsetHeight}px`)
                    }
                })
                break;
            case "bottomright":
                _layerElement.forEach((element, i) => {
                    if (!element.tmpY) element.tmpY = ev.pageY
                    if (!element.tmpH) element.tmpH = element.offsetHeight
                    element.style.height = `${element.tmpH + (ev.pageY - element.tmpY)}px`
                    if (!element.tmpX) element.tmpX = ev.pageX
                    if (!element.tmpW) element.tmpW = element.offsetWidth
                    element.style.width = `${element.tmpW + (ev.pageX - element.tmpX)}px`
                    if (i === 0) {
                        demoCardRef.current.style.setProperty('--selectedW', `${element.offsetWidth}px`)
                        demoCardRef.current.style.setProperty('--selectedH', `${element.offsetHeight}px`)
                    }
                })
                break;
            case "bottomleft":
                _layerElement.forEach((element, i) => {
                    if (!element.tmpY) element.tmpY = ev.pageY
                    if (!element.tmpH) element.tmpH = element.offsetHeight
                    element.style.height = `${element.tmpH + (ev.pageY - element.tmpY)}px`
                    if (!element.tmpX) element.tmpX = ev.pageX
                    if (!element.tmpW) element.tmpW = element.offsetWidth
                    element.style.width = `${element.tmpW - (ev.pageX - element.tmpW)}px`
                    if (i === 0) {
                        demoCardRef.current.style.setProperty('--selectedW', `${element.offsetWidth}px`)
                        demoCardRef.current.style.setProperty('--selectedH', `${element.offsetHeight}px`)
                    }
                })
                break;
            default:
                break;
        }
    }

    const checkResize = (ev, rect) => {
        if (ev.pageX >= (rect.x - 4) && ev.pageX <= (rect.x + 4)) {
            if (ev.pageY >= (rect.y - 4) && ev.pageY <= (rect.y + 4)) {
                return "topleft"
            } else if (ev.pageY >= (rect.bottom - 4) && ev.pageY <= (rect.bottom + 4)) {
                return "bottomleft"
            } else if (ev.pageY >= rect.y && ev.pageY <= rect.bottom) {
                return "left"
            } else return null
        } else if (ev.pageX >= (rect.right - 4) && ev.pageX <= (rect.right + 4)) {
            if (ev.pageY >= (rect.y - 4) && ev.pageY <= (rect.y + 4)) {
                return "topright"
            } else if (ev.pageY >= (rect.bottom - 4) && ev.pageY <= (rect.bottom + 4)) {
                return "bottomright"
            } else if (ev.pageY >= rect.y && ev.pageY <= rect.bottom) {
                return "right"
            } else return null
        } else if (ev.pageY >= (rect.y - 4) && ev.pageY <= (rect.y + 4) && ev.pageX >= rect.x && ev.pageX <= rect.right) {
            return "top"
        } else if (ev.pageY >= (rect.bottom - 4) && ev.pageY <= (rect.bottom + 4) && ev.pageX >= rect.x && ev.pageX <= rect.right) {
            return "bottom"
        } else return null
    }

    const handleOnMouseUp = (ev) => {
        ev.stopPropagation()
        ev.preventDefault()
        const resizeType = demoCardRef.current.getAttribute("on-resize")
        const _selectedId = methodProps.getValues("selectedId")
        let _layerItem = demoCardRef.current.querySelector(".dragging.layer-item")
        if (demoCardRef.current.getAttribute("on-drag")) {
            if (resizeType) {
                if (_selectedId) {
                    const _layerElement = demoCardRef.current.querySelectorAll(`.layer-item[id="${_selectedId}"]`)
                    _layerElement.forEach(e => {
                        delete e.tmpW
                        delete e.tmpH
                        delete e.tmpX
                        delete e.tmpY
                    })
                    methodProps.setValue("layers", _layers.fields.map(e => {
                        if (e.Id === _selectedId) {
                            var _tmp = { ...e, Setting: { ...e.Setting, style: { ...e.Setting.style } } }
                            if (["top", "bottom"].some(t => resizeType.includes(t)))
                                _tmp.Setting.style.height = `${Math.round(parseFloat(_layerElement[0].style.height.replace("px", "")))}px`
                            if (["left", "right"].some(t => resizeType.includes(t)))
                                _tmp.Setting.style.width = `${Math.round(parseFloat(_layerElement[0].style.width.replace("px", "")))}px`
                        }
                        return _tmp ?? e
                    }))
                }
            } else if (_layerItem) {
                let _demo = demoCardRef.current.querySelector(`div[class*="demo-component-in-container"]`)
                if (_demo) {
                    const _parent = _demo.parentElement
                    const _children = [..._parent.children].filter(e => e.id !== _layerItem.id).sort((a, b) => parseInt(window.getComputedStyle(a).order) - parseInt(window.getComputedStyle(b).order))
                    const _layerIds = []
                    _children.forEach(e => {
                        const _id = e.id?.length === 32 ? e.id : _layerItem.id
                        if (_layerIds.every(id => _id !== id)) _layerIds.push(_id)
                    })
                    const updateChildren = demoCardRef.current.querySelectorAll(`.layer-item[id="${_layerItem.id}"]`)
                    updateChildren.forEach(e => e.classList.remove('dragging'))
                    methodProps.setValue("layers", _layers.fields.map(e => {
                        let _tmp = { ...e, Setting: { ...e.Setting, style: { ...e.Setting.style } } }
                        if (e.Id === _layerItem.id) {
                            _tmp.ParentId = _parent.id
                            _tmp.Setting.style.order = _demo.style.order
                        } else if (e.ParentId === _parent.id) {
                            let _idx = _layerIds.findIndex(id => e.Id === id)
                            _tmp.Setting.style.order = _idx
                        }
                        return _tmp
                    }))
                    _demo.remove()
                }
                demoCardRef.current.classList.remove("dragging")
            }
            demoCardRef.current.removeAttribute("on-drag")
        } else {
            const _layerElement = ev.target.closest('.layer-item')
            if (_layerElement?.id) methodProps.setValue("selectedId", _layerElement?.id)
        }
        methodProps.setValue("reload", true)
    }

    const renderComponentOption = (prop) => {
        switch (prop) {
            case ComponentType.container:
                icon = <OutlineContainer size={'2.8rem'} />
                break;
            case ComponentType.text:
                var icon = <Winicon src='fill/text/text' />
                break;
            case ComponentType.button:
                icon = <OutlineButton size={'2.8rem'} />
                break;
            case ComponentType.img:
                icon = <Winicon src={"fill/development/image"} />
                break;
            case ComponentType.icon:
                icon = <Winicon src={"fill/development/icon"} />
                break;
            case ComponentType.navLink:
                icon = <Winicon src={"fill/user interface/link"} />
                break;
            default:
                return null
        }
        return <div key={prop} draggable className={`col col8 ${indexStyles['component-options']}`} style={{ alignItems: 'center' }}
            onDragStart={(ev) => { ev.dataTransfer.setData("component-type", prop) }}
            onDragEnd={() => { demoCardRef.current.querySelector(`div[class*="demo-component-in-container"]`)?.remove() }}
        >
            {icon}
            <Text className="regular1" maxLine={1}>{prop}</Text>
        </div>
    }

    const onDrop = (ev) => {
        ev.preventDefault()
        let _demo = demoCardRef.current.querySelector(`div[class*="demo-component-in-container"]`)
        if (_demo) {
            const _parent = _demo.parentElement
            const _children = [..._parent.children].sort((a, b) => parseInt(window.getComputedStyle(a).order) - parseInt(window.getComputedStyle(b).order))
            const _compType = ev.dataTransfer.getData('component-type')
            let newLayer = {
                Id: randomGID(),
                DateCreated: Date.now(),
                ParentId: _parent.id,
                Name: _compType,
                Type: _compType,
                Setting: { style: { order: _children.findIndex(el => !el.id) } }
            }
            switch (_compType) {
                case ComponentType.container:
                    newLayer.Setting.className = "row"
                    const parentStyle = window.getComputedStyle(_parent)
                    if (parentStyle.flexDirection === "row") {
                        newLayer.Setting.style.height = parentStyle.flexWrap === "wrap" ? '4.8rem' : '100%'
                        newLayer.Setting.style.width = '8rem'
                    } else {
                        newLayer.Setting.style.width = '100%'
                        newLayer.Setting.style.height = '8rem'
                    }
                    break;
                case ComponentType.img:
                    newLayer.Setting.src = "https://file-mamager.wini.vn/Upload/2024/09/yuy_81f2.jpg"
                    newLayer.Setting.style = { borderRadius: '0.8rem', width: '2.4rem', height: '2.4rem', ...newLayer.Setting.style }
                    break;
                case ComponentType.text:
                    newLayer.Setting.value = "Text"
                    break;
                case ComponentType.button:
                    newLayer.Setting.label = "Button"
                    break;
                case ComponentType.icon:
                    newLayer.Setting.src = "fill/development/icon"
                    break;
                default:
                    break;
            }
            methodProps.setValue("layers", [...methodProps.getValues("layers").map((e) => {
                const _index = _children.findIndex(el => el.id === e.Id)
                if (_index >= 0) return { ...e, Setting: { ...e.Setting, style: { ...e.Setting.style, order: _index } } }
                return e
            }), newLayer])
            _parent.removeChild(_demo)
        }
    }

    useEffect(() => {
        const _selectedId = methodProps.getValues("selectedId")
        if (_selectedId) {
            const handleOnKeyDown = (ev) => {
                if (!['input', 'textarea'].includes(ev.target.localName)) {
                    const _element = demoCardRef.current.querySelector(`:scope > div .layer-item[id="${_selectedId}"]`)
                    if (_element) {
                        let _deleteIds = []
                        switch (ev.key.toLowerCase()) {
                            case 'delete':
                                _deleteIds = [..._element.querySelectorAll('*[id]')].map(e => e.id).filter(id => id.length === 32)
                                _deleteIds.push(_selectedId)
                                if (_deleteIds.length) {
                                    methodProps.setValue("selectedId", null)
                                    methodProps.setValue("layers", methodProps.getValues("layers").filter(e => !_deleteIds.includes(e.Id)))
                                }
                                break;
                            case 'backspace':
                                _deleteIds = [..._element.querySelectorAll('*[id]')].map(e => e.id).filter(id => id.length === 32)
                                _deleteIds.push(_selectedId)
                                if (_deleteIds.length) {
                                    methodProps.setValue("selectedId", null)
                                    methodProps.setValue("layers", methodProps.getValues("layers").filter(e => !_deleteIds.includes(e.Id)))
                                }
                                break;
                            case 'c':
                                if (ev.ctrlKey) {
                                    const currentLayers = methodProps.getValues("layers")
                                    const _copyIds = [..._element.querySelectorAll('*[id]')].map(e => e.id).filter(id => id.length === 32)
                                    _copyIds.unshift(_selectedId)
                                    const copyList = []
                                    for (const e of currentLayers) {
                                        const _idx = _copyIds.findIndex(id => id === e.Id)
                                        if (_idx >= 0) copyList.push({ ...e, '__sort': _idx })
                                    }
                                    Ultis.setCookie("copy-layers-in-card", JSON.stringify(copyList), 0.0035)
                                }
                                break;
                            case 'v':
                                if (ev.ctrlKey) {
                                    let _pasteLayer = Ultis.getCookie("copy-layers-in-card")
                                    if (_pasteLayer) {
                                        const _newLayers = []
                                        _pasteLayer = JSON.parse(_pasteLayer).sort((a, b) => a['__sort'] - b['__sort'])
                                        _pasteLayer.forEach((e, i) => {
                                            let _tmp = { ...e }
                                            delete _tmp['__sort']
                                            _tmp.id = _tmp.Id
                                            _tmp.Id = randomGID()
                                            _tmp.DateCreated = Date.now()
                                            if (i === 0 && _tmp.Id !== _selectedId) _tmp.ParentId = _selectedId
                                            else if (i) _tmp.ParentId = _newLayers.find(el => el.id === _tmp.ParentId)?.Id
                                            _newLayers.push(_tmp)
                                        });
                                        methodProps.setValue("layers", [...methodProps.getValues("layers"), ..._newLayers.map(e => {
                                            let _tmp = { ...e }
                                            delete _tmp.id
                                            return _tmp
                                        })])
                                        methodProps.setValue("selectedId", _newLayers[0].Id)
                                        methodProps.setValue("reload", true)
                                    }
                                }
                                break;
                            default:
                                break;
                        }
                    }
                }
            }
            window.onkeydown = handleOnKeyDown
            return () => { window.onkeydown = undefined }
        } else {
            demoCardRef.current.removeAttribute("selected-name")
        }
    }, [methodProps.watch("selectedId")])

    useEffect(() => {
        if (canvasRef.current && methodProps.getValues("layers")?.length) {
            canvasRef.current.width = canvasRef.current.parentElement.offsetWidth - 8
            canvasRef.current.height = canvasRef.current.parentElement.offsetHeight - 8
            let ctx = canvasRef.current.getContext("2d");
            ctx.clearRect(0, 0, canvasRef.current.offsetWidth, canvasRef.current.offsetHeight)
            const canvasRect = canvasRef.current.getBoundingClientRect()
            ctx.font = "1.4rem Inter";
            let _selectedItem;
            let _hoverItem;
            const selectedId = methodProps.getValues("selectedId")
            const hoverId = methodProps.getValues("hoverId")
            if (selectedId || hoverId) {
                for (const e of methodProps.getValues("layers")) {
                    if (e.Id === selectedId) _selectedItem = e
                    if (e.Id === hoverId && selectedId !== hoverId) _hoverItem = e
                }
            }
            if (_selectedItem) {
                const _layerRect = demoCardRef.current.querySelector(`.layer-item[id="${selectedId}"]`)?.getBoundingClientRect()
                if (_layerRect) {
                    ctx.strokeStyle = '#349c90'
                    ctx.lineWidth = 2
                    ctx.strokeRect(_layerRect.x - canvasRect.x, _layerRect.y - canvasRect.y, _layerRect.width, _layerRect.height)
                    ctx.fillStyle = '#349c90'
                    const textWH = ctx.measureText(_selectedItem.Name)
                    ctx.fillRect(_layerRect.x - canvasRect.x, _layerRect.y - canvasRect.y - 18, textWH.width + 4, 17)
                    ctx.fillStyle = '#fff'
                    ctx.fillText(_selectedItem.Name, _layerRect.x - canvasRect.x + 2, _layerRect.y - canvasRect.y - 4)
                }
            }
            if (_hoverItem) {
                const _layerRect = demoCardRef.current.querySelector(`.layer-item[id="${hoverId}"]`)?.getBoundingClientRect()
                if (_layerRect) {
                    ctx.lineWidth = 2
                    ctx.strokeStyle = '#9c6334'
                    ctx.strokeRect(_layerRect.x - canvasRect.x, _layerRect.y - canvasRect.y, _layerRect.width, _layerRect.height)
                    ctx.fillStyle = '#9c6334'
                    const textWH = ctx.measureText(_hoverItem.Name)
                    ctx.fillRect(_layerRect.x - canvasRect.x, _layerRect.y - canvasRect.y - 18, textWH.width + 4, 17)
                    ctx.fillStyle = '#fff'
                    ctx.fillText(_hoverItem.Name, _layerRect.x - canvasRect.x + 2, _layerRect.y - canvasRect.y - 4)
                }
            }
        }
    }, [canvasRef, methodProps.watch()])

    return <div className={`col ${indexStyles['setting-card-item-container']}`}>
        <Dialog ref={dialogRef} />
        <div className='row popup-header' style={{ padding: '1.2rem 1.2rem 1.2rem 2.4rem' }}>
            <Text className='heading-7' style={{ flex: 1 }}>{data.cardItem?.Name ?? "New card"}</Text>
            <Winicon src={"fill/user interface/e-remove"} size={'2rem'} style={{ padding: "0.4rem" }} onClick={() => { closePopup(ref) }} />
        </div>
        <div className='row' style={{ alignItems: 'start', height: "100%", flex: 1 }}>
            <div className={`col`} style={{ width: "31.4rem", borderRight: "var(--neutral-bolder-border)", height: "100%", padding: "0.4rem 0" }}>
                <Text className='heading-8' style={{ padding: "0.4rem 1.2rem" }}>Element</Text>
                <div className={`row ${indexStyles['component-options-group']}`}>
                    {componentType.map((prop) => renderComponentOption(prop))}
                </div>
                <div className={`col ${indexStyles['layer-list-container']}`}>
                    {_layers.fields.filter(e => !e.ParentId).map(e => {
                        return <LayerTile
                            key={e.Id}
                            item={e}
                            layers={_layers.fields}
                            methods={methodProps}
                        />
                    })}
                </div>
            </div>
            <div className={`col ${indexStyles['overview-container']}`} draggable={false}>
                <Text className='heading-8'>Overview</Text>
                <div ref={demoCardRef} className='col' onDragOver={handleDragToTarget} onDrop={onDrop} onMouseMove={handleOnMouseMove} onMouseUp={handleOnMouseUp}>
                    <RenderCard layers={_layers.fields} dataModel={data.dataModel ?? { Id: "demo" }} cardItem={{ ...(methods.watch()), Props: methodProps.watch() ?? {} }} />
                    <canvas ref={canvasRef} className={styles['handle-demo-card']} />
                </div>
            </div>
            <CardInforContainer methods={methods} methodsProps={methodProps} />
        </div>
        <div className="row" style={{ padding: "1.6rem 2.4rem", borderTop: "var(--neutral-bolder-border)", justifyContent: "end", gap: "0.8rem" }}>
            <Button
                label="Cancel"
                style={{ width: "7.2rem", borderRadius: '0.4rem', backgroundColor: "transparent", color: "var(--neutral-text-subtitle-color)" }}
                onClick={() => {
                    showDialog({
                        ref: dialogRef,
                        alignment: DialogAlignment.center,
                        status: ComponentStatus.WARNING,
                        title: 'Confirm cancel',
                        content: "Every changes will be unsaved",
                        submitTitle: "Submit",
                        onSubmit: () => { closePopup(ref) }
                    })
                }}
            />
            <Button
                label="Save"
                className={methods.watch("Name")?.trim()?.length ? "button-primary" : "button-disabled"}
                style={{ width: "5.8rem", borderRadius: '0.4rem' }}
                onClick={methods.handleSubmit(_onSubmit)}
            />
        </div>
    </div>
})