import { useDispatch } from "react-redux"
import { PageActions } from "../reducer"
import { BoxProperties } from "./dimension/dimension"
import { AppearanceProperties } from "./appearance/appearance"
import { BackgroundProperties } from "./background/background"

export default function SettingChart({ selected }) {
    const dispatch = useDispatch()
    const _iframe = document.getElementById("iframe-render-page-view")

    const props = {
        docBody: _iframe.contentWindow.document.body,
        selected: selected,
        onChange: (newValue) => {
            if (newValue) PageActions.updateLayerAll(dispatch, [newValue])
        }
    }

    return selected && <div className='col'>
        {selected.ParentId && <BoxProperties {...props} />}
        <AppearanceProperties {...props} />
        <BackgroundProperties {...props} />
    </div>
}