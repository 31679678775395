import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CellAlignItems, Checkbox, ComponentStatus, Dialog, DialogAlignment, Pagination, Popup, showDialog, showPopup, Text, TextField, ToastMessage, Winicon } from "wini-web-components";
import { TableController } from "./controller";
import PopupAddEditTable from "./keys/createTb";
import { Ultis } from "../../../Utils";
import PopupSettingAssets from './store';
import { useForm } from 'react-hook-form';
import PopupSearchIn from '../../module/manager/seachIn';

const searchkeyVl = "keysearch";
export default function ListTable() {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const _tbController = new TableController('table')
    const ref = useRef()
    const dialogRef = useRef()
    const [tables, setTables] = useState({ data: [], totalCount: undefined })
    const [pageDetails, setPageDetails] = useState({ page: 1, size: 10 })
    const methods = useForm({ shouldFocusError: false })
    const navigate = useNavigate()

    const showPopupAddEdit = (item) => {
        showPopup({
            ref: ref,
            hideButtonClose: true,
            style: { width: "68%", height: "100%" },
            body: <PopupAddEditTable
                ref={ref}
                item={item}
                onSuccess={getTables}
            />
        })
    }

    const showPopupSettingAssets = (item) => {
        showPopup({
            ref: ref,
            hideButtonClose: true,
            style: { width: "100%", height: "100%", maxWidth: "100%", maxHeight: "100%", borderRadius: 0 },
            body: <PopupSettingAssets
                ref={ref}
                item={item}
            />
        })
    }

    const getTables = async (page, size) => {
        let _query = []
        for (const [key, value] of searchParams) {
            if (key == searchkeyVl) methods.setValue('search-key-value', value)
            if (key == "searchin") {
                const listKeySearch = value.split(',')
                if (listKeySearch.length > 0) {
                    _query.push(`${listKeySearch.map(element => {
                        if (element === "Name") return `(@${element}:{*${searchParams.get(searchkeyVl)}*})`
                        else return `(@${element}:*${searchParams.get(searchkeyVl)}*)`
                    }).join(" | ")}`)
                }
            }
        }

        const res = await _tbController.getListSimple({
            page: page ?? pageDetails.page, size: size ?? pageDetails.size,
            query: _query.length ? _query.join(" ") : "*",
        })
        if (res.code === 200) {
            setTables({ data: res.data, totalCount: res.totalCount })
        } else ToastMessage.errors(res.message)
    }

    const searchKeyAction = async (ev) => {
        ev.target.blur()
        if (ev.target.value.length == 0) {
            searchParams.delete(searchkeyVl)
        } else {
            if (searchParams.has(searchkeyVl))
                searchParams.set(searchkeyVl, ev.target.value.trim());
            else searchParams.append(searchkeyVl, ev.target.value.trim());
        }
        if (!searchParams.has("searchin")) searchParams.set("searchin", "Name")
        navigate('?' + searchParams.toString());

        getTables(pageDetails.page, pageDetails.size)
    }

    const showSearchBox = (event) => {
        var listFilterSearch = [{ Id: "0", Name: "Name", Form: { Label: "Name", isChecked: false } }, { Id: "1", Name: "Description", Form: { Label: "Description", isChecked: false } }]

        for (const [key, value] of searchParams) {
            if (key === "searchin") {
                const valueList = value.split(',')
                if (valueList) {
                    listFilterSearch.forEach(element => {
                        if (valueList.find((e) => e == element.Form.Label)) {
                            element.isChecked = true
                        }
                    });
                }
            }
        }
        const searchInput = event.target.closest('div:has(>input)')?.getBoundingClientRect()
        showPopup({
            ref: ref,
            clickOverlayClosePopup: true,
            style: { position: 'absolute', width: searchInput?.width, top: searchInput?.bottom, left: searchInput?.x, bottom: 0, maxHeight: '100%' },
            body: <PopupSearchIn ref={ref} cols={listFilterSearch} />
        })
    }

    useEffect(() => {
        if (Ultis.getCookie("pid")) getTables()
    }, [])

    return <div className="col">
        <Popup ref={ref} />
        <Dialog ref={dialogRef} />
        <div className='row' style={{ padding: '2rem 3.2rem', gap: '0.8rem', borderBottom: 'var(--neutral-bolder-border)' }}>
            <button type='button' className='row' style={{ gap: '0.8rem' }}>
                <Winicon src="outline/files/folder" size={'2.2rem'} />
                <Text className='heading-6'>Data table</Text>
                <Winicon src={"fill/arrows/down-arrow"} size={"1.2rem"} />
            </button>
        </div>
        <div className="col table-view" style={{ padding: '0 3.2rem 1.2rem' }}>
            <div className='row' style={{ padding: '1.2rem 0', gap: "0.8rem" }}>
                <button type='button' className='row' style={{ padding: '0.4rem 1.2rem', border: "var(--neutral-bolder-border)", gap: '0.8rem', borderRadius: "0.8rem" }} onClick={() => { showPopupAddEdit() }}>
                    <Winicon src={"outline/user interface/e-add"} size={"1.4rem"} />
                    <Text className='button-text-3' style={{ color: "var(--neutral-text-subtitle-color)" }}>Add table</Text>
                </button>
                <div style={{ flex: 1 }} />
                <TextField
                    placeholder='Search'
                    prefix={<Winicon src={"fill/development/zoom"} size={"1.4rem"} />}
                    suffix={<button type='button' onClick={(event) => showSearchBox(event)}>
                        <Winicon src={"fill/arrows/down-arrow"} size={"1.2rem"} />
                    </button>}
                    name='txtSearch'
                    onComplete={searchKeyAction}
                    defaultValue={`${searchParams.get(searchkeyVl) ?? ''}`}
                    register={methods.register('search-key-value')}
                    style={{ padding: '0 1.6rem', borderRadius: '0.8rem', height: '3.2rem', width: '26.8rem', gap: '1.2rem' }}
                />
                <button type='button' className='row button-neutral' style={{ borderColor: "transparent" }} onClick={() => { }}>
                    <Winicon src='outline/user interface/setup-preferences' size={'1.6rem'} />
                    <Text className='button-text-3'>Filter</Text>
                </button>
                <div className='row divider' style={{ height: '1.2rem', margin: 0 }} />
                <button type='button' className='row button-neutral' style={{ borderColor: "transparent" }} onClick={() => { }}>
                    <Winicon src='fill/arrows/enlarge' size={'1.6rem'} />
                    <Text className='button-text-3'>Sort</Text>
                </button>
            </div>
            <div className="col table">
                <div className="row header">
                    <div className='row cell'>
                        <Checkbox size={'1.6rem'} value={false} onChange={(v) => { }} />
                    </div>
                    <div className='row header-cell' style={{ flex: 1 }}>
                        <Text className='heading-9'>Name</Text>
                    </div>
                    <div className='row header-cell' style={{ width: "24rem" }}>
                        <Text className='heading-9'>Created date</Text>
                    </div>
                    <div className='row header-cell' style={{ width: "40rem" }}>
                        <Text className='heading-9'>Description</Text>
                    </div>
                    <div className='row header-cell' style={{ width: "14rem" }}>
                        <Text className='heading-9' style={{ flex: 1, textAlign: CellAlignItems.center }}>Action</Text>
                    </div>
                </div>
                {
                    tables.data.map((item, index) => <div key={`${item.Id}`} className='row table-row'>
                        <div className='row cell'>
                            <Checkbox size={'1.6rem'} value={false} onChange={(v) => { }} />
                            <Text className='body-3'>{index + pageDetails.size * (pageDetails.page - 1) + 1}</Text>
                        </div>
                        <div className='row cell' style={{ flex: 1 }}>
                            <Text className='body-3' style={{ flex: 1, textOverflow: "ellipsis" }} maxLine={1}>{item.Name}</Text>
                        </div>
                        <div className='row cell' style={{ width: "24rem" }}>
                            <Text className='body-3'>{item.DateCreated ? Ultis.datetoString(new Date(item.DateCreated), "dd/mm/yyyy hh:mm") : "-"}</Text>
                        </div>
                        <div className='row cell' style={{ width: "40rem" }}>
                            <Text className='body-3'>{item.Description ?? "-"}</Text>
                        </div>
                        <div className='row cell' style={{ gap: "0.8rem", justifyContent: 'center', width: "14rem" }} >
                            <Winicon src='outline/user interface/key' size={'1.8rem'} style={{ padding: "0.3rem" }} onClick={() => { showPopupAddEdit(item) }} />
                            <Winicon src='outline/business/coins' size={'1.8rem'} style={{ padding: "0.3rem" }} onClick={() => { showPopupSettingAssets(item) }} />
                            <Winicon
                                src='outline/user interface/trash-can'
                                size={'1.8rem'}
                                style={{ padding: "0.3rem" }}
                                onClick={(ev) => {
                                    showDialog({
                                        ref: dialogRef,
                                        alignment: DialogAlignment.center,
                                        status: ComponentStatus.WARNING,
                                        title: 'Are you sure to delete ' + item.Name + ' table?',
                                        content: `You will not be able to recover data or setting of ${item.Name} table!`,
                                        submitTitle: "Delete",
                                        onSubmit: async () => {
                                            const res = await _tbController.delete([item.Id])
                                            if (res.code === 200) {
                                                ToastMessage.success(`Delete table ${item.Name} successfully!`)
                                                getTables()
                                            } else ToastMessage.errors(res.message)
                                        }
                                    })
                                }}
                            />
                        </div>
                    </div>)
                }
            </div>
            <div style={{ padding: '1.2rem 2.4rem' }}>
                <Pagination
                    /// Size
                    currentPage={pageDetails.page}
                    /// pageSize
                    itemPerPage={pageDetails.size}
                    // data.total
                    totalItem={tables?.totalCount ?? 0}
                    /// action
                    onChangePage={(page, size) => {
                        if (pageDetails.page !== page || pageDetails.size !== size) {
                            setPageDetails({ page: page, size: size });
                            getTables(page, size)
                        }
                    }}
                />
            </div>
        </div>
    </div>
}